import {
  OrganizationSubjectResponse,
  ReviewResponse,
  ReviewTutorResponse,
  TutorMeetingResponse,
  TutorTestScoreResponse,
} from "client/openapi";
import { Helmet } from "react-helmet";
import { ProfileBody } from "./ProfileBody";
import { ProfileHeader } from "./ProfileHeader";

export function Profile({
  pageTitle,
  tags,
  greeting,
  bio,
  imageUrl,
  initials,
  waveType,
  id,
  currentStatus,
  subjects,
  rate,
  testScores,
  reviews,
  tutors,
}: {
  pageTitle: string;

  // profile header props
  tags?: any[];
  greeting: string;
  bio: string;
  imageUrl: string | null;
  initials: string;
  waveType: number;

  // profile body props
  id?: number;
  currentStatus?: string | null;
  subjects: OrganizationSubjectResponse[];
  rate: string;
  testScores?: TutorTestScoreResponse[];
  reviews: ReviewResponse[] | ReviewTutorResponse[];
  tutors?: TutorMeetingResponse[];
}) {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <ProfileHeader
        tags={tags}
        greeting={greeting}
        bio={bio}
        imageUrl={imageUrl}
        initials={initials}
        waveType={waveType}
      />
      <ProfileBody
        id={id}
        currentStatus={currentStatus}
        subjects={subjects}
        rate={rate}
        testScores={testScores}
        reviews={reviews}
        tutors={tutors}
      />
    </>
  );
}
