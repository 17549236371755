import {
  AvailabilityMessage,
  SessionMeta,
  TutorMeetingResponse,
  MeetingResponse,
  MeetingsService,
} from "client/openapi";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import { AvailabilitiesService } from "client/openapi";
import { getBlockIndex } from "components/AvailabilitySidebar.tsx";
import { CalendarIcon } from "@radix-ui/react-icons";
import "react-day-picker/dist/style.css";
import "./index.css";
import { defaultDuration } from "components/MeetingDialog";
import { parseRecurrencePattern } from "util/parseRecurrencePattern";
import {
  getStartOfMonth,
  getStartOfNextMonth,
  getIndexOfDayInMonth,
  formatDateToMMDDYYYY,
  isPast,
  getDays,
  calculateEndTime,
} from "util/time";

const timezone = moment.tz.guess(true);

export default function AvailabilityDayPicker({
  id,
  event,
  setValue,
  setIsAvailabilitySidebarOpen,
  tutors,
  meetingDetails,
  required,
  classes,
}: {
  id: string;
  event: MeetingResponse | undefined;
  setValue: (string) => void;
  setIsAvailabilitySidebarOpen: (boolean) => void;
  tutors: TutorMeetingResponse[];
  meetingDetails: SessionMeta;
  required?: boolean;
  classes?: string;
}) {
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date(meetingDetails.start)
  );
  const [currentTutors, setCurrentTutors] =
    useState<TutorMeetingResponse[]>(tutors);
  const [previousDuration, setPreviousDuration] =
    useState<number>(defaultDuration);
  const [duration, setDuration] = useState<number>(meetingDetails.duration);
  const [availabilities, setAvailabilities] = useState<
    Array<Array<Array<AvailabilityMessage>>>
  >(() =>
    Array.from({ length: 31 }, () => Array.from({ length: 96 }, () => []))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [displayedMonth, setDisplayedMonth] = useState<Date>(selectedDate);

  const fetchAvailabilities = async (start, end) => {
    try {
      setIsLoading(true);
      const newAvailabilities: Array<Array<Array<AvailabilityMessage>>> =
        Array.from({ length: 31 }, () => Array.from({ length: 96 }, () => []));

      for (const tutor of currentTutors) {
        try {
          const tutorsAvailabilities =
            await AvailabilitiesService.getAvailabilitiesByTutor({
              tutorId: tutor.id,
              start: start,
              until: end,
              duration: 15,
            });

          tutorsAvailabilities.forEach((availability: AvailabilityMessage) => {
            const dayIndex = getIndexOfDayInMonth(availability.start);
            const timeIndex = getBlockIndex(availability.start, 15);

            if (!newAvailabilities[dayIndex][timeIndex]) {
              newAvailabilities[dayIndex][timeIndex] = [];
            }

            newAvailabilities[dayIndex][timeIndex].push(availability);
          });
        } catch (error) {
          console.error(
            `${error}: Error fetching availability for tutor ${tutor.id}`
          );
        }
      }

      if (event) {
        // if (event.is_recurring) {
        //   const groupID = event.session_group_id;
        //   const tutors = event.tutors;
        //   await MeetingsService.getSessionsBySessionGroupId({
        //     sessionGroupId: groupID,
        //   })
        //     .then((sessions) => {
        //       sessions.forEach((session) => {
        //         const recurrence_pattern = session.recurrence_pattern;
        //         const interval = parseRecurrencePattern(recurrence_pattern);
        //         const sessionStart = session.start;
        //         const sessionUntil = session.until;
        //         if (sessionUntil) {
        //           const days = getDays(sessionStart, sessionUntil, interval);
        //           days.forEach((day) => {
        //             if (
        //               new Date(day).getMonth() === new Date(start).getMonth()
        //             ) {
        //               const dayIndex = new Date(day).getDate() - 1;
        //               const startIndex = getBlockIndex(day, 15);
        //               const dayUntil = calculateEndTime(
        //                 day,
        //                 event.duration,
        //                 timezone
        //               );
        //               const untilIndex = getBlockIndex(dayUntil, 15);
        //               let start = day;
        //               for (let i = startIndex; i <= untilIndex; i++) {
        //                 const availability: AvailabilityMessage = {
        //                   start: start,
        //                   duration: 15,
        //                 };
        //                 tutors.forEach((tutor) => {
        //                   newAvailabilities[dayIndex][i].push(availability);
        //                 });
        //                 start = calculateEndTime(start, 15, timezone);
        //               }
        //             }
        //           });
        //         }
        //       });
        //     })
        //     .catch((error) => {
        //       console.error(
        //         `${error}: Error fetching sessions for group id ${groupID}`
        //       );
        //     });
        // } else {
        const dayIndex = new Date(event.start).getDate() - 1;
        const startIndex = getBlockIndex(event.start, 15);
        const dayUntil = calculateEndTime(
          event.start,
          event.duration,
          timezone
        );
        const untilIndex = getBlockIndex(dayUntil, 15);
        let start = event.start;
        for (let i = startIndex; i <= untilIndex; i++) {
          const availability: AvailabilityMessage = {
            start: start,
            duration: 15,
          };
          tutors.forEach((tutor) => {
            newAvailabilities[dayIndex][i].push(availability);
          });
        }
      }
      // }
      return newAvailabilities;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function allOverlap(availabilities: AvailabilityMessage[][]): boolean {
    const numBlocks = duration / 15;
    const isEveryBlockOverlap = (
      subsequence: AvailabilityMessage[][]
    ): boolean => {
      return subsequence.every(
        (block: AvailabilityMessage[]) => block.length === currentTutors.length
      );
    };
    for (let i = 0; i < availabilities.length - numBlocks; i++) {
      const subsequence = availabilities.slice(i, i + numBlocks + 1);
      if (isEveryBlockOverlap(subsequence)) {
        return true;
      }
    }
    return false;
  }

  function exactOverlap(availabilities: AvailabilityMessage[][]): boolean {
    const startIndex = getBlockIndex(meetingDetails.start, 15);
    const endIndex = getBlockIndex(
      calculateEndTime(meetingDetails.start, duration, timezone),
      15
    );
    const slice = availabilities.slice(startIndex, endIndex + 1);

    return slice.every(
      (block: AvailabilityMessage[]) => block.length === currentTutors.length
    );
  }

  function noOverlap(availabilities: AvailabilityMessage[][]): boolean {
    const numBlocks = duration / 15;
    const isEveryBlockEmpty = (sequence: AvailabilityMessage[][]): boolean => {
      return sequence.some(
        (block: AvailabilityMessage[]) => block.length === 0
      );
    };
    for (let i = 0; i <= availabilities.length - numBlocks; i++) {
      const sequence = availabilities.slice(i, i + numBlocks);
      if (!isEveryBlockEmpty(sequence)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    setSelectedDate(new Date(meetingDetails.start));
    setDuration(meetingDetails.duration);
    setCurrentTutors(tutors);

    const start = getStartOfMonth(displayedMonth, timezone);
    const end = getStartOfNextMonth(displayedMonth, timezone);

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const newAvailabilities = await fetchAvailabilities(start, end);
        if (newAvailabilities) setAvailabilities(newAvailabilities);
      } catch (error) {
        console.error("Error fetching availabilities:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [displayedMonth, tutors, meetingDetails.duration, isDayPickerOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const calendarContainer = document.getElementById(id);
      if (calendarContainer && !calendarContainer.contains(event.target)) {
        setIsDayPickerOpen(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [id, setIsDayPickerOpen]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValue(date);
    setDisplayedMonth(date);
    setIsAvailabilitySidebarOpen(true);
    setIsDayPickerOpen(false);
  };

  const handleMonthChange = (newMonth) => {
    setDisplayedMonth(newMonth);
  };

  useEffect(() => {
    const handleDurationChange = () => {
      setIsDayPickerOpen(true);
      setPreviousDuration(duration);
    };

    handleDurationChange();
  }, [duration, previousDuration, setIsDayPickerOpen]);

  return (
    <div
      className={`cursor-pointer relative ${classes}`}
      id={id}
      onClick={() => {
        setIsDayPickerOpen(true);
      }}
    >
      <div className="grid grid-cols-[1fr,auto] gap-5 items-center">
        <div>{formatDateToMMDDYYYY(selectedDate)}</div>
        <CalendarIcon />
      </div>
      {isDayPickerOpen && (
        <DayPicker
          id={id}
          mode="single"
          className="absolute availability-daypicker"
          selected={selectedDate}
          onSelect={handleDateChange}
          required={required}
          modifiers={
            isLoading || tutors.length === 0
              ? {}
              : {
                  past: (day) => isPast(day),
                  noOverlap: (day) =>
                    noOverlap(availabilities[day.getDate() - 1]),
                  allOverlap: (day) =>
                    allOverlap(availabilities[day.getDate() - 1]),
                  someOverlap: (day) =>
                    !noOverlap(availabilities[day.getDate() - 1]) &&
                    !allOverlap(availabilities[day.getDate() - 1]),
                  exactOverlap: (day) =>
                    exactOverlap(availabilities[day.getDate() - 1]),
                }
          }
          modifiersClassNames={{
            past: `availability-daypicker-gray`,
            exactOverlap: `availability-daypicker-darkgreen`,
            someOverlap: `availability-daypicker-yellow`,
            noOverlap: `availability-daypicker-red`,
            allOverlap: `availability-daypicker-green`,
            selected: `availability-daypicker-selected`,
            today: `availability-daypicker-today`,
          }}
          captionLayout="dropdown"
          month={displayedMonth}
          onMonthChange={handleMonthChange}
          footer={
            tutors.length === 0
              ? "Add tutors to see their availabilities."
              : "Days all tutors are available for " + duration + " mins. "
          }
          style={{ left: "-1rem" }}
        />
      )}
    </div>
  );
}
