export function parseRecurrencePattern(
  recurrencePattern: string | undefined
): number {
  if (!recurrencePattern) {
    return 0;
  }
  const regex = /INTERVAL=(\d+);/;
  const match = regex.exec(recurrencePattern ?? "");
  if (match) {
    const intervalValue = parseInt(match[1], 10);
    return intervalValue * 7;
  } else {
    return 7;
  }
}

export function parseRecurrencePatternForUntil(
  recurrencePattern: string | undefined
): string {
  if (!recurrencePattern) {
    return "";
  }
  const regex = /UNTIL=(\d{8})T/;
  const match = regex.exec(recurrencePattern ?? "");
  if (match) {
    const untilValue = match[1];
    return untilValue;
  } else {
    return "";
  }
}
