export function stringifyRateRange(range: [number, number]): string {
  range.sort();

  if (range[0] === 0 && range[1] === 0) {
    return "All tutoring sessions are free!";
  }

  let res: string = "";
  if (range[0] === 0) {
    res += "Free to ";
  } else {
    res += `$${range[0]}/hour to `;
  }

  res += `$${range[1]}/hour`;
  return res;
}
