import AdministrativeInfo from "./AdministrativeInfo";
import MeetingHistory from "../MeetingHistory";
import { StudentResponse, MeetingResponse } from "client/openapi";

export default function AdditionalStudentInfoTab({
  exportingData,
  setExportingData,
  student,
  organization_id,
  exportingMeetings,
  setExportingMeetings,
  setStatus,
  setError,
  meetings,
}: {
  exportingData: boolean;
  setExportingData: (boolean) => void;
  student: StudentResponse;
  organization_id: number;
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
  meetings: MeetingResponse[];
}) {
  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-2 h-full">
      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <AdministrativeInfo
          exportingData={exportingData}
          setExportingData={setExportingData}
          student={student}
          organization_id={organization_id}
          exportingMeetings={exportingMeetings}
          setStatus={setStatus}
          setError={setError}
        />
      </div>

      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <MeetingHistory
          meetings={meetings}
          exportingMeetings={exportingMeetings}
          setExportingMeetings={setExportingMeetings}
          student={student}
          exportingData={exportingData}
          setStatus={setStatus}
          setError={setError}
          organization_id={organization_id}
        />
      </div>
    </div>
  );
}
