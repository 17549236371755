import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from "@stripe/connect-js";
import { OrganizationsService } from "client/openapi";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function StripeKYC() {
  const navigate = useNavigate();
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const [stripeConnectInstance, setStripeConnectInstance] =
    useState<StripeConnectInstance>();

  useEffect(() => {
    if (currently_selected_organization) {
      setStripeConnectInstance(() => {
        const fetchClientSecret = async () => {
          try {
            // Fetch the AccountSession client secret
            const response = await OrganizationsService.getStripeAccountSession(
              {
                currentOrg: currently_selected_organization as number,
              }
            );
            return response.client_secret;
          } catch (error) {
            console.log("An error occurred: ", error);
            throw error;
          }
        };

        return loadConnectAndInitialize({
          publishableKey: process.env
            .REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
          fetchClientSecret: fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#625afa",
            },
          },
        });
      });
    }
  }, [currently_selected_organization]);

  return stripeConnectInstance ? (
    <div className="min-h-[950px] mb-6">
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectAccountOnboarding
          onExit={() => {
            console.log("The account has exited onboarding");
            navigate("/dashboard");
          }}
        />
      </ConnectComponentsProvider>
    </div>
  ) : (
    <div className="text-center">Can not configure Stripe at this time!</div>
  );
}

export default StripeKYC;
