import MeetingsStats from "components/StudentAttendance";
import PerformanceChart from "components/StudentPerformanceChart";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  StudentResponse,
  ChartSubscriptionResponse,
  MeetingStats,
  ParentResponse,
} from "client/openapi";
import { Dialog, DialogTrigger } from "components/Dialog";
import AddChartDialog from "components/StudentPerformanceChart/AddChartDialog";
import DeleteChartDialog from "components/StudentPerformanceChart/DeleteChartDialog";

export default function MetricTab({
  parent,
  meetingStats,
}: {
  parent: ParentResponse;
  meetingStats: MeetingStats;
}) {
  return (
    <div>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-8 mx-5 md:mx-0">
        <div className="h-80">
          <MeetingsStats parent={parent} meetings={meetingStats} />
        </div>
      </div>
    </div>
  );
}
