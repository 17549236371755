/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeetingResponse } from "../models/MeetingResponse";
import type { SessionApprovalCreate } from "../models/SessionApprovalCreate";
import type { SessionApprovalNotSetResponse } from "../models/SessionApprovalNotSetResponse";
import type { SessionApprovalResponse } from "../models/SessionApprovalResponse";
import type { SessionApprovalUpdate } from "../models/SessionApprovalUpdate";
import type { SessionCreate } from "../models/SessionCreate";
import type { SessionEditCreate } from "../models/SessionEditCreate";
import type { SessionResponse } from "../models/SessionResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class MeetingsService {
  /**
   * Get Meetings By Tutor
   * Get meetings for a tutor in a specified timeframe from the database.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByTutor({
    tutorId,
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    tutorId: number;
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings For All Tutors For Account
   * Get meetings in a specified timeframe from the database for all tutors tied to an account.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsForAllTutorsForAccount({
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/account/tutors",
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Student
   * Get meetings for a student in a specified timeframe from the database.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByStudent({
    studentId,
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    studentId: number;
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings For All Students For Account
   * Get meetings in a specified timeframe from the database for all students tied to an account.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsForAllStudentsForAccount({
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/account/students",
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Parent
   * Get meetings for a parent's students in a specified timeframe from the database.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByParent({
    parentId,
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    parentId: number;
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings For All Parents For Account
   * Get meetings in a specified timeframe from the database for all
   * parents (specifically their students) tied to an account.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsForAllParentsForAccount({
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/account/parents",
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Org
   * Get meetings for an org in a specified timeframe from the database.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByOrg({
    orgId,
    start,
    until,
    skip,
    limit = 100,
    tz = "UTC",
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
    skip?: number;
    limit?: number;
    tz?: string;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Session
   * Create a session and return the meetings.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static createSession({
    requestBody,
  }: {
    requestBody: SessionCreate;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/meetings",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Edit Session
   * Create a session_edit and return the resulting meetings from the database.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static editSession({
    sessionId,
    requestBody,
  }: {
    sessionId: number;
    requestBody: SessionEditCreate;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/meetings/edits/{session_id}",
      path: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Session
   * Return a session object from the DB.
   * This is not to be confused by a get meetings request.
   * @returns SessionResponse Successful Response
   * @throws ApiError
   */
  public static getSession({
    sessionId,
    includeInsertedSessions = false,
  }: {
    sessionId: number;
    includeInsertedSessions?: boolean;
  }): CancelablePromise<Array<SessionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/sessions/{session_id}",
      path: {
        session_id: sessionId,
      },
      query: {
        include_inserted_sessions: includeInsertedSessions,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Blocks In Session
   * Return all the blocks in a session.
   * @returns number Successful Response
   * @throws ApiError
   */
  public static getBlocksInSession({
    sessionId,
  }: {
    sessionId: number;
  }): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/sessions/{session_id}/blocks",
      path: {
        session_id: sessionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Session
   * Return a list of meetings in a given session.
   * @returns MeetingResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsBySession({
    sessionId,
    start,
    until,
    tz,
    requestBody,
  }: {
    sessionId: number;
    start?: string | null;
    until?: string | null;
    tz?: string | null;
    requestBody?: Array<number> | null;
  }): CancelablePromise<Array<MeetingResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/meetings/{session_id}",
      path: {
        session_id: sessionId,
      },
      query: {
        start: start,
        until: until,
        tz: tz,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Approve Meeting
   * Approve a meeting, if the session is recurring, a meeting start timne
   * must be provided.
   * @returns SessionApprovalResponse Successful Response
   * @throws ApiError
   */
  public static approveMeeting({
    requestBody,
  }: {
    requestBody: SessionApprovalCreate;
  }): CancelablePromise<SessionApprovalResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/meetings/approve",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meeting Approval
   * Get a meeting's approval.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMeetingApproval({
    sessionId,
    startTime,
  }: {
    sessionId: number;
    startTime: string | null;
  }): CancelablePromise<
    SessionApprovalResponse | SessionApprovalNotSetResponse
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/{session_id}/approve",
      path: {
        session_id: sessionId,
      },
      query: {
        start_time: startTime,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Meeting Approval
   * Update a meeting's approval status.
   * @returns SessionApprovalResponse Successful Response
   * @throws ApiError
   */
  public static updateMeetingApproval({
    sessionId,
    requestBody,
  }: {
    sessionId: number;
    requestBody: SessionApprovalUpdate;
  }): CancelablePromise<SessionApprovalResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/meetings/{session_id}/approve",
      path: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meeting Approvals By Time
   * Get all meeting approval objects after a given date.
   * @returns SessionApprovalResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingApprovalsByTime({
    start,
    orgId,
  }: {
    start: string;
    orgId: number;
  }): CancelablePromise<Array<SessionApprovalResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/approvals",
      query: {
        start: start,
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
