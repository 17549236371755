import { useAuth0 } from "@auth0/auth0-react";
import {
  ApiError,
  Auth0AccountRole,
  MeetingResponse,
  MeetingStats,
  MeetingsService,
  OrganizationResponse,
  OrganizationsService,
  StatisticsService,
  StudentResponse,
  StudentsService,
  TutorResponse,
  TutorsService,
} from "client/openapi";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { APIResponse, PageStatus } from "types";
import TabNavbar from "components/TabNavbar";
import { GoPerson, GoCreditCard } from "react-icons/go";
import { BarChartIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import TutorInfo from "./TutorInfo";
import TutorInfoTab from "./TutorInfoTab";
import MetricsTab from "./MetricsTab";
import "./index.css";
import TutorPaymentTab from "./TutorPaymentTab/TutorPaymentTab";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function TutorDetail() {
  const { isLoading } = useAuth0();
  const navigate = useNavigate();
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  let { id: rawTutorId } = useParams();

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();
  const [exportingData, setExportingData] = useState<boolean>(false);
  const [exportingMeetings, setExportingMeetings] = useState<boolean>(false);
  const [tutor, setTutor] = useState<TutorResponse>();
  const [organization, setOrganization] = useState<OrganizationResponse>();
  const [meetings, setMeetings] = useState<MeetingResponse[]>();
  const [orgStudents, setOrgStudents] = useState<StudentResponse[]>();
  const [activeTab, setActiveTab] = useState<string>("Tutor Info");
  const [meetingStats, setMeetingStats] = useState<MeetingStats>();

  const options = [
    { value: "Tutor Info", icon: GoPerson },
    { value: "Metrics", icon: BarChartIcon },
    { value: "Payroll", icon: GoCreditCard },
  ];

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  function getData() {
    if (!rawTutorId) {
      setStatus(PageStatus.ERROR);
      setError({ message: "No tutor specified." });
      return;
    }

    if (isLoading) {
      return;
    }

    const tutorId = Number.parseInt(rawTutorId);

    TutorsService.getTutor({ tutorId })
      .then((t) => {
        setTutor(t);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch tutor details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    OrganizationsService.getOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((o) => {
        setOrganization(o);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    MeetingsService.getMeetingsByTutor({
      tutorId,
      until: moment().utc().toISOString(),
    })
      .then((m) => {
        setMeetings(m);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    StatisticsService.getTutorMeetingStats({
      tutorId,
      start: moment().subtract(1, "year").toISOString(),
      until: moment().toISOString(),
      tz: moment.tz.guess(true),
    })
      .then((data) => {
        setMeetingStats(data);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    StudentsService.getStudentsByOrganizationIfAdmin({
      orgId: currently_selected_organization as number,
    })
      .then((data) => {
        setOrgStudents(data);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization students" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    if (!isLoading && currently_selected_organization) {
      getData();
    }
  }, [isLoading, currently_selected_organization]);

  if (currently_selected_role !== Auth0AccountRole.ORG_ADMIN) {
    return (
      <ErrorBlock
        status={PageStatus.ERROR}
        title="Unauthorized"
        message="You do not have permission to access this page."
      />
    );
  }

  return (
    <div className="container mx-auto page--tutor-profile">
      <ErrorBlock status={status} title="Error" message={error?.message} />

      {tutor && organization && meetings ? (
        <div className="mx-5 md:mx-0">
          <button onClick={() => navigate(-1)} className="flex items-center">
            <ArrowLeftIcon className="w-6 h-6 font-bold" />
            <p className="text-lg font-bold">Back</p>
          </button>

          <p className="text-4xl mt-5 font-bold">
            {tutor.first_name} {tutor.last_name}
          </p>
          <p className="text-2xl mt-2">Tutor</p>

          <div className="my-5">
            <TabNavbar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              options={options}
            />
          </div>

          {activeTab === "Tutor Info" && orgStudents && (
            <div className="grid grid-cols-1 md:grid-cols-7">
              <div className="col-span-2 flex justify-center">
                <TutorInfo
                  tutor={tutor}
                  setTutor={setTutor}
                  setError={setError}
                  setStatus={setStatus}
                />
              </div>

              <div className="col-span-5">
                <TutorInfoTab
                  meetings={meetings}
                  exportingMeetings={exportingMeetings}
                  setExportingMeetings={setExportingMeetings}
                  tutor={tutor}
                  exportingData={exportingData}
                  organization_id={currently_selected_organization as number}
                  orgStudents={orgStudents}
                  setStatus={setStatus}
                  setError={setError}
                />
              </div>
            </div>
          )}

          {activeTab === "Metrics" && meetingStats && (
            <MetricsTab tutor={tutor} meetingStats={meetingStats} />
          )}

          {activeTab === "Payroll" && (
            <div className="col-span-7">
              <TutorPaymentTab
                tutor={tutor}
                orgId={currently_selected_organization as number}
              />
            </div>
          )}
        </div>
      ) : (
        <LoadingBlock status={PageStatus.LOADING} />
      )}
    </div>
  );
}
