/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for meeting statuses.
 */
export enum MeetingStatus {
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
}
