import { DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import PaymentMethods from "../../../../../../../../components/PaymentMethods/PaymentMethods";
import { APIResponse, PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import {
  BillingType,
  ChargesService,
  OrgTransactionType,
  PaymentMethodType,
  StudentResponse,
} from "client/openapi";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import ErrorDialog from "components/ErrorDialog";

export default function OneOffChargeModal({
  orgId,
  student,
}: {
  orgId: number;
  student: StudentResponse;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [amount, setAmount] = useState<number>(0);
  const [reason, setReason] = useState<string>("");
  const [invalidPrice, setInvalidPrice] = useState<boolean>(true);
  const [invalidReason, setInvalidReason] = useState<boolean>(true);
  const [error, setError] = useState<APIResponse>();
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [paymentMethodType, setPaymentMethodType] =
    useState<PaymentMethodType>();

  const [
    makeSureUserDoesntSpamChargeButton,
    setMakeSureUserDoesntSpamChargeButton,
  ] = useState<boolean>(false);

  const handleCharge = async () => {
    setMakeSureUserDoesntSpamChargeButton(true);

    // value is over limit
    if (Number(amount / 100) > 10000) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value less than or equal to $10,000.00",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // value is under limit
    if (Number(amount / 100) < 5) {
      setInvalidPrice(true);
      setError({
        message: "Please charge for an amount greater than or equal to $5.00",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // no amount added
    if (
      amount === 0 ||
      amount === null ||
      amount === undefined ||
      Number.isNaN(Number(amount))
    ) {
      setInvalidPrice(true);
      setError({
        message: "Please enter an amount for this charge",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // no reason added
    if (reason === "" || reason === null || reason === undefined) {
      setInvalidReason(true);
      setError({
        message: "Please enter a reason for this charge",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // shorter reason needed
    if (reason.length >= 100) {
      setInvalidReason(true);
      setError({
        message: "Please enter a shorter reason for this charge",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    await ChargesService.createCharge({
      requestBody: {
        amount: amount,
        type: OrgTransactionType.STUDENT_ORG_TRANSACTION,
        billing_type: BillingType.DIRECT,
        org_id: orgId,
        reason: reason,
        student_id: student.id,
        payment_method_id: paymentMethod.includes("@")
          ? undefined
          : paymentMethod,
        invoice_email: paymentMethod.includes("@") ? paymentMethod : undefined,
        payment_method_type: paymentMethodType,
        return_url: `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/students/${student.id}`,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  };

  const handleSelectPaymentMethod = (id) => {
    if (typeof id === "string" && id.includes("@")) {
      setPaymentMethod(id);
      setPaymentMethodType(PaymentMethodType.INVOICE);
    } else if (typeof id === "object") {
      setPaymentMethod(id.payment_method_id);
      setPaymentMethodType(
        id.brand ? PaymentMethodType.CARD : PaymentMethodType.ACH
      );
    } else {
      // Handle unexpected id type if necessary
      console.error("Unexpected payment method type", id);
    }
  };

  const verifyPricingEdit: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) > 10000) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value less than or equal to $10,000.00",
      });
      return;
    }

    // value is under limit
    if (Number(value) <= 0) {
      setInvalidPrice(true);
      setError({
        message: "Amount must be greater than $5.00!",
      });
      return;
    }

    // value is under limit
    if (
      Number(value) === null ||
      Number(value) === undefined ||
      Number.isNaN(Number(value))
    ) {
      setInvalidPrice(true);
      setError({
        message: "Amount must be greater than $5.00!",
      });
      return;
    }

    setInvalidPrice(false);
    setAmount(Number(value) * 100);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const verifyReason = (reason) => {
    // no reason added
    if (reason === "") {
      setInvalidReason(true);
      setError({
        message: "Please enter a reason for this charge",
      });
      return;
    }

    // shorter reason needed
    if (reason.length >= 100) {
      setInvalidReason(true);
      setError({
        message: "Please enter a shorter reason",
      });
      return;
    }

    setInvalidReason(false);
    setReason(reason);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  const reset = () => {
    setPaymentMethod("");
    setPaymentMethodType(undefined);
    setAmount(0);
    setReason("");
    setInvalidPrice(true);
    setInvalidReason(true);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Attempting charge. Reload the page to see your changes!"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog
        message="Charge cannot be created at this time"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  return (
    <DialogContent className="dialog-content max-w-[800px]" onClose={reset}>
      <div className="mt-3 font-semibold text-center header text-lg">
        Make a one-off charge
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4 flex gap-3">
        <div className="w-6/12">
          <p className="text-sm font-bold ">Amount</p>
          <CurrencyInput
            className="input w-11/12 h-3/6"
            id="amount"
            name="amount"
            placeholder={`$100.00`}
            prefix={"$"}
            decimalsLimit={2}
            allowNegativeValue={false}
            onValueChange={verifyPricingEdit}
          />
        </div>

        <div className="w-6/12">
          <p className="text-sm font-bold" style={{ marginBottom: "7px" }}>
            Reason
          </p>
          <input
            type="text"
            className="text-sm header w-full border border-black mb-2 rounded py-0.5 px-1 border-gray-300 h-9 w-11/12"
            placeholder="What's this charge for?"
            onChange={(e) => verifyReason(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4">
        <p className="mb-2 text-sm font-bold">Bill to:</p>
        <div className="px-2">
          <PaymentMethods
            showAddCardButton
            selectable
            studentIfInitiatedByAdmin={student}
            handleSelectPaymentMethod={handleSelectPaymentMethod}
          />
        </div>
      </div>

      <div className="w-full text-center mt-6">
        <Button
          color={
            invalidPrice ||
            invalidReason ||
            paymentMethod === "" ||
            makeSureUserDoesntSpamChargeButton
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          size={ButtonSize.DEFAULT}
          onClick={handleCharge}
          disabled={
            status === PageStatus.LOADING ||
            invalidPrice ||
            invalidReason ||
            paymentMethod === "" ||
            makeSureUserDoesntSpamChargeButton
          }
        >
          Charge
        </Button>
      </div>
    </DialogContent>
  );
}
