/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ACHResponse } from "../models/ACHResponse";
import type { CreditCardResponse } from "../models/CreditCardResponse";
import type { InviteCreate } from "../models/InviteCreate";
import type { ParentResponse } from "../models/ParentResponse";
import type { ParentStudentInviteCreate } from "../models/ParentStudentInviteCreate";
import type { ParentUpdate } from "../models/ParentUpdate";
import type { ReInviteCreate } from "../models/ReInviteCreate";
import type { StudentCreate } from "../models/StudentCreate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ParentsService {
  /**
   * Get Parents By Organization
   * Get all parents associated with the given org.
   * @returns ParentResponse Successful Response
   * @throws ApiError
   */
  public static getParentsByOrganization({
    orgId,
    phrase,
    skip,
    limit = 100,
  }: {
    orgId: number;
    phrase?: string | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<ParentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/org",
      query: {
        org_id: orgId,
        phrase: phrase,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parents Me
   * Get all parents assosciated with a given account.
   * @returns ParentResponse Successful Response
   * @throws ApiError
   */
  public static getParentsMe(): CancelablePromise<Array<ParentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent
   * Get a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getParent({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Parent
   * Update a parent by its ID.
   * @returns ParentResponse Successful Response
   * @throws ApiError
   */
  public static updateParent({
    parentId,
    requestBody,
  }: {
    parentId: number;
    requestBody: ParentUpdate;
  }): CancelablePromise<ParentResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Existing Student From Parent
   * Remove an existing student from a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static removeExistingStudentFromParent({
    parentId,
    studentId,
  }: {
    parentId: number;
    studentId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/remove_student",
      query: {
        parent_id: parentId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Existing Student To Parent
   * Add an existing student to a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static addExistingStudentToParent({
    parentId,
    studentId,
  }: {
    parentId: number;
    studentId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/add_student",
      query: {
        parent_id: parentId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add New Student To Parent
   * Add a new student to a parent account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static addNewStudentToParent({
    parentId,
    requestBody,
  }: {
    parentId: any;
    requestBody: StudentCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/add_new_student",
      query: {
        parent_id: parentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Invite Parent
   * Invite a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteParent({
    requestBody,
  }: {
    requestBody: InviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/invite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reinvite Parent
   * Reinvite a parent.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static reinviteParent({
    requestBody,
  }: {
    requestBody: ReInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/reinvite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Invite Parent Student
   * Invite a parent student.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteParentStudent({
    requestBody,
  }: {
    requestBody: ParentStudentInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/student/invite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent Cards
   * Get all cards belonging to a parent.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getParentCards({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<Array<CreditCardResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/cards/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent Ach Accounts
   * Get all banks belonging to a student.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getParentAchAccounts({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parents/ach/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Parent
   * Add a payment method onto a parent.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodParent({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parents/add_payment_method/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Registered Payment Method
   * This can be used to remove a registered card from a parent OR an account.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static removeRegisteredPaymentMethod({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/parents/payment_methods/{payment_method_id}",
      path: {
        payment_method_id: paymentMethodId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
