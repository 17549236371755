import Buttons from "./Buttons";
import Statistics from "./Statistics";
import Transactions from "./Transactions";
import "./TutorPaymentTab.css";
import { useCallback, useEffect, useState } from "react";
import {
  MeetingResponse,
  MeetingsService,
  SessionApprovalResponse,
  TutorResponse,
} from "client/openapi";
import moment from "moment";
import { APIResponse, PageStatus } from "types";

function TutorPaymentTab({
  tutor,
  orgId,
}: {
  tutor: TutorResponse;
  orgId: number;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  let currentDate = new Date(); // Get the current date and time
  let pastDate = new Date(currentDate); // Create a new Date object based on the current date
  pastDate.setMonth(pastDate.getMonth() - 6);

  const [startDate, setStartDate] = useState(pastDate.toISOString());
  const [endDate, setEndDate] = useState(currentDate.toISOString());

  const [meetings, setMeetings] = useState<MeetingResponse[]>([]);
  const [approvals, setApprovals] = useState<any>({});

  const getTutorMeetings = useCallback(async () => {
    const response = await MeetingsService.getMeetingsByTutor({
      tutorId: tutor.id,
      start: moment.utc(startDate).format("YYYY-MM-DD"),
      until: moment.utc(endDate).add(1, "days").format("YYYY-MM-DD"),
      limit: 1000,
    });
    response.sort((a, b) => moment(b.start).diff(moment(a.start)));
    setMeetings(response);
  }, [endDate, startDate, tutor.id]);

  // Function to transform approvals into a nested dictionary format
  const transformData = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.session_id]) {
        acc[item.session_id] = {};
      }
      const time = moment.utc(item.meeting_time).format();
      acc[item.session_id][time] = item.approved;
      return acc;
    }, {});
  };

  // Fetching meeting approvals
  const getMeetingApprovals = useCallback(async () => {
    const response = await MeetingsService.getMeetingApprovalsByTime({
      start: moment.utc(startDate).format("YYYY-MM-DD"),
      orgId: orgId,
    });
    const transformedData = transformData(response);
    setApprovals(transformedData);
  }, [startDate]);

  useEffect(() => {
    getTutorMeetings();
    getMeetingApprovals();
  }, [endDate, getMeetingApprovals, getTutorMeetings, startDate]);

  return (
    <div>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="mt-5 lg:mt-0">
          <Buttons
            transactions={[]} //disable trends chart for now
            tutor={tutor}
            orgId={orgId}
          />
        </div>
      </div>
      <div className="mt-8">
        <Statistics
          tutor={tutor}
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <div>
        <Transactions
          tutor={tutor}
          orgId={orgId}
          transactions={meetings}
          approvals={approvals}
        />
      </div>
    </div>
  );
}
export default TutorPaymentTab;
