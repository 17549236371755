import { MeetingDatapoint } from "client/openapi";
import moment from "moment";

export enum DateRange {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

type MeetingChartDatapoint = {
  year: string;
  month: string;
  day: string;
  date: string;
  count: number;
};

export const SEVEN = 7;
export const TWELVE = 12;
export const THIRTY = 30;
export const DAYS = "days";
export const MONTHS = "months";
export const YEAR_MONTH_DAY = "YYYY-MM-DD";

export function groupMeetingStats(
  data: MeetingDatapoint[],
  range?: string
): MeetingChartDatapoint[] {
  const now = moment().format(YEAR_MONTH_DAY);

  let dateRange: DateRange | null = null;
  const key = Object.keys(DateRange).find((key) => DateRange[key] === range);
  if (key) {
    dateRange = DateRange[key];
  }

  let start;
  switch (dateRange) {
    case DateRange.WEEK:
      start = moment().subtract(SEVEN, DAYS).format(YEAR_MONTH_DAY);
      data = filterData(data, start, now);
      return groupMeetingsByDayOfWeek(data);
    case DateRange.MONTH:
      start = moment().subtract(THIRTY, DAYS).format(YEAR_MONTH_DAY);
      data = filterData(data, start, now);
      return groupMeetingsByDay(data);
    case DateRange.YEAR:
      start = moment().subtract(TWELVE, MONTHS).format(YEAR_MONTH_DAY);
      data = filterData(data, start, now);
      return groupMeetingsByMonth(data);
    default:
      return groupMeetingsByMonth(data);
  }
}

function filterData(
  data: MeetingDatapoint[],
  start: string,
  now: string
): MeetingDatapoint[] {
  return data.filter(
    (dp) =>
      moment(dp.date).isAfter(start) && moment(dp.date).isSameOrBefore(now)
  );
}

function groupMeetings(
  data: MeetingDatapoint[],
  keyFormat: string,
  dateFormat: string
): any[] {
  const groupedDates = Object.values(data).reduce((acc, obj) => {
    const date = moment(obj.date);
    const key = `${date.format(keyFormat)}`;
    let count = obj.count;

    if (acc[key]) {
      count += acc[key].count;
    }

    acc[key] = {
      year: date.format("YYYY"),
      month: date.format("MM"),
      day: date.format("DD"),
      date: date.format(dateFormat),
      count: count,
    };

    return acc;
  }, {});

  return Object.values(groupedDates);
}

export function groupMeetingsByDayOfWeek(
  data: MeetingDatapoint[]
): MeetingChartDatapoint[] {
  return groupMeetings(data, "YYYY - MM - DD", "ddd");
}

export function groupMeetingsByDay(
  data: MeetingDatapoint[]
): MeetingChartDatapoint[] {
  return groupMeetings(data, "YYYY - MM - DD", "DD");
}

export function groupMeetingsByMonth(
  data: MeetingDatapoint[]
): MeetingChartDatapoint[] {
  return groupMeetings(data, "YYYY - MM", "MMM");
}
