import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  InviteType,
  OrganizationResponse,
  OrganizationSettingsResponse,
  ParentResponse,
  StudentResponse,
  StudentsService,
  TutorResponse,
} from "client/openapi";
import Avatar from "components/Avatar";
import {
  Button,
  ButtonColor,
  ButtonFill,
  ButtonSize,
  LinkButton,
} from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import { Dialog, DialogTrigger } from "components/Dialog";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountStatus } from "types";
import { concatenateName } from "util/concatenateName";
import { getButtonRoleColor, getRoleColor } from "util/contextColor";
import { generateInitials } from "util/generateInitials";
import EmptyState from "../EmptyState";
import PageNav from "../PageNav";
import UserListActions from "../UserListActions";
import "./index.css";
import Notifications from "util/notifications";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export enum StudentDashboardAction {
  MESSAGE = "message",
  NOTE = "note",
  DATA = "data",
}

function StudentsList({
  role,
  parentIfOnParentDashboard,
  tutorIfOnTutorDashboard,
  organizationIfOnOrgDashboard,
  settings,
  org_name,
}: {
  role: Auth0AccountRole;
  parentIfOnParentDashboard?: ParentResponse;
  tutorIfOnTutorDashboard?: TutorResponse;
  organizationIfOnOrgDashboard?: OrganizationResponse;
  settings?: OrganizationSettingsResponse;
  org_name?: string;
}) {
  const { currently_selected_role, organizations_available_to_role } =
    useContext(OrgRolesAndAccountContext);

  const [initialStudents, setInitialStudents] = useState<StudentResponse[]>([]);
  const [students, setStudents] = useState<StudentResponse[]>([]);
  const [phrase, setPhrase] = useState<string>("");
  const [debouncedPhrase, setDebouncedPhrase] = useState<string>(phrase);

  // TODO: Remove this and use tutor.totalStudents when it is supported
  const [totalStudents, setTotalStudents] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const limit = 10;

  // THIS WILL BREAK CERTAIN DASHBOARDS, CHANGE THE QUERY TO FILTER
  const fetchStudents = useCallback(
    async (pageNumber: number) => {
      const skip = pageNumber * limit;
      const phraseIfIncluded =
        phrase === "" && initialStudents.length === 0
          ? undefined
          : phrase && phrase.length > 2
          ? phrase
          : undefined;

      if (organizationIfOnOrgDashboard) {
        await StudentsService.getStudentsByOrganizationIfAdmin({
          orgId: organizationIfOnOrgDashboard.id,
          skip: skip,
          limit: limit,
          phrase: phraseIfIncluded,
        }).then((data: StudentResponse[]) => {
          setStudents(data);
          if (phrase === "") {
            setInitialStudents(data);
          } else {
            setTotalStudents(students.length);
            setNumPages(Math.ceil(students.length / limit));
          }
        });
      }

      if (tutorIfOnTutorDashboard) {
        await StudentsService.getStudentsByOrganizationIfTutor({
          orgId: tutorIfOnTutorDashboard.org_id,
          skip: skip,
          limit: limit,
          phrase: phraseIfIncluded,
        }).then((data: StudentResponse[]) => {
          setStudents(data);
          if (phrase === "") {
            setInitialStudents(data);
          } else {
            setTotalStudents(students.length);
            setNumPages(Math.ceil(students.length / limit));
          }
        });
      }

      if (parentIfOnParentDashboard) {
        await StudentsService.getStudentsByOrganizationIfParent({
          orgId: parentIfOnParentDashboard.org_id,
          skip: skip,
          limit: limit,
          phrase: phraseIfIncluded,
        }).then((data: StudentResponse[]) => {
          setStudents(data);
          if (phrase === "") {
            setInitialStudents(data);
          } else {
            setTotalStudents(students.length);
            setNumPages(Math.ceil(students.length / limit));
          }
        });
      }
    },
    [
      initialStudents.length,
      organizationIfOnOrgDashboard,
      parentIfOnParentDashboard,
      phrase,
      students.length,
      tutorIfOnTutorDashboard,
    ]
  );

  // update `phrase` after 1 second
  useEffect(() => {
    const timer = setTimeout(() => setPhrase(debouncedPhrase), 1000);
    return () => clearTimeout(timer);
  }, [debouncedPhrase]);

  // submit a new search
  useEffect(() => {
    if (phrase !== "") {
      setCurrentPage(0);
      fetchStudents(0);
    } else {
      fetchStudents(currentPage);
      setTotalStudents(
        tutorIfOnTutorDashboard?.students
          ? tutorIfOnTutorDashboard.students.length
          : organizationIfOnOrgDashboard?.students
          ? organizationIfOnOrgDashboard.students.length
          : parentIfOnParentDashboard?.students
          ? parentIfOnParentDashboard.students.length
          : 0
      );
      setNumPages(
        Math.ceil(
          (tutorIfOnTutorDashboard?.students
            ? tutorIfOnTutorDashboard.students.length
            : organizationIfOnOrgDashboard?.students
            ? organizationIfOnOrgDashboard.students.length
            : parentIfOnParentDashboard?.students
            ? parentIfOnParentDashboard.students.length
            : 0) / limit
        )
      );
    }
  }, [
    currentPage,
    fetchStudents,
    organizationIfOnOrgDashboard,
    parentIfOnParentDashboard,
    phrase,
    tutorIfOnTutorDashboard,
  ]);

  return (
    <>
      {initialStudents.length > 0 ? (
        <div className="py-6 px-7 rounded-xl bg-neutral-50">
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <div className="flex flex-col items-start">
              <h2 className="text-xl font-bold">Students </h2>
              {[Auth0AccountRole.ORG_TUTOR, Auth0AccountRole.PARENT].includes(
                currently_selected_role as Auth0AccountRole
              ) &&
                (organizations_available_to_role?.length as number) > 1 &&
                (tutorIfOnTutorDashboard || parentIfOnParentDashboard) &&
                org_name}
              {students.length > 0 ? (
                <PageNav
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  numPages={numPages}
                  limit={limit}
                  totalUsers={totalStudents}
                  role={role}
                  fetchUsers={fetchStudents}
                />
              ) : null}
            </div>

            {[Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
              currently_selected_role as Auth0AccountRole
            ) && (
              <UserListActions
                users={students}
                userType={Auth0AccountRole.ME}
                buttonColor={getButtonRoleColor(role)}
                debouncedPhrase={debouncedPhrase}
                setDebouncedPhrase={setDebouncedPhrase}
                role={role}
                fetchUsers={fetchStudents}
                currentPage={currentPage}
                orgId={
                  (organizationIfOnOrgDashboard?.id ||
                    tutorIfOnTutorDashboard?.org_id ||
                    parentIfOnParentDashboard?.org_id) as number
                }
              />
            )}
          </div>

          {students.length > 0 ? (
            <div className="grid gap-8 mt-6 lg:grid-cols-2">
              {students.map((student) => (
                <StudentListItem
                  key={student.id}
                  student={student}
                  role={role}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              type_of_user_being_invited={Auth0AccountRole.ME}
              includeInviteUserDialog={false}
              title="No students"
              message="Please search again"
              buttonColor={getButtonRoleColor(role)}
              fetchUsers={fetchStudents}
              currentPage={currentPage}
              orgId={
                (organizationIfOnOrgDashboard?.id ||
                  tutorIfOnTutorDashboard?.org_id ||
                  parentIfOnParentDashboard?.org_id) as number
              }
            />
          )}
        </div>
      ) : (
        <EmptyState
          type_of_user_being_invited={Auth0AccountRole.ME}
          includeInviteUserDialog={true}
          title="No students"
          message={
            currentPage === 0
              ? tutorIfOnTutorDashboard
                ? `No students from ${org_name} have been assigned to you.`
                : parentIfOnParentDashboard
                ? `You do not have any students in ${org_name}.`
                : ""
              : "There are no more students. See previous page."
          }
          buttonColor={getButtonRoleColor(role)}
          fetchUsers={fetchStudents}
          currentPage={currentPage}
          orgId={
            (organizationIfOnOrgDashboard?.id ||
              tutorIfOnTutorDashboard?.org_id ||
              parentIfOnParentDashboard?.org_id) as number
          }
        />
      )}
    </>
  );
}

function StudentListItem({
  role,
  student,
}: {
  role: Auth0AccountRole;
  student: StudentResponse;
}) {
  const { account } = useContext(OrgRolesAndAccountContext);

  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const [avoidSpammingInviteButton, setAvoidSpammingInviteButton] =
    useState<boolean>(false);

  async function reinviteStudent() {
    StudentsService.reinviteStudent({
      requestBody: {
        first_name: student.first_name,
        last_name: student.last_name,
        phone_number: student.phone_number ? student.phone_number : undefined,
        email: student.email as string,
        org_invited_by: student.org_id,
        acct_invited_by: account?.reference_id as string,
        type: InviteType.STUDENT,
        user_id: student.id,
      },
    })
      .then((s) => {
        Notifications.success("Student reinvited!");
        setAvoidSpammingInviteButton(false);
      })
      .catch((e: ApiError) => {
        Notifications.error("Unable to reinvite student");
        console.error(`Error (#${e.status}): ${e.message}`);
        setAvoidSpammingInviteButton(false);
      });
  }

  return (
    <div className="flex flex-row items-center justify-between student-list-container">
      <div className="flex flex-row items-center gap-4">
        <Avatar
          src={student.image_url}
          fallback={generateInitials(concatenateName(student))}
          alt={generateInitials(concatenateName(student))}
          color={getRoleColor(role)}
        />

        <div>
          {!student.account && (
            <div className="mb-1">
              <Tag
                size={TagSize.MINI}
                color={TagColor.GOLD}
                item={AccountStatus.INVITED}
              />
            </div>
          )}

          <h3 className="m-0 primsecond-text">
            {student.first_name} {student.last_name}
          </h3>

          <p className="small-text text-muted">{student.email}</p>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:items-center md:flex-row student-list-buttons">
        {!student.account &&
          [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
            currently_selected_role as Auth0AccountRole
          ) && (
            <Button
              size={ButtonSize.SMALL}
              color={
                avoidSpammingInviteButton
                  ? ButtonColor.PURPLE
                  : getButtonRoleColor(role)
              }
              disabled={avoidSpammingInviteButton}
              fill={ButtonFill.DEFAULT}
              onClick={() => {
                setAvoidSpammingInviteButton(true);
                reinviteStudent();
              }}
            >
              Reinvite
            </Button>
          )}
        <Dialog>
          <DialogTrigger asChild>
            <Button
              size={ButtonSize.SMALL}
              color={getButtonRoleColor(role)}
              fill={ButtonFill.HOLLOW}
            >
              Message
            </Button>
          </DialogTrigger>

          <ComposeMessageDialog students={[student]} org_id={student.org_id} />
        </Dialog>

        <LinkButton
          to={`/manage/students/${student.id}`}
          size={ButtonSize.SMALL}
          color={getButtonRoleColor(role)}
        >
          View Details <ArrowRightIcon />
        </LinkButton>
      </div>
    </div>
  );
}

export default StudentsList;
