/**
 * Pluralize a string.
 *
 * @param singular singular form of the word
 * @param plural plural form of the word
 * @param count count of noun
 * @returns singular or plural string depending on the count
 */
function pluralize(singular: string, plural: string, count: number): string {
  if (count === 1) {
    return singular;
  } else {
    return plural;
  }
}

export default pluralize;
