/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents values to filter by for payroll exports.
 */
export enum FilterBy {
  ALL_SESSIONS = "All Sessions",
  APPROVED_SESSIONS = "Approved Sessions",
  DENIED_SESSIONS = "Denied Sessions",
  NEITHER_APPROVED_NOR_DENIED = "Neither Approved Nor Denied",
}
