/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents attendance status for a student.
 */
export enum Attendance {
  PRESENT = "present",
  ABSENT = "absent",
  LATE = "late",
  UNKNOWN = "unknown",
}
