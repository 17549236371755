import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightIcon, CheckCircledIcon } from "@radix-ui/react-icons";
import { ApiError, InvitesService } from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { ErrorBlock } from "components/StatusBlock";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageStatus } from "types";

export default function AcceptInvite() {
  const { loginWithRedirect } = useAuth0();
  const { auth0Secret } = useParams();

  const [error, setError] = useState<string>();

  async function acceptInvite() {
    if (auth0Secret) {
      await InvitesService.acceptInvite({ a0Secret: auth0Secret }).catch(
        (e: ApiError) => {
          setError("Unable to accept invite.");
          console.error(`Error (#${e.status}): ${e.message}`);
        }
      );
    }
  }

  useEffect(() => {
    if (!auth0Secret) {
      setError("Unable to accept invite.");
    } else {
      acceptInvite();
    }
  }, []);

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="container page--student-data">
          <section className="student-profile--section">
            <div>
              <span className="student-profile--section-header">
                <h2 className="headline-9">Signup Completed</h2>
              </span>
            </div>

            <div className="student-profile--section-item">
              {error ? (
                <ErrorBlock status={PageStatus.ERROR} message={error} />
              ) : (
                <div>
                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <CheckCircledIcon
                      className="text-green"
                      style={{
                        height: "48px",
                        width: "48px",
                      }}
                    />

                    <h3 className="m-0 text-green">Success!</h3>
                  </div>

                  <p>
                    Your account setup is now complete! You may log in with your
                    new password at any time.
                  </p>

                  <Button
                    to="/login"
                    color={ButtonColor.GREEN}
                    size={ButtonSize.LARGE}
                    extraClasses="mt-4"
                    onClick={() => {
                      loginWithRedirect({
                        authorizationParams: {
                          redirect_uri: `${process.env.REACT_APP_FRONTEND_DOMAIN}/dashboard`,
                        },
                      });
                    }}
                  >
                    Log In <ArrowRightIcon />
                  </Button>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
