/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents all valid message types.
 */
export enum MessageType {
  MEETING_SCHEDULED = "Meeting Scheduled",
  MEETING_CANCELLED = "Meeting Cancelled",
  MEETING_RESCHEDULED = "Meeting Rescheduled",
  MEETING_UPDATED = "Meeting Updated",
  ACCOUNT_VERIFICATION = "Account Verification",
  ANNOUNCEMENT = "Announcement",
  DIRECT = "Direct",
  ORGANIZATION = "Organization",
  ORGANIZATION_TUTORS = "Organization Tutors",
  ORGANIZATION_STUDENTS = "Organization Students",
  ORGANIZATION_ADMINS = "Organization Admins",
  TUTOR_STUDENTS = "Tutor Students",
  ORGANIZATION_INVITE = "Organization Invite",
  TUTOR_INVITE = "Tutor Invite",
  STUDENT_INVITE = "Student Invite",
  PARENT_INVITE = "Parent Invite",
  OTHER = "Other",
}
