import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { DataKey } from "recharts/types/util/types";

const topMargin = 10;
const leftMargin = 3;
const bottomMargin = 20;
const rightMargin = 15;

export default function StatsAreaChart({
  data,
  xKey,
  xAxisLabel,
  yKey,
  yAxisLabel,
  formatter,
  xDomain,
  yDomain,
}: {
  data: any[];
  xKey: DataKey<any>;
  xAxisLabel: string;
  yKey: DataKey<any>;
  yAxisLabel: string;
  formatter?: (value: number) => string;
  xDomain?: number[];
  yDomain?: number[];
}) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: topMargin,
          left: leftMargin,
          bottom: bottomMargin,
          right: rightMargin,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xKey}
          axisLine={{ stroke: "black" }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          domain={data.length === 0 ? ["No data during this range"] : xDomain}
          style={{ fontSize: "14px" }}
          tickFormatter={formatter}
          type="number"
        >
          <Label
            value={xAxisLabel}
            position="insideBottom"
            style={{ textAnchor: "middle", fontSize: "14px" }}
            fill="black"
            fontWeight="bold"
            dy={bottomMargin}
          />
        </XAxis>
        <YAxis
          dataKey={yKey}
          axisLine={{ stroke: "black" }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          style={{ fontSize: "14px" }}
          domain={yDomain}
          type="number"
        >
          <Label
            value={yAxisLabel}
            position="insideLeft"
            angle={-90}
            fill="black"
            fontWeight="bold"
            style={{ textAnchor: "middle", fontSize: "14px" }}
          />
        </YAxis>
        {data.length > 0 && <Tooltip labelFormatter={formatter} />}
        <Area
          type="linear"
          dataKey={yKey}
          stroke="#7ABFFF"
          fill="rgba(0, 133, 255, 0.13)"
          dot={{ fill: "#90CAFF", stroke: "#90CAFF", fillOpacity: 1, r: 3 }}
          activeDot={{
            fill: "#0085FF",
            stroke: "#0085FF",
            fillOpacity: 1,
            r: 3,
          }}
        ></Area>
      </AreaChart>
    </ResponsiveContainer>
  );
}
