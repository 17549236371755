import { OrganizationSubjectResponse } from "client/openapi";
import { TagColor } from "components/Tag";
import { TagList } from "components/Tag/TagList";
import { SubjectLevel } from "types";
import pluralize from "util/pluralize";
import "./index.css";

export function ProfileSubjects({
  subjects,
}: {
  subjects: OrganizationSubjectResponse[];
}) {
  const decoratedSubjects = subjects.map((s: OrganizationSubjectResponse) => {
    switch (s.level) {
      case SubjectLevel.HONORS: {
        return "Honors " + s.name;
      }
      case SubjectLevel.AP: {
        return "AP " + s.name;
      }
      case SubjectLevel.IB: {
        return "IB " + s.name;
      }
      default: {
        return s.name;
      }
    }
  });

  return (
    <>
      {subjects.length > 0 && (
        <>
          <h2 className="profile_body_title profile_body_title_big">
            {pluralize("Subject", "Subjects", subjects.length)} of Focus
          </h2>

          {decoratedSubjects.length > 0 && (
            <div className="profile_subjects_group">
              <TagList list={decoratedSubjects} color={TagColor.GOLD} />
            </div>
          )}
        </>
      )}
    </>
  );
}
