import { DialogClose } from "@radix-ui/react-dialog";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  InviteType,
  ParentResponse,
  ParentsService,
  StudentResponse,
  StudentsService,
  TutorsService,
  TutorStudentsService,
} from "client/openapi";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { ButtonColor } from "components/Button";
import Select from "components/Select";
import { concatenateName } from "util/concatenateName";
import PhoneInput from "react-phone-input-2";
import { TutorContext } from "../TutorDashboard/tutorContext";
import { ParentContext } from "../ParentDashboard/parentContext";

const emptyAccount = {
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
};

function InviteStudentDialog({
  fetchStudents,
  currentPage,
  orgId,
}: {
  fetchStudents: (page: number) => Promise<void>;
  currentPage: number;
  orgId: number;
}) {
  const { account, currently_selected_role } = useContext(
    OrgRolesAndAccountContext
  );
  const { available_tutors, update_tutor_state } = useContext(TutorContext);

  const [newStudent, setNewStudent] = useState(emptyAccount);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [parentOptions, setParentOptions] = useState<ParentResponse[]>();
  const [parent, setParent] = useState<ParentResponse>();
  const [isEmailRequired, setIsEmailRequired] = useState(false);

  function getNewestParents() {
    ParentsService.getParentsByOrganization({ orgId: orgId })
      .then((parents) => {
        setParentOptions(parents);
      })
      .catch((e: ApiError) => {
        setError(e);
      });
  }

  useEffect(() => {
    setIsEmailRequired(!parent);
  }, [parent]);

  async function inviteStudent() {
    setStatus(PageStatus.LOADING);

    try {
      let invite_response;

      // Inviting a student directly to the organization
      if (newStudent.email) {
        invite_response = await StudentsService.inviteStudentToOrg({
          requestBody: {
            first_name: newStudent.first_name,
            last_name: newStudent.last_name,
            phone_number: newStudent.phone_number || undefined,
            email: newStudent.email,
            org_invited_by: orgId,
            acct_invited_by: account?.reference_id as string,
            type: InviteType.STUDENT,
          },
        });

        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Student invited!`);
        fetchStudents(currentPage);

        if (invite_response?.invited_entity && parent) {
          await ParentsService.addExistingStudentToParent({
            parentId: parent.id,
            studentId: invite_response.invited_entity.id,
          });
        }
      } // Inviting a parent-student pair (only if email is not provided)
      else if (!newStudent.email && parent) {
        invite_response = await ParentsService.inviteParentStudent({
          requestBody: {
            first_name: newStudent.first_name,
            last_name: newStudent.last_name,
            type: InviteType.PARENT_STUDENT,
            org_invited_by: orgId,
            acct_invited_by: account?.reference_id as string,
            phone_number: newStudent.phone_number || undefined,
            email: parent.email as string,
            parent_id: parent.id,
          },
        });

        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Student invited!`);
        fetchStudents(currentPage);
      }

      if (
        available_tutors &&
        currently_selected_role === Auth0AccountRole.ORG_TUTOR
      ) {
        const students_tutor = available_tutors.find(
          (tutor) => tutor.org_id === orgId
        );

        if (students_tutor) {
          await TutorStudentsService.createTutorStudentRelationship({
            tutorId: students_tutor.id,
            studentId: invite_response.invited_entity.id,
          });

          update_tutor_state({ need_to_get_latest_tutor_info: true });
        }
      }
    } catch (e: any) {
      setStatus(PageStatus.ERROR);
      setError(e);
    }
  }

  const handlePhoneChange = (newPhone: string) => {
    setNewStudent({
      ...newStudent,
      phone_number: newPhone,
    });
  };

  function handleUpdate(ev) {
    const value = ev.target.value;

    setNewStudent({
      ...newStudent,
      [ev.target.name]: value,
    });
  }

  function handleSubmit() {
    return async (ev) => {
      ev.preventDefault();
      await inviteStudent();
    };
  }

  function clearForm() {
    setNewStudent(emptyAccount);
    setStatus(undefined);
    setParent(undefined);
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent
        className="dialog-content"
        onOpen={getNewestParents}
        onClose={clearForm}
      >
        <div className="invite-dialog">
          <h2 className="invite-dialog--title">Invite a Student</h2>

          {status === PageStatus.SUCCESS ? (
            <>
              <p className="mb-4">
                <b>
                  {newStudent.first_name} {newStudent.last_name}
                </b>{" "}
                has been successfully invited!
              </p>

              <DialogActions>
                <DialogAction
                  primary={true}
                  color={ButtonColor.SKYBLUE}
                  onClick={clearForm}
                >
                  Invite Another <ArrowRightIcon />
                </DialogAction>

                <DialogClose asChild>
                  <DialogAction color={ButtonColor.SKYBLUE} onClick={clearForm}>
                    Close
                  </DialogAction>
                </DialogClose>
              </DialogActions>
            </>
          ) : (
            <>
              <form onSubmit={handleSubmit()}>
                <label htmlFor="first_name" className="input-label">
                  First Name
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    className="input"
                    placeholder="Amy"
                    value={newStudent.first_name}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="last_name" className="mt-4 input-label">
                  Last Name
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    className="input"
                    placeholder="Smith"
                    value={newStudent.last_name}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required
                  />
                </label>

                <label htmlFor="add-parent" className="mt-4 input-label">
                  Parent (optional)
                  <Select
                    id="add-parent"
                    name="add-parent"
                    options={parentOptions}
                    value={parent}
                    isDisabled={parentOptions?.length === 0}
                    getOptionLabel={(p) => `${p ? concatenateName(p) : ""}`}
                    getOptionValue={(p) => (p ? p.id.toString() : "")}
                    onChange={(parent: ParentResponse) => setParent(parent)}
                    className="w-6/6 h-9 mt-2 text-sm"
                  />
                </label>

                <label htmlFor="email" className="mt-4 input-label">
                  Email Address (optional if invited with parent)
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="input"
                    placeholder="amy@example.com"
                    value={newStudent.email}
                    onChange={handleUpdate}
                    disabled={status === PageStatus.LOADING}
                    required={isEmailRequired}
                  />
                </label>

                <label htmlFor="phone_number" className="mt-4 input-label">
                  Phone Number (optional)
                  <PhoneInput
                    country={"us"}
                    value={newStudent.phone_number}
                    onChange={handlePhoneChange}
                    prefix="+"
                    inputClass="input"
                    placeholder="+1 (555) 555-5555"
                    dropdownStyle={{ display: "none" }}
                    countryCodeEditable={false}
                  />
                </label>

                <DialogActions>
                  <DialogAction
                    primary={true}
                    color={ButtonColor.SKYBLUE}
                    type="submit"
                    disabled={status === PageStatus.LOADING}
                  >
                    {status === PageStatus.LOADING ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <>
                        Invite <ArrowRightIcon />
                      </>
                    )}
                  </DialogAction>

                  <DialogClose asChild>
                    <DialogAction
                      color={ButtonColor.SKYBLUE}
                      onClick={clearForm}
                    >
                      Cancel
                    </DialogAction>
                  </DialogClose>
                </DialogActions>
              </form>
            </>
          )}
        </div>
      </DialogContent>
    </>
  );
}

export default InviteStudentDialog;
