import Notes from "./Notes";
import {
  Auth0AccountRole,
  ParentResponse,
  OrganizationResponse,
  NoteResponse,
  AccountResponse,
} from "client/openapi";
import { AccountRoles } from "types";

export default function NotesTab({
  newInternalNoteOpen,
  setNewInternalNoteOpen,
  newParentNoteOpen,
  setNewParentNoteOpen,
  parent,
  organization,
  internalNotes,
  setInternalNotes,
  parentNotes,
  setParentNotes,
  account,
}: {
  newInternalNoteOpen: boolean;
  setNewInternalNoteOpen: (open: boolean) => void;
  newParentNoteOpen: boolean;
  setNewParentNoteOpen: (open: boolean) => void;
  parent: ParentResponse;
  organization: OrganizationResponse | undefined;
  internalNotes: NoteResponse[];
  setInternalNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  parentNotes: NoteResponse[];
  setParentNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  account: AccountResponse;
}) {
  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-2 h-full">
      <div className="flex justify-center mx-5 mt-5 md:justify-end md:mx-0 md:mt-0">
        <Notes
          title="Internal Notes"
          newNoteOpen={newInternalNoteOpen}
          setNewNoteOpen={setNewInternalNoteOpen}
          parent={parent}
          organization={organization}
          notes={internalNotes}
          setNotes={setInternalNotes}
          account={account}
          toolTipMessage="These notes are private to this parent's profile."
          text_parent_or_student_parents={false}
        />
      </div>

      <div className="flex justify-center mx-5 mt-5 md:justify-end md:mx-0 md:mt-0">
        <Notes
          title="Parent Notes"
          newNoteOpen={newParentNoteOpen}
          setNewNoteOpen={setNewParentNoteOpen}
          parent={parent}
          organization={organization}
          notes={parentNotes}
          setNotes={setParentNotes}
          account={account}
          toolTipMessage="These notes are automatically texted and emailed to the parent."
          text_parent_or_student_parents={true}
        />
      </div>
    </div>
  );
}
