import { NotificationManager } from "react-notifications";

class Notifications {
  static info(text: string) {
    NotificationManager.info(text);
  }

  static success(text: string) {
    NotificationManager.success(text);
  }

  static error(text: string) {
    NotificationManager.info(text);
  }
}

export default Notifications;
