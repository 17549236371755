import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Dialog } from "components/Dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Button, ButtonSize } from "components/Button";
import { PlusIcon } from "@radix-ui/react-icons";
import { getButtonRoleColor } from "util/contextColor";
import { getHighestRole } from "util/accounts";
import NewNoteDialog from "components/Dashboard/NewNoteDialog";
import { AccountRoles, UserType } from "types";
import NotesDialog from "components/Dashboard/NotesDialog";
import {
  Auth0AccountRole,
  ParentResponse,
  OrganizationResponse,
  NoteResponse,
  AccountResponse,
} from "client/openapi";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import "../index.css";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function Notes({
  title,
  newNoteOpen,
  setNewNoteOpen,
  parent,
  organization,
  notes,
  setNotes,
  account,
  toolTipMessage,
  text_parent_or_student_parents,
}: {
  title: string;
  newNoteOpen: boolean;
  setNewNoteOpen: (open: boolean) => void;
  parent: ParentResponse;
  organization: OrganizationResponse | undefined;
  notes: NoteResponse[];
  setNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  account: AccountResponse;
  toolTipMessage?: string;
  text_parent_or_student_parents: boolean;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  return (
    <section className="parent-profile--section" id="parent-notes">
      <div className="parent-profile--section-header">
        <div className="flex flex-row items-center">
          <h2>{title}</h2>
          {toolTipMessage && (
            <div className="ml-2 mr-2">
              <Tooltip>
                <TooltipTrigger>
                  <InfoCircledIcon className="w-5 h-5 text-gray-400" />
                </TooltipTrigger>
                <TooltipContent>{toolTipMessage}</TooltipContent>
              </Tooltip>
            </div>
          )}
        </div>

        <Dialog open={newNoteOpen}>
          <DialogTrigger asChild>
            <Button
              color={getButtonRoleColor(
                currently_selected_role
                  ? currently_selected_role
                  : Auth0AccountRole.ME
              )}
              size={ButtonSize.SMALL}
              onClick={() => setNewNoteOpen(true)}
            >
              Add Note <PlusIcon />
            </Button>
          </DialogTrigger>

          <NewNoteDialog
            role={currently_selected_role ? currently_selected_role : null}
            authorType={
              currently_selected_role === Auth0AccountRole.ORG_ADMIN
                ? Auth0AccountRole.ORG_ADMIN
                : Auth0AccountRole.ORG_TUTOR
            }
            parent_recipient={parent}
            setOpen={() => setNewNoteOpen(false)}
            notes={notes}
            setNotes={setNotes}
            text_parent_or_student_parents={text_parent_or_student_parents}
          />
        </Dialog>
      </div>

      <div className="parent-profile--section-items">
        <div className="parent-profile--section-item">
          <NotesDialog
            role={currently_selected_role ? currently_selected_role : null}
            author={account}
            parent_recipient={parent}
            organization={organization}
            notes={notes}
            setNotes={setNotes}
            text_parent_or_student_parents={text_parent_or_student_parents}
          />
        </div>
      </div>
    </section>
  );
}
