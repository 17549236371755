import moment from "moment";
import "../index.css";
import { useEffect, useState, useRef } from "react";
import TextInput from "components/Inputs/TextInput";
import SimpleSelect from "components/Select/simple";
import Select from "components/Select";
import {
  MeetingResponse,
  SessionMeta,
  TutorMeetingResponse,
} from "client/openapi";
import { capitalize } from "util/capitalize";
import AvailabilityDayPicker from "components/AvailabilityDayPicker";
import DayInput from "components/DayPicker";

const DATE_FORMAT = "yyyy-MM-DD";
const TIME_FORMAT = "h:mma";

export default function EditMeetingDetails({
  id,
  tutors,
  event,
  originalMeetingDetails,
  saveMeetingDetails,
  setIsAvailabilitySidebarOpen,
  setAvailabilitySidebarDate,
}: {
  id: number;
  tutors: TutorMeetingResponse[];
  event: MeetingResponse | undefined;
  originalMeetingDetails: SessionMeta;
  saveMeetingDetails: (meeting: SessionMeta) => void;
  setIsAvailabilitySidebarOpen: (boolean) => void;
  setAvailabilitySidebarDate: (date: string) => void;
}) {
  const [meetingDetails, setMeetingDetails] = useState<SessionMeta>({
    ...originalMeetingDetails,
  });
  const [endTimeOptions, setEndTimeOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [changingDate, setChangingDate] = useState<boolean>(false);

  const getStartTime = () => {
    return moment(meetingDetails.start).format(TIME_FORMAT);
  };

  const getEndTime = () => {
    if (meetingDetails.duration < 5) {
      return "Choose . . .";
    }
    return moment(handleCheckModernDate(meetingDetails.start))
      .add(meetingDetails.duration, "minutes")
      .format(TIME_FORMAT);
  };

  const handleChangeStartDate = (date: Date) => {
    const startTime = meetingDetails.start.split("T")[1];
    const newStart = moment(date).format(DATE_FORMAT) + "T" + startTime;
    const isOriginalDST = moment(meetingDetails.start).isDST();
    const isDST = moment(newStart).isDST();
    setMeetingDetails({
      ...meetingDetails,
      start:
        isDST !== isOriginalDST
          ? isDST
            ? moment(newStart).subtract(1, "hour").format()
            : moment(newStart).add(1, "hour").format()
          : newStart,
    });
    setAvailabilitySidebarDate(
      isDST !== isOriginalDST
        ? isDST
          ? moment(newStart).subtract(1, "hour").format()
          : moment(newStart).add(1, "hour").format()
        : newStart
    );
  };

  const handleChangeEndDate = (date: Date) => {
    if (meetingDetails.interval === 0) {
      setMeetingDetails({ ...meetingDetails, until: undefined });
      return;
    }
    setMeetingDetails({
      ...meetingDetails,
      until: moment(date).format(),
    });
  };

  const handleChangeStartTime = (ev) => {
    const isCurrentlyDST = moment().isDST();
    const isMeetingDST = moment(meetingDetails.start).isDST();
    const startTime = moment(ev.value, TIME_FORMAT)
      .subtract(isCurrentlyDST !== isMeetingDST ? 60 : 0, "minutes")
      .format()
      .split("T")[1];
    const newStart =
      moment(meetingDetails.start).format(DATE_FORMAT) + "T" + startTime;
    const endTime = moment(meetingDetails.start)
      .add(meetingDetails.duration, "minutes")
      .format();
    setMeetingDetails({
      ...meetingDetails,
      start: newStart,
      duration: moment(ev.value, TIME_FORMAT).isSameOrAfter(
        moment(getEndTime(), TIME_FORMAT)
      )
        ? meetingDetails.duration
        : moment(endTime).diff(moment(newStart), "minutes"),
    });
  };

  const handleCheckModernDate = (date) => {
    if (date.startsWith("0")) {
      return "2024-" + date.split("-").slice(1).join("-");
    } else {
      return date;
    }
  };

  const handleChangeEndTime = (ev) => {
    const isDST = moment(meetingDetails.start).isDST();
    const date = moment(meetingDetails.start).format().split("T")[0];
    const endTime =
      date + "T" + moment(ev.value, TIME_FORMAT).format().split("T")[1];
    setMeetingDetails({
      ...meetingDetails,
      duration: moment(endTime).diff(moment(meetingDetails.start), "minutes"), // -
      // (isDST ? 60 : 0),
    });
  };

  const handleChangeLocation = (ev) => {
    setMeetingDetails({ ...meetingDetails, location: ev.target.value });
  };

  const getTimeOptions = () => {
    const options: { label: string; value: string }[] = [];
    new Array(24).fill(0).forEach((acc, index) => {
      const hour = moment({ hour: index }).format(TIME_FORMAT);
      const hour15 = moment({ hour: index, minute: 15 }).format(TIME_FORMAT);
      const hour30 = moment({ hour: index, minute: 30 }).format(TIME_FORMAT);
      const hour45 = moment({ hour: index, minute: 45 }).format(TIME_FORMAT);
      options.push({ label: hour, value: hour });
      options.push({ label: hour15, value: hour15 });
      options.push({ label: hour30, value: hour30 });
      options.push({ label: hour45, value: hour45 });
    });
    return options;
  };

  const getEndTimeOptions = () => {
    const startTimeIndex = getTimeOptions().findIndex((time) => {
      return time.value === getStartTime();
    });
    setEndTimeOptions(getTimeOptions().slice(startTimeIndex + 1));
  };

  const getRepeatOptions = () => {
    return [
      { value: "never", label: "Never" },
      { value: "every 7 days", label: "Every 7 days" },
      { value: "every 14 days", label: "Every 14 days" },
      { value: "every 21 days", label: "Every 21 days" },
      { value: "every 28 days", label: "Every 28 days" },
    ];
  };

  const frequencyToInterval = (frequency: string) => {
    let interval = 0;
    switch (frequency) {
      case "every 7 days":
        interval = 1;
        break;
      case "every 14 days":
        interval = 2;
        break;
      case "every 21 days":
        interval = 3;
        break;
      case "every 28 days":
        interval = 4;
        break;
      default:
        break;
    }
    return interval;
  };

  const intervalToFrequency = (interval: number) => {
    let freq = "never";
    switch (interval) {
      case 1:
        freq = "every 7 days";
        break;
      case 2:
        freq = "every 14 days";
        break;
      case 3:
        freq = "every 21 days";
        break;
      case 4:
        freq = "every 28 days";
        break;
      default:
        break;
    }
    return freq;
  };

  const changeFrequency = (frequency: { value: string; label: string }) => {
    setMeetingDetails({
      ...meetingDetails,
      interval: frequencyToInterval(frequency.value),
      is_recurring: frequency.value !== "never",
    });
  };

  useEffect(() => {
    if (!changingDate) {
      saveMeetingDetails(meetingDetails);
    }
  }, [meetingDetails, changingDate]);

  useEffect(() => {
    getEndTimeOptions();
  }, [meetingDetails.start]);

  return (
    <>
      <div className="grid grid-flow-row bg-gray-100 rounded-lg p-4 mr-2">
        <div className="flex flex-wrap gap-2 text-sm">
          <div className="mr-2 mt-1">
            <p className="text-xs font-bold">Date</p>
            <AvailabilityDayPicker
              id={"start-date" + id}
              event={event}
              setValue={handleChangeStartDate}
              setIsAvailabilitySidebarOpen={setIsAvailabilitySidebarOpen}
              tutors={tutors}
              meetingDetails={meetingDetails}
              required
              classes="py-2 px-1 m-0 border-green-300 border-0 border-b-2 bg-transparent text-sm"
            />
          </div>
          <div className="mr-2 mt-1">
            <p className="text-xs font-bold">Time</p>
            <div className="flex items-center">
              <SimpleSelect
                id="start-time"
                options={getTimeOptions()}
                value={{
                  value: moment(
                    handleCheckModernDate(meetingDetails.start)
                  ).format(TIME_FORMAT),
                  label: moment(
                    handleCheckModernDate(meetingDetails.start)
                  ).format(TIME_FORMAT),
                }}
                placeholder="XX:XXam"
                onChange={handleChangeStartTime}
                required
              />
              -
              <SimpleSelect
                id="end-time"
                options={endTimeOptions}
                value={{
                  value: getEndTime(),
                  label: getEndTime(),
                }}
                placeholder="XX:XXam"
                onChange={handleChangeEndTime}
                required
              />
            </div>
          </div>
          <div className="mr-2 mt-1 min-w-[8rem]">
            <p className="text-xs font-bold">Repeats</p>
            <Select
              id="repeat-on"
              options={getRepeatOptions()}
              value={{
                value: intervalToFrequency(meetingDetails.interval ?? 0),
                label: capitalize(
                  intervalToFrequency(meetingDetails.interval ?? 0)
                ),
              }}
              placeholder="Repeats..."
              onChange={changeFrequency}
              isDisabled={event ? true : false}
              required
            />
          </div>
          <div className="mt-1">
            {meetingDetails.interval !== 0 && (
              <>
                <p className="text-xs font-bold">Ends on or before</p>
                <DayInput
                  id={"end-date" + id}
                  value={
                    meetingDetails.until
                      ? moment(meetingDetails.until).toISOString()
                      : ""
                  }
                  classes="py-2 px-1 m-0 border-green-300 border-0 border-b-2 bg-transparent text-sm"
                  setValue={handleChangeEndDate}
                  required
                  min={moment(meetingDetails.start)
                    .add(1, "day")
                    .format(DATE_FORMAT)}
                />
              </>
            )}
          </div>
        </div>
        <div className="col-span-12 grid-rows-5 mt-4">
          <p className="pb-2 text-xs font-bold">Location</p>
          <TextInput
            id="location"
            name="location"
            placeholder="Location"
            value={meetingDetails.location ?? ""}
            setValue={handleChangeLocation}
          />
        </div>
      </div>
    </>
  );
}
