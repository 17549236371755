import { Auth0AccountRole, MeetingResponse } from "client/openapi";
import Meeting from "components/Dashboard/Calendar/MeetingBlocks/Meeting";
import { DialogContent } from "components/Dialog";
import moment from "moment";

export default function EventBlockDialog({
  dateTime,
  meetings,
  allMeetings,
  role,
  setEvents,
}: {
  dateTime: string;
  meetings: MeetingResponse[];
  allMeetings: MeetingResponse[];
  role: Auth0AccountRole;
  setEvents: () => Promise<void>;
}) {
  return (
    <DialogContent className="dialog-content">
      <div className="flex flex-col">
        <h1 className="mb-2">{dateTime}</h1>
        <div className="grid grid-cols-2 gap-2">
          {meetings
            .sort((a, b) => {
              return moment(a.start).diff(moment(b.start));
            })
            .map((mtg, index) => {
              return (
                <Meeting
                  key={index}
                  meeting={mtg}
                  meetings={allMeetings}
                  index={index}
                  role={role}
                  setEvents={setEvents}
                  showAllDetails={true}
                />
              );
            })}
        </div>
      </div>
    </DialogContent>
  );
}
