import { StudentOrgTransactionResponse } from "client/openapi";
import { DialogContent } from "components/Dialog";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

export default function Trends({
  transactions,
}: {
  transactions: StudentOrgTransactionResponse[];
}) {
  const [data, setData] = useState<
    Array<{
      date: number;
      year?: number;
      revenue: number;
      cost: number;
      net: number;
    }>
  >();
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [xAxis, setXAxis] = useState("monthly");

  useEffect(() => {
    const start = moment(startDate);
    const end = moment(endDate);
    const diff = end.diff(start, "days");
    const xData: Array<{
      date: number;
      revenue: number;
      cost: number;
      net: number;
    }> = [];
    if (diff >= 180) {
      const type = "monthly";
      const startMonth = start.month();
      const endMonth = end.month();
      const startYear = start.year();
      const endYear = end.year();
      // push data for each month for each year
      for (let i = startYear; i <= endYear; i++) {
        for (let j = 0; j < 12; j++) {
          if (i === startYear && j < startMonth) {
            continue;
          } else if (i === endYear && j > endMonth) {
            break;
          } else {
            xData.push(
              setXData(xData, j, type, startYear === endYear ? undefined : i)
            );
          }
        }
      }
      setData(xData);
      setXAxis(type);
    } else if (diff >= 90) {
      const type = "weekly";
      for (let i = start.week(); i <= end.week() + 1; i++) {
        xData.push(setXData(xData, i, type));
      }
      setData(xData);
      setXAxis(type);
    } else {
      const type = "daily";
      for (let i = start.dayOfYear(); i <= end.dayOfYear() + 1; i++) {
        xData.push(setXData(xData, i, type));
      }
      setData(xData);
      setXAxis(type);
    }
  }, [startDate, endDate, transactions]);

  const setXData = (xData, i: number, type: string, year?: number) => {
    let currentMonthRevenue = 0;
    let currentMonthCost = 0;
    transactions.forEach((transaction) => {
      let day =
        type === "monthly"
          ? moment(transaction.timestamp).month()
          : type === "weekly"
          ? moment(transaction.timestamp).week()
          : moment(transaction.timestamp).dayOfYear();
      if (year) {
        let transactionYear = moment(transaction.timestamp).year();
        if (transactionYear !== year) {
          return;
        }
      }
      if (day === i) {
        if (transaction.amount < 0) {
          currentMonthCost += transaction.amount;
        } else {
          currentMonthRevenue += transaction.amount;
        }
      }
    });
    let net = currentMonthRevenue + currentMonthCost;
    if (xData.length > 0) {
      net += xData[xData.length - 1].net;
    }
    if (year) {
      return {
        date: i,
        year: year,
        revenue: currentMonthRevenue,
        cost: currentMonthCost * -1,
        net: net,
      };
    }
    return {
      date: i,
      revenue: currentMonthRevenue,
      cost: currentMonthCost * -1,
      net: net,
    };
  };

  const CustomizedXAxisTick = ({
    x,
    y,
    payload,
  }: {
    x?: any;
    y?: any;
    payload?: any;
  }) => {
    if (
      typeof x === "undefined" ||
      typeof y === "undefined" ||
      typeof payload === "undefined"
    ) {
      return null;
    }
    const { value } = payload;
    return (
      <g transform={`translate(${x + 10},${y + 4})`}>
        <text
          x={0}
          y={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          className="text-xs"
        >
          {xAxis === "monthly" && moment().month(value).format("MMM")}
          {xAxis === "weekly" && moment().week(value).format("MM/DD")}
          {xAxis === "daily" && moment().dayOfYear(value).format("MM/DD")}
        </text>
      </g>
    );
  };

  return (
    <DialogContent className="dialog-content min-w-[90%] md:min-w-[80%] lg:min-w-[60%]">
      <div className="my-3 font-semibold header text-lg">Financials</div>
      <div className="flex items-center">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="text-sm border-0 border-b border-gray-300 mr-2 max-w-[8.5rem]"
        />
        -
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="text-sm ml-2 border-0 border-b border-gray-300 max-w-[8.5rem]"
        />
      </div>
      <div className="grid grid-cols-10 mt-4 my-4 min-h-[20rem]">
        <ResponsiveContainer
          width="100%"
          height="100%"
          maxHeight={375}
          className="md:col-span-7 col-span-10"
        >
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tick={<CustomizedXAxisTick />}
              dataKey="date"
              tickMargin={7}
            />
            <YAxis />
            <Line dataKey="revenue" stroke="green" dot={false} />
            <Line dataKey="cost" stroke="red" dot={false} />
            <Area
              dataKey="net"
              stroke="#3DB7E3"
              fill="rgba(62, 182, 227, 0.28)"
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>

        <div className="col-span-10 md:col-span-3 ml-6 md:min-h-[20rem] flex flex-col md:block mt-4 md:mt-0">
          <div className="flex md:block items-center">
            <p className="text-sm font-bold header mr-2">
              Total Revenue (estimated):
            </p>
            <p className="text-sm font-bold header text-green-500">
              ${data?.reduce((a, b) => a + b.revenue, 0).toFixed(2)}
            </p>
          </div>
          <div className="flex md:block items-center mt-2">
            <p className="text-sm font-bold header mr-2">
              Total Cost of Tutoring (estimated):
            </p>
            <p className="text-sm font-bold header text-red-600">
              ${data?.reduce((a, b) => a + b.cost, 0).toFixed(2)}
            </p>
          </div>
          <div className="flex md:block mt-2 items-center">
            <p className="text-sm font-bold header mr-2">
              Total Net Income (estimated):
            </p>
            <p className="text-sm font-bold header text-cyan-400">
              ${data && data[data?.length - 1]?.net.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
