import { DialogContent } from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  ACHResponse,
  CreditCardResponse,
  OrganizationsService,
  StudentResponse,
  StudentsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "./index.css";
import PaymentMethods from "../../../../../../../../components/PaymentMethods/PaymentMethods";
import Cards from "../../../../../../../../components/PaymentMethods/cards";
import Banks from "../../../../../../../../components/PaymentMethods/bank";

export default function PaymentPolicies({
  student,
  orgId,
}: {
  student: StudentResponse;
  orgId: number;
}) {
  const [editing, setEditing] = useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<
    CreditCardResponse | ACHResponse
  >();
  const [newPaymentMethod, setNewPaymentMethod] = useState<string>();
  const [paymentEmail, setPaymentEmail] = useState<string>();
  const [missingPaymentMethod, setMissingPaymentMethod] = useState<boolean>();

  type AllPaymentMethods = CreditCardResponse | ACHResponse;
  const [paymentMethodsOnFile, setPaymentMethodsOnFile] = useState<
    AllPaymentMethods[]
  >([]);

  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    let newMethods: AllPaymentMethods[] = [];

    try {
      const existing_cards = await StudentsService.getStudentCards({
        studentId: student.id,
      });
      if (existing_cards) {
        newMethods = [...newMethods, ...existing_cards];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    try {
      const existing_banks = await StudentsService.getStudentAchAccounts({
        studentId: student.id,
      });
      if (existing_banks) {
        newMethods = [...newMethods, ...existing_banks];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    setPaymentMethodsOnFile(newMethods);
  }, [orgId, student.id]);

  useEffect(() => {
    getAndSetPaymentMethodsOnFile();
  }, [getAndSetPaymentMethodsOnFile]);

  function findPaymentMethodById(paymentMethodId) {
    return Array.from(paymentMethodsOnFile).find(
      (paymentMethod) => paymentMethod.payment_method_id === paymentMethodId
    );
  }

  async function editPaymentPolicy() {
    if (missingPaymentMethod) {
      setError({
        message: "Please select a payment method.",
      });
      return;
    }

    await OrganizationsService.updateStudentPricing({
      orgId: orgId,
      studentId: student.id,
      requestBody: {
        charge_fees_to_payment_method: newPaymentMethod
          ? newPaymentMethod?.includes("@")
            ? ""
            : newPaymentMethod
          : currentPaymentMethod
          ? currentPaymentMethod.payment_method_id
          : "",
        invoice_fees_to_email: newPaymentMethod
          ? newPaymentMethod?.includes("@")
            ? newPaymentMethod
            : ""
          : currentPaymentMethod
          ? ""
          : paymentEmail,
      },
    })
      .then((policy) => {
        setEditing(false);
        setCurrentPaymentMethod(
          policy.charge_fees_to_payment_method
            ? findPaymentMethodById(policy.charge_fees_to_payment_method)
            : undefined
        );
        setPaymentEmail(policy.invoice_fees_to_email as string | undefined);
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Student payment method set!`);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  }

  const getExistingPaymentPolicy = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrganizationsService.getStudentPricingByStudentId({
      orgId: orgId,
      studentId: student.id,
    })
      .then((policy) => {
        setStatus(PageStatus.SUCCESS);
        setCurrentPaymentMethod(
          policy.charge_fees_to_payment_method
            ? findPaymentMethodById(policy.charge_fees_to_payment_method)
            : undefined
        );
        setPaymentEmail(policy.invoice_fees_to_email as string | undefined);
      })
      .catch((error) => {
        setStatus(PageStatus.ERROR);
        setError(error);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId, student.id, paymentMethodsOnFile]);

  function reset() {
    getExistingPaymentPolicy();
    setNewPaymentMethod("");
    setEditing(false);
  }

  useEffect(() => {
    if (!newPaymentMethod) {
      setMissingPaymentMethod(true);
    } else {
      setMissingPaymentMethod(false);
    }
  }, [currentPaymentMethod, newPaymentMethod, paymentEmail]);

  const handleSelectPaymentMethod = (id) => {
    if (typeof id === "string" && id.includes("@")) {
      setNewPaymentMethod(id);
    } else if (typeof id === "object") {
      setNewPaymentMethod(id.payment_method_id);
    } else {
      // Handle unexpected id type if necessary
      console.error("Unexpected payment method type", id);
    }
  };

  useEffect(() => {
    getExistingPaymentPolicy();
  }, [getExistingPaymentPolicy, paymentMethodsOnFile]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <DialogContent onClose={reset} className="custom-payment-policies-dialog ">
      <div className="pl-4 pr-4">
        <div className=" mt-5">
          <span className="font-semibold text-lg header">Fees</span>
        </div>
        <hr className="mt-2 mb-1" />
        <div className="mt-2 ">
          <div>
            The following payment method will be used for fees related to
            absence, cancellation, or rescheduling (if applicable):{" "}
          </div>
          <div className="mt-2">
            {currentPaymentMethod || paymentEmail ? (
              <div>
                {currentPaymentMethod && "brand" in currentPaymentMethod ? (
                  <Cards
                    key={currentPaymentMethod.payment_method_id}
                    card={currentPaymentMethod as CreditCardResponse}
                  />
                ) : currentPaymentMethod &&
                  "bank_name" in currentPaymentMethod ? (
                  <Banks
                    key={currentPaymentMethod.payment_method_id}
                    bank={currentPaymentMethod as ACHResponse}
                  />
                ) : (
                  <span className="text-sm font-bold">
                    Invoice sent to {paymentEmail}
                  </span>
                )}
                <div className="mt-4">
                  <Button
                    color={ButtonColor.PURPLE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      setEditing(!editing);
                      setNewPaymentMethod("");
                    }}
                  >
                    {editing ? "Stop Editing" : "Change Payment Method"}
                  </Button>
                </div>
                {editing && (
                  <PaymentMethods
                    studentIfInitiatedByAdmin={student}
                    showAddCardButton
                    showAction
                    selectable
                    handleSelectPaymentMethod={handleSelectPaymentMethod}
                  />
                )}
              </div>
            ) : (
              <div>
                <span className="font-semibold text-sm">
                  No payment method has been set. Add one:{" "}
                </span>
                <PaymentMethods
                  studentIfInitiatedByAdmin={student}
                  showAddCardButton
                  showAction
                  selectable
                  handleSelectPaymentMethod={handleSelectPaymentMethod}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full text-center mt-6">
          <Button
            color={missingPaymentMethod ? ButtonColor.GRAY : ButtonColor.GREEN}
            size={ButtonSize.DEFAULT}
            onClick={editPaymentPolicy}
            disabled={missingPaymentMethod}
          >
            Save
          </Button>
        </div>
      </div>
    </DialogContent>
  );
}
