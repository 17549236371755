import React, { useCallback, useContext, useEffect, useState } from "react";
import RadioInput from "components/Inputs/RadioInput";
import styles from "../styles.module.css";
import {
  AccountRolesService,
  Auth0AccountRole,
  StudentResponse,
  StudentsService,
  TutorResponse,
  TutorsService,
} from "client/openapi";
import Notifications from "util/notifications";
import Select from "components/Select";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { Student } from "types";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export interface OrganizationTabProps {
  changeHandler: (values: Omit<OrganizationTabProps, "changeHandler">) => void;
  autoapprove_payroll_entries: boolean;
  tutors_can_view_unassigned_students: boolean;
}

export const OrganizationTabContent: React.FC<OrganizationTabProps> = ({
  changeHandler,
  autoapprove_payroll_entries,
  tutors_can_view_unassigned_students,
}) => {
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );
  const [tutorUpForPromotion, setTutorUpForPromotion] =
    useState<TutorResponse>();
  const [tutor, setTutor] = useState<TutorResponse>();
  const [tutors, setTutors] = useState<TutorResponse[]>([]);
  const [student, setStudent] = useState<StudentResponse>();
  const [students, setStudents] = useState<StudentResponse[]>([]);
  const [dontSpamRemoveTutorButton, setDontSpamRemoveTutorButton] =
    useState<boolean>(false);
  const [dontSpamRemoveStudentButton, setDontSpamRemoveStudentButton] =
    useState<boolean>(false);
  const [dontSpamAdminPromotion, setDontSpamAdminPromotion] =
    useState<boolean>(false);

  const handleChange = (input: boolean, fromStudentVisibility: boolean) => {
    const newValues = {
      tutors_can_view_unassigned_students: fromStudentVisibility
        ? input
        : tutors_can_view_unassigned_students,
      autoapprove_payroll_entries: fromStudentVisibility
        ? autoapprove_payroll_entries
        : input,
    };
    changeHandler(newValues);
  };

  const handleTutorUpForPromotionChange = (option: any) => {
    setTutorUpForPromotion(option);
  };

  const handleTutorChange = (option: any) => {
    setTutor(option);
  };

  const handleStudentChange = (option: any) => {
    setStudent(option);
  };

  const getAndSetTutors = useCallback(async () => {
    TutorsService.getTutorsByOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((tutors) => {
        setTutors(tutors);
      })
      .catch((error) => {
        Notifications.error("Could not get tutors");
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [currently_selected_organization]);

  const getAndSetStudents = useCallback(async () => {
    StudentsService.getStudentsByOrganizationIfAdmin({
      orgId: currently_selected_organization as number,
    })
      .then((students) => {
        setStudents(students);
      })
      .catch((error) => {
        Notifications.error("Could not get students");
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [currently_selected_organization]);

  useEffect(() => {
    getAndSetTutors();
    getAndSetStudents();
  }, [getAndSetStudents, getAndSetTutors]);

  // async function addAccountRole() {
  //   AccountRolesService.addRole({
  //     requestBody: {
  //       role: Auth0AccountRole.ORG_ADMIN,
  //       account_id: tutorUpForPromotion?.account.reference_id as string,
  //     },
  //   })
  //     .then(() => {
  //       Notifications.success("This tutor is now an admin!");
  //       setDontSpamAdminPromotion(false);
  //     })
  //     .catch(() => {
  //       Notifications.error("Can not make this user an admin at this time!");
  //       setDontSpamAdminPromotion(false);
  //     });
  // }

  // async function removeTutor() {
  //   TutorsService.removeTutorFromOrg({
  //     tutorId: tutor?.id as number,
  //     orgId: currently_selected_organization as number,
  //   })
  //     .then(() => {
  //       Notifications.success("Tutor removed from org!");
  //       setDontSpamRemoveTutorButton(false);
  //     })
  //     .catch(() => {
  //       Notifications.error("Can not remove this user at this time!");
  //       setDontSpamRemoveTutorButton(false);
  //     });
  // }

  // async function removeStudent() {
  //   StudentsService.removeStudentFromOrg({
  //     studentId: student?.id as number,
  //     orgId: currently_selected_organization,
  //   })
  //     .then(() => {
  //       Notifications.success("Student removed from org!");
  //       setDontSpamRemoveStudentButton(false);
  //     })
  //     .catch(() => {
  //       Notifications.error("Can not remove this student at this time!");
  //       setDontSpamRemoveStudentButton(false);
  //     });
  // }

  return (
    <>
      <div className={styles.infoItem}>
        <p className={styles.label}>Tutors Can :</p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"book-sessions-with-all-studnets"}
            name={"book-sessions-with-all-studnets"}
            value={"true"} // return true on handleChange
            label="See and book sessions with all students."
            setValue={(e) => handleChange(Boolean(e.target.value), true)}
            checked={tutors_can_view_unassigned_students === true}
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"see-sessions-with-paired-students"}
            name={"see-sessions-with-paired-students"}
            value={""} // return false on handleChange
            label="Only see and book sessions with students they are paired with."
            setValue={(e) => handleChange(Boolean(e.target.value), true)}
            checked={tutors_can_view_unassigned_students === false}
          />
        </div>
      </div>
      <div className={styles.infoItem}>
        <p className={styles.label}>
          Auto approve events on calendar for tutor payroll :
        </p>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"approve-events"}
            name={"approve-events"}
            value={"true"} // return true on handleChange
            label="Yes"
            setValue={(e) => handleChange(Boolean(e.target.value), false)}
            checked={autoapprove_payroll_entries === true}
          />
        </div>
        <div className={styles.radioContainer}>
          <RadioInput
            id={"dont-approve-events"}
            name={"dont-approve-events"}
            value={""} // return false on handleChange
            label="No"
            setValue={(e) => handleChange(Boolean(e.target.value), false)}
            checked={autoapprove_payroll_entries === false}
          />
        </div>
      </div>
      {/* <div>
        <p className="text-sm font-bold mb-1">Make a Tutor an Admin</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="tutors-up-for-promotion"
              options={tutors}
              value={tutorUpForPromotion as TutorResponse}
              getOptionLabel={(t) =>
                t.account.first_name + " " + t.account.last_name
              }
              getOptionValue={(t) => t.account.reference_id}
              placeholder="Tutor..."
              isDisabled={tutors?.length === 0}
              onChange={handleTutorUpForPromotionChange}
            />
          </div>
          <div>
            <Button
              color={
                tutorUpForPromotion === undefined ||
                tutorUpForPromotion === null ||
                dontSpamAdminPromotion
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                tutorUpForPromotion === undefined ||
                tutorUpForPromotion === null ||
                dontSpamAdminPromotion
              }
              onClick={addAccountRole}
              size={ButtonSize.DEFAULT}
            >
              Make Admin
            </Button>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-bold mt-5 mb-1">Remove a Tutor</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="tutors"
              options={tutors}
              value={tutor as TutorResponse}
              getOptionLabel={(t) =>
                t.account.first_name + " " + t.account.last_name
              }
              getOptionValue={(t) => t.account.reference_id}
              placeholder="Tutor..."
              isDisabled={tutors?.length === 0}
              onChange={handleTutorChange}
            />
          </div>
          <div>
            <Button
              color={
                tutor === undefined ||
                tutor === null ||
                dontSpamRemoveTutorButton
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                tutor === undefined ||
                tutor === null ||
                dontSpamRemoveTutorButton
              }
              onClick={removeTutor}
              size={ButtonSize.DEFAULT}
            >
              Remove Tutor
            </Button>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-bold mt-5 mb-1">Remove a Student</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="students"
              options={students}
              value={student as StudentResponse}
              getOptionLabel={(s) =>
                s.account.first_name + " " + s.account.last_name
              }
              getOptionValue={(s) => s.account.reference_id}
              placeholder="Student..."
              isDisabled={students?.length === 0}
              onChange={handleStudentChange}
            />
          </div>
          <div>
            <Button
              color={
                student === undefined ||
                student === null ||
                dontSpamRemoveStudentButton
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                student === undefined ||
                student === null ||
                dontSpamRemoveStudentButton
              }
              onClick={removeStudent}
              size={ButtonSize.DEFAULT}
            >
              Remove Student
            </Button>
          </div>
        </div>
      </div> */}
    </>
  );
};
