/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represent the types of organization transactions.
 */
export enum OrgTransactionType {
  TADPOLE_ORG_TRANSACTION = "tadpole_org_transaction",
  ORG_BANK_TRANSACTION = "org_bank_transaction",
  STUDENT_ORG_TRANSACTION = "student_org_transaction",
}
