/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents values to sort by for payroll exports.
 */
export enum SortBy {
  DATE = "Date",
  STUDENT = "Student",
  TUTOR = "Tutor",
}
