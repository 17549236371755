import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor, ButtonSize, LinkButton } from "components/Button";
import "styles/LandingPage/index.css";

export default function RequestDemo() {
  return (
    <>
      <h3 className="max-w-lg ml-auto mr-auto text-3xl font-thin text-center">
        Get fully onboarded in a matter of days. See{" "}
        <b className="landing-page--text-gradient">student well-being</b> and
        <b className="landing-page--text-gradient"> testing outcomes</b>{" "}
        skyrocket.
      </h3>

      <div className="flex flex-col items-center p-10 mt-2">
        <LinkButton
          to="#"
          color={ButtonColor.BLUE}
          size={ButtonSize.LARGE}
          extraClasses="shadow-md"
          style={{
            height: "70px",
            border: "none",
            fontSize: "20px",
            background: "linear-gradient(90deg, #5D75F4 0%, #30CEDC 100%)",
          }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
          }}
        >
          Request a Demo <ArrowRightIcon />
        </LinkButton>
      </div>
    </>
  );
}
