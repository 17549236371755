import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  InviteType,
  OrganizationResponse,
  TutorResponse,
  TutorsService,
} from "client/openapi";
import Avatar from "components/Avatar";
import {
  Button,
  ButtonColor,
  ButtonFill,
  ButtonSize,
  LinkButton,
} from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import { Dialog, DialogTrigger } from "components/Dialog";
import { TagColor, TagSize } from "components/Tag";
import { Tag } from "components/Tag/TagChip";
import { useContext, useEffect, useState } from "react";
import { AccountStatus, Organization, UserType } from "types";
import { concatenateName } from "util/concatenateName";
import { getButtonRoleColor, getRoleColor } from "util/contextColor";
import { generateInitials } from "util/generateInitials";
import EmptyState from "../EmptyState";
import PageNav from "../PageNav";
import "./index.css";
import UserListActions from "../UserListActions";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function TutorsList({
  organization,
  role,
}: {
  organization: OrganizationResponse;
  role: Auth0AccountRole;
}) {
  const [initialTutors, setInitialTutors] = useState<TutorResponse[]>([]);
  const [tutors, setTutors] = useState<TutorResponse[]>([]);
  const [phrase, setPhrase] = useState<string>("");
  const [debouncedPhrase, setDebouncedPhrase] = useState<string>(phrase);

  // TODO: Remove this and use organization.totalTutors when it is supported
  const [totalTutors, setTotalTutors] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const limit = 10;

  async function fetchTutors(pageNumber: number): Promise<void> {
    const skip = pageNumber * limit;
    if (organization) {
      if (phrase === "" && initialTutors.length === 0) {
        await TutorsService.getTutorsByOrganization({
          orgId: organization.id,
          skip: skip,
          limit: limit,
        }).then((data: TutorResponse[]) => {
          setInitialTutors(data);
          setTutors(data);
        });
      } else {
        await TutorsService.getTutorsByOrganization({
          orgId: organization.id,
          phrase: phrase && phrase.length > 2 ? phrase : undefined,
          skip: skip,
          limit: limit,
        }).then((data: TutorResponse[]) => {
          setTutors(data);
          if (phrase !== "") {
            setTotalTutors(data.length);
            setNumPages(Math.ceil(data.length / limit));
          }
        });
      }
    }
  }

  // update `phrase` after 1 second
  useEffect(() => {
    const timer = setTimeout(() => setPhrase(debouncedPhrase), 1000);
    return () => clearTimeout(timer);
  }, [debouncedPhrase]);

  // submit a new search
  useEffect(() => {
    if (phrase !== "") {
      setCurrentPage(0);
      fetchTutors(0);
    } else {
      fetchTutors(currentPage);
      setTotalTutors(organization ? organization.tutors.length : 0);
      setNumPages(
        Math.ceil((organization ? organization.tutors.length : 0) / limit)
      );
    }
  }, [phrase]);

  return (
    <>
      {initialTutors.length > 0 ? (
        <div className="py-6 px-7 rounded-xl bg-neutral-50">
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <div className="flex flex-col items-start">
              <h2 className="text-xl font-bold">Tutors</h2>
              {tutors.length > 0 ? (
                <PageNav
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  numPages={numPages}
                  limit={limit}
                  totalUsers={totalTutors}
                  role={role}
                  fetchUsers={fetchTutors}
                />
              ) : null}
            </div>

            <UserListActions
              users={tutors}
              userType={Auth0AccountRole.ORG_TUTOR}
              buttonColor={ButtonColor.PURPLE}
              debouncedPhrase={debouncedPhrase}
              setDebouncedPhrase={setDebouncedPhrase}
              role={role}
              fetchUsers={fetchTutors}
              currentPage={currentPage}
              orgId={organization.id}
            />
          </div>

          {tutors.length > 0 ? (
            <div className="grid gap-8 mt-6 lg:grid-cols-2">
              {tutors.map((tutor) => (
                <TutorListItem
                  key={tutor.id}
                  role={role}
                  tutor={tutor}
                  organization={organization}
                />
              ))}
            </div>
          ) : (
            <EmptyState
              type_of_user_being_invited={Auth0AccountRole.ORG_TUTOR}
              includeInviteUserDialog={false}
              title="No tutors"
              message="Please search again"
              buttonColor={ButtonColor.PURPLE}
              fetchUsers={fetchTutors}
              currentPage={currentPage}
              orgId={organization.id}
            />
          )}
        </div>
      ) : (
        <EmptyState
          type_of_user_being_invited={Auth0AccountRole.ORG_TUTOR}
          includeInviteUserDialog={true}
          title="No tutors"
          message="Your organization does not have any tutors yet."
          buttonColor={ButtonColor.PURPLE}
          fetchUsers={fetchTutors}
          currentPage={currentPage}
          orgId={organization.id}
        />
      )}
    </>
  );
}

function TutorListItem({
  role,
  tutor,
  organization,
}: {
  role: Auth0AccountRole;
  tutor: TutorResponse;
  organization?: OrganizationResponse;
}) {
  const { account } = useContext(OrgRolesAndAccountContext);

  const [avoidSpammingInviteButton, setAvoidSpammingInviteButton] =
    useState<boolean>(false);

  async function reinviteTutor() {
    TutorsService.reinviteTutor({
      requestBody: {
        first_name: tutor.first_name,
        last_name: tutor.last_name,
        phone_number: tutor.phone_number ? tutor.phone_number : undefined,
        email: tutor.email as string,
        org_invited_by: tutor.org_id,
        acct_invited_by: account?.reference_id as string,
        type: InviteType.TUTOR,
        user_id: tutor.id,
      },
    })
      .then((s) => {
        Notifications.success("Tutor reinvited!");
        setAvoidSpammingInviteButton(false);
      })
      .catch((e: ApiError) => {
        Notifications.error("Unable to reinvite tutor");
        console.error(`Error (#${e.status}): ${e.message}`);
        setAvoidSpammingInviteButton(false);
      });
  }

  return (
    <div className="flex flex-row items-center justify-between tutor-list-container">
      <div className="flex flex-row items-center gap-4">
        <Avatar
          src={tutor.image_url}
          fallback={generateInitials(concatenateName(tutor))}
          alt={generateInitials(concatenateName(tutor))}
          color={getRoleColor(role)}
        />

        <div>
          {organization && !tutor.account && (
            <div className="mb-1">
              <Tag
                size={TagSize.MINI}
                color={TagColor.GOLD}
                item={AccountStatus.INVITED}
              />
            </div>
          )}

          <h3 className="m-0 primsecond-text">
            {tutor.first_name} {tutor.last_name}
          </h3>

          <p className="small-text text-muted">{tutor.email}</p>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:items-center md:flex-row tutor-list-buttons">
        {!tutor.account && (
          <Button
            size={ButtonSize.SMALL}
            color={
              avoidSpammingInviteButton
                ? ButtonColor.PURPLE
                : getButtonRoleColor(role)
            }
            disabled={avoidSpammingInviteButton}
            fill={ButtonFill.DEFAULT}
            onClick={() => {
              setAvoidSpammingInviteButton(true);
              reinviteTutor();
            }}
          >
            Reinvite
          </Button>
        )}
        <Dialog>
          <DialogTrigger asChild>
            <Button
              size={ButtonSize.SMALL}
              color={ButtonColor.PURPLE}
              fill={ButtonFill.HOLLOW}
            >
              Message
            </Button>
          </DialogTrigger>

          <ComposeMessageDialog tutors={[tutor]} org_id={tutor.org_id} />
        </Dialog>

        <LinkButton
          to={`/manage/tutors/${tutor.id}`}
          size={ButtonSize.SMALL}
          color={ButtonColor.PURPLE}
        >
          View Details <ArrowRightIcon />
        </LinkButton>
      </div>
    </div>
  );
}

export default TutorsList;
