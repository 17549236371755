import SignupType from "../SignupType";
import SignupCredentials from "../SignupCredentials";
import SignupContact from "../SignupContact";
import SignupAddress from "../SignupAddress";
import { AccountRoles } from "types";
import SignupSuccess from "../SignupSuccess";
import SignupOrgDetails from "../SignupOrgDetails";

function SignupWizard({
  account,
  step,
  setStep,
  type,
  setType,
  values,
  handleUpdate,
  submitCredentials,
  submitOrgDetails,
  submitContact,
  submitAddress,
  loading,
  errors,
}) {
  return (
    <section className="auth">
      <div className="container mx-auto auth__form">
        {/* BACK BUTTON */}
        {step > 2 && step < 4 && (
          <button className="mb-4 btn__back" onClick={() => setStep(step - 1)}>
            <svg
              width={14}
              height={9}
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.83398L7 7.77539L13 1.83398"
                stroke="#262626"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="small-text">Back</span>
          </button>
        )}

        {/* STUDENT STEPS */}
        {step !== 0 && type === AccountRoles.STUDENT && (
          <div className="signup__nav signup__nav_3">
            <div
              className={`signup__nav_circle ${step === 1 && "active"} ${
                step > 1 && "active_bg"
              }`}
            >
              1
            </div>

            <div className={`signup__nav_line ${step > 1 && "line_active"}`} />

            <div
              className={`signup__nav_circle ${step === 2 && "active"} ${
                step > 2 && "active_bg"
              }`}
            >
              {step >= 2 && 2}
            </div>

            <div className={`signup__nav_line ${step > 2 && "line_active"}`} />

            <div className={`signup__nav_circle ${step === 5 && "active"}`}>
              {step === 5 && 3}
            </div>
          </div>
        )}

        {/* TUTOR STEPS */}
        {step !== 0 && type === AccountRoles.TUTOR && (
          <div className="signup__nav">
            <div
              className={`signup__nav_circle ${step === 1 && "active"} ${
                step > 1 && "active_bg"
              }`}
            >
              1
            </div>

            <div className={`signup__nav_line ${step > 1 && "line_active"}`} />

            <div
              className={`signup__nav_circle ${step === 2 && "active"} ${
                step > 2 && "active_bg"
              }`}
            >
              {step >= 2 && 2}
            </div>

            <div className={`signup__nav_line ${step > 2 && "line_active"}`} />

            <div
              className={`signup__nav_circle ${step === 3 && "active"} ${
                step > 3 && "active_bg"
              }`}
            >
              {step >= 3 && 3}
            </div>

            <div className={`signup__nav_line ${step > 2 && "line_active"}`} />

            <div className={`signup__nav_circle ${step === 5 && "active"}`}>
              {step === 5 && 4}
            </div>
          </div>
        )}

        {/* ORG STEPS */}
        {step !== 0 && type === AccountRoles.ORGANIZATION && (
          <div className="signup__nav signup__nav_5">
            <div
              className={`signup__nav_circle ${step === 1 && "active"} ${
                step > 1 && "active_bg"
              }`}
            >
              1
            </div>

            <div className={`signup__nav_line ${step > 1 && "line_active"}`} />

            <div
              className={`signup__nav_circle ${step === 2 && "active"} ${
                step > 2 && "active_bg"
              }`}
            >
              {step >= 2 && 2}
            </div>

            <div className={`signup__nav_line ${step > 2 && "line_active"}`} />

            <div
              className={`signup__nav_circle ${step === 3 && "active"} ${
                step > 3 && "active_bg"
              }`}
            >
              {step >= 3 && 3}
            </div>

            <div className={`signup__nav_line ${step > 3 && "line_active"}`} />

            <div
              className={`signup__nav_circle ${step === 4 && "active"} ${
                step > 3 && "active_bg"
              }`}
            >
              {step >= 4 && 4}
            </div>

            <div className={`signup__nav_line ${step > 4 && "line_active"}`} />

            <div className={`signup__nav_circle ${step === 5 && "active"}`}>
              {step === 5 && 5}
            </div>
          </div>
        )}

        {step === 0 && <SignupType setStep={setStep} setType={setType} />}

        {/* {step === 1 && (
          <SignupCredentials
            values={values}
            handleUpdate={handleUpdate}
            handleSubmit={submitCredentials}
            loading={loading}
            errors={errors}
          />
        )} */}

        {step === 1 && !account && (
          <SignupContact
            values={values}
            handleUpdate={handleUpdate}
            handleSubmit={submitContact}
            setStep={setStep}
            loading={loading}
            errors={errors}
          />
        )}

        {step === 2 && (
          <SignupOrgDetails
            values={values}
            handleUpdate={handleUpdate}
            handleSubmit={submitOrgDetails}
            loading={loading}
            errors={errors}
          />
        )}

        {step === 3 && (
          <SignupAddress
            values={values}
            handleUpdate={handleUpdate}
            handleSubmit={submitAddress}
            loading={loading}
            errors={errors}
          />
        )}

        {step === 4 && <SignupSuccess type={type} />}
      </div>
    </section>
  );
}

export default SignupWizard;
