import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonColor } from "components/Button";
import { Dialog, DialogTrigger } from "components/Dialog";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AccountRoles } from "types/accountRoles";

import {
  UserSettingsDialog,
  UserSettingsValue,
} from "components/UserSettingsDialog";
import {
  AccountResponse,
  AccountStatus,
  AccountsService,
  Auth0AccountRole,
  OrganizationsService,
} from "client/openapi";
import { concatenateName } from "util/concatenateName";
import { generateInitials } from "util/generateInitials";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import styles from "./styles.module.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

const emptyAccount = {
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
  id: 1,
  reference_id: "",
  status: AccountStatus.INVITED,
  created_at: "2024-02-25T19:42:03.315649",
  modified_at: "",
  role: 25,
  google_calendar_enabled: false,
  outlook_calendar_enabled: false,
  availability: [],
};

const ProfileDropDown = () => {
  const { logout } = useAuth0();
  const { isAuthenticated, user, isLoading } = useAuth0();
  const { currently_selected_role, currently_selected_organization, account } =
    useContext(OrgRolesAndAccountContext);

  const [isOpen, setIsOpen] = useState(false);

  const [settingsValues, setSettingsValues] = useState<UserSettingsValue>({
    Personal: emptyAccount,
    Organization: {
      autoapprove_payroll_entries: false,
      tutors_can_view_unassigned_students: false,
    },
  });

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem(
      account ? account.reference_id : "OrgRolesAndAccountState"
    );
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const getSettingsData = useCallback(() => {
    if (!isLoading) {
      setSettingsValues((prevSettings) => ({
        ...prevSettings,
        Personal: account as AccountResponse,
      }));

      if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
        OrganizationsService.getOrganizationSettings({
          orgId: currently_selected_organization as number,
        }).then((orgRes) => {
          const {
            autoapprove_payroll_entries,
            tutors_can_view_unassigned_students,
          } = orgRes;

          setSettingsValues((prevSettings) => ({
            ...prevSettings,
            Organization: {
              autoapprove_payroll_entries:
                autoapprove_payroll_entries as boolean,
              tutors_can_view_unassigned_students:
                tutors_can_view_unassigned_students as boolean,
            },
          }));
        });
      }
    }
  }, [currently_selected_organization, currently_selected_role, isLoading]);

  useEffect(() => {
    if (!isLoading && account) {
      getSettingsData();
    }
  }, [
    getSettingsData,
    isAuthenticated,
    isLoading,
    currently_selected_organization,
    account,
  ]);

  //   useEffect(() => {
  //     document.removeEventListener("mousedown", handleClickOutside);

  //     // Function to handle clicks outside the dropdown
  //     function handleClickOutside(event) {
  //       if (
  //         isOpen &&
  //         dropdownRef.current &&
  //         !dropdownRef.current.contains(event.target)
  //       ) {
  //         setIsOpen(false);
  //       }
  //     }

  //     // Add event listener when component mounts
  //     document.addEventListener("mousedown", handleClickOutside);

  //     // Clean up the event listener when component unmounts
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [isOpen]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <Button
        color={ButtonColor.GREEN}
        onClick={toggleDropdown}
        extraClasses={"nav__button " + (isOpen ? styles.activeDropDown : "")}
      >
        <div className={styles.profileBtn}>
          <ChevronDownIcon />
          <span>{account?.first_name}</span>
          <div className={styles.initials}>
            <p>
              {account ? generateInitials(concatenateName(account), 2) : ""}
            </p>
          </div>
        </div>
      </Button>
      {isOpen && (
        <div className={styles.dropdownContent}>
          <Dialog>
            <DialogTrigger asChild>
              <button>
                {currently_selected_role === Auth0AccountRole.ORG_ADMIN &&
                  "Personal "}
                Settings
              </button>
            </DialogTrigger>
            <UserSettingsDialog inOrgSettings={false} values={settingsValues} />
          </Dialog>
          {currently_selected_role === Auth0AccountRole.ORG_ADMIN && (
            <Dialog>
              <DialogTrigger asChild>
                <button>Organization Settings</button>
              </DialogTrigger>
              <UserSettingsDialog
                inOrgSettings={true}
                values={settingsValues}
              />
            </Dialog>
          )}
          <button onClick={handleLogout}>Log Out</button>
        </div>
      )}
    </div>
  );
};

export default ProfileDropDown;
