/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartPrototypeCreate } from "../models/ChartPrototypeCreate";
import type { ChartPrototypeResponse } from "../models/ChartPrototypeResponse";
import type { ChartPrototypeUpdate } from "../models/ChartPrototypeUpdate";
import type { ChartSubjectCreate } from "../models/ChartSubjectCreate";
import type { ChartSubjectResponse } from "../models/ChartSubjectResponse";
import type { ChartSubscriptionCreate } from "../models/ChartSubscriptionCreate";
import type { ChartSubscriptionResponse } from "../models/ChartSubscriptionResponse";
import type { ChartSubscriptionUpdate } from "../models/ChartSubscriptionUpdate";
import type { DeleteResponse } from "../models/DeleteResponse";
import type { MetricDefinitionCreate } from "../models/MetricDefinitionCreate";
import type { MetricDefinitionResponse } from "../models/MetricDefinitionResponse";
import type { MetricDefinitionUpdate } from "../models/MetricDefinitionUpdate";
import type { MetricValueCreate } from "../models/MetricValueCreate";
import type { MetricValueResponse } from "../models/MetricValueResponse";
import type { MetricValueUpdate } from "../models/MetricValueUpdate";
import type { SubscriptionValueCreate } from "../models/SubscriptionValueCreate";
import type { SubscriptionValueResponse } from "../models/SubscriptionValueResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ChartsService {
  /**
   * Get Chart Subscription
   * Get a chart subscription by its ID.
   * @returns ChartSubscriptionResponse Successful Response
   * @throws ApiError
   */
  public static getChartSubscription({
    chartSubscriptionId,
  }: {
    chartSubscriptionId: number;
  }): CancelablePromise<ChartSubscriptionResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_subscription/{chart_subscription_id}",
      path: {
        chart_subscription_id: chartSubscriptionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Chart Subscription
   * Update a chart subscription.
   * @returns ChartSubscriptionResponse Successful Response
   * @throws ApiError
   */
  public static updateChartSubscription({
    chartSubscriptionId,
    requestBody,
  }: {
    chartSubscriptionId: number;
    requestBody: ChartSubscriptionUpdate;
  }): CancelablePromise<ChartSubscriptionResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/charts/chart_subscription/{chart_subscription_id}",
      path: {
        chart_subscription_id: chartSubscriptionId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Chart Subscription
   * Delete a chart subscription.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteChartSubscription({
    chartSubscriptionId,
  }: {
    chartSubscriptionId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/charts/chart_subscription/{chart_subscription_id}",
      path: {
        chart_subscription_id: chartSubscriptionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get All Chart Subscriptions For Student
   * Get all chart subscriptions for a given student in an organization.
   * @returns ChartSubscriptionResponse Successful Response
   * @throws ApiError
   */
  public static getAllChartSubscriptionsForStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<ChartSubscriptionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_subscription/charts_by_student/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get All Aggregate Chart Subscriptions In Org
   * Get all aggregate charts in an organization.
   * @returns ChartSubscriptionResponse Successful Response
   * @throws ApiError
   */
  public static getAllAggregateChartSubscriptionsInOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<ChartSubscriptionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_subscription_aggregate_charts/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Chart Subscription
   * Create a chart subscription.
   * @returns ChartSubscriptionResponse Successful Response
   * @throws ApiError
   */
  public static createChartSubscription({
    requestBody,
  }: {
    requestBody: ChartSubscriptionCreate;
  }): CancelablePromise<ChartSubscriptionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charts/chart_subscription",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Data For Chart Subscription
   * Get all metric values for a chart subscription.
   * @returns MetricValueResponse Successful Response
   * @throws ApiError
   */
  public static getDataForChartSubscription({
    chartSubscriptionId,
  }: {
    chartSubscriptionId: number;
  }): CancelablePromise<Array<MetricValueResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_subscription_data/{chart_subscription_id}",
      path: {
        chart_subscription_id: chartSubscriptionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Metric Value
   * Get a metric value by its ID.
   * @returns MetricValueResponse Successful Response
   * @throws ApiError
   */
  public static getMetricValue({
    metricValueId,
  }: {
    metricValueId: number;
  }): CancelablePromise<MetricValueResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/metric_value/{metric_value_id}",
      path: {
        metric_value_id: metricValueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Metric Value
   * Update a metric value.
   * @returns MetricValueResponse Successful Response
   * @throws ApiError
   */
  public static updateMetricValue({
    metricValueId,
    requestBody,
  }: {
    metricValueId: number;
    requestBody: MetricValueUpdate;
  }): CancelablePromise<MetricValueResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/charts/metric_value/{metric_value_id}",
      path: {
        metric_value_id: metricValueId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Metric Value
   * Delete a metric value.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteMetricValue({
    metricValueId,
  }: {
    metricValueId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/charts/metric_value/{metric_value_id}",
      path: {
        metric_value_id: metricValueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Metric Value
   * Create a metric value.
   * @returns MetricValueResponse Successful Response
   * @throws ApiError
   */
  public static createMetricValue({
    requestBody,
  }: {
    requestBody: MetricValueCreate;
  }): CancelablePromise<MetricValueResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charts/metric_value",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Metric Definition By Id
   * Get metric definition by id.
   * @returns MetricDefinitionResponse Successful Response
   * @throws ApiError
   */
  public static getMetricDefinitionById({
    metricId,
  }: {
    metricId: number;
  }): CancelablePromise<MetricDefinitionResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/metric_definitions/{metric_id}",
      path: {
        metric_id: metricId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Metric Definition
   * Update a metric metric_definition record in the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateMetricDefinition({
    metricId,
    requestBody,
  }: {
    metricId: number;
    requestBody: MetricDefinitionUpdate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/charts/metric_definitions/{metric_id}",
      path: {
        metric_id: metricId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Metric Definition
   * Delete a metric_definition record in the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteMetricDefinition({
    metricId,
  }: {
    metricId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/charts/metric_definitions/{metric_id}",
      path: {
        metric_id: metricId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Metric Definition
   * Create a metric_definition record in the database.
   * @returns MetricDefinitionResponse Successful Response
   * @throws ApiError
   */
  public static createMetricDefinition({
    requestBody,
  }: {
    requestBody: MetricDefinitionCreate;
  }): CancelablePromise<MetricDefinitionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charts/metric_definitions",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Chart Prototype
   * Get a chart prototype by its ID.
   * @returns ChartPrototypeResponse Successful Response
   * @throws ApiError
   */
  public static getChartPrototype({
    chartPrototypeId,
  }: {
    chartPrototypeId: number;
  }): CancelablePromise<ChartPrototypeResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_prototype/{chart_prototype_id}",
      path: {
        chart_prototype_id: chartPrototypeId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Chart Prototype
   * Update a chart prototype.
   * @returns ChartPrototypeResponse Successful Response
   * @throws ApiError
   */
  public static updateChartPrototype({
    chartPrototypeId,
    requestBody,
  }: {
    chartPrototypeId: number;
    requestBody: ChartPrototypeUpdate;
  }): CancelablePromise<ChartPrototypeResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/charts/chart_prototype/{chart_prototype_id}",
      path: {
        chart_prototype_id: chartPrototypeId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Chart Prototype
   * Delete a chart prototype.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteChartPrototype({
    chartPrototypeId,
  }: {
    chartPrototypeId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/charts/chart_prototype/{chart_prototype_id}",
      path: {
        chart_prototype_id: chartPrototypeId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Chart Prototypes In Org
   * Get all chart prototypes that have a given org ID.
   * @returns ChartPrototypeResponse Successful Response
   * @throws ApiError
   */
  public static getChartPrototypesInOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<ChartPrototypeResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_prototypes_for_org/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Chart Prototype
   * Create a chart prototype.
   * @returns ChartPrototypeResponse Successful Response
   * @throws ApiError
   */
  public static createChartPrototype({
    requestBody,
  }: {
    requestBody: ChartPrototypeCreate;
  }): CancelablePromise<ChartPrototypeResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charts/chart_prototype",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Chart Subject
   * Get a chart subject by its ID.
   * @returns ChartSubjectResponse Successful Response
   * @throws ApiError
   */
  public static getChartSubject({
    chartSubjectId,
  }: {
    chartSubjectId: number;
  }): CancelablePromise<ChartSubjectResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_subject/{chart_subject_id}",
      path: {
        chart_subject_id: chartSubjectId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Chart Subject
   * Delete a chart subject.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteChartSubject({
    chartSubjectId,
  }: {
    chartSubjectId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/charts/chart_subject/{chart_subject_id}",
      path: {
        chart_subject_id: chartSubjectId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Chart Subjects By Chart Prototype
   * Get all chart subjects for a given chart prototype.
   * @returns ChartSubjectResponse Successful Response
   * @throws ApiError
   */
  public static getChartSubjectsByChartPrototype({
    chartPrototypeId,
  }: {
    chartPrototypeId: number;
  }): CancelablePromise<Array<ChartSubjectResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/chart_subject/chart_prototype/{chart_prototype_id}",
      path: {
        chart_prototype_id: chartPrototypeId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Chart Subject
   * Create a chart subject.
   * @returns ChartSubjectResponse Successful Response
   * @throws ApiError
   */
  public static createChartSubject({
    requestBody,
  }: {
    requestBody: ChartSubjectCreate;
  }): CancelablePromise<ChartSubjectResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charts/chart_subject",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Subscription Value
   * Get a subscription value by its ID.
   * @returns SubscriptionValueResponse Successful Response
   * @throws ApiError
   */
  public static getSubscriptionValue({
    subscriptionValueId,
  }: {
    subscriptionValueId: number;
  }): CancelablePromise<SubscriptionValueResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/subscription_value/{subscription_value_id}",
      path: {
        subscription_value_id: subscriptionValueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Subscription Value
   * Delete a subscription value.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteSubscriptionValue({
    subscriptionValueId,
  }: {
    subscriptionValueId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/charts/subscription_value/{subscription_value_id}",
      path: {
        subscription_value_id: subscriptionValueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Subscription Values By Chart Subscription
   * Get all subscription values for a given chart subscription.
   * @returns SubscriptionValueResponse Successful Response
   * @throws ApiError
   */
  public static getSubscriptionValuesByChartSubscription({
    chartSubscriptionId,
  }: {
    chartSubscriptionId: number;
  }): CancelablePromise<Array<SubscriptionValueResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charts/subscription_values/chart_subscription/{chart_subscription_id}",
      path: {
        chart_subscription_id: chartSubscriptionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Subscription Value
   * Create a subscription value.
   * @returns SubscriptionValueResponse Successful Response
   * @throws ApiError
   */
  public static createSubscriptionValue({
    requestBody,
  }: {
    requestBody: SubscriptionValueCreate;
  }): CancelablePromise<SubscriptionValueResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charts/subscription_value",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
