export default function MeetingInfoItem(props: {
  icon: JSX.Element;
  children;
}) {
  return (
    <div className="grid grid-cols-12 p-2 pr-2">
      <div className="pt-1">{props.icon}</div>
      <div className="col-span-11 pb-4 border-b-2 border-b-slate-300">
        {props.children}
      </div>
    </div>
  );
}
