import { Dialog, DialogTrigger } from "components/Dialog";
import { Button, ButtonSize, ButtonColor, ButtonFill } from "components/Button";
import DiscardDialog from "../Popups/discard";

export default function MeetingDialogTrigger({
  size,
  color,
  fill,
  disabled,
  loading = false,
  extraClasses,
  label,
  dialog,
}: {
  size?: ButtonSize;
  color?: ButtonColor;
  fill?: ButtonFill;
  disabled?: boolean;
  loading?: boolean;
  extraClasses?: string;
  label: string;
  dialog: JSX.Element;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          extraClasses={extraClasses}
          size={size}
          color={color}
          fill={fill}
          disabled={disabled}
        >
          <p className="font-semibold">
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <>{label}</>
            )}
          </p>
        </Button>
      </DialogTrigger>
      {dialog}
    </Dialog>
  );
}
