/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the status of an account.
 */
export enum AccountStatus {
    INVITED = 'Invited',
    INCOMPLETE = 'Incomplete',
    AWAITING_CONFIRMATION = 'Awaiting Confirmation',
    ACTIVE = 'Active',
    PAUSED = 'Paused',
    BANNED = 'Banned',
}
