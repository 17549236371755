/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents all account roles for users.
 */
export enum AccountRole {
    '_25' = 25,
    '_20' = 20,
    '_15' = 15,
    '_10' = 10,
    '_5' = 5,
}
