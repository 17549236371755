/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppleCalendarAPICreds } from "../models/AppleCalendarAPICreds";
import type { CalendarEventResponse } from "../models/CalendarEventResponse";
import type { OutlookCalendarAPICreds } from "../models/OutlookCalendarAPICreds";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class CalendarService {
  /**
   * Get Google Auth Url
   * Get the authorization URL for the client to visit.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static getGoogleAuthUrl(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/calendar/google/auth_url",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Enable Google Calendar
   * Enable the google calendar API for an account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static enableGoogleCalendar({
    code,
  }: {
    code: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/calendar/google/enable",
      query: {
        code: code,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Revoke Google Calendar
   * Revoke Tadpole's access to a google account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static revokeGoogleCalendar(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/calendar/google/revoke",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Google Calendar
   * Get meetings from a user's Google calendar.
   * By default, returns all meetings in the next month from Google 'primary' calendar.
   * @returns CalendarEventResponse Successful Response
   * @throws ApiError
   */
  public static getGoogleCalendar({
    accountId,
    start,
    until,
    calendarId,
  }: {
    accountId: string;
    start?: string | null;
    until?: string | null;
    calendarId?: any;
  }): CancelablePromise<Array<CalendarEventResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/calendar/google/{account_id}",
      path: {
        account_id: accountId,
      },
      query: {
        start: start,
        until: until,
        calendar_id: calendarId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Authenticate Outlook Calendar
   * Authenticate the client with Microsoft Graph.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static authenticateOutlookCalendar(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/calendar/outlook/auth",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Outlook Calendar Events
   * Get events from outlook calendar.
   * @returns CalendarEventResponse Successful Response
   * @throws ApiError
   */
  public static getOutlookCalendarEvents({
    calendarId,
    requestBody,
    start,
    until,
  }: {
    calendarId: any;
    requestBody: OutlookCalendarAPICreds;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<Array<CalendarEventResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/calendar/outlook",
      query: {
        start: start,
        until: until,
        calendar_id: calendarId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Apple Calendar
   * Get meetings from a user's Apple calendar.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAppleCalendar({
    requestBody,
    start,
    until,
    calendarId,
  }: {
    requestBody: AppleCalendarAPICreds;
    start?: string | null;
    until?: string | null;
    calendarId?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/calendar/apple",
      query: {
        start: start,
        until: until,
        calendar_id: calendarId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
