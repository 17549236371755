import React from "react";
import PhoneInput from "react-phone-input-2";
import { Button, ButtonColor } from "components/Button";
import { AccountResponse } from "client/openapi";
import styles from "../styles.module.css";

export interface PersonalTabProps {
  changeHandler: (values: AccountResponse) => void;
  email: string;
  phone_number: string;
}

export const PersonalTabContent: React.FC<PersonalTabProps> = ({
  changeHandler,
  email,
  phone_number,
  ...account
}) => {
  const handlePhoneChange = (newPhone: string) => {
    const newAccount = {
      ...account,
      email,
      phone_number: newPhone,
    };

    changeHandler(newAccount as AccountResponse);
  };

  return (
    <>
      <div className={styles.infoItem}>
        <p className={styles.label}>Email</p>
        <p>{email}</p>
      </div>
      <div className={styles.infoItem}>
        <p className={styles.label}>Phone</p>
        <div className={styles.textInputContainer}>
          <div>
            <PhoneInput
              country={"us"}
              value={phone_number}
              onChange={handlePhoneChange}
              prefix="+"
              inputClass="p-2 m-0 input w-full"
              placeholder="+1 (555) 555-5555"
              dropdownStyle={{ display: "none" }}
              countryCodeEditable={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
