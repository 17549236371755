/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeetingStats } from "../models/MeetingStats";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StatisticsService {
  /**
   * Get Org Meeting Stats
   * Get statistics about an organization's meetings.
   * @returns MeetingStats Successful Response
   * @throws ApiError
   */
  public static getOrgMeetingStats({
    orgId,
    start,
    until,
    tz = "UTC",
  }: {
    orgId: number;
    start: string;
    until: string;
    tz?: string;
  }): CancelablePromise<MeetingStats> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/statistics/organizations/{org_id}/meetings",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Meeting Stats
   * Get statistics about a student's meetings.
   * @returns MeetingStats Successful Response
   * @throws ApiError
   */
  public static getStudentMeetingStats({
    studentId,
    start,
    until,
    tz = "UTC",
  }: {
    studentId: number;
    start: string;
    until: string;
    tz?: string;
  }): CancelablePromise<MeetingStats> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/statistics/students/meetings",
      query: {
        student_id: studentId,
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Meeting Stats
   * Get statistics about a tutor's meetings.
   * @returns MeetingStats Successful Response
   * @throws ApiError
   */
  public static getTutorMeetingStats({
    tutorId,
    start,
    until,
    tz = "UTC",
  }: {
    tutorId: number;
    start: string;
    until: string;
    tz?: string;
  }): CancelablePromise<MeetingStats> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/statistics/tutors/meetings",
      query: {
        tutor_id: tutorId,
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent Meeting Stats
   * Get statistics about a parent's meetings.
   * @returns MeetingStats Successful Response
   * @throws ApiError
   */
  public static getParentMeetingStats({
    parentId,
    start,
    until,
    tz = "UTC",
  }: {
    parentId: number;
    start: string;
    until: string;
    tz?: string;
  }): CancelablePromise<MeetingStats> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/statistics/parents/meetings",
      query: {
        parent_id: parentId,
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
