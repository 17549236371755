/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents values to group by for payroll exports.
 */
export enum GroupBy {
  STUDENT = "Student",
  TUTOR = "Tutor",
  NONE = "None",
}
