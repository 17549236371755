import { DialogContent } from "components/Dialog";
import { FaRegClock } from "react-icons/fa6";
import "./index.css";

function WaitingDialog({
  message,
  onClose,
}: {
  message: string;
  onClose?: () => void;
}) {
  return (
    <DialogContent
      onClose={onClose}
      className="dialog-content waiting-dialog-content text-center flex flex-col justify-center align-center"
    >
      <h2 className="waiting-dialog-text font-md w-1/2 mx-auto">
        <FaRegClock
          className="mx-auto"
          style={{ width: "6em", height: "6em" }}
        />
      </h2>
      <h2 className="waiting-dialog-text">{message}</h2>
    </DialogContent>
  );
}

export default WaitingDialog;
