import { useAuth0 } from "@auth0/auth0-react";
import { ParentResponse, ParentsService } from "client/openapi";
import {
  createContext,
  Context,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

interface ParentState {
  current_parent: ParentResponse | null | undefined;
  available_parents: ParentResponse[] | null | undefined;
  need_to_get_latest_parent_info: boolean;
}

type CurrParentContextInterface = ParentState & {
  update_parent_state: (updates: Partial<ParentState>) => void;
};

const defaultParentState: ParentState = {
  current_parent: null,
  available_parents: null,
  need_to_get_latest_parent_info: false,
};

export const ParentContext = createContext<CurrParentContextInterface>({
  ...defaultParentState,
  update_parent_state: () => {},
});

function getParentByCurrentlySelectedOrg(
  parents: ParentResponse[],
  orgId: number
): ParentResponse | undefined {
  return parents.find((parent) => parent.org_id === orgId);
}

export const ParentProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0();
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const [available_parents, set_available_parents] =
    useState<ParentResponse[]>();
  const [current_parent, set_current_parent] = useState<ParentResponse>();
  const [parentState, setParentState] =
    useState<ParentState>(defaultParentState);

  const update_parent_state = (updates: Partial<ParentState>) => {
    setParentState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  // fetch the current account's parents
  const fetchParents = useCallback(() => {
    ParentsService.getParentsMe()
      .then((parents) => {
        set_available_parents(parents);
        update_parent_state({ need_to_get_latest_parent_info: false });
      })
      .catch((err) => {
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  }, []);

  const setCurrentParent = useCallback(
    (parents: ParentResponse[]) => {
      set_current_parent(
        getParentByCurrentlySelectedOrg(
          parents,
          currently_selected_organization as number
        )
      );
    },
    [currently_selected_organization]
  );

  useEffect(() => {
    if (user) {
      if (!available_parents || parentState.need_to_get_latest_parent_info) {
        fetchParents();
      }

      if (
        available_parents &&
        currently_selected_organization &&
        !parentState.need_to_get_latest_parent_info
      ) {
        setCurrentParent(available_parents);
      }

      if (
        available_parents &&
        current_parent &&
        !parentState.need_to_get_latest_parent_info
      ) {
        const newParentState: ParentState = {
          current_parent: current_parent,
          available_parents: available_parents,
          need_to_get_latest_parent_info: false,
        };

        setParentState(newParentState);
      }
    }
  }, [
    available_parents,
    current_parent,
    currently_selected_organization,
    fetchParents,
    setCurrentParent,
    parentState.need_to_get_latest_parent_info,
    user,
  ]);

  return (
    <ParentContext.Provider value={{ ...parentState, update_parent_state }}>
      {children}
    </ParentContext.Provider>
  );
};
