import { TrashIcon } from "@radix-ui/react-icons";
import bankImage from "./bank.png";
import { ACHResponse, CreditCardResponse } from "client/openapi";
import { APIResponse, PageStatus } from "types";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";

export default function Emails({
  email,
  showAction,
  selectable = false,
  handleSelectPaymentMethod,
}: {
  email: string;
  showAction?: boolean;
  selectable?: boolean;
  handleSelectPaymentMethod?: (
    method: CreditCardResponse | ACHResponse | string
  ) => void;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <tr className="header text-sm align-top">
      <td className="flex gap-2 font-normal mb-1 items-center">
        {selectable && handleSelectPaymentMethod && (
          <input
            type="radio"
            name="bill-to"
            onChange={() => handleSelectPaymentMethod(email)}
          />
        )}
        <p className="text-nowrap whitespace-nowrap">{email}</p>
      </td>
    </tr>
  );
}
