export default function RadioInput({
  id,
  name,
  label,
  value,
  setValue,
  checked,
  disabled,
  required,
  classes,
}: {
  id: string;
  name: string;
  label: string;
  value: string;
  setValue: (value: any) => void;
  checked: boolean;
  disabled?: boolean;
  required?: boolean;
  classes?: string;
}) {
  return (
    <>
      <input
        id={id}
        name={name}
        type="radio"
        className={`p-2 m-0 outline-none border-black ${classes}`}
        value={value}
        onChange={(e) => setValue(e)}
        checked={checked}
        disabled={disabled}
        required={required}
      />
      <label htmlFor={name} className="input-label">
        {label}
      </label>
    </>
  );
}
