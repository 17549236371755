import "./index.css";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import moment from "moment";
import { FaClock } from "react-icons/fa6";
import {
  BillingType,
  OrgTransactionType,
  StudentOrgTransactionResponse,
  StudentResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { useNavigate } from "react-router-dom";
import { PiStudentBold } from "react-icons/pi";

export const billingType = [
  { value: "REFUND", label: "Refund" },
  { value: "UPFRONT", label: "Upfront charge" },
  { value: "DIRECT", label: "Direct charge" },
  { value: "RESCHEDULING_FEE", label: "Rescheduling Fee" },
  { value: "CANCELLATION_FEE", label: "Cancellation Fee" },
  { value: "ABSENCE_FEE", label: "Absence Fee" },
  { value: "BUNDLE", label: "Auto-pay charge, bundled meetings" },
  {
    value: "MEETING_CHARGED_INDIVIDUALLY",
    label: "Auto-pay charge, individual meeting",
  },
];

function Transactions({
  transactions,
  orgId,
}: {
  transactions: any[];
  orgId: number;
}) {
  const navigate = useNavigate();
  return (
    <div className="transactions-container">
      <table className="table">
        <thead className="tableHead border-2 border-b-0 border-gray-300">
          <tr>
            <th
              style={{ width: "13%", paddingLeft: "20px" }}
              className="text-xs md:text-sm lg:text-base p-2"
            >
              Date
            </th>
            <th
              style={{ width: "14.5%" }}
              className="text-xs md:text-sm lg:text-base p-2"
            >
              Student
            </th>
            <th
              style={{ width: "18.5%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              {" "}
              Amount
            </th>
            <th
              style={{ width: "20.5%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Service
            </th>
            <th
              style={{ width: "10.0%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Type
            </th>
            <th
              style={{ width: "10.5%" }}
              className="centeredTableData text-xs md:text-sm lg:text-base"
            >
              Status
            </th>
            <th
              style={{ width: "12.5%" }}
              className="centeredTableData text-xs md:text-sm lg:text-base"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => {
            if (
              (transaction.transaction_type ===
                OrgTransactionType.STUDENT_ORG_TRANSACTION &&
                transaction.billing_type !== BillingType.MEETING_UNCHARGED &&
                transaction.billing_type !==
                  BillingType.MEETING_CHARGED_IN_BUNDLE &&
                transaction.billing_type !==
                  BillingType.MEETING_PAID_WITH_CREDITS) ||
              transaction.transaction_type ===
                OrgTransactionType.ORG_BANK_TRANSACTION
            ) {
              return (
                <tr
                  key={index}
                  className={`tableRow border-2 border-gray-300 ${
                    index === transactions.length - 1 && "!border-b-2 rounded-b"
                  } ${index === 0 && "border-t-0"}`}
                >
                  <td
                    className="text-xs md:text-sm "
                    style={{ paddingLeft: "20px" }}
                  >
                    {moment(transaction.timestamp).format("MM/DD/YYYY")}
                  </td>
                  <td className="text-xs md:text-sm ">
                    {transaction.student
                      ? transaction.student.first_name +
                        " " +
                        transaction.student.last_name
                      : ""}
                  </td>
                  <td className="text-xs md:text-sm ">
                    $
                    {parseFloat((transaction.amount / 100).toString()).toFixed(
                      2
                    )}
                  </td>
                  <td className="text-xs md:text-sm ">{transaction.reason}</td>
                  <td className="text-xs md:text-sm">
                    {transaction.transaction_type ===
                    OrgTransactionType.STUDENT_ORG_TRANSACTION
                      ? billingType.find(
                          (t) => transaction.billing_type === t.value
                        )?.label
                      : "Payout to Bank"}
                  </td>
                  <td>
                    {transaction.status.includes("SUCCESSFUL") ? (
                      transaction.status.includes("REFUND") ? (
                        <IoCheckmarkCircle className="mx-auto purple-mark" />
                      ) : (
                        <IoCheckmarkCircle className="mx-auto green-mark" />
                      )
                    ) : transaction.status.includes("PENDING") ? (
                      <FaClock
                        className="mx-auto"
                        style={{ color: "#FFB341", fontSize: "20px" }}
                      />
                    ) : transaction.status.includes("FAILED") ? (
                      <IoCloseCircle className="mx-auto red-mark" />
                    ) : (
                      <IoCloseCircle className="mx-auto black" />
                    )}
                  </td>
                  <td className="centeredTableData">
                    {transaction.transaction_type !==
                      OrgTransactionType.ORG_BANK_TRANSACTION && (
                      <Button
                        onClick={() =>
                          navigate(`/manage/students/${transaction.student_id}`)
                        }
                        color={ButtonColor.SKYBLUE}
                        size={ButtonSize.SMALL}
                      >
                        <PiStudentBold className="mx-auto" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Transactions;
