import moment from "moment";
import {
  Auth0AccountRole,
  MeetingResponse,
  MeetingsService,
} from "client/openapi";
import { useCallback, useContext, useEffect, useState } from "react";
import { GoCheckCircleFill, GoCircle, GoXCircleFill } from "react-icons/go";
import { concatenateTutorSubject } from "util/concatenateSubject";
import { getStudents, getTutors } from "util/getAttendees";
import styles from "./styles.module.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function EventTable({
  events,
  setSessionUpdated,
}: {
  events: MeetingResponse[];
  setSessionUpdated: (day: string) => void;
}) {
  const [selectedRadio, setSelectedRadio] = useState(
    Array(events.length).fill(undefined)
  );

  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const handleRadioChange = async (index, radioIndex) => {
    const originalSelectedRadio = [...selectedRadio];
    const updatedSelectedRadio = [...selectedRadio];
    updatedSelectedRadio[index] = radioIndex;
    setSelectedRadio(updatedSelectedRadio);
    if (originalSelectedRadio[index] === undefined) {
      await MeetingsService.approveMeeting({
        requestBody: {
          session_id: events[index].session_id,
          meeting_time: events[index].start,
          approved: radioIndex === 0,
        },
      });
      await setSessionUpdated(events[index].start);
      return;
    }
    await MeetingsService.updateMeetingApproval({
      sessionId: events[index].session_id,
      requestBody: {
        session_id: events[index].session_id,
        meeting_time: events[index].start,
        approved: radioIndex === 0,
        approved_modified_at: moment().format(),
      },
    });
  };

  const getApprovalStatus = useCallback(async () => {
    await Promise.all(
      events.map(async (event, index) => {
        await MeetingsService.getMeetingApproval({
          sessionId: event.session_id,
          startTime: event.start,
        }).then((res) => {
          setSelectedRadio((prev) => {
            const updatedSelectedRadio = [...prev];
            updatedSelectedRadio[index] =
              res.approved === null ? undefined : res.approved ? 0 : 1;
            return updatedSelectedRadio;
          });
        });
      })
    );
  }, [events]);

  const getApprovalIcon = (index: number) => {
    // map the checked Radio to a status icon for non admins
    const icons = {
      undefined: <GoCircle />,
      "0": <GoCheckCircleFill color="var(--green)" />,
      "1": <GoXCircleFill color="var(--red)" />,
    };
    const checked = selectedRadio[index];
    const checkedAsString = "" + checked;
    return icons[checkedAsString];
  };

  useEffect(() => {
    getApprovalStatus();
  }, [events, getApprovalStatus]);

  const isAdmin = currently_selected_role === Auth0AccountRole.ORG_ADMIN;

  return (
    <>
      {events.length > 0 ? (
        <tr>
          <td className="bg-gray-300 p-0">
            <table className="w-full bg-white border-collapse">
              <thead>
                <tr className="px-7 grid grid-cols-12 bg-white text-xs text-gray-400 mb-2">
                  <th className="col-span-2 text-left font-normal">Activity</th>
                  <th className="col-span-1 text-left font-normal">
                    Time Start
                  </th>
                  <th className="col-span-1 text-left font-normal">Time End</th>
                  <th className="col-span-3 text-left font-normal">Tutors</th>
                  <th className="col-span-3 text-left font-normal">Students</th>
                  {isAdmin ? (
                    <>
                      <th className="col-span-1 text-center font-normal">
                        Approve
                      </th>
                      <th className="col-span-1 text-center font-normal">
                        Deny
                      </th>
                    </>
                  ) : (
                    <th className="col-span-1 text-center font-normal">
                      Status
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-gray-300 my-2">
                {events.map((meeting, index) => (
                  <tr key={index}>
                    <td
                      className={`text-sm
                                ${index === 0 ? "pt-2" : "p-1"}
                                ${index == events.length - 1 ? "pb-2" : "p-1"}
                            `}
                    >
                      <div className="bg-white py-4 px-6 rounded-md grid grid-cols-12">
                        <div className="col-span-2">
                          <b>
                            {meeting.name ??
                              concatenateTutorSubject(meeting.subject)}
                          </b>
                        </div>
                        <div className="col-span-1">
                          {moment(meeting.start).format("h:mma")}
                        </div>
                        <div className="col-span-1">
                          {moment(meeting.start)
                            .add(meeting.duration, "minutes")
                            .format("h:mma")}
                        </div>
                        <div className="col-span-3">{getTutors(meeting)}</div>
                        <div className="col-span-3">{getStudents(meeting)}</div>
                        {isAdmin ? (
                          <>
                            <div className="col-span-1 text-center">
                              <input
                                type="radio"
                                name={`radio${index}`}
                                checked={selectedRadio[index] === 0}
                                onChange={() => {}}
                                onClick={() => handleRadioChange(index, 0)}
                                className="rounded"
                                style={{
                                  backgroundImage:
                                    'url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e")',
                                }}
                              />
                            </div>
                            <div className="col-span-1 text-center">
                              <input
                                type="radio"
                                name={`radio${index}`}
                                checked={selectedRadio[index] === 1}
                                onChange={() => {}}
                                onClick={() => handleRadioChange(index, 1)}
                                className="rounded"
                                style={{
                                  backgroundImage:
                                    'url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e")',
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="col-span-1 text-center">
                            <div className={styles.statusSvgContainer}>
                              {getApprovalIcon(index)}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      ) : (
        <tr>
          <td
            className={`border-2 border-gray-300 px-8 py-2 text-lg bg-gray-300`}
          >
            No meetings
          </td>
        </tr>
      )}
    </>
  );
}
