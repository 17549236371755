import { Auth0AccountRole, MeetingResponse } from "client/openapi";
import moment from "moment";
import { useEffect, useState } from "react";
import { PageStatus } from "types";
import { concatenateTutorSubject } from "util/concatenateSubject";
import range from "util/range";
import "./index.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../../src/sass/custom.scss";
import Meeting from "./MeetingBlocks/Meeting";
import EventBlock from "./MeetingBlocks/EventBlock";
import { convertUTCtoLocal } from "util/timezone";

const BLOCK_ID = 9810811199107;

export type CalendarEvent = {
  id: number;
  title: string;
  start: Date;
  end: Date;
  resources?: any[];
};

function divideCalendarEvent(event: CalendarEvent): CalendarEvent[] {
  const dividedEvents: CalendarEvent[] = [];
  const currentStart = moment(event.start);
  const currentEnd = moment(event.end);

  while (currentStart.isBefore(currentEnd)) {
    let intervalEnd = moment(currentStart).add(30, "minutes");
    if (intervalEnd.isAfter(currentEnd)) {
      intervalEnd = currentEnd;
    }

    const resources = event.resources?.filter((resource) => {
      const resourceStart = moment(resource.start);
      const resourceEnd = moment(resource.start).add(
        resource.duration,
        "minutes"
      );
      return (
        resourceStart.isBefore(intervalEnd) && resourceEnd.isAfter(currentStart)
      );
    });

    if (resources?.length === 1 || resources?.length === 2) {
      resources?.forEach((resource) => {
        dividedEvents.push({
          id: resource.session_id,
          title: resource.name ?? concatenateTutorSubject(resource.subject),
          start: moment(resource.start).toDate(),
          end: moment(resource.start)
            .add(resource.duration, "minutes")
            .toDate(),
          resources: undefined,
        });
      });
    } else {
      dividedEvents.push({
        id: event.id,
        title: `Event Block%${resources?.length} sessions`,
        start: currentStart.toDate(),
        end: intervalEnd.toDate(),
        resources: resources,
      });
    }

    currentStart.add(30, "minutes");
  }

  return mergeCalendarEvents(dividedEvents);
}

function mergeCalendarEvents(events: CalendarEvent[]): CalendarEvent[] {
  const mergedEvents = events.reduce(
    (result: CalendarEvent[], event: CalendarEvent) => {
      if (event.id > BLOCK_ID) {
        result.push(event);
        return result;
      }
      const existingEvent = result.find((e) => e.id === event.id);
      if (existingEvent) {
        existingEvent.end = event.end;
      } else {
        result.push({ ...event });
      }
      return result;
    },
    []
  );

  return mergedEvents;
}

function CalendarWrapper({
  role,
  weekStart,
  events,
  setEvents,
  days,
  status,
  mobileView,
}: {
  role: Auth0AccountRole;
  events: MeetingResponse[];
  setEvents: () => Promise<void>;
  weekStart: Date;
  days: number;
  status: PageStatus;
  mobileView: boolean;
}) {
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [backgroundEvents, setBackgroundEvents] = useState<CalendarEvent[]>([]);
  const localizer = momentLocalizer(moment);

  function getEvents(events: MeetingResponse[], day: number) {
    return events
      .filter((e) => {
        return moment(e.start).isSame(
          moment(weekStart).add(range(days).indexOf(day), "days"),
          "day"
        );
      })
      .sort((a, b) => a.start.localeCompare(b.start));
  }

  const getEventFromCalendar = (event, start) => {
    let selectedEvent = range(days)
      .map((day) => getEvents(events, day))
      .flat()
      .filter((e) => {
        return (
          e.session_id === event.id && moment(e.start).isSame(start, "day")
        );
      });
    return selectedEvent[0];
  };

  function removeOverlappingMeetings(
    meetings: MeetingResponse[]
  ): CalendarEvent[] {
    const resultMeetings: CalendarEvent[] = [];
    let overlappingMeetings: CalendarEvent[] = [];

    for (let i = 0; i < meetings.length; i++) {
      const currentMeeting = {
        id: meetings[i].session_id,
        title: meetings[i].name ?? concatenateTutorSubject(meetings[i].subject),
        start: moment(meetings[i].start).toDate(),
        end: moment(meetings[i].start)
          .add(meetings[i].duration, "minutes")
          .toDate(),
      };

      if (overlappingMeetings.length === 0) {
        overlappingMeetings.push(currentMeeting);
      } else {
        const earliestOverlappingMeeting = overlappingMeetings.sort((a, b) => {
          return moment(a.start).diff(moment(b.start));
        })[0];
        const lastOverlappingMeeting = overlappingMeetings.sort((a, b) => {
          return moment(b.end).diff(moment(a.end));
        })[0];
        const currentMeetingStart = moment(currentMeeting.start);
        const currentMeetingEnd = moment(currentMeeting.end);
        const lastOverlappingStart = moment(earliestOverlappingMeeting.start);
        const lastOverlappingEnd = moment(lastOverlappingMeeting.end);
        if (
          currentMeetingStart.isBefore(lastOverlappingEnd) &&
          currentMeetingEnd.isAfter(lastOverlappingStart)
        ) {
          overlappingMeetings.push(currentMeeting);
        } else {
          if (overlappingMeetings.length <= 2) {
            resultMeetings.push(...overlappingMeetings);
          } else {
            const start = overlappingMeetings[0].start;
            const end = overlappingMeetings.sort((a, b) =>
              moment(b.end).diff(moment(a.end), "minutes")
            )[0].end;
            resultMeetings.push({
              id: overlappingMeetings[0].id + BLOCK_ID,
              title: `Event Block`,
              start,
              end: end,
              resources: overlappingMeetings.map((meeting) =>
                getEventFromCalendar(meeting, start)
              ),
            });
          }
          overlappingMeetings = [currentMeeting];
        }
      }
    }

    if (overlappingMeetings.length <= 2) {
      resultMeetings.push(...overlappingMeetings);
    } else {
      const start = overlappingMeetings[0].start;
      const end = overlappingMeetings.sort((a, b) =>
        moment(b.end).diff(moment(a.end), "minutes")
      )[0].end;
      resultMeetings.push({
        id: overlappingMeetings[0].id + BLOCK_ID,
        title: `Event Block`,
        start,
        end: end,
        resources: overlappingMeetings.map((meeting) =>
          getEventFromCalendar(meeting, start)
        ),
      });
    }

    const dividedMeetings = resultMeetings
      .map((meeting) => {
        if (meeting.id > BLOCK_ID) {
          return divideCalendarEvent(meeting);
        } else {
          return meeting;
        }
      })
      .flat();

    return dividedMeetings;
  }

  const convertTimesToLocalTimezone = (events: CalendarEvent[]) => {
    return events.map((event) => {
      return {
        ...event,
        start: convertUTCtoLocal(moment(event.start).format()),
        end: convertUTCtoLocal(moment(event.end).format()),
      };
    });
  };

  useEffect(() => {
    setBackgroundEvents(
      range(days)
        .map((day) => removeOverlappingMeetings(getEvents(events, day)))
        .flat()
        .filter((mtg) => {
          return mtg.id >= BLOCK_ID;
        })
    );
    setCalendarEvents(
      range(days)
        .map((day) => removeOverlappingMeetings(getEvents(events, day)))
        .flat()
        .filter((mtg) => {
          return mtg.id < BLOCK_ID;
        })
    );
  }, [events, weekStart]);

  const CustomEvent = (event) => {
    if (event.event.id > BLOCK_ID) {
      return (
        <EventBlock
          meeting={event.event}
          allMeetings={events}
          index={event.event.id}
          role={role}
          setEvents={setEvents}
        />
      );
    } else if (getEventFromCalendar(event.event, event.event.start)) {
      return (
        <Meeting
          meeting={getEventFromCalendar(event.event, event.event.start)}
          meetings={events}
          index={event.event.id}
          role={role}
          setEvents={setEvents}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Calendar
        dayLayoutAlgorithm="no-overlap"
        components={{
          week: {
            header: ({ date }) => (
              <div>
                <span className="calendar--date-number">
                  {localizer.format(date, "DD")}
                </span>
                <span className="calendar--day-separator px-3">|</span>
                {localizer.format(date, "dddd")}
              </div>
            ),
          },
          event: ({ event }) => <CustomEvent event={event} />,
        }}
        view={mobileView ? Views.DAY : Views.WEEK}
        localizer={localizer}
        defaultDate={weekStart}
        date={weekStart}
        events={calendarEvents}
        // here, background events are a bit of a misnomer, they are actually in the foreground
        // this is because background events are not factored into the overlap algorithm
        // so we want events to not be overlapping and background events will never overlap anyways
        // we enforce this by setting the z-index of background events to 3 and events are 1
        backgroundEvents={backgroundEvents}
        formats={{
          timeGutterFormat: (date, culture) =>
            localizer.format(date, "h A", culture),
        }}
        scrollToTime={moment()
          .subtract(moment().hour() !== 0 ? 1 : 0, "hour")
          .toDate()}
        toolbar={false}
        onNavigate={() => {}}
        onView={() => {}}
        popup
      />
    </>
  );
}

export default CalendarWrapper;
