import { Dialog, DialogTrigger } from "components/Dialog";
import {
  PlusIcon,
  Pencil1Icon,
  TrashIcon,
  DownloadIcon,
} from "@radix-ui/react-icons";
import { ButtonColor, ButtonSize, Button } from "components/Button";
import DeleteOrgNoteKeyDialog from "components/OrgNoteKeyDialogs/Delete";
import EditOrgNoteKeyDialog from "components/OrgNoteKeyDialogs/Edit";
import NewOrgNoteKeyDialog from "components/OrgNoteKeyDialogs/New";
import { ErrorBlock } from "components/StatusBlock";
import { saveAs } from "file-saver";
import {
  ApiError,
  ExportsService,
  OrganizationNoteKeyResponse,
  OrganizationResponse,
} from "client/openapi";
import { useState } from "react";
import Notifications from "util/notifications";
import { APIResponse, PageStatus } from "types";

export default function AdminInfo({
  keys,
  setKeys,
  organization,
}: {
  keys: OrganizationNoteKeyResponse[];
  setKeys: any;
  organization: OrganizationResponse;
}) {
  const [exportingData, setExportingData] = useState<boolean>(false);
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  async function exportStudents() {
    if (!organization) {
      return Notifications.error("An unexpected error occurred");
    }

    setExportingData(true);

    await ExportsService.exportStudents({
      orgId: organization.id,
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(
            "All students' data have been saved to your computer!"
          );
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save file.");
        }
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to export students' data" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    setExportingData(false);
  }
  return (
    <>
      {keys && keys.length > 0 ? (
        <>
          <section className="student-data--fields">
            <div className="student-data--header">
              <h2>Current Fields</h2>

              <div className="student-data--field-actions">
                <Button
                  color={ButtonColor.SKYBLUE}
                  size={ButtonSize.SMALL}
                  onClick={() => exportStudents()}
                >
                  Export Students{" "}
                  {exportingData ? (
                    <span
                      className="spinner-border spinner-border-square"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <DownloadIcon />
                  )}
                </Button>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.PURPLE} size={ButtonSize.SMALL}>
                      Create New Field <PlusIcon />
                    </Button>
                  </DialogTrigger>

                  <NewOrgNoteKeyDialog
                    organization={organization}
                    keys={keys}
                    setKeys={setKeys}
                  />
                </Dialog>
              </div>
            </div>

            {keys
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((k) => (
                <div key={k.id} className="student-data--field">
                  <div className="student-data--field-inner">
                    {k.name}
                    <div className="student-data--field-actions">
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button
                            color={ButtonColor.PURPLE}
                            size={ButtonSize.SMALL}
                          >
                            <Pencil1Icon /> Edit
                          </Button>
                        </DialogTrigger>

                        <EditOrgNoteKeyDialog
                          keyId={k.id}
                          currentName={k.name}
                          keys={keys}
                          setKeys={setKeys}
                        />
                      </Dialog>

                      <Dialog>
                        <DialogTrigger asChild>
                          <button className="button button--small button--red">
                            <TrashIcon /> Delete
                          </button>
                        </DialogTrigger>

                        <DeleteOrgNoteKeyDialog
                          noteKey={k}
                          keys={keys}
                          setKeys={setKeys}
                        />
                      </Dialog>
                    </div>
                  </div>
                </div>
              ))}
          </section>
        </>
      ) : (
        <div
          className="container mx-auto"
          style={{
            display: "flex",
            backgroundColor: "#f8f8f8",
            borderRadius: "0.75rem",
            alignItems: "center",
            padding: "5rem 1.5rem",
            textAlign: "center",
          }}
        >
          <div className="page--student-data-inner">
            <ErrorBlock
              status={PageStatus.ERROR}
              title="No fields"
              message="You do not have any custom student data fields configured."
            />

            <Dialog>
              <DialogTrigger asChild>
                <Button
                  color={ButtonColor.PURPLE}
                  size={ButtonSize.LARGE}
                  extraClasses="mt-5"
                >
                  Create Field <PlusIcon />
                </Button>
              </DialogTrigger>

              <NewOrgNoteKeyDialog
                organization={organization}
                keys={keys}
                setKeys={setKeys}
              />
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
}
