import {
  ChartSubscriptionResponse,
  MetricValueResponse,
  ChartsService,
  ChartXAxisType,
} from "client/openapi";
import moment from "moment";
import {
  Button,
  ButtonColor,
  ButtonShape,
  ButtonSize,
} from "components/Button";
import AddDataDialog from "./AddDataDialog";
import { Dialog, DialogTrigger } from "components/Dialog";
import { SizeIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { useState, useEffect } from "react";
import LineChart from "components/LineChart";
import { ExpandedChartDialog } from "./ExpandedChartDialog";

export type PerformanceChartDatapoint = {
  metric_value_id: number;
  xKey: number;
  score: number;
  timestamp: string;
};

export function filterChartDP(
  chartDP: PerformanceChartDatapoint[],
  start: number,
  end: number
) {
  return chartDP.filter((datapoint) => {
    return datapoint.xKey >= start && datapoint.xKey <= end;
  });
}

export function generateChartDP(
  student_data: MetricValueResponse[],
  chart: ChartSubscriptionResponse
) {
  if (student_data.length === 0) {
    return [];
  }

  const datapoints: PerformanceChartDatapoint[] = student_data.map(
    (metric_value) => {
      const hourPredictor =
        chart.chart_prototype.chart_x_axis_type ===
        ChartXAxisType.HOURS_OF_TUTORING;
      return {
        metric_value_id: metric_value.id,
        xKey: hourPredictor
          ? (metric_value.hours as number)
          : moment(metric_value.date).valueOf(),
        score: metric_value.value,
        timestamp: metric_value.date,
      };
    }
  );

  return datapoints.sort((a, b) => a.xKey - b.xKey);
}

function findMinAndMax(dotsDP: PerformanceChartDatapoint[]): [number, number] {
  if (dotsDP.length === 0) {
    return [0, 0];
  }
  let min = dotsDP[0].xKey;
  let max = dotsDP[0].xKey;
  for (let i = 0; i < dotsDP.length; i++) {
    if (dotsDP[i].xKey < min) {
      min = dotsDP[i].xKey;
    }
    if (dotsDP[i].xKey > max) {
      max = dotsDP[i].xKey;
    }
  }

  return [min, max];
}

export default function PerformanceChart({
  id,
  studentId,
  initialChart,
  updateCharts,
  setUpdateCharts,
}: {
  id: number;
  studentId: number;
  initialChart: ChartSubscriptionResponse;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  const [chart, setChart] = useState<ChartSubscriptionResponse>(initialChart);
  const [chartDP, setChartDP] = useState<PerformanceChartDatapoint[]>();
  const [rawData, setRawData] = useState<MetricValueResponse[]>([]);
  const [minMax, setMinMax] = useState<[number, number]>(findMinAndMax([]));
  const [showAddDataDialog, setShowAddDataDialog] = useState(false);

  const addMetricValueToRawData = (newData: MetricValueResponse) => {
    ChartsService.getDataForChartSubscription({
      chartSubscriptionId: chart.id,
    })
      .then((data) => {
        setRawData(data);
      })
      .catch((err) => console.log(err));
  };

  const deleteMetricValueFromRawData = (datapoint: number) => {
    const newRawData = rawData.filter((data) => data.id !== datapoint);
    setRawData(newRawData);
  };

  let xAxisLabel;
  switch (chart.chart_prototype.chart_x_axis_type) {
    case ChartXAxisType.HOURS_OF_TUTORING:
      xAxisLabel = "Hours of Tutoring";
      break;
    case ChartXAxisType.DATE:
      xAxisLabel = "Time";
      break;
  }

  const yAxisLabel = chart.chart_prototype.metric_definition.y_axis_name;
  let yDomain: number[] = [];
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_min);
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_max);

  useEffect(() => {
    ChartsService.getDataForChartSubscription({
      chartSubscriptionId: chart.id,
    })
      .then((data) => {
        setRawData(data);
      })
      .catch((err) => console.log(err));
  }, [chart]);

  useEffect(() => {
    const updatedChartDP = generateChartDP(rawData, chart);
    setChartDP(updatedChartDP);
    setMinMax(findMinAndMax(updatedChartDP as PerformanceChartDatapoint[]));
  }, [chart, rawData]);

  return (
    <div className="font-montserrat w-full h-full">
      <div className="flex flex-row items-center justify-between h-1/5 w-full">
        <div className="w-3/5">
          <Tooltip>
            <TooltipTrigger>
              <h2 className="font-bold text-xl overflow-hidden whitespace-nowrap text-ellipsis">
                {chart.chart_prototype.chart_title}
              </h2>
            </TooltipTrigger>

            <TooltipContent>{chart.chart_prototype.chart_title}</TooltipContent>
          </Tooltip>
        </div>

        <div className="flex flex-row items-center gap-2 w-2/5 justify-end">
          <div className="relative" id={"add-data" + id}>
            <Button
              color={ButtonColor.SKYBLUE}
              size={ButtonSize.SMALL}
              shape={ButtonShape.CHIP}
              extraClasses="w-24 h-6"
              onClick={() => setShowAddDataDialog(!showAddDataDialog)}
            >
              Add data
            </Button>

            {showAddDataDialog && chartDP && (
              <div className="absolute right-0 top-10">
                <AddDataDialog
                  id={"add-data" + id}
                  studentId={studentId}
                  setShowAddDataDialog={setShowAddDataDialog}
                  chart={chart}
                  updateCharts={updateCharts}
                  setUpdateCharts={setUpdateCharts}
                  addMetricValueToRawData={addMetricValueToRawData}
                />
              </div>
            )}
          </div>

          <Dialog>
            <DialogTrigger>
              <Tooltip>
                <TooltipTrigger>
                  <SizeIcon cursor="pointer" className="h-7 w-7" />
                </TooltipTrigger>

                <TooltipContent>Expand</TooltipContent>
              </Tooltip>
            </DialogTrigger>

            {chartDP && (
              <ExpandedChartDialog
                studentId={studentId}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                chart={chart}
                minMax={minMax}
                initialChartDP={chartDP}
                updateCharts={updateCharts}
                setUpdateCharts={setUpdateCharts}
                addMetricValueToRawData={addMetricValueToRawData}
                deleteMetricValueFromRawData={deleteMetricValueFromRawData}
              />
            )}
          </Dialog>
        </div>
      </div>

      <div className="w-full h-4/5">
        {chartDP && (
          <LineChart
            data={chartDP}
            xKey="xKey"
            xAxisLabel={xAxisLabel}
            yKey="score"
            yAxisLabel={yAxisLabel}
            formatter={
              chart.chart_prototype.chart_x_axis_type === ChartXAxisType.DATE
                ? (timestamp) => moment(timestamp).format("MM/DD/YYYY")
                : undefined
            }
            xDomain={[minMax[0], minMax[1]]}
            yDomain={yDomain}
          />
        )}
      </div>
    </div>
  );
}
