import {
  MeetingResponse,
  Auth0AccountRole,
  ParentResponse,
  ExportsService,
  ApiError,
  MeetingStatus,
  TutorMeetingResponse,
  StudentResponse,
  TutorSubjectResponse,
} from "client/openapi";
import { Button, ButtonSize } from "components/Button";
import { getButtonRoleColor } from "util/contextColor";
import { getHighestRole } from "util/accounts";
import Notifications from "util/notifications";
import moment from "moment";
import { concatenateName } from "util/concatenateName";
import { saveAs } from "file-saver";
import { APIResponse, PageStatus } from "types";
import { DownloadIcon } from "@radix-ui/react-icons";
import Avatar from "components/Avatar";
import { generateInitials } from "util/generateInitials";
import { concatenateMeetingDetails } from "util/concatenateSubject";
import "../index.css";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function MeetingHistory({
  meetings,
  parent,
  exportingMeetings,
  setExportingMeetings,
  setStatus,
  setError,
  organization_id,
}: {
  meetings: MeetingResponse[];
  parent: ParentResponse;
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
  organization_id: number;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  async function exportParentMeetings() {
    if (!parent) {
      return Notifications.error("Unable to export meeting data.");
    }

    setExportingMeetings(true);

    // await ExportsService.exportParentMeetings({
    //   parentId: parent.id,
    //   orgId: organization_id,
    //   until: moment().toISOString(),
    //   tz: moment.tz.guess(true),
    // })
    //   .then((data) => {
    //     try {
    //       const blob = new Blob([data], { type: "text/csv" });
    //       const fileName = "export.csv";
    //       saveAs(blob, fileName);
    //       Notifications.success(
    //         `${parent.first_name}'s meeting export has been saved to your computer!`
    //       );
    //     } catch (error) {
    //       console.error(error);
    //       Notifications.error("Unable to save file.");
    //     }
    //   })
    //   .catch((e: ApiError) => {
    //     setStatus(PageStatus.ERROR);
    //     setError({ message: "Unable to export parent's meetings" });
    //     console.error(`Error (#${e.status}): ${e.message}`);
    //   });

    setExportingMeetings(false);
  }

  return (
    <section className="parent-profile--section" id="parent-meeting-history">
      <div className="parent-profile--section-header">
        <h2>Meeting History</h2>

        <div className="flex flex-row gap-1.5 items-center">
          {meetings.length > 0 && (
            <Button
              color={getButtonRoleColor(
                currently_selected_role
                  ? currently_selected_role
                  : Auth0AccountRole.ME
              )}
              size={ButtonSize.SMALL}
              onClick={() => exportParentMeetings()}
            >
              Export{" "}
              {exportingMeetings ? (
                <span
                  className="spinner-border spinner-border-square"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <DownloadIcon aria-label="Export Meetings" />
              )}
            </Button>
          )}
        </div>
      </div>

      <div className="parent-profile--section-items">
        {meetings.length > 0 ? (
          meetings
            .filter((m) => m.status !== MeetingStatus.CANCELLED)
            .sort(
              (a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()
            )
            .map((m, i) => (
              <div
                key={i}
                className="parent-profile--section-item parent-detail--historical-meeting"
              >
                <Avatar
                  src={m.tutors[0].image_url}
                  alt={`${m.tutors[0].first_name} ${m.tutors[0].last_name}`}
                  fallback={generateInitials(
                    `${m.tutors[0].first_name} ${m.tutors[0].last_name}`,
                    2
                  )}
                />
                <div className="parent-detail--historical-meeting--details">
                  <p>
                    <b>{moment(m.start).format("ddd, MMM D, YYYY")}</b> &middot;{" "}
                    {m.duration}m
                  </p>
                  <p>
                    {concatenateMeetingDetails(m.subject, m.tutors, m.students)}
                  </p>
                </div>
              </div>
            ))
        ) : (
          <div className="parent-profile--section-item">No meetings.</div>
        )}
      </div>
    </section>
  );
}
