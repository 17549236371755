/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubjectCategory } from "../models/SubjectCategory";
import type { SubjectLevel } from "../models/SubjectLevel";
import type { TutorSubjectCreate } from "../models/TutorSubjectCreate";
import type { TutorSubjectResponse } from "../models/TutorSubjectResponse";
import type { TutorSubjectUpdate } from "../models/TutorSubjectUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TutorSubjectsService {
  /**
   * Get Subjects By Tutor
   * Get all tutor subjects for a specified tutor from the database.
   * @returns TutorSubjectResponse Successful Response
   * @throws ApiError
   */
  public static getSubjectsByTutor({
    tutorId,
    category,
    level,
    active,
    skip,
    limit = 100,
  }: {
    tutorId: number;
    category?: SubjectCategory | null;
    level?: SubjectLevel | null;
    active?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<TutorSubjectResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor_subjects/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        category: category,
        level: level,
        active: active,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Subject
   * Create a new tutor subject and returns the resulting subject from the database.
   * @returns TutorSubjectResponse Successful Response
   * @throws ApiError
   */
  public static createSubject({
    requestBody,
  }: {
    requestBody: TutorSubjectCreate;
  }): CancelablePromise<TutorSubjectResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutor_subjects",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Subject
   * Update a tutor subject by its ID using specified attributes
   * within TutorSubjectUpdate to update the database and return
   * the updated subject.
   * @returns TutorSubjectResponse Successful Response
   * @throws ApiError
   */
  public static updateSubject({
    subjectId,
    requestBody,
  }: {
    subjectId: number;
    requestBody: TutorSubjectUpdate;
  }): CancelablePromise<TutorSubjectResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutor_subjects/{subject_id}",
      path: {
        subject_id: subjectId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
