/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Auth0AccountRole } from "../models/Auth0AccountRole";
import type { BooleanResponse } from "../models/BooleanResponse";
import type { OrganizationAcceptPaymentsResponse } from "../models/OrganizationAcceptPaymentsResponse";
import type { OrganizationCreate } from "../models/OrganizationCreate";
import type { OrganizationNameResponse } from "../models/OrganizationNameResponse";
import type { OrganizationResponse } from "../models/OrganizationResponse";
import type { OrganizationSettingsResponse } from "../models/OrganizationSettingsResponse";
import type { OrganizationSettingsUpdate } from "../models/OrganizationSettingsUpdate";
import type { OrganizationStripeSessionClientSecret } from "../models/OrganizationStripeSessionClientSecret";
import type { OrganizationUpdate } from "../models/OrganizationUpdate";
import type { OrgBalanceResponse } from "../models/OrgBalanceResponse";
import type { OrgPricingConditionCreate } from "../models/OrgPricingConditionCreate";
import type { OrgPricingConditionResponse } from "../models/OrgPricingConditionResponse";
import type { OrgPricingConditionUpdate } from "../models/OrgPricingConditionUpdate";
import type { PricingModelCreate } from "../models/PricingModelCreate";
import type { PricingModelResponse } from "../models/PricingModelResponse";
import type { PricingModelUpdate } from "../models/PricingModelUpdate";
import type { StudentPricingConditionCreate } from "../models/StudentPricingConditionCreate";
import type { StudentPricingConditionUpdate } from "../models/StudentPricingConditionUpdate";
import type { StudentPricingResponse } from "../models/StudentPricingResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OrganizationsService {
  /**
   * Create Organization
   * Create a new organization and returns the resulting organization from the database.
   * @returns OrganizationResponse Successful Response
   * @throws ApiError
   */
  public static createOrganization({
    requestBody,
  }: {
    requestBody: OrganizationCreate;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Total Revenue
   * Get the total revenue of the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTotalRevenue({
    orgId,
    start,
    until,
  }: {
    orgId: number;
    start: string;
    until: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/total_revenue/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Total Compensation
   * Get total compensation across all tutors.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTotalCompensation({
    orgId,
    start,
    until,
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/total_compensation/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organizations Me By Role
   * Get all organizations affiliated with the currently authorized user
   * as the specifed role from the database.
   * @returns OrganizationResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationsMeByRole({
    role,
  }: {
    role: Auth0AccountRole;
  }): CancelablePromise<Array<OrganizationResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/me/role",
      query: {
        role: role,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization
   * Get an organization by its org_id from the database.
   * @returns OrganizationResponse Successful Response
   * @throws ApiError
   */
  public static getOrganization({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Organization
   * Update an organization by its ID using specified attributes
   * within OrganizationUpdate to update the database.
   * @returns OrganizationResponse Successful Response
   * @throws ApiError
   */
  public static updateOrganization({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrganizationUpdate;
  }): CancelablePromise<OrganizationResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Stripe Account Session
   * Get an AccountSession.client_secret for the current org.
   * @returns OrganizationStripeSessionClientSecret Successful Response
   * @throws ApiError
   */
  public static getStripeAccountSession({
    currentOrg,
  }: {
    currentOrg: number;
  }): CancelablePromise<OrganizationStripeSessionClientSecret> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/me/stripe_account_session/{current_org}",
      path: {
        current_org: currentOrg,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organizations By Account
   * Get all organizations affiliated with an account from the database.
   * @returns OrganizationResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationsByAccount({
    accountId,
  }: {
    accountId: string;
  }): CancelablePromise<Array<OrganizationResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/by_account/{account_id}",
      path: {
        account_id: accountId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Accept Payments Status
   * See if an organization can accept payments.
   * If not, a reason is provided.
   * @returns OrganizationAcceptPaymentsResponse Successful Response
   * @throws ApiError
   */
  public static getAcceptPaymentsStatus({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrganizationAcceptPaymentsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/accept_payments_status/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Balance By Org
   * Retrieve an organization's balance.
   * @returns OrgBalanceResponse Successful Response
   * @throws ApiError
   */
  public static getBalanceByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrgBalanceResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/balance/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Pricing Conditions
   * Create an org's initial pricing conditions (may be a partial create).
   * @returns OrgPricingConditionResponse Successful Response
   * @throws ApiError
   */
  public static createOrgPricingConditions({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrgPricingConditionCreate;
  }): CancelablePromise<OrgPricingConditionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Pricing Conditions
   * Get an org's pricing conditions.
   * @returns OrgPricingConditionResponse Successful Response
   * @throws ApiError
   */
  public static getOrgPricingConditions({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrgPricingConditionResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Org Pricing Conditions
   * Update an org's initial pricing conditions.
   * @returns OrgPricingConditionResponse Successful Response
   * @throws ApiError
   */
  public static updateOrgPricingConditions({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrgPricingConditionUpdate;
  }): CancelablePromise<OrgPricingConditionResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Pricing Conditions
   * Delete an org's pricing conditions.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static deletePricingConditions({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Student Pricing
   * Create a student's pricing conditions.
   * @returns OrgPricingConditionResponse Successful Response
   * @throws ApiError
   */
  public static createStudentPricing({
    requestBody,
  }: {
    requestBody: StudentPricingConditionCreate;
  }): CancelablePromise<OrgPricingConditionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/student_pricing/{org_id}",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Pricing By Student Id
   * Get a student's pricing conditions.
   * @returns StudentPricingResponse Successful Response
   * @throws ApiError
   */
  public static getStudentPricingByStudentId({
    orgId,
    studentId,
  }: {
    orgId: number;
    studentId: number;
  }): CancelablePromise<StudentPricingResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/student_pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Student Pricing
   * Update a student's pricing conditions.
   * @returns StudentPricingResponse Successful Response
   * @throws ApiError
   */
  public static updateStudentPricing({
    orgId,
    studentId,
    requestBody,
  }: {
    orgId: number;
    studentId: number;
    requestBody: StudentPricingConditionUpdate;
  }): CancelablePromise<StudentPricingResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/student_pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        student_id: studentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Settings
   * Get an organization's settings.
   * @returns OrganizationSettingsResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationSettings({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<OrganizationSettingsResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/settings/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Organization Settings
   * Update an organization's settings.
   * @returns OrganizationSettingsResponse Successful Response
   * @throws ApiError
   */
  public static updateOrganizationSettings({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: OrganizationSettingsUpdate;
  }): CancelablePromise<OrganizationSettingsResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/settings/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Transaction History By Org
   * Get organization transaction history of organization's affiliated with
   * currently authenticated user.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrganizationTransactionHistoryByOrg({
    orgId,
    start,
    until,
    skip,
    limit = 100,
  }: {
    orgId: number;
    start: string;
    until: string;
    skip?: number;
    limit?: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/transactions/by_org",
      query: {
        orgId: orgId,
        start: start,
        until: until,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Transaction History By Student
   * Get organization transaction history of organization's affiliated with
   * currently authenticated user, filtered by student_id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrganizationTransactionHistoryByStudent({
    studentId,
    start,
    until,
    skip,
    limit = 100,
  }: {
    studentId: number;
    start: string;
    until: string;
    skip?: number;
    limit?: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/transactions/me/by_student/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        start: start,
        until: until,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Organization Custom Pricing
   * Create an organization's custom pricing.
   * @returns PricingModelResponse Successful Response
   * @throws ApiError
   */
  public static createOrganizationCustomPricing({
    requestBody,
  }: {
    requestBody: PricingModelCreate;
  }): CancelablePromise<PricingModelResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/custom/pricing/",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Custom Pricing
   * Get an organization's custom pricing.
   * @returns PricingModelResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationCustomPricing({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<PricingModelResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/custom/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Organization Custom Pricing
   * Update an organization's custom pricing.
   * @returns PricingModelResponse Successful Response
   * @throws ApiError
   */
  public static updateOrganizationCustomPricing({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: PricingModelUpdate;
  }): CancelablePromise<PricingModelResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/custom/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Org Custom Pricing
   * Delete an org's custom pricing.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteOrgCustomPricing({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/custom/pricing/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Admin From Org
   * Remove an admin account from an organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static removeAdminFromOrg({
    orgId,
    accountId,
  }: {
    orgId: number;
    accountId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/remove_admin_from_org/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        account_id: accountId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Organization Names
   * Given a list of organization ids, get their corresponding names, and return a list of OrganizationNameResponse,
   * representing org names mapped to their ids.
   * @returns OrganizationNameResponse Successful Response
   * @throws ApiError
   */
  public static getOrganizationNames({
    requestBody,
  }: {
    requestBody: Array<number>;
  }): CancelablePromise<Array<OrganizationNameResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/org_names",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
