/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentResponse } from "../models/StudentResponse";
import type { TutorAssignedStudentResponse } from "../models/TutorAssignedStudentResponse";
import type { TutorResponse } from "../models/TutorResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TutorStudentsService {
  /**
   * Create Tutor Student Relationship
   * Create a tutor-student relationship and returns the new relationship from the database.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static createTutorStudentRelationship({
    tutorId,
    studentId,
  }: {
    tutorId: number;
    studentId: number;
  }): CancelablePromise<TutorResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutor-students/{tutor_id}/{student_id}",
      path: {
        tutor_id: tutorId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Tutor Student Relation
   * Unassign a student from a tutor.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static removeTutorStudentRelation({
    tutorId,
    studentId,
  }: {
    tutorId: number;
    studentId: number;
  }): CancelablePromise<TutorResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/tutor-students/{tutor_id}/{student_id}",
      path: {
        tutor_id: tutorId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Tutor Relationship
   * Get the relationship between tutor and student.
   * @returns TutorAssignedStudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentTutorRelationship({
    relationshipId,
  }: {
    relationshipId: number;
  }): CancelablePromise<TutorAssignedStudentResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor-students/{relationship_id}",
      path: {
        relationship_id: relationshipId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Assigned Students
   * Get all of a tutor's assigned students
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getTutorAssignedStudents({
    tutorId,
  }: {
    tutorId: number;
  }): CancelablePromise<Array<StudentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor-students/tutor/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get All Tutors With Student
   * Get a list of tutors this student is assigned to.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static getAllTutorsWithStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<TutorResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor-students/student/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
