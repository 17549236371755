import {
  OrganizationSubjectResponse,
  ReviewResponse,
  ReviewTutorResponse,
  TutorMeetingResponse,
  TutorTestScoreResponse,
} from "client/openapi";
import { ProfileSubjects } from "components/Profile/ProfileSubjects";
import { TagColor } from "components/Tag";
import { TagList } from "components/Tag/TagList";
import TutorsGrid from "components/TutorsGrid";
import moment from "moment";
import pluralize from "util/pluralize";
import "./index.css";

export function ProfileBody({
  id,
  currentStatus,
  subjects,
  rate,
  testScores,
  reviews,
  tutors,
}: {
  id?: number;
  currentStatus?: string | null;
  subjects: OrganizationSubjectResponse[];
  rate: string;
  testScores?: TutorTestScoreResponse[];
  reviews: ReviewResponse[] | ReviewTutorResponse[];
  tutors?: TutorMeetingResponse[];
}) {
  let testScoreTags: string[] = [];

  testScores?.forEach((s) => {
    testScoreTags.push(
      `${s.test_name} (Score: ${s.score}, ${moment(s.taken_at).format(
        "MMM DD, YYYY"
      )})`
    );
  });

  return (
    <div className="profile_body">
      <div className="container mx-auto profile_body_content">
        <div className="tutor_info">
          {currentStatus && (
            <p className="profile_body_title">
              <b>Currently:</b> {currentStatus}
            </p>
          )}

          <div className="profile_body_section">
            <ProfileSubjects subjects={subjects} />
          </div>

          {testScores && testScores.length > 0 && (
            <>
              <h2 className="pb-0 profile_body_title profile_body_title_big">
                Standardized {pluralize("Test", "Tests", testScores.length)}
              </h2>
              <div className="profile_subjects_group">
                <TagList list={testScoreTags} color={TagColor.GOLD} />
              </div>
            </>
          )}
        </div>

        {id && (
          <div className="profile--book-card">
            <a
              href={`/tutors/${id}/book`}
              className="button button--green button--large"
            >
              Book Meeting &rarr;
            </a>
          </div>
        )}
      </div>

      {/* {reviews.length > 0 && (
        <div className="container mx-auto profile_body_reviews_section">
          <h2 className="profile_body_title profile_body_title_big">
            {reviews.length} {pluralize("Review", "Reviews", reviews.length)}
          </h2>

          <ReviewList reviews={reviews} />
        </div>
      )} */}

      {tutors && tutors.length > 0 && (
        <div className="container mx-auto organization--tutors">
          <h2 className="profile_body_title profile_body_title_big">
            Our {tutors.length} {pluralize("Tutor", "Tutors", tutors.length)}
          </h2>

          <TutorsGrid tutors={tutors} />
        </div>
      )}
    </div>
  );
}
