import "./index.css";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { VscSettings } from "react-icons/vsc";
import { LuLineChart } from "react-icons/lu";
import { Dialog, DialogTrigger } from "components/Dialog";
import Trends from "../Modals/Trends";
import PaymentInformationModal from "../../../../../../components/PaymentMethods";
import RepeatOnce from "./repeatOnce";
import PayUpfrontImage from "./servicesupfront.png";
import OneOffChargeModal from "../Modals/Charges/OneOffCharge";
import TutoringUpfrontModal from "../Modals/Charges/TutoringUpfront";
import { StudentOrgTransactionResponse, StudentResponse } from "client/openapi";
import CustomRatesModal from "../Modals/CustomRates";
import { LuClipboardCheck } from "react-icons/lu";
import PaymentPolicies from "../Modals/Charges/PaymentPolicies";

function Buttons({
  transactions,
  student,
  orgId,
}: {
  transactions: StudentOrgTransactionResponse[];
  student: StudentResponse;
  orgId: number;
}) {
  return (
    <div className="flex md:grid mx-4 md:grid-cols-6 grid-cols-3 lg:gap-8 md:gap-6">
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <img src={PayUpfrontImage} alt="Pay Upfront" className="w-[45%]" />
          <span className="mt-2">Charge for services up front</span>
        </DialogTrigger>
        <TutoringUpfrontModal student={student} orgId={orgId} />
      </Dialog>
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <RepeatOnce />
          <span className="mt-2">Charge a one-time fee</span>
        </DialogTrigger>
        <OneOffChargeModal orgId={orgId} student={student} />
      </Dialog>
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <VscSettings
            color="black"
            size="42"
            style={{ transform: "rotate(90deg)" }}
          />
          <span className="mt-2">Set custom student rates</span>
        </DialogTrigger>
        <CustomRatesModal student={student} orgId={orgId} />
      </Dialog>
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <LuClipboardCheck color="black" size="42" />
          <span className="mt-2">Set Policy for Penalties</span>
        </DialogTrigger>
        <PaymentPolicies student={student} orgId={orgId} />
      </Dialog>
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <IoMdInformationCircleOutline color="black" size="42" />
          <span className="mt-2">Payment information</span>
        </DialogTrigger>
        <PaymentInformationModal studentIfInitiatedByAdmin={student} />
      </Dialog>
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <LuLineChart color="black" size="42" />
          <span className="mt-2">View payment trends</span>
        </DialogTrigger>
        <Trends transactions={transactions} />
      </Dialog>
    </div>
  );
}
export default Buttons;
