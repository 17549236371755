import moment from "moment";

export function renderTimestamp(ts: Date): string {
  const delta: number = Math.abs(moment(ts).diff(moment(new Date()), "hours"));

  if (delta < 24) {
    return moment(ts).fromNow();
  } else {
    return moment(ts).format("MMM D, YYYY");
  }
}
