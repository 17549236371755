import {
  AccountUpdate,
  AccountsService,
  OrganizationSettingsUpdate,
  OrganizationsService,
} from "client/openapi";
import { Tab } from ".";
import { useAuth0 } from "@auth0/auth0-react";
import Notifications from "util/notifications";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export const useUserSettingsUpdateEndpoints = (tab: Tab) => {
  const { user } = useAuth0();
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const updatePersonalSettings = async (body: AccountUpdate) => {
    const phone_number = "+".concat(body.phone_number || "");
    AccountsService.updateAccount({
      requestBody: {
        phone_number,
      },
    })
      .then((res) =>
        Notifications.success("Personal settings updated successfully")
      )
      .catch((err) =>
        Notifications.error(
          "There was an issue updating your personal settings"
        )
      );
  };

  const updateOrganizationSettings = async (
    body: Omit<OrganizationSettingsUpdate, "changeHandler">
  ) => {
    OrganizationsService.updateOrganizationSettings({
      orgId: currently_selected_organization as number,
      requestBody: body,
    })
      .then((res) =>
        Notifications.success("Organization settings updated successfully")
      )
      .catch((err) =>
        Notifications.error(
          "There was an issue updating your organization settings"
        )
      );
  };

  const Endpoints = {
    Personal: updatePersonalSettings,
    Organization: updateOrganizationSettings,
  };

  return Endpoints[tab];
};
