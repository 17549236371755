import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Account, APIResponse, HTTPMethod, PageStatus } from "types";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

type APIEndpointRequest = {
  endpoint: string;
  method?: HTTPMethod;
  body?: BodyInit;
  authenticate?: boolean;
  usesAcct?: boolean;
  headers?: HeadersInit;
};

type APIEndpointResponse<ResponseModel> = {
  status: PageStatus;
  data: ResponseModel | undefined;
  error: string;
  rawError: APIResponse | undefined;
};

export function useAPI<ResponseModel>(
  options: APIEndpointRequest
): APIEndpointResponse<ResponseModel> {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();
  const [data, setData] = useState<ResponseModel>();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { account } = useContext(OrgRolesAndAccountContext);

  useEffect(() => {
    const { endpoint, method, body, authenticate, usesAcct, headers } = options;
    const reqHeaders = headers ? new Headers(headers) : new Headers();

    const fetchData = async () => {
      setStatus(PageStatus.LOADING);
      if (authenticate) {
        if (!isAuthenticated) {
          return;
        }
        try {
          reqHeaders.set(
            "Authorization",
            `Bearer ${await getAccessTokenSilently()}`
          );
        } catch (e: any) {
          navigate("/");
        }
      }
      if (!isLoading) {
        if (usesAcct && !account) {
          return;
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_API_DOMAIN}/${endpoint}`,
            {
              method: method || HTTPMethod.GET,
              headers: reqHeaders,
              body,
            }
          );
          const result = await response.json();

          if (result?.detail || result?.error) {
            setStatus(PageStatus.ERROR);
            setError(result as APIResponse);
          } else {
            setStatus(PageStatus.SUCCESS);
            setData(result as ResponseModel);
          }
        } catch (e: any) {
          setStatus(PageStatus.ERROR);
          setError({ message: "An unexpected error occurred" });
          console.error(`Error (#${e?.code}): ${e?.message}`);
        }
      }
    };

    fetchData();
  }, [isAuthenticated, isLoading, account]);

  return {
    status,
    data,
    error:
      error?.message ||
      (typeof error?.detail !== "object" && error?.detail?.toString()) ||
      "An unexpected error occurred",
    rawError: error,
  };
}

export function useCurrentAccount(): APIEndpointResponse<Account> {
  const { status, data, error, rawError } = useAPI<Account>({
    endpoint: "accounts/me",
    authenticate: true,
    usesAcct: false,
  });

  return {
    status,
    data,
    error,
    rawError,
  };
}
