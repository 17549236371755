import {
  ApiError,
  OrgNotesService,
  OrganizationNoteKeyResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { DialogClose, DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "../index.css";

function EditOrgNoteKeyDialog({
  keyId,
  currentName,
  keys,
  setKeys,
}: {
  keyId: number;
  currentName: string;
  keys: OrganizationNoteKeyResponse[];
  setKeys: React.Dispatch<React.SetStateAction<OrganizationNoteKeyResponse[]>>;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [name, setName] = useState<string>(currentName);

  async function updateKey() {
    await OrgNotesService.updateOrgNoteKey({
      orgNoteKeyId: keyId,
      requestBody: {
        name,
      },
    })
      .then(() => {
        const key = keys.filter((n) => n.id === keyId)[0];
        keys = keys.filter((n) => n.id !== keyId);
        key["name"] = name;

        setStatus(PageStatus.SUCCESS);
        setKeys([...keys, key]);
        Notifications.success(`Field updated!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to update key" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleUpdate(ev) {
    setName(ev.target.value);
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    await updateKey();
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="new-org-note-key--dialog">
          <div className="new-org-note-key--dialog-header">
            <h2 className="new-org-note-key--title">
              Update "{currentName}" Field
            </h2>
          </div>

          <form onSubmit={handleSubmit}>
            <label htmlFor="name" hidden>
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className="input"
              placeholder="Textbook"
              value={name || ""}
              maxLength={50}
              onChange={handleUpdate}
            />

            <div className="mt-4 clear-availability--actions">
              <Button
                color={ButtonColor.PURPLE}
                size={ButtonSize.SMALL}
                type="submit"
                disabled={status === PageStatus.LOADING}
              >
                Save Field &rarr;
              </Button>
              <DialogClose asChild>
                <Button
                  color={ButtonColor.PURPLE}
                  size={ButtonSize.SMALL}
                  fill={ButtonFill.HOLLOW}
                  disabled={status === PageStatus.LOADING}
                >
                  Cancel
                </Button>
              </DialogClose>
            </div>
          </form>
        </div>
      </DialogContent>
    </>
  );
}

export default EditOrgNoteKeyDialog;
