import { TutorMeetingResponse } from "client/openapi";
import TutorCard from "components/TutorCard";
import "./index.css";

export default function TutorGrid({
  tutors,
}: {
  tutors: TutorMeetingResponse[];
}) {
  return (
    <div className="tutors--grid">
      {tutors.map((t) => (
        <TutorCard tutor={t} key={t.id} />
      ))}
    </div>
  );
}
