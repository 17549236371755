import MeetingsBySubjectIllustration from "./illustrations/MeetingsBySubject";
import MeetingsOverTimeIllustration from "./illustrations/MeetingsOverTime";
import NotesIllustration from "./illustrations/Notes";

export default function FeaturesCarousel() {
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-8 items-center">
      <NotesIllustration />
      <MeetingsOverTimeIllustration />
      <MeetingsBySubjectIllustration />
    </div>
  );
}
