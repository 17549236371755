import Avatar from "components/Avatar";
import { UserTypes } from "types";
import { PlusIcon } from "@radix-ui/react-icons";
import { getUserColor, makeRoleHumanReadable } from "util/contextColor";
import { Auth0AccountRole } from "client/openapi";

export default function GenericAddButton({
  userType,
  newView = false,
  onClick,
}: {
  userType: Auth0AccountRole;
  newView?: boolean;
  onClick: () => void;
}) {
  return (
    <div className="meeting-dialog--attendee cursor-pointer" onClick={onClick}>
      <div>
        <Avatar
          alt="Add user"
          color={getUserColor(userType)}
          fallback={
            <div className="w-2/3 text-black">
              <svg viewBox="0 0 15 15">
                <PlusIcon />
              </svg>
            </div>
          }
          inverted={true}
        />
      </div>
      <div
        className={`meeting-dialog${newView ? "-new" : ""}--attendee-details`}
      >
        <span>Add {makeRoleHumanReadable(userType)}</span>
      </div>
    </div>
  );
}
