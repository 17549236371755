import { TrashIcon } from "@radix-ui/react-icons";
import cardImage from "./credit-card.png";
import {
  ACHResponse,
  CreditCardResponse,
  StudentsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import SuccessDialog from "components/SuccessDialog";
import ErrorDialog from "components/ErrorDialog";

export default function Cards({
  card,
  showAction,
  selectable = false,
  handleSelectPaymentMethod,
}: {
  card: CreditCardResponse;
  showAction?: boolean;
  selectable?: boolean;
  handleSelectPaymentMethod?: (
    method: CreditCardResponse | ACHResponse | string
  ) => void;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [deleteIntent, setDeleteIntent] = useState(false);

  const deleteCard = () => {
    setStatus(PageStatus.LOADING);
    StudentsService.removeRegisteredPaymentMethod({
      paymentMethodId: card.payment_method_id,
    })
      .then(() => {
        Notifications.success("Payment method deleted.");
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message="Payment method deleted!" />;
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog message="Could not delete payment method at this time!" />
    );
  }

  return (
    <tr className="header text-sm align-top" style={{ width: "100%" }}>
      <td className="flex justify-between gap-2 font-bold mr-2 items-center">
        <div className="flex gap-2 items-center mr-2">
          {selectable && handleSelectPaymentMethod && (
            <input
              type="radio"
              name="bill-to"
              onChange={() => handleSelectPaymentMethod(card)}
            />
          )}
          <img src={cardImage} alt="card" className="w-5" />
          <p className="text-nowrap whitespace-nowrap">
            {card.brand}{" "}
            <span className="text-gray-400"> ending with {card.last_four}</span>
          </p>
        </div>
        <div
          className="flex justify-between w-5/12 ml-10"
          style={{ marginBottom: "2px" }}
        >
          {`${card.expiration_month} / ${card.expiration_year}`}
          {showAction &&
            (deleteIntent ? (
              <Button
                onClick={deleteCard}
                color={ButtonColor.RED}
                size={ButtonSize.EXTRA_SMALL}
              >
                delete
              </Button>
            ) : (
              <Button
                color={ButtonColor.LIGHT_PURPLE}
                size={ButtonSize.EXTRA_SMALL}
                onClick={() => setDeleteIntent(true)}
              >
                <TrashIcon />
              </Button>
            ))}
        </div>
      </td>
    </tr>
  );
}
