import "./index.css";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { useEffect, useState } from "react";
import DayInput from "components/DayInput";
import {
  ChartSubscriptionResponse,
  ChartsService,
  MetricValueCreate,
  MetricValueResponse,
  SubscriptionValueCreate,
} from "client/openapi";
import Notifications from "util/notifications";
import moment from "moment";

const timezone = moment.tz.guess(true);

export default function AddDataDialog({
  id,
  studentId,
  setShowAddDataDialog,
  chart,
  updateCharts,
  setUpdateCharts,
  addMetricValueToRawData,
}: {
  id: string;
  studentId: number;
  setShowAddDataDialog: (show: boolean) => void;
  chart: ChartSubscriptionResponse;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
  addMetricValueToRawData: (data: MetricValueResponse) => void;
}) {
  const subject = chart.chart_prototype.metric_definition.y_axis_name;
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().tz(timezone).format("MM/DD/YYYY")
  );
  const [score, setScore] = useState<number>(0.0);
  const min = chart.chart_prototype.metric_definition.y_axis_min;
  const max = chart.chart_prototype.metric_definition.y_axis_max;
  const [isLoading, setIsLoading] = useState(false);
  const [dontSpamAddButton, setDontSpamAddButton] = useState(false);

  async function addData() {
    const newMetricValue: MetricValueCreate = {
      value: score,
      date: moment(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DDTHH:mm"),
      student_id: studentId,
      metric_definition_id: chart.chart_prototype.metric_definition.id,
    };

    const metricValue = await ChartsService.createMetricValue({
      requestBody: newMetricValue,
    });

    const newSubscriptionValue: SubscriptionValueCreate = {
      chart_subscription_id: chart.id,
      metric_value_id: metricValue.id,
    };

    await ChartsService.createSubscriptionValue({
      requestBody: newSubscriptionValue,
    });

    return metricValue;
  }

  async function addDataHandler() {
    if (!selectedDate) {
      Notifications.error("Please select a date");
      return;
    }
    const metric_min = chart.chart_prototype.metric_definition.y_axis_min;
    const metric_max = chart.chart_prototype.metric_definition.y_axis_max;
    if (metric_min && score < metric_min) {
      Notifications.error(
        "Score cannot be less than minimum score: " + metric_min
      );
      return;
    } else if (metric_max && score > metric_max) {
      Notifications.error(
        "Score cannot be greater than maximum score: " + metric_max
      );
      return;
    }

    setIsLoading(true);

    addData()
      .then((metric_value) => {
        addMetricValueToRawData(metric_value);
        setShowAddDataDialog(false);
        setIsLoading(false);
        setUpdateCharts(!updateCharts);
        Notifications.success("Successfully added data to chart");
        setDontSpamAddButton(false);
      })
      .catch((e) => {
        console.error("Unable to add data to chart", e);
      });
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const addDataDialog = document.getElementById(id);
      if (addDataDialog && !addDataDialog.contains(event.target)) {
        setShowAddDataDialog(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      id={id}
      className="bubble-shape font-montserrat flex justify-center items-center px-10 py-6"
    >
      <div className="outside-triangle">
        <svg
          width="20"
          height="12"
          viewBox="0 0 20 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.92497 0.671875L19.6713 10.9649H0.178658L9.92497 0.671875Z"
            fill="black"
          />
          <path
            d="M9.92456 1.73047L18.6962 11.0567H1.15288L9.92456 1.73047Z"
            fill="white"
          />
        </svg>
      </div>

      <div className="h-full w-full flex flex-col items-center justify-between">
        <div className="w-60">
          <h2 className="text-2xl font-semibold">Add Data</h2>
          <span className="text-lg font-semibold mt-2">{subject}</span>
          <div className="mt-4 w-24 cursor-default" id="add-data-date">
            <div>X-axis: Date</div>
            <DayInput
              id="add-data-date"
              value={selectedDate}
              setValue={setSelectedDate}
              classes="mt-2 w-60 border border-black rounded-md px-1 h-8"
              required={true}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="add-data-score">Y-axis: Score</label>
            <input
              id="add-data-score"
              type="number"
              className="mt-2 w-60 border border-black rounded-md p-0 px-1 h-8"
              value={isNaN(score) ? "" : score}
              min={min}
              max={max}
              onChange={(e) => setScore(parseFloat(e.target.value))}
            />
          </div>
        </div>

        {isLoading && <div>Adding...</div>}

        <div className="w-full flex flex-row justify-center gap-2">
          <Button
            disabled={dontSpamAddButton}
            color={dontSpamAddButton ? ButtonColor.GRAY : ButtonColor.SKYBLUE}
            size={ButtonSize.SMALL}
            extraClasses="w-24 h-6"
            onClick={() => {
              setDontSpamAddButton(true);
              addDataHandler();
            }}
          >
            Add
          </Button>
          <Button
            color={ButtonColor.BLACK}
            fill={ButtonFill.HOLLOW}
            size={ButtonSize.SMALL}
            extraClasses="w-24 h-6"
            onClick={() => {
              setShowAddDataDialog(false);
              setSelectedDate(moment().tz(timezone).format("MM/DD/YYYY"));
              setScore(0.0);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
