/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountAvailabilityCreate } from "../models/AccountAvailabilityCreate";
import type { AccountAvailabilityResponse } from "../models/AccountAvailabilityResponse";
import type { AccountAvailabilityUpdate } from "../models/AccountAvailabilityUpdate";
import type { AvailabilityMessage } from "../models/AvailabilityMessage";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AvailabilitiesService {
  /**
   * Get Availabilities By Account
   * Get an account's availabilities between the later of `start`
   * or the current time and `until` from the database.
   * @returns AvailabilityMessage Successful Response
   * @throws ApiError
   */
  public static getAvailabilitiesByAccount({
    until,
    start,
    duration = 30,
  }: {
    until: string;
    start?: string | null;
    duration?: number;
  }): CancelablePromise<Array<AvailabilityMessage>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/availabilities",
      query: {
        until: until,
        start: start,
        duration: duration,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Account Availabilities
   * Create an account's daily availabilities and returns the result from the database.
   * @returns AccountAvailabilityResponse Successful Response
   * @throws ApiError
   */
  public static createAccountAvailabilities({
    requestBody,
  }: {
    requestBody: AccountAvailabilityCreate;
  }): CancelablePromise<AccountAvailabilityResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/availabilities",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Account Availabilities
   * Update an account's daily availabilities by their id using specified attributes
   * within AccountAvailabilityUpdate to update the database and
   * return the updated account's daily availabilities.
   * @returns AccountAvailabilityResponse Successful Response
   * @throws ApiError
   */
  public static updateAccountAvailabilities({
    requestBody,
  }: {
    requestBody: AccountAvailabilityUpdate;
  }): CancelablePromise<AccountAvailabilityResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/availabilities",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Availabilities By Tutor
   * Get an account's availabilities between the later of `start`
   * or the current time and `until` from the database.
   * @returns AvailabilityMessage Successful Response
   * @throws ApiError
   */
  public static getAvailabilitiesByTutor({
    tutorId,
    until,
    start,
    duration = 30,
  }: {
    tutorId: number;
    until: string;
    start?: string | null;
    duration?: number;
  }): CancelablePromise<Array<AvailabilityMessage>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/availabilities/tutor",
      query: {
        tutor_id: tutorId,
        until: until,
        start: start,
        duration: duration,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
