/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents what type a payment method is (ACH, card, invoice).
 */
export enum PaymentMethodType {
  CARD = "CARD",
  INVOICE = "INVOICE",
  ACH = "ACH",
  UNKNOWN = "UNKNOWN",
}
