/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from "../models/DeleteResponse";
import type { NoteCreate } from "../models/NoteCreate";
import type { NoteResponse } from "../models/NoteResponse";
import type { NoteUpdate } from "../models/NoteUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NotesService {
  /**
   * Get Note By Id
   * Get a note by its id from the database.
   * @returns NoteResponse Successful Response
   * @throws ApiError
   */
  public static getNoteById({
    noteId,
  }: {
    noteId: number;
  }): CancelablePromise<NoteResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/notes/{note_id}",
      path: {
        note_id: noteId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Note
   * Update a note by its ID using specified attributes
   * within NoteUpdate to update the database.
   * @returns NoteResponse Successful Response
   * @throws ApiError
   */
  public static updateNote({
    noteId,
    requestBody,
  }: {
    noteId: number;
    requestBody: NoteUpdate;
  }): CancelablePromise<NoteResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/notes/{note_id}",
      path: {
        note_id: noteId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Note
   * Delete a note by its ID from the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteNote({
    noteId,
  }: {
    noteId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/notes/{note_id}",
      path: {
        note_id: noteId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Notes For Student
   * Get notes about a student from the database.
   * @returns NoteResponse Successful Response
   * @throws ApiError
   */
  public static getNotesForStudent({
    studentId,
    getTextedNotes = false,
  }: {
    studentId: number;
    getTextedNotes?: boolean;
  }): CancelablePromise<Array<NoteResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/notes/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        get_texted_notes: getTextedNotes,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Notes For Parent
   * Get notes about a parent from the database.
   * @returns NoteResponse Successful Response
   * @throws ApiError
   */
  public static getNotesForParent({
    parentId,
    getTextedNotes = false,
  }: {
    parentId: number;
    getTextedNotes?: boolean;
  }): CancelablePromise<Array<NoteResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/notes/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      query: {
        get_texted_notes: getTextedNotes,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Note
   * Create a new note and returns the resulting note from the database.
   * @returns NoteResponse Successful Response
   * @throws ApiError
   */
  public static createNote({
    requestBody,
  }: {
    requestBody: NoteCreate;
  }): CancelablePromise<NoteResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notes",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
