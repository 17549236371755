import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "components/LoginButton";
import ProfileDropDown from "components/ProfileDropdown";
import LogoutButton from "components/ProfileDropdown";

const AuthenticationButton = ({
  isLandingPage,
}: {
  isLandingPage: boolean;
}) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <ProfileDropDown />
  ) : (
    <LoginButton isLandingPage={isLandingPage} />
  );
};

export default AuthenticationButton;
