/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from "../models/DeleteResponse";
import type { StudentAttendanceCreate } from "../models/StudentAttendanceCreate";
import type { StudentAttendanceResponse } from "../models/StudentAttendanceResponse";
import type { StudentAttendanceUpdate } from "../models/StudentAttendanceUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StudentAttendanceService {
  /**
   * Create Student Attendance
   * Create a student_attendance record in the database.
   * @returns StudentAttendanceResponse Successful Response
   * @throws ApiError
   */
  public static createStudentAttendance({
    requestBody,
  }: {
    requestBody: StudentAttendanceCreate;
  }): CancelablePromise<StudentAttendanceResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/student_attendance",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Student Attendance
   * Delete a student_attendance record in the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteStudentAttendance({
    studentAttendanceId,
  }: {
    studentAttendanceId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/student_attendance/{student_attendance_id}",
      path: {
        student_attendance_id: studentAttendanceId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Attendance
   * Get a student attendance record directly from the database.
   * @returns StudentAttendanceResponse Successful Response
   * @throws ApiError
   */
  public static getStudentAttendance({
    studentAttendanceId,
  }: {
    studentAttendanceId: number;
  }): CancelablePromise<StudentAttendanceResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_attendance/{student_attendance_id}",
      path: {
        student_attendance_id: studentAttendanceId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Student Attendance
   * Update a student_attendance record in the database.
   * @returns StudentAttendanceResponse Successful Response
   * @throws ApiError
   */
  public static updateStudentAttendance({
    studentAttendanceId,
    requestBody,
  }: {
    studentAttendanceId: number;
    requestBody: StudentAttendanceUpdate;
  }): CancelablePromise<StudentAttendanceResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/student_attendance/{student_attendance_id}",
      path: {
        student_attendance_id: studentAttendanceId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Attendances By Session
   * Get all student attendance records of a specific session from the database.
   * @returns StudentAttendanceResponse Successful Response
   * @throws ApiError
   */
  public static getStudentAttendancesBySession({
    sessionId,
    studentId,
    start,
    until,
  }: {
    sessionId: number;
    studentId?: number | null;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<Array<StudentAttendanceResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_attendance/sessions/{session_id}",
      path: {
        session_id: sessionId,
      },
      query: {
        student_id: studentId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
