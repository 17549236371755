import { Pencil1Icon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Button, ButtonColor } from "components/Button";
import {
  StudentContactResponse,
  ApiError,
  StudentContactsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import "../index.css";
import PhoneInput from "react-phone-input-2";

export default function ParentContactInfo({
  contact,
  studentContacts,
  setStudentContacts,
  setStatus,
  setError,
}: {
  contact?: StudentContactResponse;
  studentContacts: StudentContactResponse[];
  setStudentContacts: (contacts: StudentContactResponse[]) => void;
  setStatus: (status: PageStatus) => void;
  setError: (error: APIResponse) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [firstNameInput, setFirstNameInput] = useState(
    contact?.first_name || ""
  );
  const [lastNameInput, setLastNameInput] = useState(contact?.last_name || "");
  const [emailInput, setEmailInput] = useState(contact?.email || "");
  const [phoneInput, setPhoneInput] = useState(contact?.phone_number || "");
  const [addressInput, setAddressInput] = useState(
    contact?.address_line_1 || ""
  );

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && isEditing) {
      updateStudentContactInfo();
    }
  };

  const handleCancel = () => {
    setFirstNameInput(contact?.first_name || "");
    setLastNameInput(contact?.last_name || "");
    setEmailInput(contact?.email || "");
    setPhoneInput(contact?.phone_number || "");
    setAddressInput(contact?.address_line_1 || "");
    setIsEditing(false);
  };

  async function updateStudentContactInfo() {
    const dataChanged =
      firstNameInput !== contact?.first_name ||
      lastNameInput !== contact?.last_name ||
      emailInput !== contact?.email ||
      phoneInput !== contact?.phone_number ||
      addressInput !== contact?.address_line_1;

    if (dataChanged && contact) {
      await StudentContactsService.updateStudentContact({
        studentContactId: contact.id,
        requestBody: {
          first_name: firstNameInput,
          last_name: lastNameInput,
          email: emailInput,
          phone_number: phoneInput,
          address_line_1: addressInput,
        },
      })
        .then((updatedStudentContact) => {
          setStudentContacts(
            studentContacts.map((contact: StudentContactResponse) =>
              contact.id === updatedStudentContact.id
                ? updatedStudentContact
                : contact
            )
          );
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "Unable to update student contact info" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }

    setIsEditing(false);
  }

  const handlePhoneChange = (newPhone: string) => {
    setPhoneInput(newPhone);
  };

  return (
    <div className="student-profile--section flex justify-center">
      <div
        className={`flex flex-col gap-y-7 w-10/12 pt-5 ${
          isEditing ? `pb-2 mr-5` : `pb-16`
        }`}
      >
        {contact ? (
          <div className="text-lg font-bold">Additional Parent Contact</div>
        ) : (
          <div className="text-lg font-bold">No parent contact.</div>
        )}

        {contact && (
          <>
            <div className="text-md flex flex-row">
              <label className="font-bold w-2/5" htmlFor="first-name">
                First Name:
              </label>
              {isEditing ? (
                <input
                  id="first-name"
                  value={firstNameInput}
                  className="w-3/5 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setFirstNameInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-3/5 h-6">{firstNameInput}</div>
              )}
            </div>
            <div className="text-md flex flex-row">
              <label className="font-bold w-2/5" htmlFor="last-name">
                Last Name:
              </label>
              {isEditing ? (
                <input
                  id="last-name"
                  value={lastNameInput}
                  className="w-3/5 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setLastNameInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-3/5 h-6">{lastNameInput}</div>
              )}
            </div>
            <div className="text-md flex flex-row">
              <label className="font-bold w-2/5" htmlFor="email">
                Email:
              </label>
              {isEditing ? (
                <input
                  id="email"
                  value={emailInput}
                  className="w-3/5 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setEmailInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-3/5 break-words">{emailInput}</div>
              )}
            </div>
            <div className="text-md flex flex-row">
              <label className="font-bold w-2/5" htmlFor="phone">
                Phone:
              </label>
              <div className="w-3/5">
                {isEditing ? (
                  <PhoneInput
                    country={"us"}
                    value={phoneInput}
                    onChange={handlePhoneChange}
                    prefix="+"
                    inputClass="parent-phone-input-edit"
                    placeholder="+1 (555) 555-5555"
                    dropdownStyle={{ display: "none" }}
                    countryCodeEditable={false}
                    onKeyDown={handleEnterKeyPress}
                  />
                ) : phoneInput === null ||
                  phoneInput === "1" ||
                  phoneInput === "" ||
                  phoneInput === undefined ? (
                  ""
                ) : (
                  <PhoneInput
                    country={"us"}
                    value={phoneInput}
                    onChange={handlePhoneChange}
                    prefix="+"
                    inputClass="parent-phone-input-view"
                    placeholder="+1 (555) 555-5555"
                    dropdownStyle={{ display: "none" }}
                    countryCodeEditable={false}
                    disabled={true}
                    onKeyDown={handleEnterKeyPress}
                  />
                )}
              </div>
            </div>
            <div className="text-md flex flex-row">
              <label className="font-bold w-2/5" htmlFor="address">
                Address:
              </label>
              {isEditing ? (
                <input
                  id="address"
                  value={addressInput}
                  className="w-3/5 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setAddressInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-3/5 h-6">{addressInput}</div>
              )}
            </div>
            {isEditing && (
              <div className="flex flex-row gap-2">
                <Button
                  color={ButtonColor.SKYBLUE}
                  onClick={updateStudentContactInfo}
                  extraClasses="w-20 h-7"
                >
                  Save
                </Button>
                <Button
                  color={ButtonColor.RED}
                  onClick={handleCancel}
                  extraClasses="w-20 h-7"
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      {!isEditing && contact && (
        <div className="mt-5">
          <Pencil1Icon
            className="w-5 h-5 cursor-pointer"
            onClick={() => setIsEditing(true)}
          />
        </div>
      )}
    </div>
  );
}
