/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a type of billing, as for an organization transaction.
 */
export enum BillingType {
  UPFRONT = "UPFRONT",
  DIRECT = "DIRECT",
  BUNDLE = "BUNDLE",
  MEETING_UNCHARGED = "MEETING_UNCHARGED",
  MEETING_CHARGED_IN_BUNDLE = "MEETING_CHARGED_IN_BUNDLE",
  MEETING_CHARGED_INDIVIDUALLY = "MEETING_CHARGED_INDIVIDUALLY",
  MEETING_PAID_WITH_CREDITS = "MEETING_PAID_WITH_CREDITS",
  CANCELLATION_FEE = "CANCELLATION_FEE",
  RESCHEDULING_FEE = "RESCHEDULING_FEE",
  ABSENCE_FEE = "ABSENCE_FEE",
  REFUND = "REFUND",
}
