import Students from "./Students";
import {
  StudentResponse,
  ParentResponse,
  MeetingResponse,
} from "client/openapi";
import MeetingHistory from "./Meetings";

export default function ParentInfoTab({
  parent,
  orgStudents,
  parentStudents,
  setParentStudents,
  meetings,
  exportingMeetings,
  setExportingMeetings,
  setStatus,
  setError,
}: {
  parent: ParentResponse;
  orgStudents: StudentResponse[];
  parentStudents: StudentResponse[];
  setParentStudents: (students: StudentResponse[]) => void;
  meetings: MeetingResponse[];
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
}) {
  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-2 h-full">
      <div className="flex justify-center mx-3 mt-5 md:justify-end md:mx-0 md:mt-0">
        <Students
          parent={parent}
          orgStudents={orgStudents}
          parentStudents={parentStudents}
          setParentStudents={setParentStudents}
        />
      </div>
      <div className="flex justify-center mx-5 mt-5 md:justify-end md:mx-0 md:mt-0">
        <MeetingHistory
          meetings={meetings}
          exportingMeetings={exportingMeetings}
          setExportingMeetings={setExportingMeetings}
          parent={parent}
          setStatus={setStatus}
          setError={setError}
          organization_id={parent.org_id}
        />
      </div>
    </div>
  );
}
