import {
  Auth0AccountRole,
  OpenAPI,
  OrganizationResponse,
  OrganizationSubjectResponse,
  OrganizationsService,
  StudentResponse,
  TutorMeetingResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill } from "components/Button";
import ManageOrgSubjectsDialog from "components/Dashboard/ManageOrgSubjectsDialog";
import { Statistic } from "components/Dashboard/Statistics";
import StudentsList, {
  StudentDashboardAction,
} from "components/Dashboard/StudentsList";
import TutorsList from "components/Dashboard/TutorsList";
import UpcomingMeetings from "components/Dashboard/UpcomingMeetings";
import { Dialog, DialogTrigger } from "components/Dialog";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { APIResponse, AccountRoles, PageStatus } from "types";
import { getContextColor } from "util/contextColor";
import pluralize from "util/pluralize";
import "../TutorDashboard/index.css";
import FilterCalendarDialog from "../FilterCalendarDialog";
import MeetingDialog from "components/MeetingDialog";
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import ParentsList from "../ParentsList";

export default function OrganizationDashboard() {
  const { isAuthenticated, user, isLoading } = useAuth0();

  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  // set up state
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [organization, setOrganization] =
    useState<OrganizationResponse | null>();

  const [subjects, setSubjects] = useState<OrganizationSubjectResponse[]>([]);
  const [tutors, setTutors] = useState<TutorMeetingResponse[]>([]);
  const [students, setStudents] = useState<StudentResponse[]>([]);

  const [subjectFilters, setSubjectFilters] = useState<number[]>();
  const [tutorFilters, setTutorFilters] = useState<number[]>();
  const [studentFilters, setStudentFilters] = useState<number[]>();

  const [error, setError] = useState<APIResponse>();

  const [stats, setStats] = useState<Statistic[]>([]);
  const [addedStart, setAddedStart] = useState<Date>();

  //fetch the current account's tutor
  const fetchOrganization = () => {
    setStatus(PageStatus.LOADING);

    OrganizationsService.getOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((organizationResult) => {
        setStatus(PageStatus.SUCCESS);
        setOrganization(organizationResult);
        setSubjects(organizationResult.subjects);
        setStudents(organizationResult.students);
        setTutors(organizationResult.tutors);
        setStats([
          {
            label: pluralize(
              "Tutor",
              "Tutors",
              organizationResult.tutors.length
            ),
            value: organizationResult.tutors.length,
          },
          {
            label: pluralize(
              "Student",
              "Students",
              organizationResult.students.length
            ),
            value: organizationResult.students.length,
          },
          {
            label: pluralize(
              "Meeting this week",
              "Meetings this week",
              organizationResult.num_sessions_week as number
            ),
            value: organizationResult.num_sessions_week as number,
          },
          {
            label: pluralize(
              "Meeting this month",
              "Meetings this month",
              organizationResult.num_sessions_month as number
            ),
            value: organizationResult.num_sessions_month as number,
          },
        ]);
      })
      .catch((e: any) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e?.code}): ${e?.message}`);
      });
  };

  useEffect(() => {
    if (!isLoading && currently_selected_organization) {
      fetchOrganization();
    }
  }, [currently_selected_organization, isLoading]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <LoadingBlock status={status} />

      <ErrorBlock status={status} message={error?.message} />

      {status === PageStatus.SUCCESS && organization && (
        <>
          <div className="m-4">
            <UpcomingMeetings
              studentFilters={studentFilters}
              tutorFilters={tutorFilters}
              subjectFilters={subjectFilters}
              addedStart={addedStart}
            >
              <div className="list--actions">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN}>Book Meeting</Button>
                  </DialogTrigger>

                  <MeetingDialog
                    isCreate={true}
                    role={Auth0AccountRole.ORG_ADMIN}
                    setEvents={(e?: string) =>
                      Promise.resolve(setAddedStart(moment(e).toDate()))
                    }
                  />
                </Dialog>

                <Button
                  color={ButtonColor.GREEN}
                  fill={ButtonFill.HOLLOW}
                  disabled
                  title="Coming soon!"
                >
                  Log Activity
                </Button>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN} fill={ButtonFill.HOLLOW}>
                      Manage Subjects
                    </Button>
                  </DialogTrigger>

                  <ManageOrgSubjectsDialog
                    orgId={organization.id}
                    subjects={subjects}
                    setSubjects={setSubjects}
                  />
                </Dialog>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN} fill={ButtonFill.HOLLOW}>
                      Filter Calendar
                    </Button>
                  </DialogTrigger>

                  <FilterCalendarDialog
                    subjects={subjects}
                    students={students}
                    tutors={tutors}
                    submitFilters={(tutors, students, subjects) => {
                      setTutorFilters(tutors?.map((t) => t.id));
                      setStudentFilters(students?.map((s) => s.id));
                      setSubjectFilters(subjects?.map((s) => s.id));
                    }}
                  />
                </Dialog>
              </div>
            </UpcomingMeetings>
          </div>

          <div className="container px-4 mx-auto mt-8">
            <TutorsList
              organization={organization}
              role={Auth0AccountRole.ORG_ADMIN}
            />
          </div>

          <div className="container px-4 mx-auto mt-8 mb-8">
            <StudentsList
              role={Auth0AccountRole.ORG_ADMIN}
              organizationIfOnOrgDashboard={organization}
              org_name={organization.name}
            />
          </div>

          <div className="container px-4 mx-auto mt-8 mb-8">
            <ParentsList
              organization={organization}
              role={Auth0AccountRole.ORG_ADMIN}
            />
          </div>

          {/* <div className="container px-4 mx-auto">
            <div className="edit-profile-link org-dashboard-buttons">
              <Link
                to={`/organizations/${organization.id}`}
                className={`button button--large button--${getContextColor(
                  AccountRoles.ORGANIZATION
                )}`}
              >
                <>View Profile &rarr;</>
              </Link>

              <Link
                to="/organizations/profile"
                className={`button button--hollow button--large button--${getContextColor(
                  AccountRoles.ORGANIZATION
                )}`}
              >
                <>Edit Profile &rarr;</>
              </Link>
            </div>
          </div> */}
        </>
      )}
    </>
  );
}
