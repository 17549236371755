import { DayPicker, Matcher } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { CalendarIcon } from "@radix-ui/react-icons";
import "./index.css";
import { useState, useEffect } from "react";
import moment from "moment";

export default function DayInput({
  id,
  value,
  setValue,
  min,
  max,
  required,
  classes,
  dayPickerClasses,
}: {
  id: string;
  value: string | number;
  setValue: ((string) => void) | ((number) => void);
  min?: string | number;
  max?: string | number;
  required?: boolean;
  classes?: string;
  dayPickerClasses?: string;
}) {
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(value)
  );
  const [disabeldDays, setDisabledDays] = useState<Matcher[]>([]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (typeof value === "string") setValue(date);
    else if (typeof value === "number") setValue(moment(date).valueOf());
    setIsDayPickerOpen(false);
  };

  useEffect(() => {
    if (min && max) {
      setDisabledDays([{ before: new Date(min) }, { after: new Date(max) }]);
    } else if (min) {
      setDisabledDays([{ before: new Date(min) }]);
    } else if (max) {
      setDisabledDays([{ after: new Date(max) }]);
    } else {
      setDisabledDays([]);
    }
  }, [min, max]);

  useEffect(() => {
    if (!value) {
      setSelectedDate(undefined);
      return;
    }
    setSelectedDate(moment(value).toDate());
  }, [value]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const calendarContainer = document.getElementById(id);
      if (calendarContainer && !calendarContainer.contains(event.target)) {
        setIsDayPickerOpen(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [id, setIsDayPickerOpen]);

  return (
    <div
      className={`cursor-pointer relative ${classes}`}
      id={id}
      onClick={() => {
        setIsDayPickerOpen(true);
      }}
    >
      <div className="grid grid-cols-[1fr,auto] gap-5 items-center h-full">
        <div>
          {selectedDate
            ? moment(selectedDate).format("MM/DD/YYYY")
            : "MM/DD/YYYY"}
        </div>
        <CalendarIcon />
      </div>
      {isDayPickerOpen && (
        <DayPicker
          id={id}
          mode="single"
          disabled={disabeldDays}
          className={`text-sm absolute ${dayPickerClasses}`}
          selected={selectedDate}
          onSelect={handleDateChange}
          required={required}
          captionLayout="dropdown"
          defaultMonth={selectedDate}
          style={{ left: "-1rem" }}
        />
      )}
    </div>
  );
}
