import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  Label,
  ResponsiveContainer,
  Dot,
  ScatterChart,
} from "recharts";
import { useState } from "react";

const topMargin = 10;
const leftMargin = 3;
const bottomMargin = 20;
const rightMargin = 15;

export default function LinearRegressionChart({
  dotsData,
  lineData,
  xAxisLabel,
  xKey,
  dotYKey,
  lineYKey,
  yAxisLabel,
  xDomain,
  yDomain,
  formatter,
  setHoveredDot,
  linearRegression,
}: {
  dotsData: any[];
  lineData: any[];
  xKey: string;
  xAxisLabel: string;
  dotYKey: string;
  lineYKey: string;
  yAxisLabel: string;
  xDomain?: number[];
  yDomain?: number[];
  formatter?: (value: number) => string;
  setHoveredDot?: (value: [number, number] | undefined) => void;
  linearRegression?: {
    slope: number;
    intercept: number;
    rSquared: number;
  };
}) {
  const [computedDot, setComputedDot] = useState<
    [number, number] | undefined
  >();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        margin={{
          top: topMargin,
          left: leftMargin,
          bottom: bottomMargin,
          right: rightMargin,
        }}
        onMouseMove={(event) => {
          if (event !== null) {
            const xValue = event.xValue;
            const yValue = event.yValue;
            if (xValue && yValue) {
              setHoveredDot?.([xValue, yValue]);

              if (
                linearRegression &&
                !Number.isNaN(linearRegression.slope) &&
                !Number.isNaN(linearRegression.intercept)
              ) {
                const computedY =
                  linearRegression.slope * xValue + linearRegression.intercept;
                setComputedDot([xValue, computedY]);
              }
            }
          }
        }}
        onMouseLeave={() => {
          setHoveredDot?.(undefined);
          setComputedDot?.(undefined);
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {dotsData.length > 0 && <Tooltip labelFormatter={formatter} />}
        <XAxis
          dataKey={xKey}
          axisLine={{ stroke: "black" }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          domain={dotsData.length === 0 ? [0, 0] : xDomain}
          style={{ fontSize: "14px" }}
          tickFormatter={formatter}
          type="number"
        >
          <Label
            value={xAxisLabel}
            position="insideBottom"
            style={{ textAnchor: "middle", fontSize: "14px" }}
            fill="black"
            fontWeight="bold"
            dy={bottomMargin}
          />
        </XAxis>
        <YAxis
          axisLine={{ stroke: "black" }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          style={{ fontSize: "14px" }}
          domain={yDomain}
        >
          <Label
            value={yAxisLabel}
            position="insideLeft"
            angle={-90}
            fill="black"
            fontWeight="bold"
            style={{ textAnchor: "middle", fontSize: "14px" }}
          />
        </YAxis>
        <Scatter
          data={dotsData}
          dataKey={dotYKey}
          fill="#90CAFF"
          shape={<Dot r={3} />}
        />
        {!isNaN(lineData[0][lineYKey]) && !isNaN(lineData[1][lineYKey]) && (
          <Scatter
            data={lineData}
            dataKey={lineYKey}
            fill="none"
            line={{ stroke: "#06F", strokeWidth: 1 }}
          />
        )}
        {computedDot && (
          <Scatter
            data={[{ [xKey]: computedDot[0], computedY: computedDot[1] }]}
            dataKey="computedY"
            fill="#06F"
            shape={<Dot r={3} />}
          />
        )}
      </ScatterChart>
    </ResponsiveContainer>
  );
}
