/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PermissionsService {
  /**
   * Get User Permissions
   * Get all permissions a user has in the database, and return a dictionary with
   * the key being org_id and the values being a list of Auth0AccountRoles.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getUserPermissions(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/permissions",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
}
