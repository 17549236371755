import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { PlusIcon } from "@radix-ui/react-icons";
import { Auth0AccountRole, OrganizationResponse } from "client/openapi";
import { Button, ButtonColor } from "components/Button";
import { Organization, Tutor, UserType } from "types";
import { getDialog } from "../helpers";

function InviteUserDialog({
  type_of_user_being_invited,
  buttonColor,
  buttonText,
  fetchUsers,
  currentPage,
  orgId,
}: {
  type_of_user_being_invited: Auth0AccountRole;
  buttonColor: ButtonColor;
  buttonText?: string;
  fetchUsers: (page: number) => Promise<void>;
  currentPage: number;
  orgId: number;
}) {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button color={buttonColor} extraClasses="w-full">
            {buttonText ?? "Invite"} <PlusIcon />
          </Button>
        </DialogTrigger>

        {getDialog({
          type_of_user_being_invited,
          fetchUsers,
          currentPage,
          orgId,
        })}
      </Dialog>
    </>
  );
}

export default InviteUserDialog;
