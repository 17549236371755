import { ChartsService } from "client/openapi";
import { ButtonColor } from "components/Button";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import SuccessDialog from "components/SuccessDialog";
import { useState } from "react";
import { PageStatus } from "types";
import Notifications from "util/notifications";

export default function DeleteChartDialog({
  chartId,
  setUpdateCharts,
  updateCharts,
  close,
}: {
  chartId?: number;
  setUpdateCharts: (updateCharts: boolean) => void;
  updateCharts: boolean;
  close: () => void;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);

  const deleteChart = async () => {
    if (!chartId) {
      return;
    }
    try {
      setStatus(PageStatus.LOADING);
      await ChartsService.deleteChartPrototype({
        chartPrototypeId: chartId,
      });
      setUpdateCharts(!updateCharts);
      setStatus(PageStatus.SUCCESS);
    } catch {
      setStatus(PageStatus.ERROR);
      Notifications.error("An unexpected error occurred. Please try again");
    }
  };

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message={"Chart deleted!"} onClose={close} />;
  }

  return (
    <DialogContent
      className="bg-white dialog-content dialog-content--left font-montserrat p-4"
      alignLeft={true}
      showClose={false}
    >
      <div className="mt-3 font-semibold text-center header text-2xl mt-4">
        Delete Chart
      </div>

      <div className="mt-3 p-4">
        <p className="header text-gray-400 font-bold mb-3 text-center">
          You are about to delete this chart and all student data attached to
          it.
        </p>
      </div>

      <DialogActions>
        <DialogAction
          primary={true}
          color={ButtonColor.RED}
          onClick={deleteChart}
        >
          Delete
        </DialogAction>
        <DialogClose asChild>
          <DialogAction color={ButtonColor.BLACK}>Cancel</DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
