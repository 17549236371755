/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BooleanResponse } from "../models/BooleanResponse";
import type { ChargeCreate } from "../models/ChargeCreate";
import type { OrgBankTransactionResponse } from "../models/OrgBankTransactionResponse";
import type { StudentOrgTransactionResponse } from "../models/StudentOrgTransactionResponse";
import type { TadpoleOrgTransactionResponse } from "../models/TadpoleOrgTransactionResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ChargesService {
  /**
   * Create Charge
   * Create a charge.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createCharge({
    requestBody,
  }: {
    requestBody: ChargeCreate;
  }): CancelablePromise<
    | TadpoleOrgTransactionResponse
    | OrgBankTransactionResponse
    | StudentOrgTransactionResponse
  > {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charges",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Refund
   * Issue a refund for a past charge.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createRefund({
    transactionId,
    retryingTransactionId,
  }: {
    transactionId: string;
    retryingTransactionId?: string | null;
  }): CancelablePromise<
    StudentOrgTransactionResponse | TadpoleOrgTransactionResponse
  > {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charges/refund/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      query: {
        retrying_transaction_id: retryingTransactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Void Transaction Invoice
   * Void an invoice.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static voidTransactionInvoice({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/charges/void/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Transaction
   * Retrieve a transaction from the DB.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTransaction({
    transactionId,
  }: {
    transactionId: number;
  }): CancelablePromise<
    | TadpoleOrgTransactionResponse
    | OrgBankTransactionResponse
    | StudentOrgTransactionResponse
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/charges/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
