import {
  ChartsService,
  ChartSubscriptionResponse,
  OrganizationResponse,
  ChartXAxisType,
  MetricValueResponse,
  StudentResponse,
  StudentsService,
  AccountResponse,
} from "client/openapi";
import moment from "moment";
import "moment-timezone";
import { Dialog, DialogTrigger } from "components/Dialog";
import {
  SizeIcon,
  Pencil1Icon,
  DrawingPinIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { useState, useEffect } from "react";
import LinearRegressionChart from "components/LinearRegressionChart";
import { calculateLinearRegression } from "util/math";
import CreateChartDialog from "pages/org-performance/Metrics/createChartDialog";
import ExpandedChartDialog from "./ExpandedChartDialog";
import Notifications from "util/notifications";
import "./index.css";

export type PerformanceChartDot = {
  x: number;
  score: number;
  timestamp: string;
  student: StudentResponse;
};

export type PerformanceChartLine = {
  x: number;
  line: number;
};

function generateDotsForTime(student_data: MetricValueResponse[]) {
  const datapoints: PerformanceChartDot[] = [];

  student_data.forEach((data_point) => {
    datapoints.push({
      x: data_point.days_elapsed as number,
      score: data_point.value,
      timestamp: data_point.date,
      student: data_point.student,
    });
  });

  return datapoints;
}

function generateDotsForHours(student_data: MetricValueResponse[]) {
  const datapoints: PerformanceChartDot[] = [];

  student_data.forEach((data_point) => {
    datapoints.push({
      x: data_point.hours as number,
      score: data_point.value,
      timestamp: data_point.date,
      student: data_point.student,
    });
  });

  return datapoints;
}

export function filterChartDP(
  dotsDP: PerformanceChartDot[],
  start: number,
  end: number
) {
  return dotsDP.filter((datapoint) => {
    return datapoint.x >= start && datapoint.x <= end;
  });
}

function findMinAndMax(dotsDP: PerformanceChartDot[]): [number, number] {
  if (dotsDP.length === 0) {
    return [0, 0];
  }
  let min = dotsDP[0].x;
  let max = dotsDP[0].x;
  for (let i = 0; i < dotsDP.length; i++) {
    if (dotsDP[i].x < min) {
      min = dotsDP[i].x;
    }
    if (dotsDP[i].x > max) {
      max = dotsDP[i].x;
    }
  }

  if (min === max) {
    return [0, max];
  }

  return [min, max];
}

export default function PerformanceChart({
  chart,
  organization,
  updateCharts,
  setUpdateCharts,
}: {
  chart: ChartSubscriptionResponse;
  organization: OrganizationResponse;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  const [defaultDotsDP, setDefaultDotsDP] = useState<PerformanceChartDot[]>();
  const [dotsDP, setDotsDP] = useState<PerformanceChartDot[]>();
  const [lineDP, setLineDP] = useState<PerformanceChartLine[]>();
  const [minMax, setMinMax] = useState<[number, number]>(findMinAndMax([]));
  const [rawData, setRawData] = useState<MetricValueResponse[]>([]);
  const [defaultMinMax, setDefaultMinMax] = useState<[number, number]>(
    findMinAndMax([])
  );
  const [linearRegression, setLinearRegression] = useState<{
    slope: number;
    intercept: number;
    rSquared: number;
    confidenceInterval: number[];
  }>();

  let xAxisLabel;
  switch (chart.chart_prototype.chart_x_axis_type) {
    case ChartXAxisType.HOURS_OF_TUTORING:
      xAxisLabel = "Hours of Tutoring";
      break;
    case ChartXAxisType.DATE:
      xAxisLabel = "Time (Days Elapsed)";
      break;
  }

  let yAxisLabel;
  let yDomain: number[] = [];
  yAxisLabel = chart.chart_prototype.metric_definition.y_axis_name;
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_min);
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_max);

  async function updateChartPin() {
    await ChartsService.updateChartSubscription({
      chartSubscriptionId: chart.id,
      requestBody: {
        pinned: !chart.pinned,
      },
    })
      .then((res) => {
        setUpdateCharts(!updateCharts);
        if (res.pinned) {
          Notifications.success("Chart pinned successfully!");
        } else {
          Notifications.success("Chart unpinned successfully!");
        }
      })
      .catch((err) => {
        console.error("Cannot update chart: ", err);
      });
  }

  useEffect(() => {
    ChartsService.getDataForChartSubscription({
      chartSubscriptionId: chart.id,
    })
      .then((data) => {
        setRawData(data);
      })
      .catch((err) => console.log(err));
  }, [chart]);

  useEffect(() => {
    if (rawData.length > 0) {
      let updatedDotsDP: PerformanceChartDot[] = [];

      if (chart.chart_prototype.chart_x_axis_type === ChartXAxisType.DATE) {
        updatedDotsDP = generateDotsForTime(rawData);
      } else {
        updatedDotsDP = generateDotsForHours(rawData);
      }

      setDotsDP(updatedDotsDP);
      setDefaultDotsDP(updatedDotsDP);
      setDefaultMinMax(findMinAndMax(updatedDotsDP));
      setMinMax(findMinAndMax(updatedDotsDP));
    }
  }, [chart.chart_prototype.chart_x_axis_type, rawData]);

  useEffect(() => {
    if (dotsDP) {
      const coordinates = dotsDP.map((datapoint) => {
        return {
          x: datapoint.x,
          y: datapoint.score,
        };
      });
      const [slope, intercept, rSquared, confidenceInterval] =
        calculateLinearRegression(coordinates);
      setLinearRegression({
        slope: slope,
        intercept: intercept,
        rSquared: rSquared,
        confidenceInterval: confidenceInterval,
      });
      const start: PerformanceChartLine = {
        x: minMax[0],
        line: slope * minMax[0] + intercept,
      };
      const end: PerformanceChartLine = {
        x: minMax[1],
        line: slope * minMax[1] + intercept,
      };
      const updatedLineDP: PerformanceChartLine[] = [];
      updatedLineDP.push(start);
      updatedLineDP.push(end);
      setLineDP(updatedLineDP);
    }
  }, [dotsDP, minMax]);

  return (
    <div className="font-montserrat w-full h-full">
      <div className="flex flex-row items-center justify-between h-1/5 w-full">
        <div className="flex flex-row items-center justify-start gap-2 w-3/5">
          <Tooltip>
            <TooltipTrigger>
              <h2 className="font-bold text-xl overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer">
                {chart.chart_prototype.chart_title}
              </h2>
            </TooltipTrigger>

            <TooltipContent>{chart.chart_prototype.chart_title}</TooltipContent>
          </Tooltip>

          <div>
            <Tooltip>
              <TooltipTrigger>
                <InfoCircledIcon
                  cursor="pointer"
                  className="h-5 w-5 text-gray-300"
                />
              </TooltipTrigger>

              <TooltipContent>
                <div className="w-60">
                  How did we create this? Tadpole aggregated data across{" "}
                  {rawData && rawData.length}{" "}
                  {rawData.length === 1 ? "student" : "students"} to assemble a
                  scatter plot with a line of best fit. These statistics help to
                  quantify the impact of your tutoring program.
                </div>
              </TooltipContent>
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-row items-center gap-1 w-2/5 justify-end">
          <Dialog>
            <DialogTrigger>
              <Pencil1Icon cursor="pointer" className="h-6 w-6 icon-button" />
            </DialogTrigger>

            <CreateChartDialog
              chart={chart.chart_prototype}
              organization={organization}
              updateCharts={updateCharts}
              setUpdateCharts={setUpdateCharts}
            />
          </Dialog>

          <DrawingPinIcon
            className={`cursor-pointer h-6 w-6 ${
              chart.pinned ? `text_skyblue` : ``
            } icon-button`}
            onClick={updateChartPin}
          />

          <Dialog>
            <DialogTrigger>
              <Tooltip>
                <TooltipTrigger>
                  <SizeIcon cursor="pointer" className="h-6 w-6 icon-button" />
                </TooltipTrigger>

                <TooltipContent>Expand</TooltipContent>
              </Tooltip>
            </DialogTrigger>

            {defaultDotsDP && dotsDP && lineDP && linearRegression && (
              <ExpandedChartDialog
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                chart={chart}
                organization={organization}
                defaultMinMax={defaultMinMax}
                minMax={minMax}
                setMinMax={setMinMax}
                defaultDotsDP={defaultDotsDP}
                displayDotsDP={dotsDP}
                setDisplayDotsDP={setDotsDP}
                displayLineDP={lineDP}
                linearRegression={linearRegression}
              />
            )}
          </Dialog>
        </div>
      </div>

      <div className="w-full h-4/5">
        {dotsDP && lineDP && linearRegression && rawData?.length > 0 ? (
          <LinearRegressionChart
            dotsData={dotsDP}
            lineData={lineDP}
            xKey="x"
            xAxisLabel={xAxisLabel}
            dotYKey="score"
            lineYKey="line"
            yAxisLabel={yAxisLabel}
            xDomain={[minMax[0], minMax[1]]}
            yDomain={yDomain}
            formatter={undefined}
          />
        ) : (
          <div className="w-full">No student data to display.</div>
        )}
      </div>
    </div>
  );
}
