import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor } from "components/Button";
import { ChangeEventHandler, FormEventHandler } from "react";
import { Signup } from "types";

function SignupAddress({
  values,
  handleUpdate,
  handleSubmit,
  loading,
  errors,
}: {
  values: Signup;
  handleUpdate: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  errors: Signup;
}) {
  return (
    <form
      action="#"
      onSubmit={handleSubmit}
      className="flex items-center justify-center"
      style={{ height: "75vh" }}
    >
      <div>
        <h1 className="mt-5 headline-9">Organization Details</h1>

        <div className="mt-2 mb-2 signup_text">
          What are the official details of your organization?
        </div>

        <label htmlFor="name" className="input-label">
          Name
          <input
            id="name"
            name="name"
            type="text"
            className="input"
            placeholder="Boston Tutoring, Inc."
            value={values.name}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        <div className="mt-4 d-flex">
          <Button color={ButtonColor.GREEN} block={true} type="submit">
            Next <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </form>
  );
}

export default SignupAddress;
