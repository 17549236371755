import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus, StudentData } from "types";
import { getRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import "./index.css";
import {
  ApiError,
  Auth0AccountRole,
  OrgNotesService,
  OrganizationNoteKeyResponse,
  OrganizationStudentNoteResponse,
  StudentResponse,
} from "client/openapi";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function StudentDataDialog({
  role,
  student,
  organizationId,
}: {
  role: Auth0AccountRole | null;
  student: StudentResponse;
  organizationId: number;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();
  const [cachedNotes, setCachedNotes] = useState<
    OrganizationStudentNoteResponse[]
  >([]);
  const [notes, setNotes] = useState<OrganizationStudentNoteResponse[]>([]);
  const [savingKeys, setSavingKeys] = useState<number[]>([]);
  const [focusedKey, setFocusedKey] = useState<number>();
  const [modifiedKeys, setModifiedKeys] = useState<number[]>([]);
  const { account } = useContext(OrgRolesAndAccountContext);

  const MAX_CONTENT_LENGTH: number = 100;

  async function fetchNotes() {
    setStatus(PageStatus.LOADING);

    OrgNotesService.getOrgNotes({
      orgId: student.org_id,
      studentId: student.id,
    })
      .then((notesResult) => {
        setCachedNotes(notesResult);
        setNotes(notesResult);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: any) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e?.code}): ${e?.message}`);
      });
  }

  async function createValue(keyId: number, content: string) {
    const key = notes.find((n) => n.key.id === keyId)?.key;
    if (content === "") {
      return;
    } else if (!key) {
      return setError({ message: "An unexpected error occurred." });
    } else if (content.length > MAX_CONTENT_LENGTH) {
      return setError({
        message: `The value of "${key.name}" is too long. Max length is ${MAX_CONTENT_LENGTH}, current value is ${content.length} characters.`,
      });
    }
    setSavingKeys([...savingKeys, keyId]);

    await OrgNotesService.createOrgNoteValue({
      requestBody: {
        key_id: keyId,
        student_id: student.id,
        content,
      },
    })
      .then((value) => {
        const cachedNote = cachedNotes.filter((n) => n.key.id === keyId)[0];
        cachedNote["value"] = value;
        setCachedNotes([...cachedNotes]);

        const note = notes.filter((n) => n.key.id === keyId)[0];
        note["value"] = value;
        setNotes([...notes]);

        setSavingKeys(savingKeys.filter((k) => k !== keyId));
        Notifications.success(`"${key.name}" data entered!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function updateValue(keyId: number, valueId: number, content: string) {
    const key = notes.find((n) => n.key.id === keyId)?.key;
    if (content === "") {
      return deleteValue(keyId, valueId);
    } else if (!key) {
      return setError({ message: "An unexpected error occurred." });
    } else if (content.length > MAX_CONTENT_LENGTH) {
      return setError({
        message: `The value of "${key.name}" is too long. Max length is ${MAX_CONTENT_LENGTH}, current value is ${content.length} characters.`,
      });
    }
    setSavingKeys([...savingKeys, keyId]);

    await OrgNotesService.updateOrgNoteValue({
      orgNoteValueId: valueId,
      requestBody: {
        content,
      },
    })
      .then((value) => {
        const note = cachedNotes.filter((n) => n.key.id === keyId)[0];
        note["value"] = value;
        setCachedNotes([...cachedNotes]);

        setSavingKeys(savingKeys.filter((k) => k !== keyId));
        Notifications.success(`"${key.name}" data updated!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function deleteValue(keyId: number, valueId: number) {
    const key = notes.find((n) => n.key.id === keyId)?.key;
    if (!key) {
      return setError({ message: "An unexpected error has occurred." });
    }
    setSavingKeys([...savingKeys, keyId]);

    await OrgNotesService.deleteOrgNoteValue({ orgNoteValueId: valueId })
      .then(() => {
        const cachedNote = cachedNotes.filter((n) => n.key.id === keyId)[0];
        cachedNote["value"] = undefined;
        setCachedNotes([...cachedNotes]);

        const note = notes.filter((n) => n.key.id === keyId)[0];
        note["value"] = undefined;
        setNotes([...notes]);

        setSavingKeys(savingKeys.filter((k) => k !== keyId));
        Notifications.success(`"${key.name}" data deleted!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to delete data" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  const handleChange = (key: OrganizationNoteKeyResponse, value) => {
    if (!modifiedKeys?.includes(key.id)) {
      const modifiedKeysUpdates = [...modifiedKeys, key.id];
      setModifiedKeys(modifiedKeysUpdates);
    }
    setNotes((prevNotes) => {
      return prevNotes.map((item) => {
        if (item.key.id === key.id) {
          return { ...item, value };
        }
        return item;
      });
    });
  };

  const handleBlur = (keyId: number) => {
    const note = notes.find((n) => n.key.id === keyId);
    const cachedNote = cachedNotes.find((n) => n.key.id === keyId);
    setFocusedKey(undefined);

    if (
      note?.value &&
      cachedNote &&
      note.value.content !== cachedNote.value?.content
    ) {
      if (note.value.id) {
        updateValue(keyId, note.value.id, note.value.content);
      } else {
        createValue(keyId, note.value.content);
      }
    }
  };

  useEffect(() => {
    if (account) {
      fetchNotes();
    }
  }, [account]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <div className="org-notes--dialog">
      {status === PageStatus.LOADING ? (
        <div className="flex flex-col items-center justify-center gap-4 py-5">
          <span
            className="spinner-border"
            role="status"
            aria-hidden="true"
          ></span>

          <p>Loading...</p>
        </div>
      ) : null}

      {status === PageStatus.SUCCESS && notes && notes.length === 0 ? (
        <p>
          Your organization does not have any custom fields configured for
          storing student data.
        </p>
      ) : null}

      <div className="org-notes">
        {notes
          .sort((a, b) => a.key.name.localeCompare(b.key.name))
          .map((n: OrganizationStudentNoteResponse) => (
            <section
              className={`org-notes--pair ${getRoleColor(role)}`}
              key={n.key.id}
            >
              <div className="org-notes--status">
                <label
                  htmlFor={n.key.id.toString()}
                  className="leading-5 input-label"
                >
                  {n.key.name}
                </label>
                <span>
                  {savingKeys.includes(n.key.id)
                    ? "Saving..."
                    : focusedKey === n.key.id
                    ? "Editing..."
                    : modifiedKeys.includes(n.key.id)
                    ? "Saved!"
                    : ""}
                </span>
              </div>
              <input
                id={n.key.id.toString()}
                name={n.key.id.toString()}
                type="text"
                className="mt-1 input"
                placeholder="..."
                value={n.value?.content || ""}
                maxLength={MAX_CONTENT_LENGTH}
                onChange={(ev) =>
                  handleChange(n.key, {
                    id: n.value?.id || null,
                    content: ev.target.value,
                  })
                }
                onFocus={() => setFocusedKey(n.key.id)}
                onBlur={() => handleBlur(n.key.id)}
                disabled={savingKeys.includes(n.key.id)}
              />
            </section>
          ))}
      </div>
    </div>
  );
}

export default StudentDataDialog;
