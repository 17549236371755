import { DialogContent } from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import PaymentMethods from "../../../../../../../../components/PaymentMethods/PaymentMethods";
import { APIResponse, PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import {
  BillingType,
  ChargesService,
  OrgSubjectsService,
  OrgTransactionType,
  OrganizationSubjectResponse,
  PaymentMethodType,
  StudentResponse,
  StudentSubjectsService,
} from "client/openapi";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import ErrorDialog from "components/ErrorDialog";
import Select from "components/Select";

export default function TutoringUpfrontModal({
  orgId,
  student,
}: {
  orgId: number;
  student: StudentResponse;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [hours, setHours] = useState<number>(0);
  const [amount, setAmount] = useState<number>(500);
  const [invalidHours, setInvalidHours] = useState<boolean>(true);
  const [invalidAmount, setInvalidAmount] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<OrganizationSubjectResponse[]>([]);
  const [selectedSubject, setSelectedSubject] =
    useState<OrganizationSubjectResponse>();
  const [specialStudentRates, setSpecialStudentRates] = useState({});
  const [studentRate, setStudentRate] = useState<number>(0);
  const [error, setError] = useState<APIResponse>();
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [paymentMethodType, setPaymentMethodType] =
    useState<PaymentMethodType>();

  const [
    makeSureUserDoesntSpamChargeButton,
    setMakeSureUserDoesntSpamChargeButton,
  ] = useState<boolean>(false);

  const handleCharge = async () => {
    setMakeSureUserDoesntSpamChargeButton(true);

    // value is over limit
    if (Number(hours) > 1000) {
      setInvalidHours(true);
      setError({
        message: "Please charge for no more than 100 hours at a time",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // value is under limit
    if (Number(hours) <= 0) {
      setInvalidHours(true);
      setError({
        message: "Hours must be greater than 0",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // no hours specified
    if (hours === 0 || hours === null || hours === undefined) {
      setInvalidHours(true);
      setError({
        message: "Please specify how many hours you'd like to charge for",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // charging for less than 5 dollars
    if (
      amount < 500 ||
      amount === null ||
      amount === undefined ||
      Number.isNaN(Number(amount))
    ) {
      setInvalidAmount(true);
      setError({
        message: "Please charge for an amount greater than or equal to $5.00",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // charging for less than 5 dollars
    if (selectedSubject === undefined || selectedSubject === null) {
      setError({
        message: "Make sure your subject is selected!",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    ChargesService.createCharge({
      requestBody: {
        amount: amount,
        type: OrgTransactionType.STUDENT_ORG_TRANSACTION,
        billing_type: BillingType.UPFRONT,
        org_id: orgId,
        student_id: student.id,
        hours: hours,
        reason: `${hours} hours of ${selectedSubject?.name} tutoring`,
        payment_method_id: paymentMethod.includes("@")
          ? undefined
          : paymentMethod,
        invoice_email: paymentMethod.includes("@") ? paymentMethod : undefined,
        payment_method_type: paymentMethodType,
        org_subject_id: selectedSubject?.id,
        return_url: `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/students/${student.id}`,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  };

  const verifyHours: CurrencyInputProps["onValueChange"] = (value, name) => {
    // value is over limit
    if (Number(value) > 100) {
      setInvalidHours(true);
      setError({
        message: "Please charge for no more than 100 hours at a time",
      });
      return;
    }

    // value is under limit
    if (Number(value) <= 0) {
      setInvalidHours(true);
      setError({
        message: "Hours must be greater than 0",
      });
      return;
    }

    if (
      Number(value) === null ||
      Number(value) === undefined ||
      Number.isNaN(Number(value))
    ) {
      setInvalidHours(true);
      setError({
        message: "Hours must be greater than 0",
      });
      return;
    }

    setInvalidHours(false);
    setHours(Number(value));
    setAmount(studentRate * Number(value));
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const handleSubjectChange = (option: any) => {
    setSelectedSubject(option);

    const student_rate = option
      ? specialStudentRates[option.id]
        ? specialStudentRates[option.id]
        : (option.default_price_for_students as number)
      : 0.0;

    setStudentRate(student_rate);
    setAmount(student_rate * hours);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const handleSelectPaymentMethod = (id) => {
    if (typeof id === "string" && id.includes("@")) {
      setPaymentMethod(id);
      setPaymentMethodType(PaymentMethodType.INVOICE);
    } else if (typeof id === "object") {
      setPaymentMethod(id.payment_method_id);
      setPaymentMethodType(
        id.brand ? PaymentMethodType.CARD : PaymentMethodType.ACH
      );
    } else {
      // Handle unexpected id type if necessary
      console.error("Unexpected payment method type", id);
    }
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const reset = () => {
    setInvalidHours(true);
    setInvalidAmount(true);
    setAmount(0);
    setHours(0);
    setSelectedSubject(undefined);
    setStudentRate(0);
    setPaymentMethod("");
    setPaymentMethodType(undefined);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const getAndSetSubjects = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrgSubjectsService.getSubjectsByOrg({
      orgId: orgId,
    })
      .then((subjects) => {
        setSubjects(subjects);
      })
      .catch((error) => {
        setError(error);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  const getSpecialStudentRates = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentSubjectsService.getStudentSubjectsByStudentId({
      studentId: student.id,
    })
      .then((studentSubjects) => {
        const prices = {};
        studentSubjects.forEach((subject) => {
          prices[subject.org_subject.id] = subject.custom_price_rate;
        });
        setSpecialStudentRates(prices);
      })
      .catch((error) => {
        setError(error);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [student.id]);

  useEffect(() => {
    getAndSetSubjects();
    getSpecialStudentRates();
  }, [getAndSetSubjects, getSpecialStudentRates]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  useEffect(() => {
    if (amount < 500 || amount === null || amount === undefined) {
      setInvalidAmount(true);
      setError({
        message: "Please charge for an amount greater than or equal to $5.00",
      });
    }

    setInvalidAmount(false);
  }, [amount]);

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Attempting charge. Reload the page to see your changes!"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog
        message="Charge cannot be created at this time"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  return (
    <DialogContent className="dialog-content max-w-[800px]" onClose={reset}>
      <div className="mt-3 font-semibold text-center header text-lg">
        Charge For Tutoring Upfront
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4 flex gap-3">
        <div className="w-5/12 mr-2">
          <p className="text-sm font-bold mb-1">Subject</p>
          <Select
            id="subject"
            options={subjects}
            value={selectedSubject as OrganizationSubjectResponse}
            getOptionLabel={(s) => s.name}
            getOptionValue={(s) => s.id}
            placeholder="Subject..."
            isDisabled={subjects?.length === 0}
            onChange={handleSubjectChange}
          />
        </div>

        <div className="w-3/12">
          <p className="text-sm font-bold mb-3">Rate</p>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(studentRate / 100)}
        </div>
        <div className="w-5/12">
          <p className="text-sm font-bold">Hours</p>
          {/*Note: We are using a currency input, but that's just because it's also convenient for entering hours of tutoring. Don't be fooled! */}
          <CurrencyInput
            className="input w-11/12 h-3/6"
            id="amount"
            name="amount"
            placeholder={`10`}
            decimalsLimit={0}
            allowNegativeValue={false}
            onValueChange={verifyHours}
          />
        </div>
        <div className="w-3/12">
          <p className="text-sm font-bold mb-3">Total</p>
          {hours
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(amount / 100)
            : "$0.00"}
        </div>
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4">
        <p className="mb-2 text-sm font-bold">Bill to:</p>
        <div className="px-2">
          <PaymentMethods
            showAddCardButton
            selectable
            studentIfInitiatedByAdmin={student}
            handleSelectPaymentMethod={handleSelectPaymentMethod}
          />
        </div>
      </div>

      <div className="w-full text-center mt-6">
        <Button
          color={
            invalidHours ||
            invalidAmount ||
            selectedSubject === undefined ||
            paymentMethod === "" ||
            makeSureUserDoesntSpamChargeButton
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          size={ButtonSize.DEFAULT}
          onClick={handleCharge}
          disabled={
            invalidHours ||
            invalidAmount ||
            selectedSubject === undefined ||
            paymentMethod === "" ||
            makeSureUserDoesntSpamChargeButton
          }
        >
          Charge
        </Button>
      </div>
    </DialogContent>
  );
}
