import moment from "moment";

export function getStartOfMonth(date: Date, timezone: string): string {
  const startOfMonth = moment.tz(date, timezone).startOf("month");
  return startOfMonth.toISOString();
}

export function getStartOfNextMonth(date: Date, timezone: string): string {
  const startOfNextMonth = moment
    .tz(date, timezone)
    .startOf("month")
    .add(1, "month");
  return startOfNextMonth.toISOString();
}

export function getIndexOfDayInMonth(dateString: string) {
  const date = new Date(dateString);
  const dayIndex = date.getDate() - 1;
  return dayIndex;
}

export function formatDateToMMDDYYYY(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedValue = `${year}-${month}-${day}`;
  return formattedValue;
}

export function isPast(date: Date) {
  const today = moment().startOf("day");
  return moment(date).isBefore(today);
}

/**
 * Returns the number of days between two dates given an interval between each day.
 */
export function getDays(start: string, until: string, interval: number) {
  const result: string[] = [];
  let startMoment = moment.utc(start);
  const untilMoment = moment.utc(until);
  while (startMoment.isSameOrBefore(untilMoment)) {
    result.push(startMoment.toISOString());
    startMoment.add(interval, "day");
  }
  return result;
}

export function startOfDay(inputTime: string, timezone: string): string {
  const inputDate = moment.tz(inputTime, timezone).startOf("day");
  const result = inputDate.toISOString();
  return result;
}

export function startOfNextDay(inputTime: string, timezone: string): string {
  const inputDate = moment.tz(inputTime, timezone).startOf("day").add(1, "day");
  const result = inputDate.toISOString();
  return result;
}

/**
 * Calculates the end time given a start time, duration, and timezone.
 * @param duration in minutes
 */
export function calculateEndTime(
  startTime: string,
  duration: number,
  timezone: string
) {
  const startMoment = moment(startTime).tz(timezone);
  const endMoment = startMoment.clone().add(duration, "minutes");
  return endMoment.toISOString();
}

export function formatTimeToHMMA(date: Date): string {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}
