import React from "react";
import styles from "./styles.module.css";
import { Tab } from "components/UserSettingsDialog";

interface UserSettingsTabsProps {
  tabs: Tab[];
  activeTab: Tab;
  handleClick: (tab: Tab) => void;
}

export const UserSettingsTabs: React.FC<UserSettingsTabsProps> = ({
  tabs,
  activeTab,
  handleClick,
}) => {
  return (
    <div className={styles.tabsWrapper}>
      {tabs.map((tab) => (
        <div key={tab} className={styles.tab} onClick={() => handleClick(tab)}>
          <p className={tab === activeTab ? styles.active : ""}>{tab}</p>
        </div>
      ))}
    </div>
  );
};
