import "./index.css";

function wavePath(waveType: number) {
  switch (waveType) {
    default:
    case 1:
      return "M0 75.7348L48 65.2677C96 54.8006 192 33.8665 288 18.2275C384 2.71159 480 -7.63233 576 7.1447C672 21.9217 768 61.8197 864 80.291C960 98.7623 1056 95.8069 1152 83.3696C1248 70.8091 1344 48.6435 1392 37.5608L1440 26.478V141H1392C1344 141 1248 141 1152 141C1056 141 960 141 864 141C768 141 672 141 576 141C480 141 384 141 288 141C192 141 96 141 48 141H0V75.7348Z";

    case 2:
      return "M0 94.5L1440 0V140H0V94.5Z";

    case 3:
      return "M0 110V140H1440V0C626.5 0 353.333 76.3333 0 110Z";

    case 4:
      return "M1200 0C1320 0 1320 125 1440 125V140H0V125C120 125 120 0 240 0C360 0 360 125 480 125C600 125 600 0 720 0C840 0 840 125 960 125C1080 125 1080 0 1200 0Z";

    case 5:
      return "M0 140V0H1440V140H0Z";

    case 6:
      return "M0 0L120 21.8629C240 43.8843 480 87.6101 720 91.0955C960 94.5809 1200 57.509 1320 39.1315L1440 20.5955V141H1320C1200 141 960 141 720 141C480 141 240 141 120 141H0V0Z";
  }
}

export function Wave({ waveType }: { waveType: number }) {
  return (
    <svg
      viewBox="0 0 1200 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path d={wavePath(waveType)} className="wave" />
    </svg>
  );
}
