import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Dialog, DialogTrigger } from "components/Dialog";
import PayPerSessionModal from "../Modals/Charges/PayPerSession";
import {
  PaymentPlanResponse,
  StudentResponse,
  StudentSubjectsService,
  StudentsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import CreditDetails from "./creditDetails";

function Credit({
  student,
  orgId,
}: {
  student: StudentResponse;
  orgId: number;
}) {
  interface Credits {
    [key: number]: number;
  }

  const [paymentPlan, setPaymentPlan] = useState<PaymentPlanResponse>();

  const [totalCredits, setTotalCredits] = useState<number>();
  const [currentCredits, setCurrentCredits] = useState<Credits>({});
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  const getExistingCreditsStudent = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentSubjectsService.getStudentSubjectsByStudentId({
      studentId: student.id,
    })
      .then((studentSubjects) => {
        const credits = {};
        studentSubjects.forEach((subject) => {
          credits[subject.org_subject.id] = subject.credits;
        });
        setCurrentCredits(credits);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [student.id]);

  useEffect(() => {
    getExistingCreditsStudent();
  }, [getExistingCreditsStudent]);

  const getPaymentPlan = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentsService.getPaymentPlan({
      studentId: student.id,
    })
      .then((plan) => {
        setPaymentPlan(plan);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [student.id]);

  useEffect(() => {
    getExistingCreditsStudent();
  }, [getExistingCreditsStudent]);

  useEffect(() => {
    getPaymentPlan();
  }, [getPaymentPlan]);

  useEffect(() => {
    // Calculate the total price
    const prices = Object.values(currentCredits);
    const total = prices.reduce((acc, price) => acc + (price || 0), 0);
    setTotalCredits(total);
  }, [currentCredits]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <div className="credit-container pt-2.5 mx-3">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="balance-styling">{`Account Balance: ${new Intl.NumberFormat(
          "en-US",
          {
            style: "currency",
            currency: "USD",
          }
        ).format(totalCredits ? totalCredits / 100 : 0)}`}</div>
        <Dialog>
          <DialogTrigger>
            <div className="balance-button-styling text-xs rounded-full py-0.5 px-3 ml-2">
              details
            </div>
          </DialogTrigger>
          <CreditDetails
            student={student}
            orgId={orgId}
            currentStudentCredits={currentCredits}
          />
        </Dialog>
      </div>
      <div className="pay-per-session-styling flex items-center gap-2">
        Automatically bill per session :{" "}
        {paymentPlan?.frequency === "NONE" ? "No" : "Yes"}
        <Dialog>
          <DialogTrigger>
            <div className="edit-button-styling text-xs rounded-full py-0.5 px-3">
              edit
            </div>
          </DialogTrigger>
          <PayPerSessionModal student={student} orgId={orgId} />
        </Dialog>
      </div>
    </div>
  );
}
export default Credit;
