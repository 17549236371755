import { ChartsService, ChartSubscriptionResponse } from "client/openapi";
import { DialogContent } from "components/Dialog";
import { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor } from "components/Button";
import Notifications from "util/notifications";

export default function DeleteChartDialog({
  studentId,
  updateCharts,
  setUpdateCharts,
}: {
  studentId: number;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [charts, setCharts] = useState<ChartSubscriptionResponse[]>([]);
  const [filteredCharts, setFilteredCharts] = useState<
    ChartSubscriptionResponse[]
  >([]);
  const [selectedChart, setSelectedChart] =
    useState<ChartSubscriptionResponse | null>(null);

  async function fetchCharts() {
    await ChartsService.getAllChartSubscriptionsForStudent({
      studentId: studentId,
    })
      .then((response) => {
        setCharts(response);
        setFilteredCharts(response);
      })
      .catch((err) => {
        console.error(`Unable to fetch charts: ${err}`);
      });
  }

  async function deleteChart() {
    if (selectedChart) {
      await ChartsService.deleteChartSubscription({
        chartSubscriptionId: selectedChart.id,
      })
        .then(() => {
          setUpdateCharts(!updateCharts);
          Notifications.success("Chart deleted successfully");
          fetchCharts();
        })
        .catch((err) => {
          console.error(`Unable to delete chart: ${err}`);
        });
    } else {
      Notifications.error("Please select a chart to delete");
    }
  }

  useEffect(() => {
    fetchCharts();
  }, [updateCharts]);

  useEffect(() => {
    const filtered = charts.filter((chart) =>
      chart.chart_prototype.chart_title
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setFilteredCharts(filtered);
  }, [searchQuery, charts]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left medium pb-2 px-12 font-montserrat flex flex-col justify-between"
      style={{ height: "85vh" }}
    >
      <div className="pb-5 pt-10">
        <h2 className="font-bold text-3xl">Delete Chart</h2>

        <div className="w-full h-9 border border-black flex flex-row justify-between items-center rounded-lg mt-5">
          <input
            type="text"
            className="w-11/12  border-transparent focus:border-transparent focus:ring-0 p-0 pl-1"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <MagnifyingGlassIcon className="w-1/12" />
        </div>

        <div className="mt-7 max-h-72 overflow-y-auto">
          {filteredCharts.length === 0 ? (
            <div>No charts available.</div>
          ) : (
            filteredCharts.map((chart, index) => (
              <div
                className={`flex flex-row items-center gap-2 ml-1 ${
                  index === filteredCharts.length - 1 ? `mb-0` : `mb-8`
                }`}
                key={chart.chart_prototype.chart_title}
              >
                <input
                  type="radio"
                  name="delete-chart-radio"
                  id={`delete-chart-${index}`}
                  onChange={() => setSelectedChart(chart)}
                />
                <label htmlFor={`delete-chart-${index}`} className="font-bold">
                  {chart.chart_prototype.chart_title}
                </label>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center mb-6">
        <div className="w-72 text-center">
          Charts deleted from a student’s page cannot be recovered.
        </div>
        <Button
          color={ButtonColor.RED}
          extraClasses="mt-4 rounded-xl"
          onClick={deleteChart}
        >
          Delete
        </Button>
      </div>
    </DialogContent>
  );
}
