import { ApiError, NoteResponse, NotesService } from "client/openapi";
import { DialogClose, DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "./index.css";

function DeleteNoteDialog({
  noteId,
  notes,
  setNotes,
}: {
  noteId: number;
  notes: NoteResponse[];
  setNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  async function deleteNote() {
    setStatus(PageStatus.LOADING);

    await NotesService.deleteNote({ noteId: noteId })
      .then(() => {
        setNotes(notes.filter((n) => n.id !== noteId));
        setStatus(PageStatus.SUCCESS);
        Notifications.success("Note deleted!");
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content">
        <div>
          <div className="delete-note--dialog-header">
            <h2 className="delete-note--title">Are you sure?</h2>
            <p>
              All tutors and organization administrators will lose access to
              this note. This action is irreversible.
            </p>
          </div>

          <div className="mt-4 clear-availability--actions">
            <button
              className="button button--small button--red"
              type="submit"
              disabled={status === PageStatus.LOADING}
              onClick={() => deleteNote()}
            >
              Delete Note &rarr;
            </button>
            <DialogClose asChild>
              <button
                className="button button--small button--red button--hollow"
                disabled={status === PageStatus.LOADING}
              >
                Cancel
              </button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </>
  );
}

export default DeleteNoteDialog;
