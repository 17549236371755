/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents all valid Auth0 account roles.
 */
export enum Auth0AccountRole {
  ME = "me",
  PARENT = "parent",
  ORG_ADMIN = "org-admin",
  ORG_TUTOR = "org-tutor",
  TADPOLE_ADMIN = "tadpole-admin",
}
