import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { forwardRef, ReactNode } from "react";
import "./index.css";
export const DialogContent = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentProps<typeof DialogPrimitive.Content & any>
>((props, forwardedRef) => {
  const {
    children,
    onClose,
    onOpen,
    alignLeft,
    gradientHeader = false,
    showClose = true,
    ...itemProps
  } = props;

  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className="dialog-overlay" />
      <DialogPrimitive.Content
        {...itemProps}
        ref={forwardedRef}
        onCloseAutoFocus={onClose}
        onOpenAutoFocus={onOpen}
      >
        {showClose && (
          <DialogPrimitive.Close aria-label="Close" asChild>
            <button
              className={`dialog-close${
                alignLeft ? " dialog-close--left" : ""
              }`}
              aria-label="Close"
              onClick={onClose}
            >
              <Cross2Icon
                width={alignLeft ? 35 : 15}
                height={alignLeft ? 35 : 15}
              />
            </button>
          </DialogPrimitive.Close>
        )}
        {gradientHeader && (
          <div
            className="h-14 bg-gradient-to-r from-[#00c53bba] to-[#1AD7C1] from-50% to-50% -translate-y-10 border-2 border-b-black -translate-x-7"
            style={{ width: "calc(100% + 3.5rem)" }}
          />
        )}
        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
});
export const DialogActions = ({ children }: { children: ReactNode }) => {
  return <div className="dialog--actions">{children}</div>;
};
export const DialogAction = ({
  primary = false,
  children,
  ...props
}: {
  primary?: boolean;
  children: ReactNode;
  [props: string]: any;
}) => {
  return (
    <Button
      size={ButtonSize.SMALL}
      fill={primary ? ButtonFill.DEFAULT : ButtonFill.HOLLOW}
      color={ButtonColor.ORANGE}
      {...props}
    >
      {children}
    </Button>
  );
};
export const Dialog = DialogPrimitive.Root;
export const DialogClose = DialogPrimitive.Close;
export const DialogTrigger = DialogPrimitive.Trigger;
