import { DialogContent } from "components/Dialog";
import "./index.css";
import { CheckCircledIcon } from "@radix-ui/react-icons";

function SuccessDialog({
  message,
  onClose,
}: {
  message: string;
  onClose?: () => void;
}) {
  return (
    <DialogContent
      onClose={onClose}
      className="dialog-content success-dialog-content text-center flex flex-col justify-center align-center"
    >
      <h2 className="success-dialog-text font-md w-1/2 mx-auto">
        <svg viewBox="0 0 15 15">
          <CheckCircledIcon />
        </svg>
      </h2>
      <h2 className="success-dialog-text">{message}</h2>
    </DialogContent>
  );
}

export default SuccessDialog;
