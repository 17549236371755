import {
  Auth0AccountRole,
  MeetingResponse,
  MeetingsService,
} from "client/openapi";
import moment from "moment";
import { useEffect, useState } from "react";
import { PageStatus } from "types";
import range from "util/range";
import React from "react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagFill, TagSize } from "components/Tag";
import "./index.css";
import EventTable from "./Components/eventTable";

enum ApprovalStatus {
  UNDETERMINED = "UNDETERMINED",
  NO_MEETINGS = "NO_MEETINGS",
  PENDING = "PENDING",
  REVIEWED = "REVIEWED",
}

function TutoringHours({
  weekStart,
  events,
  days,
  status,
  mobileView,
}: {
  events: MeetingResponse[];
  weekStart: Date;
  days: number;
  status: PageStatus;
  mobileView: boolean;
}) {
  const [calendarEvents, setCalendarEvents] = useState<MeetingResponse[][]>([]);
  const [selectedDay, setSelectedDay] = useState<number>(-1);
  const [approvalStatus, setApprovalStatus] = useState<
    Array<{ day: number; approvals: any[]; status: ApprovalStatus }>
  >(
    Array(days)
      .fill(0)
      .map((_, index) => ({
        day: index,
        approvals: [],
        status: ApprovalStatus.UNDETERMINED,
      }))
  );
  const [isLoading, setIsLoading] = useState<boolean>(
    status === PageStatus.LOADING
  );

  const getEventsForDay = (day: number) =>
    events
      .filter((e) =>
        moment(e.start).isSame(
          moment(weekStart).add(range(days).indexOf(day), "days"),
          "day"
        )
      )
      .sort((a, b) => a.start.localeCompare(b.start));

  const getApprovalForMeeting = async (event: MeetingResponse) => {
    return await MeetingsService.getMeetingApproval({
      sessionId: event.session_id,
      startTime: event.start,
    });
  };

  const getApprovalForDay = async (event: MeetingResponse[], index: number) => {
    try {
      let updatedApprovalStatus = [...approvalStatus];
      updatedApprovalStatus[index] = {
        day: index,
        approvals: [],
        status: ApprovalStatus.UNDETERMINED,
      };

      const approvals = await Promise.all(
        event.map(async (evt) => {
          return await getApprovalForMeeting(evt);
        })
      );

      if (approvals.some((approval) => approval.approved === null)) {
        updatedApprovalStatus[index] = {
          day: index,
          approvals: approvals,
          status: ApprovalStatus.PENDING,
        };
      } else {
        updatedApprovalStatus[index] = {
          day: index,
          approvals: approvals,
          status: ApprovalStatus.REVIEWED,
        };
      }

      return updatedApprovalStatus;
    } catch (error) {
      console.error(
        "Error occurred while fetching approvals for the day:",
        error
      );
    }
    return approvalStatus;
  };

  const getApprovalStatusForAllDays = async () => {
    if (status !== PageStatus.SUCCESS) return;
    const rangeDays = range(days).map((day) => getEventsForDay(day));
    setCalendarEvents(rangeDays);
    setIsLoading(true);
    let updatedApprovalStatus = [...approvalStatus];
    for (let index = 0; index < rangeDays.length; index++) {
      const event = rangeDays[index];
      const response = await getApprovalForDay(event, index);
      updatedApprovalStatus[index] = response[index];
    }
    setApprovalStatus(updatedApprovalStatus);
    setIsLoading(false);
  };

  const getNewApprovalStatus = async (day: string) => {
    const meeting = events.find((e) => e.start === day);
    if (!meeting) return;
    const response = await getApprovalForMeeting(meeting);
    const updatedApprovalStatus = [...approvalStatus];
    const selected = range(days).indexOf(selectedDay);
    const approvals = [...updatedApprovalStatus[selected].approvals];
    const index = approvals.findIndex(
      (approval) => approval.meeting_time === day
    );
    if (
      approvals.findIndex((approval) => approval.meeting_time === day) !== -1
    ) {
      approvals.splice(index, 1, response);
    }
    updatedApprovalStatus[selected] = {
      day: selectedDay,
      approvals: approvals,
      status: approvals.some((approval) => approval.approved === null)
        ? ApprovalStatus.PENDING
        : ApprovalStatus.REVIEWED,
    };
    setApprovalStatus(updatedApprovalStatus);
  };

  useEffect(() => {
    if (status === PageStatus.SUCCESS) {
      getApprovalStatusForAllDays();
    } else if (status === PageStatus.LOADING) {
      setIsLoading(true);
    }
  }, [status]);

  return (
    <>
      <table className="w-full border-separate border-spacing-0">
        <tbody className="bg-white">
          {calendarEvents.map((event, index) => {
            const day = moment(weekStart)
              .add(index, "days")
              .format(`${mobileView ? "ddd, MMM" : "dddd, MMMM"} Do`);
            const isSelected = selectedDay === index;
            return (
              <React.Fragment key={index}>
                <tr>
                  <td
                    className={`border-gray-300 px-4 md:px-8 py-4 text-xl bg-white 
                                    ${
                                      index == calendarEvents.length - 1 ||
                                      isSelected
                                        ? "border-b-0"
                                        : "border-b-2"
                                    }
                                `}
                  >
                    <div className="grid grid-cols-12">
                      <b className="col-span-7 sm:col-span-8 md:col-span-10">
                        {day}
                      </b>
                      <div className="col-span-4 sm:col-span-3 md:col-span-1">
                        {!isLoading &&
                          approvalStatus[index].status !==
                            ApprovalStatus.UNDETERMINED && (
                            <Tag
                              color={
                                approvalStatus[index].approvals.length === 0
                                  ? TagColor.PURPLE
                                  : approvalStatus[index].status ===
                                    ApprovalStatus.REVIEWED
                                  ? TagColor.BLUE
                                  : TagColor.GOLD
                              }
                              item={
                                approvalStatus[index].approvals.length === 0
                                  ? "No Meetings"
                                  : approvalStatus[index].status ===
                                    ApprovalStatus.REVIEWED
                                  ? "Reviewed"
                                  : "Pending"
                              }
                              size={TagSize.MINI}
                              fill={TagFill.TRANSLUCENT}
                            />
                          )}
                      </div>
                      <div className="col-span-1 md:col-span-1">
                        <div className="float-right">
                          <ChevronDownIcon
                            width="30"
                            height="30"
                            className={`cursor-pointer ${
                              isSelected && "transform rotate-180"
                            }`}
                            onClick={() =>
                              setSelectedDay(isSelected ? -1 : index)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                {isSelected && (
                  <EventTable
                    events={event}
                    setSessionUpdated={(day) => getNewApprovalStatus(day)}
                  />
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default TutoringHours;
