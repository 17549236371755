/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a kind of metric predictor. e.g., timestamp, tutoring completed
 * by this point, money spent by this point.
 *
 * These should be used by the backend code to derive the appropriate
 * predictor values.
 */
export enum ChartXAxisType {
  DATE = "DATE",
  HOURS_OF_TUTORING = "HOURS_OF_TUTORING",
}
