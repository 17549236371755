import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  Dialog,
  DialogAction,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import Select from "components/Select";
import { useEffect, useState } from "react";
import "./index.css";
import {
  ChartsService,
  ChartPrototypeResponse,
  MetricDefinitionResponse,
  OrganizationResponse,
  TutorsService,
  OrganizationSubjectResponse,
  MetricDefinitionCreate,
  ChartPrototypeCreate,
  MetricDefinitionUpdate,
  ChartPrototypeUpdate,
  ChartSubjectCreate,
  ChartSubscriptionCreate,
  ChartXAxisType,
} from "client/openapi";
import {
  concatenateSubject,
  concatenateTutorSubject,
} from "util/concatenateSubject";
import { PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import Notifications from "util/notifications";
import { FaTrashAlt } from "react-icons/fa";
import DeleteChartDialog from "./deleteChartDialog";

export default function CreateChartDialog({
  organization,
  chart,
  updateCharts,
  setUpdateCharts,
}: {
  organization: OrganizationResponse;
  chart?: ChartPrototypeResponse;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  const isEditing = chart !== undefined;
  const [metric, setMetric] = useState<MetricDefinitionResponse>();
  const [chartInfo, setChartInfo] = useState<ChartPrototypeResponse>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [xAxis, setXAxis] = useState<ChartXAxisType>(
    chart?.chart_x_axis_type ?? ChartXAxisType.HOURS_OF_TUTORING
  );
  const [yAxis, setYAxis] = useState("");
  const [subjects, setSubjects] = useState<Array<OrganizationSubjectResponse>>(
    []
  );
  const [selectedSubjects, setSelectedSubjects] = useState<
    Array<OrganizationSubjectResponse>
  >([]);
  const [startingSubjects, setStartingSubjects] = useState<
    Array<OrganizationSubjectResponse>
  >([]);
  const [range, setRange] = useState([NaN, NaN]);
  const [rangeError, setRangeError] = useState(false);
  const [data, setData] = useState<Array<{ x: number; y: number | undefined }>>(
    [
      { x: 0, y: 0 },
      { x: 1, y: 200 },
      { x: 2, y: 400 },
      { x: 3, y: 600 },
      { x: 4, y: 800 },
      { x: 5, y: undefined },
      { x: 6, y: undefined },
      { x: 7, y: undefined },
      { x: 8, y: undefined },
      { x: 9, y: undefined },
    ]
  );
  const [title, setTitle] = useState(chart?.chart_title ?? "");
  const getXAxisOptions = [
    { value: ChartXAxisType.HOURS_OF_TUTORING, label: "Hours of Tutoring" },
    { value: ChartXAxisType.DATE, label: "Date" },
  ];

  const handleChangeXAxis = (e) => {
    setXAxis(e.value);
  };

  const handleChangeYAxis = (e) => {
    setYAxis(e.target.value);
  };

  const handleChangeRange = (e: any, start: boolean) => {
    setRangeError(false);
    let r = range;
    if (start) {
      r = [parseInt(e.target.value), range[1]];
      if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
        r = [NaN, range[1]];
      } else if (parseInt(e.target.value) >= range[1]) {
        setRangeError(true);
      } else {
        updateData(r);
      }
    } else {
      r = [range[0], parseInt(e.target.value)];
      if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
        r = [range[0], NaN];
      } else if (parseInt(e.target.value) <= range[0]) {
        setRangeError(true);
      } else {
        updateData(r);
      }
    }
    setRange(r);
  };

  const updateData = (range) => {
    if (isNaN(range[0])) {
      range[0] = 0;
    } else if (isNaN(range[1])) {
      return;
    }
    const updatedData = data.map((point) => {
      if (point.x === 0) {
        return { x: point.x, y: Math.round(range[0]) };
      } else if (point.x === 1) {
        return {
          x: point.x,
          y: Math.round(range[0] + (range[1] - range[0]) / 4),
        };
      } else if (point.x === 2) {
        return {
          x: point.x,
          y: Math.round(range[0] + ((range[1] - range[0]) / 4) * 2),
        };
      } else if (point.x === 3) {
        return {
          x: point.x,
          y: Math.round(range[0] + ((range[1] - range[0]) / 4) * 3),
        };
      } else if (point.x === 4) {
        return { x: point.x, y: Math.round(range[1]) };
      } else {
        return { x: point.x, y: undefined };
      }
    });
    setData(updatedData);
  };

  async function getAllSubjects() {
    let allSubjects = new Set<OrganizationSubjectResponse>();
    let existingSubjects = new Set<OrganizationSubjectResponse>();

    TutorsService.getTutorsByOrganization({
      orgId: organization.id,
    }).then((allTutors) => {
      allTutors?.forEach((tutor) => {
        tutor.subjects.forEach((subject) => {
          allSubjects.add(subject.org_subject);
        });
      });
      setSubjects(Array.from(allSubjects));
    });

    if (chart) {
      ChartsService.getChartSubjectsByChartPrototype({
        chartPrototypeId: chart.id,
      }).then((chartPrototypeSubjects) => {
        chartPrototypeSubjects?.forEach((chartPrototypeSubject) => {
          existingSubjects.add(chartPrototypeSubject.org_subject);
        });
        setSelectedSubjects(Array.from(existingSubjects));
        setStartingSubjects(Array.from(existingSubjects));
      });
    }
  }

  const handleChangeSubjects = (e) => {
    setSelectedSubjects(e);
  };

  async function getExistingChartData() {
    const chart_info = await ChartsService.getChartPrototype({
      chartPrototypeId: chart?.id as number,
    }).catch((err) => {
      console.error("Cannot get chart: ", err);
    });

    if (chart_info) {
      setXAxis(chart_info.chart_x_axis_type);
      setTitle(chart_info.chart_title);
      setChartInfo(chart_info);
    }

    const metric = await ChartsService.getMetricDefinitionById({
      metricId: chart?.metric_definition.id as number,
    }).catch((err) => {
      console.error("Cannot get metric: ", err);
    });

    if (metric) {
      setYAxis(metric.y_axis_name);
      setRange([metric.y_axis_min ?? 0, metric.y_axis_max ?? 800]);
      updateData([metric.y_axis_min ?? 0, metric.y_axis_max ?? 800]);
      setMetric(metric);
    }
  }

  useEffect(() => {
    getAllSubjects();

    if (chart) {
      getExistingChartData();
    }
  }, [chart]);

  const disabled =
    rangeError ||
    isNaN(range[0]) ||
    isNaN(range[1]) ||
    !title ||
    !yAxis ||
    !subjects ||
    !xAxis ||
    (xAxis === ChartXAxisType.HOURS_OF_TUTORING && selectedSubjects.length < 1);

  const createChart = async () => {
    setStatus(PageStatus.LOADING);

    const newMetricDefinition: MetricDefinitionCreate = {
      y_axis_name: yAxis,
      y_axis_min: range[0],
      y_axis_max: range[1],
      org_id: organization.id,
    };

    ChartsService.createMetricDefinition({
      requestBody: newMetricDefinition,
    })
      .then((metric) => {
        const newChartPrototype: ChartPrototypeCreate = {
          chart_title: title,
          chart_x_axis_type: xAxis,
          organization_id: organization.id,
          metric_definition_id: metric.id,
        };

        ChartsService.createChartPrototype({
          requestBody: newChartPrototype,
        }).then((chart) => {
          const newAggregateChartforChartPrototype: ChartSubscriptionCreate = {
            pinned: false,
            is_aggregate_chart: true,
            chart_prototype_id: chart.id,
          };

          ChartsService.createChartSubscription({
            requestBody: newAggregateChartforChartPrototype,
          }).then(() => {
            selectedSubjects.forEach((subject) => {
              const newChartSubject: ChartSubjectCreate = {
                chart_prototype_id: chart.id,
                org_subject_id: subject.id,
              };

              ChartsService.createChartSubject({
                requestBody: newChartSubject,
              });
            });

            setUpdateCharts(!updateCharts);
            setStatus(PageStatus.SUCCESS);
          });
        });
      })
      .catch((err) => {
        setStatus(PageStatus.ERROR);
        Notifications.error("An unexpected error occurred. Please try again");
      });
  };

  const editChart = async () => {
    if (!metric || !chart) {
      return;
    }
    try {
      setStatus(PageStatus.LOADING);

      const updatedMetricDefinition: MetricDefinitionUpdate = {
        y_axis_name: yAxis,
        y_axis_min: range[0],
        y_axis_max: range[1],
      };

      await ChartsService.updateMetricDefinition({
        metricId: metric?.id,
        requestBody: updatedMetricDefinition,
      });

      const updatedChartPrototype: ChartPrototypeUpdate = {
        chart_title: title,
        chart_x_axis_type: xAxis,
        metric_definition_id: metric?.id,
      };

      const updatedChart = await ChartsService.updateChartPrototype({
        chartPrototypeId: chart.id,
        requestBody: updatedChartPrototype,
      });

      if (
        updatedChart.chart_x_axis_type === ChartXAxisType.HOURS_OF_TUTORING &&
        selectedSubjects
      ) {
        const oldSubjects =
          await ChartsService.getChartSubjectsByChartPrototype({
            chartPrototypeId: updatedChart.id,
          });

        await oldSubjects.forEach((oldSubject) => {
          ChartsService.deleteChartSubject({
            chartSubjectId: oldSubject.id,
          });
        });

        await selectedSubjects.forEach((subject) => {
          const newChartSubject: ChartSubjectCreate = {
            chart_prototype_id: updatedChart.id,
            org_subject_id: subject.id,
          };

          ChartsService.createChartSubject({
            requestBody: newChartSubject,
          });
        });
      }

      setUpdateCharts(!updateCharts);
      setStatus(PageStatus.SUCCESS);
    } catch {
      setStatus(PageStatus.ERROR);
      Notifications.error("An unexpected error occurred. Please try again");
    }
  };

  const reset = () => {
    setTitle(chartInfo?.chart_title ?? "");
    setXAxis(chartInfo?.chart_x_axis_type ?? ChartXAxisType.HOURS_OF_TUTORING);
    setSelectedSubjects(startingSubjects);
    setYAxis(metric?.y_axis_name ?? "");
    setRange([metric?.y_axis_min ?? 0, metric?.y_axis_max ?? 800]);
    setRangeError(false);
    if (metric) {
      updateData([metric.y_axis_min ?? 0, metric.y_axis_max ?? 800]);
    } else {
      setData([
        { x: 0, y: 0 },
        { x: 1, y: 200 },
        { x: 2, y: 400 },
        { x: 3, y: 600 },
        { x: 4, y: 800 },
        { x: 5, y: undefined },
        { x: 6, y: undefined },
        { x: 7, y: undefined },
        { x: 8, y: undefined },
        { x: 9, y: undefined },
      ]);
    }
  };

  const CustomizedXAxisTick = () => {
    return null; // Return null to hide the tick labels
  };

  const CustomizedYAxisTick = ({
    x,
    y,
    payload,
  }: {
    x?: any;
    y?: any;
    payload?: any;
  }) => {
    if (
      typeof x === "undefined" ||
      typeof y === "undefined" ||
      typeof payload === "undefined"
    ) {
      return null;
    }
    const { value } = payload;
    if (value === data[0].y || value === data[4].y) {
      return (
        <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
          {`${value}`}
        </text>
      );
    } else {
      return null;
    }
  };

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message={isEditing ? "Chart updated!" : "Chart created!"}
        onClose={() => setStatus(PageStatus.EDITING)}
      />
    );
  }

  return (
    <DialogContent
      className="bg-white dialog-content dialog-content--left medium-wide font-montserrat pb-2 overflow-x-hidden"
      alignLeft={true}
      gradientHeader={true}
      showClose={true}
      onClose={reset}
    >
      <div className="mt-[-1rem]">
        <h1 className="text-3xl font-bold flex justify-between items-center">
          <div>{isEditing ? "Edit Chart" : "Create a Chart"}</div>
          <Dialog>
            <DialogTrigger asChild>
              <div role="button" className="text-red-500">
                {isEditing && <FaTrashAlt />}
              </div>
            </DialogTrigger>

            <DeleteChartDialog
              chartId={chart?.id}
              updateCharts={updateCharts}
              setUpdateCharts={setUpdateCharts}
              close={() => setStatus(PageStatus.EDITING)}
            />
          </Dialog>
        </h1>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          className="outline-none focus:outline-none w-1/2 p-2 text-xl font-bold mt-2 placeholder:text-gray-400 border-none"
          placeholder="Title"
        />
        <hr className="border-t-2" />
        <div className="grid grid-cols-3">
          <div className="col-span-3 sm:col-span-1">
            <h3 className="dialog--label mt-3 font-bold text-sm">X-Axis:</h3>
            <Select
              id="x-axis"
              options={getXAxisOptions}
              value={{
                value: xAxis,
                label: getXAxisOptions.find((option) => option.value === xAxis)
                  ?.label,
              }}
              placeholder="X-Axis"
              onChange={(e) => {
                handleChangeXAxis(e);
              }}
              required
              className="text-sm font-bold placeholder:text-gray-400"
            />
            {xAxis === ChartXAxisType.HOURS_OF_TUTORING && (
              <div className="flex flex-col mt-5">
                <h3 className="font-bold text-xs">
                  Which subjects should we use to calculate hours?
                </h3>
                <Select
                  id="subjects"
                  options={subjects}
                  value={selectedSubjects}
                  getOptionLabel={(s) => `${concatenateSubject(s)}`}
                  getOptionValue={(s) => s.id.toString()}
                  placeholder="Subject"
                  onChange={(e) => {
                    handleChangeSubjects(e);
                  }}
                  required
                  isMulti
                  className="text-sm font-bold"
                />
              </div>
            )}
            <div className="flex flex-col mt-5">
              <h3 className="font-bold dialog--label text-sm">Y-Axis:</h3>
              <input
                value={yAxis}
                onChange={(e) => {
                  handleChangeYAxis(e);
                }}
                className="outline-none focus:outline-none rounded-md border border-gray-300 w-full p-2 text-sm font-bold mt-2 placeholder:text-gray-400"
                placeholder="Y-Axis Label"
                maxLength={35}
              />
            </div>
            <div className="flex flex-col mt-5">
              <h3 className="font-bold text-xs flex">
                Range&nbsp;
                <p className="text-gray-400 text-xs">(pick any number value)</p>
              </h3>
              <div className="flex flex-row">
                <input
                  className={`w-1/3 border-0 border-b-2 !border-black p-2 text-xs text-center mr-3 placeholder:text-gray-400 focus:ring-0 focus:shadow-none ${
                    rangeError ? "text-red-500" : ""
                  }`}
                  type="number"
                  placeholder="Lowest"
                  value={range[0].toString() ?? ""}
                  onChange={(e) => {
                    handleChangeRange(e, true);
                  }}
                />
                -
                <input
                  className={`w-1/3 border-0 border-b-2 !border-black p-2 text-xs text-center mx-3 placeholder:text-gray-400 focus:ring-0 focus:shadow-none ${
                    rangeError ? "text-red-500" : ""
                  }`}
                  type="number"
                  placeholder="Highest"
                  value={range[1].toString() ?? ""}
                  onChange={(e) => {
                    handleChangeRange(e, false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-span-3 sm:col-span-2 mt-3 sm:ml-10 rounded-lg border-2 border-blue-300 shadow-lg p-1">
            <h3 className="font-bold text-2xl px-3 pt-1 pb-2">
              {title !== "" ? title : "Chart Title"}
            </h3>
            <ResponsiveContainer width="100%" height="100%" maxHeight={275}>
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  label={{
                    value: getXAxisOptions.find(
                      (option) => option.value === xAxis
                    )?.label,
                    position: "insideBottom",
                    style: { fontWeight: "bold", fontSize: "smaller" },
                  }}
                  tick={<CustomizedXAxisTick />}
                />
                <YAxis
                  dataKey="y"
                  type="number"
                  tickCount={5}
                  domain={range}
                  ticks={[
                    data[0].y ?? 0,
                    data[1].y ?? 0,
                    data[2].y ?? 0,
                    data[3].y ?? 0,
                    data[4].y ?? 0,
                  ]}
                  tick={<CustomizedYAxisTick />}
                  label={{
                    value: yAxis !== "" ? yAxis : "Label",
                    angle: -90,
                    position: "insideLeft",
                    offset: 30,
                    style: {
                      fontWeight: "bold",
                      fontSize: "smaller",
                      textAnchor: "middle",
                    },
                  }}
                  className="custom-y-axis"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="grid grid-cols-6 lg:grid-cols-8 gap-x-4 gap-y-2 mt-5">
          <div className="col-span-6 sm:col-span-3 md:col-span-2 md:col-start-2 lg:col-start-3 flex flex-col">
            <DialogAction
              size={ButtonSize.SMALL}
              color={disabled ? ButtonColor.GRAY : ButtonColor.SKYBLUE}
              primary={true}
              disabled={disabled}
              onClick={() => {
                isEditing ? editChart() : createChart();
              }}
            >
              {isEditing ? "Apply Changes" : "Create Chart"}
            </DialogAction>
          </div>
          <div className="col-span-6 sm:col-span-3 md:col-span-2 sm:col-start-4 lg:col-start-5 flex flex-col mb-3">
            <DialogClose asChild>
              <Button
                size={ButtonSize.SMALL}
                color={ButtonColor.BLACK}
                fill={ButtonFill.HOLLOW}
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
