import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { ReactNode } from "react";
import "./index.css";

export default function Avatar({
  src,
  alt,
  fallback,
  color,
  large,
  inverted = false,
  append,
}: {
  src?: string | null;
  alt: string;
  fallback: any;
  color?: string;
  large?: boolean;
  inverted?: boolean;
  append?: ReactNode;
}) {
  return (
    <div className={`relative avatar--default ${large && "avatar--large"}`}>
      <AvatarPrimitive.Root
        className={`avatar--root avatar--default ${
          inverted && "avatar--inverted"
        } ${large && "avatar--large"} ${color || ""}`}
      >
        <AvatarPrimitive.Image
          className="avatar--image"
          src={src as string | undefined}
          alt={alt}
        />
        <AvatarPrimitive.Fallback className={`avatar--fallback`} delayMs={50}>
          {fallback}
        </AvatarPrimitive.Fallback>
      </AvatarPrimitive.Root>
      {append && <div className="absolute bottom-0 right-0">{append}</div>}
    </div>
  );
}
