import { DialogContent } from "components/Dialog";
import "./index.css";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  ACHResponse,
  CreditCardResponse,
  PaymentPlanFrequency,
  PaymentPlanResponse,
  StudentResponse,
  StudentsService,
} from "client/openapi";
import PaymentMethods from "../../../../../../../../components/PaymentMethods/PaymentMethods";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import Cards from "../../../../../../../../components/PaymentMethods/cards";
import Banks from "../../../../../../../../components/PaymentMethods/bank";

export default function PayPerSessionModal({
  student,
  orgId,
}: {
  student: StudentResponse;
  orgId: number;
}) {
  const [isPaymentPlan, setIsPaymentPlan] = useState<boolean>(false);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlanResponse>();

  const [editing, setEditing] = useState<boolean>(false);
  const [checked, setChecked] = useState([false, true]);
  const [interval, setInterval] = useState<number>();

  const [newInterval, setNewInterval] = useState<number>();
  const [invalidInterval, setInvalidInterval] = useState<boolean>(true);

  type AllPaymentMethods = CreditCardResponse | ACHResponse;
  const [paymentMethodsOnFile, setPaymentMethodsOnFile] = useState<
    AllPaymentMethods[]
  >([]);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<
    CreditCardResponse | ACHResponse
  >();
  const [newPaymentMethod, setNewPaymentMethod] = useState<string>("");
  const [paymentEmail, setPaymentEmail] = useState<string>();

  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [error, setError] = useState<APIResponse>();

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    let newMethods: AllPaymentMethods[] = [];

    try {
      const existing_cards = await StudentsService.getStudentCards({
        studentId: student.id,
      });
      if (existing_cards) {
        newMethods = [...newMethods, ...existing_cards];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    try {
      const existing_banks = await StudentsService.getStudentAchAccounts({
        studentId: student.id,
      });
      if (existing_banks) {
        newMethods = [...newMethods, ...existing_banks];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    setPaymentMethodsOnFile(newMethods);
  }, [orgId, student.id]);

  useEffect(() => {
    getAndSetPaymentMethodsOnFile();
  }, [getAndSetPaymentMethodsOnFile]);

  const findPaymentMethodById = useCallback(
    (paymentMethodId) => {
      return Array.from(paymentMethodsOnFile).find(
        (paymentMethod) => paymentMethod.payment_method_id === paymentMethodId
      );
    },
    [paymentMethodsOnFile]
  );

  const getPaymentPlan = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentsService.getPaymentPlan({
      studentId: student.id,
    })
      .then((plan) => {
        setPaymentPlan(plan);
        setStatus(PageStatus.SUCCESS);

        if (plan && plan.frequency === PaymentPlanFrequency.WEEKLY) {
          setChecked([true, false]);
          setInterval(plan.interval);
          setCurrentPaymentMethod(
            plan ? findPaymentMethodById(plan.payment_method) : undefined
          );
          setPaymentEmail(plan.invoice_email as string | undefined);
          setIsPaymentPlan(true);
          setEditing(false);
        } else {
          setChecked([false, true]);
          setIsPaymentPlan(false);
        }
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [student.id, findPaymentMethodById]);

  useEffect(() => {
    if (student) {
      getPaymentPlan();
    }
  }, [getPaymentPlan, paymentMethodsOnFile, student]);

  const updatePaymentPlan = async () => {
    if (
      (checked[0] === true && newPaymentMethod === "") ||
      newPaymentMethod === undefined ||
      newPaymentMethod === null
    ) {
      setError({
        message: "Invalid Payment Method",
      });
      return;
    }

    if (checked[0] === true && invalidInterval) {
      setError({
        message: "Please enter a valid number of weeks",
      });
      return;
    }

    StudentsService.updatePaymentPlan({
      studentId: student.id,
      requestBody: {
        allow_negative: checked[0] === true ? false : true,
        payment_method:
          checked[0] === true
            ? newPaymentMethod
              ? newPaymentMethod?.includes("@")
                ? ""
                : newPaymentMethod
              : currentPaymentMethod
              ? currentPaymentMethod.payment_method_id
              : ""
            : "",
        invoice_email:
          checked[0] === true
            ? newPaymentMethod
              ? newPaymentMethod?.includes("@")
                ? newPaymentMethod
                : ""
              : currentPaymentMethod
              ? ""
              : paymentEmail
            : "",
        frequency:
          checked[0] === true
            ? PaymentPlanFrequency.WEEKLY
            : PaymentPlanFrequency.NONE,
        interval: checked[0] === true ? newInterval : undefined,
      },
    })
      .then((plan) => {
        setStatus(PageStatus.SUCCESS);
        getPaymentPlan();
        setEditing(false);
        Notifications.success(`Student payment plan updated!`);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  };

  const handleSelectPaymentMethod = (id) => {
    if (typeof id === "string" && id.includes("@")) {
      setNewPaymentMethod(id);
    } else if (typeof id === "object") {
      setNewPaymentMethod(id.payment_method_id);
    } else {
      // Handle unexpected id type if necessary
      console.error("Unexpected payment method type", id);
    }
  };

  const verifyWeeks: CurrencyInputProps["onValueChange"] = (value, name) => {
    // value is over limit
    if (Number(value) > 26) {
      setInvalidInterval(true);
      setError({
        message: "Please bundle no more than six months of charges at a time",
      });
      return;
    }

    if (Number(value) <= 0) {
      setInvalidInterval(true);
      setError({
        message: "Weeks must be greater than zero",
      });
      return;
    }

    if (
      Number(value) === null ||
      Number(value) === undefined ||
      Number.isNaN(Number(value))
    ) {
      setInvalidInterval(true);
      setError({
        message: "Enter a value for Bill Every",
      });
      return;
    }

    setInvalidInterval(false);
    setNewInterval(Number(value));
  };

  function clearPaymentPlan() {
    setNewInterval(0);
    setNewPaymentMethod("");
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <DialogContent
      className="dialog-content max-w-[800px]"
      onClose={getPaymentPlan}
    >
      <div className="mt-3 font-semibold text-center header text-lg">
        Automatic Billing
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4">
        <p className="header font-medium text-sm">
          <div className="mb-2 font-bold">
            Auto bill student on a per-session basis:
          </div>
          <input
            type="radio"
            className={`!border-black ml-2 rounded !bg-none ${
              checked[0] && "!bg-green-400"
            } focus:!outline-none`}
            checked={checked[0]}
            onChange={() => setChecked([true, false])}
          />{" "}
          &nbsp;Yes
          <input
            type="radio"
            className={`!border-black ml-2 rounded !bg-none ${
              checked[1] && "!bg-green-400"
            } focus:!outline-none`}
            checked={checked[1]}
            onChange={() => setChecked([false, true])}
          />{" "}
          &nbsp;No
        </p>

        {checked[0] &&
          (!editing ? (
            <div>
              <div className="header text-sm mt-3">
                {isPaymentPlan ? (
                  <div>
                    {" "}
                    <div className="font-bold header mb-2 text-sm">
                      Payment Plan:
                    </div>{" "}
                    Student will be charged every {interval} week(s) using:
                    <div className="mt-2 mb-2">
                      {currentPaymentMethod || paymentEmail ? (
                        <div>
                          {currentPaymentMethod &&
                          "brand" in currentPaymentMethod ? (
                            <Cards
                              key={currentPaymentMethod.payment_method_id}
                              card={currentPaymentMethod as CreditCardResponse}
                            />
                          ) : currentPaymentMethod &&
                            "bank_name" in currentPaymentMethod ? (
                            <Banks
                              key={currentPaymentMethod.payment_method_id}
                              bank={currentPaymentMethod as ACHResponse}
                            />
                          ) : (
                            <span className="text-sm font-bold">
                              {paymentEmail}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div>
                          <span className="font-semibold text-sm">
                            No payment method has been set. Add one:
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-xs">
                      {" "}
                      Note: For any given subject, auto-pay will use all credits
                      a student has on file before billing with their payment
                      method.{" "}
                    </div>
                  </div>
                ) : (
                  "No payment plan on file."
                )}
              </div>
              <div className="mt-4">
                <Button
                  color={ButtonColor.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => setEditing(!editing)}
                >
                  Edit Payment Plan
                </Button>
              </div>
            </div>
          ) : (
            <>
              {/*Note: We are using a currency input, but that's just because it's also convenient 
              for entering the payment plan interval. Don't be fooled! */}
              <p className="mt-4 mb-2 text-sm font-bold">Bill every:</p>
              <CurrencyInput
                className="input w-11/12 h-3/6"
                id="weeks"
                name="weeks"
                placeholder={`12 weeks`}
                decimalsLimit={0}
                allowNegativeValue={false}
                onValueChange={verifyWeeks}
              />
              <p className="mt-4 mb-2 text-sm font-bold">Bill to:</p>
              <div className="px-2">
                <PaymentMethods
                  showAddCardButton
                  selectable
                  studentIfInitiatedByAdmin={student}
                  handleSelectPaymentMethod={handleSelectPaymentMethod}
                />
              </div>
              <div className="mt-4 ml-1">
                <Button
                  color={ButtonColor.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    setEditing(!editing);
                    clearPaymentPlan();
                  }}
                >
                  Cancel Editing
                </Button>
              </div>
            </>
          ))}
      </div>

      <div className="w-full text-center mt-6">
        <Button
          color={
            editing && (newPaymentMethod === "" || invalidInterval)
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          size={ButtonSize.SMALL}
          onClick={updatePaymentPlan}
          disabled={editing && (newPaymentMethod === "" || invalidInterval)}
        >
          Save
        </Button>
      </div>
    </DialogContent>
  );
}
