import { TagColor, TagSize } from "components/Tag";
import { Tag } from "components/Tag/TagChip";
import "./index.css";

export function TagList({
  list,
  size,
  color,
}: {
  list: string[];
  size?: TagSize;
  color?: TagColor;
}) {
  return (
    <div className="tag_list">
      <ul>
        {list.map((item) => (
          <li key={item}>
            <Tag size={size} item={item} color={color} />
          </li>
        ))}
      </ul>
    </div>
  );
}
