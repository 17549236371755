import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import {
  ApiError,
  OrgSubjectsService,
  OrganizationSubjectResponse,
  OrganizationsService,
  StudentResponse,
  StudentSubjectsService,
  StudentsService,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import "./index.css";

export default function CreditDetails({
  orgId,
  student,
  currentStudentCredits,
}: {
  orgId: number;
  student: StudentResponse;
  currentStudentCredits: any;
}) {
  const [editId, setEditId] = useState<number>();
  const [subjects, setSubjects] = useState<OrganizationSubjectResponse[]>([]);
  // const [currentStudentPrices, setCurrentStudentPrices] = useState({});
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  const getAndSetSubjects = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrgSubjectsService.getSubjectsByOrg({
      orgId: orgId,
    })
      .then((subjects) => {
        setSubjects(subjects);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  function handleClose() {
    setEditId(undefined);
    setStatus(undefined);
  }

  useEffect(() => {
    getAndSetSubjects();
  }, [getAndSetSubjects]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <DialogContent className="custom-student-price-dialog">
      <div className="mt-3 font-semibold text-center header text-lg">
        Credits on File
      </div>

      {subjects.length > 0 && (
        <table className="table mt-3">
          <thead className="text-left text-xs text-gray-500 =">
            <tr>
              <th className="w-1/6">Subject/Activity</th>
              <th className="w-1/6">
                {student.first_name}'s Credit Balance ($)
              </th>
              <th className="w-1/12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {subjects
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .map((s) => (
                <tr
                  key={s.id}
                  className="student-subject-item odd:bg-gray-50 even:bg-white"
                >
                  <td>
                    <div title={s.name} className="student-subject-title">
                      {s.name}
                    </div>
                  </td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      currentStudentCredits[s.id]
                        ? currentStudentCredits[s.id] / 100
                        : 0
                    )}
                  </td>
                  <td>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90%",
                      }}
                    >
                      {/* {editId === s.id ? (
                        <Button
                          size={ButtonSize.EXTRA_SMALL}
                          color={
                            invalidPrice ? ButtonColor.GRAY : ButtonColor.GREEN
                          }
                          disabled={
                            status === PageStatus.LOADING || invalidPrice
                          }
                          onClick={editStudentRate}
                        >
                          {status === PageStatus.LOADING ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      ) : (
                        <Button
                          size={ButtonSize.EXTRA_SMALL}
                          color={ButtonColor.PURPLE}
                          onClick={() => {
                            setEditId(s.id);
                            setNewPrice(
                              Number(
                                currentStudentPrices[s.id]
                                  ? (currentStudentPrices[s.id] / 100).toFixed(
                                      2
                                    )
                                  : (
                                      (s.default_price_for_students as number) /
                                      100
                                    ).toFixed(2)
                              )
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )} */}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <DialogActions>
        <DialogClose asChild>
          <DialogAction
            color={ButtonColor.PURPLE}
            onClick={() => handleClose()}
          >
            Close
          </DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
