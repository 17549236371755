/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents all recipients for organization messages.
 */
export enum OrgMessageRecipient {
  ACCOUNTS = "Accounts",
  STUDENTS = "Students",
  TUTORS = "Tutors",
  PARENTS = "Parents",
}
