import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import {
  ACHResponse,
  AccountResponse,
  AccountsService,
  ApiError,
  Auth0AccountRole,
  CreditCardResponse,
  InviteResponse,
  InviteStatus,
  InvitesService,
  StudentResponse,
  StudentsService,
} from "client/openapi";
import ProfileData from "components/Dashboard/ProfileData";
import UpcomingMeetings from "components/Dashboard/UpcomingMeetings";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { APIResponse, AccountRoles, PageStatus } from "types";
import PaymentInformationModal from "components/PaymentMethods";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { StudentContext } from "./studentContext";
import Invites from "../InvitesModal/Invites";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

export default function StudentDashboard() {
  const { isLoading } = useAuth0();

  const { current_student } = useContext(StudentContext);

  const { currently_selected_organization, account } = useContext(
    OrgRolesAndAccountContext
  );

  const [invites, setInvites] = useState<InviteResponse[]>();

  const [addedStart, setAddedStart] = useState<Date>();

  // set up state
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  const [clientSecret, setClientSecret] = useState<string>();
  type AllPaymentMethods = CreditCardResponse | ACHResponse;
  const [paymentMethodsOnFile, setPaymentMethodsOnFile] =
    useState<AllPaymentMethods[]>();

  useEffect(() => {
    if (account) {
      const getClientSecretForSetupIntent = async () => {
        setStatus(PageStatus.LOADING);
        try {
          const clientSecret = await AccountsService.addPaymentMethodAccount();
          setStatus(PageStatus.SUCCESS);
          setClientSecret(clientSecret);
        } catch (e: any) {
          setStatus(PageStatus.ERROR);
          setError({ error: "Could not get student payment details." });
          console.error(`Error (#${e.status}): ${e.message}`);
        }
      };

      getClientSecretForSetupIntent();
    }
  }, [account]);

  const options = { clientSecret: clientSecret };

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    let newMethods: AllPaymentMethods[] = [];

    try {
      const existing_cards = await AccountsService.getCards();
      if (existing_cards) {
        newMethods = [...newMethods, ...existing_cards];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    try {
      const existing_banks = await AccountsService.getAchAccounts();
      if (existing_banks) {
        newMethods = [...newMethods, ...existing_banks];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    setPaymentMethodsOnFile(newMethods);
  }, [account?.reference_id]);

  useEffect(() => {
    if (account) {
      getAndSetPaymentMethodsOnFile();
    }
  }, [account]);

  const getOrgInvites = async () => {
    InvitesService.getInvitesByAccountAndStatus({
      status: InviteStatus.PENDING,
    })
      .then((invites) => {
        setInvites(invites);
      })
      .catch((err) => {
        setError({ error: "Could not get invites!" });
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  };

  useEffect(() => {
    if (account) {
      getOrgInvites();
    }
  }, [account]);

  useEffect(() => {
    if (current_student && account) {
      setStatus(PageStatus.SUCCESS);
    }
  }, [account, current_student]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <LoadingBlock status={status} />

      <ErrorBlock
        status={status}
        message={error?.message || "Error encountered"}
      />

      {account && current_student && options && options.clientSecret && (
        <Elements key={clientSecret} stripe={stripePromise} options={options}>
          {paymentMethodsOnFile && paymentMethodsOnFile.length === 0 && (
            <div className="container mx-auto mb-4">
              <div className="mt-4 tutor__profile_block">
                <div className="tutor__profile_block-text">
                  <span className="primsecond-text">
                    You have no payment methods.
                  </span>
                  <p className="mt-1 secondary-text">
                    Add a payment method to your profile so you can start
                    getting tutored!
                  </p>
                </div>

                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--orange">
                      Add Payment Method &rarr;
                    </button>
                  </DialogTrigger>
                  <PaymentInformationModal
                    initiatedByStudentOrParentDirectly={true}
                  />
                </Dialog>
              </div>
            </div>
          )}
          {invites && invites.length > 0 && (
            <div className="container mx-auto mb-4">
              <div className="mt-4 invite_block">
                <div className="tutor__profile_block-text">
                  <span className="primsecond-text">
                    You have been invited to an organization!
                  </span>
                  <p className="mt-1 secondary-text">
                    View invites and accept them if you'd like to receive
                    tutoring.
                  </p>
                </div>

                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--green">
                      View Invites &rarr;
                    </button>
                  </DialogTrigger>
                  <Invites invites={invites} setInvites={setInvites} />
                </Dialog>
              </div>
            </div>
          )}

          <div className="container mx-auto">
            <UpcomingMeetings addedStart={addedStart}>
              <div className="list--actions">
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--green">
                      View or Add Payment Methods &rarr;
                    </button>
                  </DialogTrigger>
                  <PaymentInformationModal
                    initiatedByStudentOrParentDirectly={true}
                  />
                </Dialog>
              </div>
            </UpcomingMeetings>
          </div>

          {/* <div className="container py-6 mx-auto my-8 px-7 rounded-xl bg-neutral-50">
            <ProfileData
              context={AccountRoles.STUDENT}
              account={account}
              student={student}
            />
          </div> */}
        </Elements>
      )}
    </>
  );
}
