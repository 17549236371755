import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor } from "components/Button";
import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import PhoneInput from "react-phone-input-2";
import { Signup } from "types";

function SignupContact({
  values,
  handleUpdate,
  handleSubmit,
  setStep,
  loading,
  errors,
}: {
  values: Signup;
  handleUpdate: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  setStep: Function;
  loading: boolean;
  errors: Signup;
}) {
  const [buttonColor, setButtonColor] = useState<ButtonColor>(
    ButtonColor.GREEN
  );
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false);

  const handlePhoneChange = (newPhone: string) => {
    const phone_number = {
      target: {
        name: "phone_number",
        value: newPhone,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleUpdate(phone_number);
  };

  useEffect(() => {
    setInvalidPhoneNumber(
      (errors.phone_number && errors.phone_number.length > 0) || false
    );
  }, [errors]);

  useEffect(() => {
    setButtonColor(invalidPhoneNumber ? ButtonColor.GRAY : ButtonColor.GREEN);
  }, [invalidPhoneNumber]);

  return (
    <form
      action="#"
      onSubmit={handleSubmit}
      className="flex items-center justify-center"
      style={{ height: "75vh" }}
    >
      <div>
        <h1 className="mt-5 headline-9">Welcome aboard!</h1>

        <div className="mt-2 mb-2 signup_text">
          Add some basic info to your profile. You can always change this later.
        </div>

        <label htmlFor="first_name" className="mt-4 input-label">
          First Name
          <input
            id="first_name"
            name="first_name"
            type="text"
            className="input"
            placeholder="John"
            value={values.first_name}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        <label htmlFor="last_name" className="mt-4 input-label">
          Last Name
          <input
            id="last_name"
            name="last_name"
            type="text"
            className="input"
            placeholder="Smith"
            value={values.last_name}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        <label htmlFor="phone_number" className="mt-4 input-label">
          Phone Number (optional)
          <PhoneInput
            country={"us"}
            value={values.phone_number}
            onChange={handlePhoneChange}
            prefix="+"
            inputClass="input"
            placeholder="+1 (555) 555-5555"
            dropdownStyle={{ display: "none" }}
            disabled={loading}
            countryCodeEditable={false}
          />
        </label>

        <div className="mt-4">
          <Button
            color={buttonColor}
            disabled={invalidPhoneNumber}
            block={true}
            type="submit"
          >
            Next <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </form>
  );
}

export default SignupContact;
