import { DialogClose } from "@radix-ui/react-dialog";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  OrgSubjectsService,
  OrganizationSubjectResponse,
  TutorResponse,
  TutorSubjectResponse,
  TutorSubjectsService,
} from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";
import Select from "components/Select";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus, Tutor } from "types";
import {
  concatenateSubject,
  concatenateTutorSubject,
} from "util/concatenateSubject";
import Notifications from "util/notifications";
import "./index.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import SelectOrganization from "components/SelectOrganization";
import { TutorContext } from "../TutorDashboard/tutorContext";

function ManageTutorSubjectsDialog() {
  const { organizations_available_to_role } = useContext(
    OrgRolesAndAccountContext
  );

  const { current_tutor, update_tutor_state } = useContext(TutorContext);

  const [newSubject, setNewSubject] = useState<OrganizationSubjectResponse>();
  const [subjectOptions, setSubjectOptions] = useState<
    OrganizationSubjectResponse[]
  >([]);

  const [subjects, setSubjects] = useState<TutorSubjectResponse[]>([]);
  const [subjectAdded, setSubjectAdded] = useState<boolean>(false);
  const [subjectEdited, setSubjectEdited] = useState<boolean>(false);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  function handleNewSubject(value) {
    setNewSubject(value);
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    addSubject();
  }

  function clearForm() {
    setNewSubject(undefined);
    setStatus(undefined);
  }

  async function addSubject() {
    if (!newSubject) {
      return setError({ message: "Please choose a subject to add." });
    }

    setStatus(PageStatus.LOADING);

    TutorSubjectsService.createSubject({
      requestBody: {
        tutor_id: current_tutor?.id as number,
        org_subject_id: newSubject.id,
        active: true,
      },
    })
      .then((newSubject) => {
        setSubjectAdded(true);
        setSubjects([...subjects, newSubject]);
        setSubjectOptions([
          ...subjectOptions.filter((s) => s.id !== newSubject.org_subject.id),
        ]);
        clearForm();
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Subject added!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to create subject." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function updateSubjectStatus(id: number, active: boolean) {
    await TutorSubjectsService.updateSubject({
      subjectId: id,
      requestBody: {
        active: active,
      },
    })
      .then((updatedSubject) => {
        setSubjectEdited(true);
        setSubjects([...subjects.filter((s) => s.id !== id), updatedSubject]);
        Notifications.success(
          `Subject ${active ? "reactivated" : "deactivated"}!`
        );
      })
      .catch((e: ApiError) => {
        setError({ error: "Unable to change subject's status." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    if (current_tutor) {
      setSubjects(current_tutor.subjects);
      let tutorSubjectIds: number[] = [];
      current_tutor.subjects.forEach((s: TutorSubjectResponse) => {
        tutorSubjectIds.push(s.org_subject.id);
      });

      OrgSubjectsService.getSubjectsByOrg({
        orgId: current_tutor.org_id,
        active: true,
      })
        .then((response) => {
          setSubjectOptions(
            response.filter((s) => !tutorSubjectIds.includes(s.id))
          );
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "Unable to fetch organization-defined subjects." });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }
  }, [current_tutor]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  function pushUpdates() {
    if (subjectAdded || subjectEdited) {
      update_tutor_state({ need_to_get_latest_tutor_info: true });
    }
  }

  return (
    <>
      {current_tutor && subjects && (
        <DialogContent
          className="mangage-tutor-subjects-dialog"
          onClose={pushUpdates}
        >
          {(organizations_available_to_role?.length as number) > 1 && (
            <div
              className="w-6/12 p-1 mb-6"
              style={{ backgroundColor: "#FFF4E5" }}
            >
              <SelectOrganization />
            </div>
          )}

          {subjects.length > 0 && (
            <table className="table">
              <thead className="text-left text-md">
                <tr>
                  <th className="w-5/6">Subject/Activity</th>
                  <th className="w-1/6">Actions</th>
                </tr>
              </thead>
              <tbody>
                {subjects
                  .sort((a, b) =>
                    a.org_subject.name > b.org_subject.name
                      ? 1
                      : b.org_subject.name > a.org_subject.name
                      ? -1
                      : 0
                  )
                  .map((s) => (
                    <tr
                      key={s.id}
                      className="subject-item odd:bg-gray-50 even:bg-white"
                    >
                      <td>
                        <div className="display-subject-title">
                          {s.org_subject.name}
                        </div>
                      </td>
                      <td>
                        {s.active ? (
                          <AiFillEye
                            className="mb-2 mt-2 ml-5"
                            onClick={() => updateSubjectStatus(s.id, false)}
                            style={{
                              color: "#FFB341",
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className="mb-2 mt-2 ml-5"
                            onClick={() => updateSubjectStatus(s.id, true)}
                            style={{
                              color: "#809BF7",
                              fontSize: "24px",
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          <form className="mt-5" onSubmit={handleSubmit}>
            <label htmlFor="subject" className="input-label">
              Add Subject
              <Select
                key={newSubject?.id}
                id="subject"
                name="subject"
                className="mt-2"
                options={subjectOptions.sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )}
                getOptionLabel={(s: OrganizationSubjectResponse) =>
                  `${concatenateSubject(s)}`
                }
                getOptionValue={(s: OrganizationSubjectResponse) =>
                  s.id.toString()
                }
                value={newSubject}
                onChange={handleNewSubject}
              />
            </label>

            <DialogActions>
              <DialogClose asChild>
                <DialogAction color={ButtonColor.ORANGE}>Close</DialogAction>
              </DialogClose>

              <DialogAction
                primary={true}
                color={ButtonColor.ORANGE}
                type="submit"
                disabled={
                  subjectOptions.length === 0 || status === PageStatus.LOADING
                }
              >
                {status === PageStatus.LOADING ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>
                    Add Subject <ArrowRightIcon />
                  </>
                )}
              </DialogAction>
            </DialogActions>
          </form>
        </DialogContent>
      )}
    </>
  );
}

export default ManageTutorSubjectsDialog;
