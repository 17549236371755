import { useAuth0 } from "@auth0/auth0-react";
import {
  AccountResponse,
  AccountsService,
  ApiError,
  Auth0AccountRole,
  MeetingResponse,
  MeetingStats,
  MeetingsService,
  NoteResponse,
  OrganizationResponse,
  OrganizationsService,
  StatisticsService,
  StudentContactResponse,
  StudentContactsService,
  StudentResponse,
  StudentsService,
  ChartsService,
  ChartSubscriptionResponse,
  ParentsService,
  ParentResponse,
} from "client/openapi";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { APIResponse, PageStatus } from "types";
import "./index.css";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import TabNavbar from "components/TabNavbar";
import StudentInfoAndIcon from "./StudentInfo";
import NotesTab from "./NotesTab/NotesTab";
import ParentInfoTab from "./ParentInfoTab/ParentInfoTab";
import AdditionalStudentInfoTab from "./StudentInfoTab/StudentInfoTab";
import PaymentsTab from "./PaymentsTab/PaymentsTab";
import MetricTab from "./MetricTab";
import { GoPeople, GoPerson, GoCreditCard } from "react-icons/go";
import { FileTextIcon, BarChartIcon } from "@radix-ui/react-icons";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

const timezone = moment.tz.guess(true);

export default function StudentDetail() {
  const { isLoading } = useAuth0();
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);
  const navigate = useNavigate();

  const { currently_selected_organization, account } = useContext(
    OrgRolesAndAccountContext
  );

  const { id: rawStudentId } = useParams();

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();
  const [student, setStudent] = useState<StudentResponse>();
  const [organization, setOrganization] = useState<OrganizationResponse>();
  const [meetings, setMeetings] = useState<MeetingResponse[]>();
  const [internalNotes, setInternalNotes] = useState<NoteResponse[]>([]);
  const [parentNotes, setParentNotes] = useState<NoteResponse[]>([]);
  const [studentContacts, setStudentContacts] = useState<
    StudentContactResponse[]
  >([]);
  const [meetingStats, setMeetingStats] = useState<MeetingStats>();
  const [currentMeetingHistoryPage, setCurrentMeetingHistoryPage] =
    useState<number>(0);
  const [exportingData, setExportingData] = useState<boolean>(false);
  const [exportingMeetings, setExportingMeetings] = useState<boolean>(false);
  const [newInternalNoteOpen, setNewInternalNoteOpen] =
    useState<boolean>(false);
  const [newParentNoteOpen, setNewParentNoteOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("Notes");
  const [charts, setCharts] = useState<ChartSubscriptionResponse[]>();
  const [updateCharts, setUpdateCharts] = useState<boolean>(false);

  const [orgParents, setOrgParents] = useState<ParentResponse[]>([]);
  const [studentParents, setStudentParents] = useState<ParentResponse[]>();

  const options = [
    { value: "Notes", icon: FileTextIcon },
    { value: "Student Info", icon: GoPerson },
    { value: "Parent Info", icon: GoPeople },
    { value: "Metrics", icon: BarChartIcon },
    { value: "Billing", icon: GoCreditCard },
  ];

  // useEffect to check URL hash, navigates the user to the appropriate tab
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#notes") {
      setActiveTab("Notes");
    }

    if (hash === "#student") {
      setActiveTab("Student Info");
    }

    if (hash === "#parent") {
      setActiveTab("Parent Info");
    }

    if (hash === "#metrics") {
      setActiveTab("Metrics");
    }

    if (hash === "#billing") {
      setActiveTab("Billing");
    }
  }, []);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  async function getMeetingHistory(studentId: number, pageNumber: number) {
    MeetingsService.getMeetingsByStudent({
      studentId: studentId,
      until: moment().toISOString(),
    })
      .then((m) => {
        setMeetings(m);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function getStudent() {
    if (!rawStudentId) {
      setStatus(PageStatus.ERROR);
      setError({ message: "No student specified." });
      return;
    }

    const studentId = Number.parseInt(rawStudentId);

    StudentsService.getStudent({ studentId })
      .then((s) => {
        setStudent(s);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch student details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function getData() {
    const studentId = Number.parseInt(rawStudentId as string);

    StudentContactsService.getStudentContactsByStudent({ studentId })
      .then((contacts) => {
        const sortedContacts = contacts.sort((a, b) => a.id - b.id);
        setStudentContacts(sortedContacts);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch student contacts" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    OrganizationsService.getOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((o) => {
        setOrganization(o);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    StudentsService.getParentsByStudent({ studentId })
      .then((parents) => {
        setStudentParents(parents);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch student details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    if (
      [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
        currently_selected_role as Auth0AccountRole
      )
    ) {
      ParentsService.getParentsByOrganization({
        orgId: currently_selected_organization as number,
      })
        .then((data) => {
          setOrgParents(data);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "Unable to fetch organization parents" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }

    await getMeetingHistory(studentId, currentMeetingHistoryPage);

    StatisticsService.getStudentMeetingStats({
      studentId: studentId,
      start: moment().subtract(1, "year").toISOString(),
      until: moment().toISOString(),
      tz: timezone,
    }).then((data) => {
      setMeetingStats(data);
    });
  }

  const fetchCharts = useCallback(() => {
    if (student) {
      ChartsService.getAllChartSubscriptionsForStudent({
        studentId: student.id,
      }).then((data) => {
        setCharts(data);
      });
    }
  }, [student]);

  useEffect(() => {
    if (!student) {
      getStudent();
    }

    if (
      !isLoading &&
      currently_selected_organization &&
      rawStudentId &&
      student
    ) {
      getData();
    }
  }, [isLoading, currently_selected_organization, student]);

  useEffect(() => {
    fetchCharts();
  }, [updateCharts, fetchCharts]);

  if (currently_selected_role === Auth0AccountRole.ME) {
    return (
      <ErrorBlock
        status={PageStatus.ERROR}
        title="Unauthorized"
        message="You do not have permission to access this page."
      />
    );
  }

  return (
    <div className="container mx-auto page--student-profile">
      <ErrorBlock status={status} title="Error" message={error?.message} />

      {account && student && meetings && organization && orgParents ? (
        <div className="mx-5 md:mx-0">
          <button onClick={() => navigate(-1)} className="flex items-center">
            <ArrowLeftIcon className="w-6 h-6 font-bold" />
            <p className="text-lg font-bold">Back</p>
          </button>

          <p className="text-4xl mt-5 font-bold">
            {student.first_name} {student.last_name}
          </p>
          <p className="text-2xl mt-2">Student</p>

          <div className="my-5">
            <TabNavbar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              options={options}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-7">
            {(activeTab === "Notes" ||
              activeTab === "Student Info" ||
              activeTab === "Parent Info") && (
              <div className="col-span-2 flex justify-center">
                <StudentInfoAndIcon
                  student={student}
                  setStudent={setStudent}
                  setError={setError}
                  setStatus={setStatus}
                />
              </div>
            )}

            <div className="col-span-5">
              {activeTab === "Notes" && (
                <NotesTab
                  newInternalNoteOpen={newInternalNoteOpen}
                  setNewInternalNoteOpen={setNewInternalNoteOpen}
                  newParentNoteOpen={newParentNoteOpen}
                  setNewParentNoteOpen={setNewParentNoteOpen}
                  student={student}
                  organization={organization}
                  internalNotes={internalNotes}
                  setInternalNotes={setInternalNotes}
                  parentNotes={parentNotes}
                  setParentNotes={setParentNotes}
                  account={account}
                  studentContacts={studentContacts}
                />
              )}

              {activeTab === "Student Info" && (
                <AdditionalStudentInfoTab
                  exportingData={exportingData}
                  setExportingData={setExportingData}
                  student={student}
                  organization_id={currently_selected_organization as number}
                  exportingMeetings={exportingMeetings}
                  setExportingMeetings={setExportingMeetings}
                  setStatus={setStatus}
                  setError={setError}
                  meetings={meetings}
                />
              )}

              {activeTab === "Parent Info" && (
                <ParentInfoTab
                  student={student}
                  orgParents={orgParents}
                  studentParents={studentParents as ParentResponse[]}
                  setStudentParents={setStudentParents}
                  parent_contact={studentContacts[0]}
                  studentContacts={studentContacts}
                  setStudentContacts={setStudentContacts}
                  setError={setError}
                  setStatus={setStatus}
                />
              )}
            </div>

            {activeTab === "Metrics" && charts && meetingStats && (
              <div className="col-span-7">
                <MetricTab
                  student={student}
                  orgId={organization.id}
                  charts={charts}
                  meetingStats={meetingStats}
                  updateCharts={updateCharts}
                  setUpdateCharts={setUpdateCharts}
                />
              </div>
            )}

            {activeTab === "Billing" &&
              (currently_selected_role === Auth0AccountRole.ORG_ADMIN ? (
                <div className="col-span-7">
                  <PaymentsTab
                    student={student}
                    orgId={currently_selected_organization as number}
                  />
                </div>
              ) : (
                <div className="col-span-7 text-center">
                  This information must be viewed as an admin!
                </div>
              ))}
          </div>
        </div>
      ) : (
        <LoadingBlock status={PageStatus.LOADING} />
      )}
    </div>
  );
}
