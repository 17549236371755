import { AccountScope, RawAccountRoles } from "types";

export enum AccountStatus {
  INVITED = "Invited",
  INCOMPLETE = "Incomplete",
  AWAITING_CONFIRMATION = "Awaiting Confirmation",
  ACTIVE = "Active",
  PAUSED = "Paused",
  BANNED = "Banned",
}

export interface AccountBase {
  email: string;
  phone_number?: string;
  first_name: string;
  last_name: string;
}

export interface Account extends AccountBase {
  id: number;
  reference_id: string;
  status: AccountStatus;
  role: RawAccountRoles;
  created_at: Date;
  modified_at: Date;
}

export interface AccountSignup {
  email: string;
  phone_number?: string;
  first_name: string;
  last_name: string;
  password: string;
  confirm_password: string;
}
