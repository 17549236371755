/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Auth0AccountRole } from "../models/Auth0AccountRole";
import type { InviteResponse } from "../models/InviteResponse";
import type { InviteStatus } from "../models/InviteStatus";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class InvitesService {
  /**
   * Resolve Org Invite
   * Resolve an invite. Accept/Deny
   * @returns any Successful Response
   * @throws ApiError
   */
  public static resolveOrgInvite({
    inviteId,
    inviteStatus,
  }: {
    inviteId: number;
    inviteStatus: InviteStatus;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/invites/resolve",
      query: {
        invite_id: inviteId,
        invite_status: inviteStatus,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Invites By Account Role And Status
   * Get invites by the given account, invite type, and status.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getInvitesByAccountRoleAndStatus({
    role,
    status,
  }: {
    role: Auth0AccountRole;
    status: InviteStatus;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/invites/by-account-role-status",
      query: {
        role: role,
        status: status,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Invites By Account And Status
   * Get invites by the given account and invite status.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getInvitesByAccountAndStatus({
    status,
  }: {
    status: InviteStatus;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/invites/by-account-status",
      query: {
        status: status,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Invites
   * Get invites by an account, status, and/or org_id. This invite enables a user
   * to join an org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getInvites({
    inviteStatus,
    orgId,
  }: {
    inviteStatus?: InviteStatus | null;
    orgId?: number | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/invites/invites",
      query: {
        invite_status: inviteStatus,
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Accept Invite
   * Accept an invite to setup an auth0 account on Tadpole.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static acceptInvite({
    a0Secret,
  }: {
    a0Secret: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/invites/accept/{a0_secret}",
      path: {
        a0_secret: a0Secret,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Invite
   * Handle an invite by invite token and account email.
   * @returns InviteResponse Successful Response
   * @throws ApiError
   */
  public static getInvite({
    token,
    email,
  }: {
    token: string;
    email: string;
  }): CancelablePromise<InviteResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/invites/{token}",
      path: {
        token: token,
      },
      query: {
        email: email,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Initial Invite
   * Get the initial invite on this account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getInitialInvite({
    accountId,
  }: {
    accountId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/invites/initial_invite",
      query: {
        account_id: accountId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
