import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import moment from "moment";
import { CalendarEvent } from "..";
import EventBlockDialog from "components/MeetingDialog/EventBlockDialog";
import { Auth0AccountRole, MeetingResponse } from "client/openapi";

export default function EventBlock({
  meeting,
  allMeetings,
  index,
  role,
  setEvents,
}: {
  meeting: CalendarEvent;
  allMeetings: MeetingResponse[];
  index: number;
  role: Auth0AccountRole;
  setEvents: () => Promise<void>;
}) {
  const meetingDuration = moment(meeting.end).diff(
    moment(meeting.start),
    "minutes"
  );
  const dateTime = moment(meeting.start).format("ddd, MMM D, hh:mm A");
  return (
    <Dialog key={meeting.id}>
      <DialogTrigger asChild>
        <div
          className={`text-white calendar--day-event-block calendar--day-event-navy cursor-pointer`}
        >
          <b className="calendar--day-title">{meeting.title.split("%")[0]}</b>

          {meetingDuration >= 45 && (
            <p className="calendar--day-time">
              {moment(meeting.start).format("h:mma")}-
              {moment(meeting.end).format("h:mma")}
            </p>
          )}

          {meetingDuration >= 30 && (
            <p className="calendar--day-time">{meeting.title.split("%")[1]}</p>
          )}
        </div>
      </DialogTrigger>

      <EventBlockDialog
        meetings={(meeting.resources as MeetingResponse[]) ?? []}
        dateTime={dateTime}
        allMeetings={allMeetings}
        role={role}
        setEvents={setEvents}
      />
    </Dialog>
  );
}
