import { ButtonColor } from "components/Button";
import {
  DialogActions,
  DialogAction,
  DialogContent,
  DialogClose,
} from "components/Dialog";
import { ActionMeta, InputActionMeta, MultiValue } from "react-select";
import Select from "components/Select";
import {
  OrganizationSubjectResponse,
  StudentResponse,
  TutorMeetingResponse,
} from "client/openapi";
import { FormEvent, useState } from "react";
import {
  concatenateSubject,
  concatenateTutorSubject,
} from "util/concatenateSubject";
import { Tutor } from "types";
import { concatenateName } from "util/concatenateName";

function FilterCalendarDialog({
  subjects,
  tutors,
  students,
  submitFilters,
}: {
  subjects: OrganizationSubjectResponse[];
  tutors: TutorMeetingResponse[];
  students: StudentResponse[];
  submitFilters: (
    tutors?: TutorMeetingResponse[],
    students?: StudentResponse[],
    subjects?: OrganizationSubjectResponse[]
  ) => void;
}) {
  const [selectedSubjects, setSelectedSubjects] = useState<
    OrganizationSubjectResponse[]
  >([]);
  const [selectedTutors, setSelectedTutors] = useState<TutorMeetingResponse[]>(
    []
  );
  const [selectedStudents, setSelectedStudents] = useState<StudentResponse[]>(
    []
  );

  const changeTutor = (newValue: MultiValue<TutorMeetingResponse>) => {
    setSelectedTutors(newValue as TutorMeetingResponse[]);
  };

  const changeStudent = (newValue: MultiValue<StudentResponse>) => {
    students && setSelectedStudents(newValue as StudentResponse[]);
  };

  const changeSubject = (newValue: MultiValue<OrganizationSubjectResponse>) => {
    setSelectedSubjects(newValue as OrganizationSubjectResponse[]);
  };

  const handleSubmit = () => {
    submitFilters(selectedTutors, selectedStudents, selectedSubjects);
  };

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="invite-dialog">
          <h2 className="invite-dialog--title">Add Subject</h2>

          <label htmlFor="tutors" className="mt-4 input-label">
            Tutors
            <Select
              id="tutors"
              options={tutors}
              value={selectedTutors}
              getOptionLabel={(t) => `${concatenateName(t)}`}
              getOptionValue={(t) => t.id.toString()}
              placeholder={tutors ? "Select..." : "Loading..."}
              onChange={changeTutor}
              isMulti
            />
          </label>

          <label htmlFor="students" className="mt-4 input-label">
            Students
            <Select
              id="students"
              options={students}
              value={selectedStudents}
              getOptionLabel={(t) => `${concatenateName(t)}`}
              getOptionValue={(t) => t.id.toString()}
              placeholder={students ? "Select..." : "Loading..."}
              onChange={changeStudent}
              isMulti
            />
          </label>

          <label htmlFor="subject" className="mt-4 input-label">
            Subject
            <Select
              id="subject"
              options={subjects}
              value={selectedSubjects}
              getOptionLabel={(s) => `${concatenateSubject(s)}`}
              getOptionValue={(s) => s.id.toString()}
              placeholder="Select..."
              isMulti
              onChange={changeSubject}
            />
          </label>

          <DialogActions>
            <DialogClose asChild>
              <DialogAction
                primary={true}
                color={ButtonColor.GREEN}
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Apply
              </DialogAction>
            </DialogClose>

            <DialogClose asChild>
              <DialogAction color={ButtonColor.BLACK}>Close</DialogAction>
            </DialogClose>
          </DialogActions>
        </div>
      </DialogContent>
    </>
  );
}

export default FilterCalendarDialog;
