import {
  ChartSubscriptionResponse,
  ChartXAxisType,
  MetricDefinitionResponse,
  MetricValueResponse,
} from "client/openapi";
import { useState, useEffect } from "react";
import { PerformanceChartDatapoint } from ".";
import { DialogContent } from "components/Dialog";
import DayInput from "components/DayInput";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import AddDataDialog from "./AddDataDialog";
import { saveAs } from "file-saver";
import Notifications from "util/notifications";
import { ChartsService } from "client/openapi";
import { GoTrash } from "react-icons/go";
import moment from "moment";
import { filterChartDP } from ".";

const timezone = moment.tz.guess(true);

export default function ViewEditDataDialog({
  studentId,
  xAxisLabel,
  yAxisLabel,
  studentName,
  chart,
  minMax,
  initialChartDP,
  updateCharts,
  setUpdateCharts,
  addMetricValueToRawData,
  deleteMetricValueFromRawData,
}: {
  studentId: number;
  xAxisLabel: string;
  yAxisLabel: string;
  studentName?: string;
  chart: ChartSubscriptionResponse;
  minMax: number[];
  initialChartDP: PerformanceChartDatapoint[];
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
  addMetricValueToRawData: (data: MetricValueResponse) => void;
  deleteMetricValueFromRawData: (data: number) => void;
}) {
  const [chartDP, setChartDP] =
    useState<PerformanceChartDatapoint[]>(initialChartDP);
  const [start, setStart] = useState<number>(minMax[0]);
  const [end, setEnd] = useState<number>(minMax[1]);
  const [showAddDataDialog, setShowAddDataDialog] = useState(false);
  const [showDeleteButtons, setShowDeleteButtons] = useState(
    Array(chartDP.length).fill(false)
  );
  const [isLoading, setIsLoading] = useState(false);

  function exportData() {
    const csv = [
      [xAxisLabel, yAxisLabel],
      ...chartDP.map((datapoint) => {
        if (
          chart.chart_prototype.chart_x_axis_type ===
          ChartXAxisType.HOURS_OF_TUTORING
        ) {
          return [datapoint.xKey, datapoint.score];
        } else if (
          chart.chart_prototype.chart_x_axis_type === ChartXAxisType.DATE
        ) {
          return [
            moment.utc(datapoint.xKey).tz(timezone).format("MM/DD/YYYY"),
            datapoint.score,
          ];
        }
      }),
    ].join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    saveAs(blob, `${chart.chart_prototype.chart_title} - ${studentName}.csv`);
    Notifications.success("Successfully exported student meetings.");
  }

  const deleteRow = (target_metric_id: number) => {
    setIsLoading(true);
    ChartsService.deleteMetricValue({ metricValueId: target_metric_id })
      .then(() => {
        setIsLoading(false);
        deleteMetricValueFromRawData(target_metric_id);
        // const updatedChartDP = chartDP.filter(datapoint => datapoint.metric_value_id !== target_metric_id)
        // setChartDP(updatedChartDP);
        setUpdateCharts(!updateCharts);
        Notifications.success("Successfully deleted data point.");
      })
      .catch((err) => {
        Notifications.error("Failed to delete data point.");
        console.error("Failed to delete data point", err);
      });
  };

  useEffect(() => {
    setStart(minMax[0]);
    setEnd(minMax[1]);
  }, [minMax]);

  useEffect(() => {
    const filteredDP = filterChartDP(initialChartDP, start, end);
    setChartDP(filteredDP);
  }, [start, end, initialChartDP]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left medium-wide pb-2 px-12 font-montserrat"
      style={{ height: "85vh" }}
      onClose={() => {
        setStart(minMax[0]);
        setEnd(minMax[1]);
        setChartDP(initialChartDP);
        setShowDeleteButtons(Array(initialChartDP.length).fill(false));
      }}
    >
      <div className="h-1/5 pb-5 pt-10">
        <h2 className="font-bold text-3xl">
          {chart.chart_prototype.metric_definition.y_axis_name}
        </h2>

        <div className="flex flex-row items-center justify-between">
          {chart.chart_prototype.chart_x_axis_type === ChartXAxisType.DATE && (
            <div className="flex flex-row gap-2 py-2">
              from
              <DayInput
                id="view-edit-start-date"
                value={start}
                setValue={setStart}
                classes="border-b w-32 h-7 border-gray-300"
                required={true}
              />
              to
              <DayInput
                id="view-edit-end-date"
                value={end}
                setValue={setEnd}
                min={start + 86400000}
                classes="border-b w-32 h-7 border-gray-300"
                required={true}
              />
            </div>
          )}

          {chart.chart_prototype.chart_x_axis_type ===
            ChartXAxisType.HOURS_OF_TUTORING && (
            <div className="flex flex-row gap-2 py-2">
              from
              <div className="w-20 h-7 border-b border-gray-300">
                <input
                  type="number"
                  id="view-edit-start-hour"
                  value={isNaN(start) ? "" : start}
                  placeholder="Start"
                  onChange={(e) => setStart(parseFloat(e.target.value))}
                  className="border-transparent focus:border-transparent focus:ring-0 w-full h-full"
                />
              </div>
              to
              <div className="w-20 h-7 border-b border-gray-300">
                <input
                  type="number"
                  id="view-edit-end-hour"
                  value={isNaN(end) ? "" : end}
                  min={isNaN(start) ? 0 : start}
                  placeholder="End"
                  onChange={(e) => setEnd(parseFloat(e.target.value))}
                  className="border-transparent focus:border-transparent focus:ring-0 w-full h-full"
                />
              </div>
              hours
            </div>
          )}

          <div className="flex flex-row items-center gap-6">
            <div className="relative" id={"add-data"}>
              <Button
                color={ButtonColor.SKYBLUE}
                extraClasses="relative rounded-xl"
                onClick={() => setShowAddDataDialog(!showAddDataDialog)}
              >
                Add Data
              </Button>

              {showAddDataDialog && (
                <div className="absolute right-0 top-14">
                  <AddDataDialog
                    id={"add-data"}
                    studentId={studentId}
                    setShowAddDataDialog={setShowAddDataDialog}
                    chart={chart}
                    updateCharts={updateCharts}
                    setUpdateCharts={setUpdateCharts}
                    addMetricValueToRawData={addMetricValueToRawData}
                  />
                </div>
              )}
            </div>

            <Button
              color={ButtonColor.SKYBLUE}
              extraClasses="rounded-xl"
              onClick={() => exportData()}
            >
              Export
            </Button>
          </div>
        </div>

        <div className="h-4/5 w-full mt-12">
          <div className="grid grid-cols-12">
            {chart.chart_prototype.chart_x_axis_type ===
              ChartXAxisType.HOURS_OF_TUTORING && (
              <span className="col-span-2 ml-0.5 font-bold">Date</span>
            )}
            <span
              className={`col-span-3 flex ${
                chart.chart_prototype.chart_x_axis_type ===
                  ChartXAxisType.HOURS_OF_TUTORING &&
                `justify-center items-center`
              } font-bold`}
            >
              {xAxisLabel}
            </span>
            <span className="col-span-3 flex justify-center items-center font-bold">
              {yAxisLabel}
            </span>
            <span
              className={`${
                chart.chart_prototype.chart_x_axis_type ===
                ChartXAxisType.HOURS_OF_TUTORING
                  ? `col-span-4`
                  : `col-span-6`
              } 
            flex justify-center items-center font-bold`}
            >
              Actions
            </span>
          </div>

          <div
            className="border-2 rounded-md overflow-y-scroll"
            style={{
              maxHeight: "384px",
            }}
          >
            {chartDP.length === 0 && (
              <div className="h-11 ml-0.5 flex items-center">
                No data to display.
              </div>
            )}

            {chartDP.length > 0 &&
              chartDP.map((datapoint: PerformanceChartDatapoint, index) => (
                <div
                  className={`grid grid-cols-12 h-11 items-center ${
                    index !== chartDP.length - 1 && `border-b-2`
                  }`}
                  key={index}
                >
                  {chart.chart_prototype.chart_x_axis_type ===
                    ChartXAxisType.HOURS_OF_TUTORING && (
                    <span className="col-span-2 ml-1">
                      {moment(datapoint.timestamp)
                        .tz(timezone)
                        .format("MM/DD/YYYY")}
                    </span>
                  )}
                  <span
                    className={`col-span-3 flex ${
                      chart.chart_prototype.chart_x_axis_type ===
                        ChartXAxisType.HOURS_OF_TUTORING &&
                      `justify-center items-center`
                    }`}
                  >
                    {chart.chart_prototype.chart_x_axis_type ===
                    ChartXAxisType.HOURS_OF_TUTORING
                      ? datapoint.xKey
                      : moment(datapoint.timestamp)
                          .tz(timezone)
                          .format("MM/DD/YYYY")}
                  </span>
                  <span className="col-span-3 flex justify-center items-center">
                    {datapoint.score}
                  </span>
                  <span
                    className={`${
                      chart.chart_prototype.chart_x_axis_type ===
                      ChartXAxisType.HOURS_OF_TUTORING
                        ? `col-span-4`
                        : `col-span-6`
                    }
                 flex justify-center items-center`}
                  >
                    {showDeleteButtons[index] ? (
                      <Button
                        color={ButtonColor.RED}
                        size={ButtonSize.EXTRA_SMALL}
                        extraClasses="rounded-xl h-6 w-20"
                        onClick={() => deleteRow(datapoint.metric_value_id)}
                      >
                        Delete
                      </Button>
                    ) : (
                      <GoTrash
                        cursor="pointer"
                        className="w-5"
                        onClick={() => {
                          setShowDeleteButtons(
                            showDeleteButtons.map((value, i) =>
                              i === index ? true : value
                            )
                          );
                        }}
                      />
                    )}
                  </span>
                </div>
              ))}
          </div>

          {isLoading && <div>Deleting ...</div>}
        </div>
      </div>
    </DialogContent>
  );
}
