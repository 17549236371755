import { Helmet } from "react-helmet";

function Header() {
  return (
    <Helmet
      defaultTitle="Tadpole Tutoring"
      titleTemplate="%s | Tadpole Tutoring"
    ></Helmet>
  );
}

export default Header;
