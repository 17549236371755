import {
  AccountsService,
  ACHResponse,
  Auth0AccountRole,
  CreditCardResponse,
  InviteResponse,
  InvitesService,
  InviteStatus,
  OrganizationNameResponse,
  OrganizationsService,
} from "client/openapi";
import { Button, ButtonColor } from "components/Button";

import StudentsList from "components/Dashboard/StudentsList";
import UpcomingMeetings from "components/Dashboard/UpcomingMeetings";
import { Dialog, DialogTrigger } from "components/Dialog";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { APIResponse, AccountRoles, PageStatus } from "types";
import "./index.css";
import MeetingDialog from "components/MeetingDialog";
import { ParentContext, ParentProvider } from "./parentContext";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import PaymentInformationModal from "components/PaymentMethods";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Invites from "../InvitesModal/Invites";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

export default function ParentDashboard() {
  const { organizations_available_to_role, account } = useContext(
    OrgRolesAndAccountContext
  );

  const { available_parents, current_parent } = useContext(ParentContext);

  // set up state
  const [error, setError] = useState<APIResponse>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [addedStart, setAddedStart] = useState<Date>();
  const [orgNames, setOrgNames] = useState<OrganizationNameResponse[]>();

  const [invites, setInvites] = useState<InviteResponse[]>();

  const [clientSecret, setClientSecret] = useState<string>();
  type AllPaymentMethods = CreditCardResponse | ACHResponse;
  const [paymentMethodsOnFile, setPaymentMethodsOnFile] =
    useState<AllPaymentMethods[]>();

  useEffect(() => {
    if (account) {
      const getClientSecretForSetupIntent = async () => {
        setStatus(PageStatus.LOADING);
        try {
          const clientSecret = await AccountsService.addPaymentMethodAccount();
          setStatus(PageStatus.SUCCESS);
          setClientSecret(clientSecret);
        } catch (e: any) {
          setStatus(PageStatus.ERROR);
          setError({ error: "Could not get student payment details." });
          console.error(`Error (#${e.status}): ${e.message}`);
        }
      };

      getClientSecretForSetupIntent();
    }
  }, [account]);

  const options = { clientSecret: clientSecret };

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    let newMethods: AllPaymentMethods[] = [];

    try {
      const existing_cards = await AccountsService.getCards();
      if (existing_cards) {
        newMethods = [...newMethods, ...existing_cards];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    try {
      const existing_banks = await AccountsService.getAchAccounts();
      if (existing_banks) {
        newMethods = [...newMethods, ...existing_banks];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    setPaymentMethodsOnFile(newMethods);
  }, [account?.reference_id]);

  useEffect(() => {
    if (account) {
      getAndSetPaymentMethodsOnFile();
    }
  }, [account]);

  const getOrgNames = useCallback(() => {
    OrganizationsService.getOrganizationNames({
      requestBody: organizations_available_to_role as number[],
    })
      .then((orgs) => {
        setOrgNames(orgs);
      })
      .catch((err) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  }, [organizations_available_to_role]);

  function getOrganizationNameById(id: number): string | undefined {
    const organization = orgNames?.find((org) => org.id === id);
    return organization
      ? organization.name
      : "Can't find org name at this time!";
  }

  useEffect(() => {
    if (organizations_available_to_role) {
      getOrgNames();
    }
  }, [getOrgNames, organizations_available_to_role]);

  const getOrgInvites = async () => {
    InvitesService.getInvitesByAccountAndStatus({
      status: InviteStatus.PENDING,
    })
      .then((invites) => {
        setInvites(invites);
      })
      .catch((err) => {
        setError({ error: "Could not get invites!" });
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  };

  useEffect(() => {
    if (account) {
      getOrgInvites();
    }
  }, [account]);

  useEffect(() => {
    if (
      available_parents &&
      current_parent &&
      orgNames &&
      options.clientSecret
    ) {
      setStatus(PageStatus.SUCCESS);
    }
  }, [available_parents, current_parent, orgNames, options.clientSecret]);

  return (
    <section>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <LoadingBlock status={status} />

      <ErrorBlock
        status={status}
        message={error?.error || "Error encountered"}
      />

      {status === PageStatus.SUCCESS && (
        <Elements key={clientSecret} stripe={stripePromise} options={options}>
          {paymentMethodsOnFile && paymentMethodsOnFile.length === 0 && (
            <div className="container mx-auto mb-4">
              <div className="mt-4 tutor__profile_block">
                <div className="tutor__profile_block-text">
                  <span className="primsecond-text">
                    You have no payment methods.
                  </span>
                  <p className="mt-1 secondary-text">
                    Add a payment method to your profile so you can start
                    getting tutored!
                  </p>
                </div>

                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--orange">
                      Add Payment Method &rarr;
                    </button>
                  </DialogTrigger>
                  <PaymentInformationModal
                    initiatedByStudentOrParentDirectly={true}
                  />
                </Dialog>
              </div>
            </div>
          )}
          {invites && invites.length > 0 && (
            <div className="container mx-auto mb-4">
              <div className="mt-4 invite_block">
                <div className="tutor__profile_block-text">
                  <span className="primsecond-text">
                    You have been invited to an organization!
                  </span>
                  <p className="mt-1 secondary-text">
                    View invites and accept them if you'd like to receive
                    tutoring.
                  </p>
                </div>

                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--green">
                      View Invites &rarr;
                    </button>
                  </DialogTrigger>
                  <Invites invites={invites} setInvites={setInvites} />
                </Dialog>
              </div>
            </div>
          )}
          <div className="container mx-auto">
            <UpcomingMeetings addedStart={addedStart}>
              <div className="list--actions">
                {/* <Dialog>
                    <DialogTrigger asChild>
                      <Button color={ButtonColor.DARK_BLUE}>
                        Book Meeting
                      </Button>
                    </DialogTrigger>

                    <MeetingDialog
                      isCreate={true}
                      role={Auth0AccountRole.PARENT}
                      setEvents={(e?: string) =>
                        Promise.resolve(setAddedStart(moment(e).toDate()))
                      }
                    />
                  </Dialog> */}
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--dark-blue">
                      View or Add Payment Methods &rarr;
                    </button>
                  </DialogTrigger>
                  <PaymentInformationModal
                    initiatedByStudentOrParentDirectly={true}
                  />
                </Dialog>
              </div>
            </UpcomingMeetings>
          </div>

          {available_parents?.map((parent) => (
            <div className="container mx-auto mt-8" key={parent.id}>
              <StudentsList
                role={Auth0AccountRole.PARENT}
                parentIfOnParentDashboard={parent}
                org_name={getOrganizationNameById(parent.org_id)}
              />
            </div>
          ))}
        </Elements>
      )}
    </section>
  );
}
