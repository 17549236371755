import {
  ChartSubscriptionCreate,
  ChartsService,
  ChartSubscriptionResponse,
  ChartPrototypeResponse,
} from "client/openapi";
import { DialogContent } from "components/Dialog";
import { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import DayInput from "components/DayInput";
import { Button, ButtonColor } from "components/Button";
import Notifications from "util/notifications";
import moment from "moment";

export default function AddChartDialog({
  orgId,
  studentId,
  updateCharts,
  setUpdateCharts,
}: {
  orgId: number;
  studentId: number;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredCharts, setFilteredCharts] = useState<
    ChartPrototypeResponse[]
  >([]);
  const [startDate, setStartDate] = useState<string>(
    moment().format("MM/DD/YYYY")
  );
  const [selectedChartPrototype, setSelectedChartPrototype] =
    useState<ChartPrototypeResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  function fetchData() {
    setLoading(true);

    ChartsService.getAllChartSubscriptionsForStudent({
      studentId: studentId,
    })
      .then((chart_subscriptions) => {
        const currentPrototypesStudentHas = chart_subscriptions.map(
          (chart_subscription) => chart_subscription.chart_prototype.id
        );

        ChartsService.getChartPrototypesInOrg({
          orgId: orgId,
        })
          .then((orgChartsResponse) => {
            const filteredCharts = orgChartsResponse.filter(
              (chart) => !currentPrototypesStudentHas.includes(chart.id)
            );

            setFilteredCharts(filteredCharts);

            setLoading(false);
          })
          .catch((err) => {
            console.error(`Unable to fetch org charts: ${err}`);
          });
      })
      .catch((err) => {
        console.error(`Unable to fetch student charts: ${err}`);
      })
      .finally(() => setLoading(false));
  }

  async function addChart() {
    if (!startDate) {
      Notifications.error("Please select a start date");
      return;
    }

    if (selectedChartPrototype) {
      const newChartSubscription: ChartSubscriptionCreate = {
        start_date: moment(startDate).format("YYYY-MM-DDTHH:mm"),
        pinned: false,
        is_aggregate_chart: false,
        student_id: studentId,
        chart_prototype_id: selectedChartPrototype.id,
      };

      ChartsService.createChartSubscription({
        requestBody: newChartSubscription,
      })
        .then(() => {
          setUpdateCharts(!updateCharts);
          Notifications.success("Successfully added chart");
          fetchData();
        })
        .catch((err) => {
          Notifications.error("Unable to add chart");
          console.error(`Unable to add chart: ${err}`);
        });
    } else {
      Notifications.error("Please select a chart");
    }
  }

  useEffect(() => {
    fetchData();
  }, [updateCharts]);

  useEffect(() => {
    const updateFilteredCharts = () => {
      setFilteredCharts((prevCharts) => {
        const filtered = prevCharts.filter((chart) =>
          chart.chart_title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return filtered;
      });
    };

    updateFilteredCharts();
  }, [searchQuery]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left medium pb-2 px-12 font-montserrat flex flex-col justify-between"
      style={{ height: "85vh" }}
    >
      <div className="pb-5 pt-10">
        <h2 className="font-bold text-3xl">Add Chart</h2>

        <div className="w-full h-9 border border-black flex flex-row justify-between items-center rounded-lg mt-5">
          <input
            type="text"
            className="w-11/12  border-transparent focus:border-transparent focus:ring-0 p-0 pl-1"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <MagnifyingGlassIcon className="w-1/12" />
        </div>

        <div className="mt-7 max-h-64 overflow-y-auto">
          {loading ? (
            <div>Loading...</div>
          ) : filteredCharts.length === 0 ? (
            <div>No charts available.</div>
          ) : (
            filteredCharts.map((chart, index) => (
              <div
                className={`flex flex-row items-center gap-2 ml-1 ${
                  index === filteredCharts.length - 1 ? `mb-0` : `mb-8`
                }`}
                key={chart.chart_title}
              >
                <input
                  type="radio"
                  name="add-chart-radio"
                  id={`add-chart-${index}`}
                  onChange={() => setSelectedChartPrototype(chart)}
                />
                <label htmlFor={`add-chart-${index}`} className="font-bold">
                  {chart.chart_title}
                </label>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center mb-6">
        <div className="w-72 text-center">
          Select the day you would like the chart to begin tracking data
        </div>
        <DayInput
          id="add-chart"
          value={startDate}
          setValue={setStartDate}
          classes="border-b border-gray-300 w-36 h-9 mt-6"
          dayPickerClasses="bottom-full mb-0"
          required={true}
        />

        <Button
          color={ButtonColor.SKYBLUE}
          extraClasses="mt-4 rounded-xl"
          onClick={addChart}
        >
          Add
        </Button>
      </div>
    </DialogContent>
  );
}
