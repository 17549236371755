import {
  ApiError,
  Attendance,
  Auth0AccountRole,
  MeetingResponse,
  MeetingsService,
  SessionCreate,
  SessionMeta,
  SessionResponse,
  StudentAttendanceResponse,
  StudentAttendanceService,
  StudentResponse,
  TutorMeetingResponse,
  TutorResponse,
  TutorSubjectResponse,
  TutorsService,
} from "client/openapi";
import "./index.css";
import "../Tag/TagChip/index.css";
import { DialogActions, DialogClose, DialogContent } from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import MeetingAttendees from "components/MeetingAttendees";
import { QuoteIcon, TrashIcon } from "@radix-ui/react-icons";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import moment from "moment";
import ViewMeetingDetails from "./Components/viewMeetingDetails";
import { useCallback, useContext, useEffect, useState } from "react";
import EditMeetingDetails from "./Components/editMeetingDetails";
import { PageStatus } from "types";
import CancelDialog from "./Popups/cancel";
import EditableContentHeaderInput from "./Components/editableMeetingHeader";
import DiscardDialog from "./Popups/discard";
import Select from "components/Select";
import SaveChangesDialog from "./Popups/saveChanges";
import MeetingDialogTrigger from "./Components/dialogTrigger";
import Notifications from "util/notifications";
import SuccessDialog from "components/SuccessDialog";
import {
  parseRecurrencePattern,
  parseRecurrencePatternForUntil,
} from "util/parseRecurrencePattern";
import AvailabilitySidebar from "components/AvailabilitySidebar.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import SelectOrganization from "components/SelectOrganization";

export const WEEKDAYS = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
export const defaultDuration = 60;

export default function MeetingDialog({
  isCreate = false,
  role,
  event,
  setEvents,
}: {
  isCreate?: boolean;
  role: Auth0AccountRole;
  event?: MeetingResponse;
  setEvents: (e?: string) => Promise<void>;
}) {
  const {
    currently_selected_role,
    currently_selected_organization,
    organizations_available_to_role,
  } = useContext(OrgRolesAndAccountContext);

  const nearest15Minutes = () => {
    const inputMoment = moment.tz(
      moment().format(),
      "YYYY-MM-DDTHH:mm:ssZ",
      moment.tz.guess()
    );
    const roundedMinutes = Math.round(inputMoment.minutes() / 15) * 15;
    inputMoment.minutes(roundedMinutes);
    inputMoment.seconds(0);
    return inputMoment.format();
  };
  const sessionCreate = {
    tutor_subject_id: event?.subject.id ?? -1,
    org_id:
      event?.subject.org_subject.org_id ??
      (currently_selected_organization as number),
    rate: event?.rate ?? 0,
    name: event?.name ?? event?.subject.org_subject.name ?? "",
    student_ids: event?.students.map((s) => s.id) ?? [],
    tutor_ids: event?.tutors.map((t) => t.id) ?? [],
    sessions: [
      {
        start: event?.start ? moment(event.start).format() : nearest15Minutes(),
        until: event?.associated_recurrence_string
          ? moment(
              parseRecurrencePatternForUntil(event.associated_recurrence_string)
            ).format()
          : undefined,
        duration: event?.duration ?? defaultDuration,
        location: event?.location ?? "",
        frequency: "WEEKLY",
        recurrence_pattern: event?.associated_recurrence_string
          ? event.associated_recurrence_string
          : undefined,
        interval: event?.associated_recurrence_string
          ? parseRecurrencePattern(event.associated_recurrence_string) / 7
          : 0,
        is_recurring: event?.is_recurring ?? false,
      },
    ],
  };

  const [isEditing, setIsEditing] = useState<boolean>(isCreate);
  const isEditable = true;
  const [status, setStatus] = useState<PageStatus>();
  const [currentMeetingInfo, setCurrentMeetingInfo] =
    useState<SessionCreate>(sessionCreate);
  const [students, setStudents] = useState<StudentResponse[]>(
    event?.students ?? []
  );
  const [tutors, setTutors] = useState<TutorMeetingResponse[]>(
    event?.tutors ?? []
  );
  const [subject, setSubject] = useState<TutorSubjectResponse | undefined>(
    event?.subject
  );
  const [availableSubjects, setAvailableSubjects] = useState<
    TutorSubjectResponse[] | undefined
  >();
  const [sessionsInSequence, setSessionsInSequence] = useState<
    SessionResponse[]
  >([]);
  const [recurrencePattern, setRecurrencePattern] = useState<
    string | undefined
  >(
    event?.associated_recurrence_string
      ? event.associated_recurrence_string
      : undefined
  );
  const [isAvailabilitySidebarOpen, setIsAvailabilitySidebarOpen] =
    useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>("");
  const [attendance, setAttendance] = useState<StudentAttendanceResponse[]>([]);
  const [availabilitySidebarDate, setAvailabilitySidebarDate] =
    useState<string>(event?.start ?? new Date().toISOString());

  const addNewSession = () => {
    const newSession = {
      start: nearest15Minutes(),
      duration: 60,
      location: "",
      frequency: "WEEKLY",
      interval: 0,
      is_recurring: false,
    };
    const sessions = currentMeetingInfo.sessions;
    sessions.push(newSession);
    setCurrentMeetingInfo({
      ...currentMeetingInfo,
      sessions: sessions,
    });
  };

  const deleteSession = (index: number) => {
    const sessions = currentMeetingInfo.sessions;
    sessions.splice(index, 1);
    setCurrentMeetingInfo({
      ...currentMeetingInfo,
      sessions: sessions,
    });
  };

  const saveSessionDetails = (session: SessionMeta, index: number) => {
    const updatedSessions: SessionMeta[] =
      currentMeetingInfo?.sessions.map((s, i) => {
        if (i === index) {
          return session;
        }
        return s;
      }) ?? [];
    setCurrentMeetingInfo({ ...currentMeetingInfo, sessions: updatedSessions });
  };

  const discardChanges = () => {
    setCurrentMeetingInfo(sessionCreate);
    setIsEditing(isCreate);
    setSubject(event?.subject);
    setTutors(event?.tutors ?? []);
    setStudents(event?.students ?? []);
    setIsAvailabilitySidebarOpen(false);
  };

  const checkCanSave = () => {
    if (
      currentMeetingInfo === undefined ||
      currentMeetingInfo.name === "" ||
      currentMeetingInfo.tutor_subject_id === -1 ||
      currentMeetingInfo.sessions.length === 0 ||
      currentMeetingInfo.tutor_ids.length === 0 ||
      currentMeetingInfo.student_ids.length === 0 ||
      currentMeetingInfo.tutor_subject_id === undefined
    ) {
      setSaveError("Please fill in all fields");
      return;
    }

    let canSave = true;
    currentMeetingInfo.sessions.forEach((s) => {
      if (
        !s.start ||
        !s.duration ||
        !s.location ||
        (s.interval !== 0 && !s.until)
      ) {
        canSave = false;
      }
    });
    if (!canSave) {
      setSaveError("Please fill in all fields");
      return;
    }
    const stringified = currentMeetingInfo.sessions.map((obj) =>
      JSON.stringify(obj)
    );
    if (new Set(stringified).size !== currentMeetingInfo.sessions.length) {
      setSaveError("Please remove duplicate sessions");
      return;
    }
    setSaveError("");
  };

  const handleRemoveAttendee = (userType: Auth0AccountRole, id: number) => {
    if (currentMeetingInfo === undefined) return;
    if (userType === Auth0AccountRole.ME) {
      const newStudents = students.filter((s) => {
        return s.id !== id;
      });
      handleAttendeesUpdate(Auth0AccountRole.ME, newStudents);
    } else {
      const newTutors = tutors.filter((t) => {
        return t.id !== id;
      });
      handleAttendeesUpdate(Auth0AccountRole.ORG_TUTOR, newTutors);
    }
  };

  const handleAttendeesUpdate = (
    userType: Auth0AccountRole,
    attendees: TutorMeetingResponse[] | StudentResponse[]
  ) => {
    if (userType === Auth0AccountRole.ME) {
      setStudents(attendees as StudentResponse[]);
      const studentIds = attendees.map((s) => s.id);
      setCurrentMeetingInfo({
        ...currentMeetingInfo,
        student_ids: studentIds,
      });
    } else {
      setTutors(attendees as TutorMeetingResponse[]);
      const tutorIds = attendees.map((t) => t.id);
      setCurrentMeetingInfo({
        ...currentMeetingInfo,
        tutor_ids: tutorIds,
      });
    }
  };

  const handleTitleChange = (e) => {
    setCurrentMeetingInfo({
      ...currentMeetingInfo,
      name: e.target.value,
    });
  };

  const handleSubjectChange = (option) => {
    setSubject(option);
    setCurrentMeetingInfo((prevState) => ({
      ...prevState,
      tutor_subject_id: option ? option.id : undefined,
    }));
  };

  useEffect(() => {
    if (
      subject &&
      !tutors.reduce(
        (acc, t) => acc || t.subjects.some((s) => s.id === subject.id),
        false
      )
    ) {
      handleSubjectChange(undefined);
    }

    let subjectOptions: TutorSubjectResponse[] = [];
    let subjectOptionStrings: string[] = [];
    tutors?.forEach((t) => {
      t.subjects.forEach((s) => {
        if (s.active && !subjectOptionStrings.includes(s.org_subject.name)) {
          subjectOptions.push(s);
          subjectOptionStrings.push(s.org_subject.name);
        }
      });
    });
    setAvailableSubjects(subjectOptions);
  }, [subject, tutors]);

  async function createSession() {
    setStatus(PageStatus.LOADING);
    if (currentMeetingInfo === undefined || !subject) return;
    const timezoneConvertedSessions = currentMeetingInfo.sessions.map((s) => {
      return {
        ...s,
        start: moment.utc(s.start).format(),
        until: s.is_recurring ? moment.utc(s.until).format() : undefined,
      };
    });
    await MeetingsService.createSession({
      requestBody: {
        tutor_subject_id: subject.id,
        org_id: currentMeetingInfo.org_id,
        rate: 0,
        name: currentMeetingInfo.name,
        student_ids: currentMeetingInfo.student_ids,
        tutor_ids: currentMeetingInfo.tutor_ids,
        sessions: timezoneConvertedSessions,
      },
    })
      .then(async (result) => {
        await Promise.all(
          result.map((meeting) => {
            return setStudentAttendance(meeting.session_id, meeting.start);
          })
        );
        setEvents(currentMeetingInfo.sessions[0].start);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        Notifications.error(`Error creating meeting: ${e.body.detail}`);
        setStatus(PageStatus.ERROR);
      });
  }

  const setStudentAttendance = async (
    session_id: number,
    meeting_time: string
  ) => {
    await Promise.all(
      currentMeetingInfo.student_ids.map((student_id) => {
        return StudentAttendanceService.createStudentAttendance({
          requestBody: {
            session_id: session_id,
            student_id: student_id,
            meeting_time: meeting_time,
            status: Attendance.PRESENT,
          },
        });
      })
    );
  };

  const getAttendance = useCallback(async () => {
    if (!event) {
      return;
    }

    await StudentAttendanceService.getStudentAttendancesBySession({
      sessionId: event.session_id,
      start: event.start,
    }).then((result) => {
      setAttendance(result);
    });
  }, [event, setAttendance]);

  useEffect(() => {
    getAttendance();
  }, [event, getAttendance]);

  useEffect(() => {
    if (currentMeetingInfo.sessions.length === 0) {
      addNewSession();
    }
    checkCanSave();
  }, [currentMeetingInfo]);

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Meeting created!"
        onClose={() => {
          setStatus(undefined);
        }}
      />
    );
  }

  return (
    <>
      <DialogContent
        className="dialog-content dialog-content--left wide bg-neutral-50 font-montserrat pb-2 min-h-[85vh] overflow-x-hidden"
        alignLeft={true}
        gradientHeader={true}
        onClose={discardChanges}
      >
        <div className="grid grid-cols-11 md:space-x-2 space-y-4 md:space-y-0 -translate-y-4">
          <div className="md:col-span-8 col-span-12 bg-white rounded-lg p-5 pb-4 gap-0 min-h-[70vh] flex flex-col">
            {isCreate &&
              currently_selected_role !== Auth0AccountRole.ORG_ADMIN &&
              organizations_available_to_role &&
              organizations_available_to_role.length > 1 && (
                <div
                  className="w-6/12 p-1"
                  style={{ backgroundColor: "#CFF7C4" }}
                >
                  <SelectOrganization />
                </div>
              )}
            <div className="grid grid-cols-12 p-2 pb-0 items-baseline">
              {!isEditing && (
                <div className="pt-2">
                  <QuoteIcon />
                </div>
              )}
              <div
                className={`block sm:grid sm:grid-cols-7 col-span-${
                  isEditing ? "12" : "11"
                } gap-4 border-b-2 pb-6 border-b-slate-300 items-center mb-4`}
              >
                {isEditing ? (
                  <>
                    <EditableContentHeaderInput
                      id="title"
                      name="title"
                      placeholder="Meeting Title"
                      value={currentMeetingInfo.name ?? ""}
                      setValue={handleTitleChange}
                      classes="col-span-5 w-full"
                    />
                    <div
                      title={
                        tutors?.length === 0
                          ? "Add tutors to add a subject"
                          : ""
                      }
                      className={`${
                        tutors?.length === 0 && "cursor-not-allowed"
                      } col-span-2`}
                    >
                      <Select
                        id="subject"
                        options={availableSubjects}
                        value={subject ? subject : "undefined"}
                        getOptionLabel={(s) => `${s.org_subject.name}`}
                        getOptionValue={(s) => s.id.toString()}
                        placeholder="Subject..."
                        isDisabled={tutors?.length === 0}
                        onChange={handleSubjectChange}
                        title="Add tutors to add subject"
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-span-7 flex justify-between items-center">
                    <h1 className="text-3xl font-bold">
                      {event?.name ?? event?.subject.org_subject.name}
                    </h1>
                    <Tag
                      color={TagColor.GREEN}
                      size={TagSize.NARROW}
                      item={event?.subject.org_subject.name as string}
                    />
                  </div>
                )}
              </div>
            </div>
            {isEditing ? (
              <div className="flex-1">
                {currentMeetingInfo.sessions.map((mtg, index) => {
                  return (
                    <div
                      key={`${mtg.start}--${mtg.duration}--${index}`}
                      className={`mb-4 grid grid-cols-12 items-center`}
                    >
                      <div
                        className={`col-span-${
                          currentMeetingInfo.sessions.length > 1 || index !== 0
                            ? "11"
                            : "12"
                        }`}
                      >
                        <EditMeetingDetails
                          id={index}
                          tutors={tutors}
                          event={event}
                          originalMeetingDetails={mtg}
                          saveMeetingDetails={(meeting) => {
                            saveSessionDetails(meeting, index);
                          }}
                          setIsAvailabilitySidebarOpen={
                            setIsAvailabilitySidebarOpen
                          }
                          setAvailabilitySidebarDate={
                            setAvailabilitySidebarDate
                          }
                        />
                      </div>
                      {(currentMeetingInfo.sessions.length > 1 ||
                        index !== 0) && (
                        <div
                          className="col-span-1 cursor-pointer m-auto"
                          onClick={() => {
                            deleteSession(index);
                          }}
                        >
                          <TrashIcon width="25" height="25" />
                        </div>
                      )}
                    </div>
                  );
                })}
                {isCreate && (
                  <Button
                    color={ButtonColor.GREEN}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      addNewSession();
                    }}
                  >
                    Add another session
                  </Button>
                )}
              </div>
            ) : (
              event && (
                <ViewMeetingDetails
                  event={event}
                  recurrencePattern={recurrencePattern}
                />
              )
            )}
            {isEditable && (
              <div className={`grid grid-cols-12`}>
                <div className="col-span-6 md:col-start-4 md:col-span-3 pr-2">
                  {isCreate ? (
                    <Tooltip>
                      <TooltipTrigger>
                        <span>
                          <DialogActions>
                            <Button
                              size={ButtonSize.SMALL}
                              color={
                                saveError === ""
                                  ? ButtonColor.GREEN
                                  : ButtonColor.GRAY
                              }
                              onClick={() => {
                                createSession();
                              }}
                              disabled={
                                saveError !== "" &&
                                status !== PageStatus.LOADING
                              }
                              extraClasses={
                                saveError === "" ? "" : "pointer-events-none"
                              }
                            >
                              {status === PageStatus.LOADING ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <>Confirm</>
                              )}
                            </Button>
                          </DialogActions>
                        </span>
                      </TooltipTrigger>
                      {saveError !== "" && (
                        <TooltipContent>{saveError}</TooltipContent>
                      )}
                    </Tooltip>
                  ) : isEditing && currentMeetingInfo && event ? (
                    <Tooltip>
                      <TooltipTrigger>
                        <span>
                          <MeetingDialogTrigger
                            label={"Save"}
                            dialog={
                              <SaveChangesDialog
                                event={event}
                                originalMeetingInfo={sessionCreate}
                                currentMeetingInfo={currentMeetingInfo}
                                originalTutors={event.tutors}
                                originalStudents={event.students}
                                tutors={tutors}
                                students={students}
                                continueFunction={() => {
                                  setIsEditing(false);
                                  setEvents();
                                }}
                                allSessions={sessionsInSequence}
                                reccurencePattern={recurrencePattern}
                                subject={subject}
                              />
                            }
                            extraClasses={`mt-5 w-full bg-white ${
                              saveError === "" ? "" : "pointer-events-none"
                            }`}
                            size={ButtonSize.SMALL}
                            color={
                              saveError === ""
                                ? ButtonColor.GREEN
                                : ButtonColor.GRAY
                            }
                            disabled={saveError !== ""}
                            loading={status === PageStatus.LOADING}
                          />
                        </span>
                      </TooltipTrigger>
                      {saveError !== "" && (
                        <TooltipContent>{saveError}</TooltipContent>
                      )}
                    </Tooltip>
                  ) : (
                    <DialogActions>
                      <Button
                        size={ButtonSize.SMALL}
                        color={
                          currently_selected_role ===
                            Auth0AccountRole.ORG_ADMIN ||
                          currently_selected_role === Auth0AccountRole.ORG_TUTOR
                            ? ButtonColor.GREEN
                            : ButtonColor.DARK_GRAY
                        }
                        disabled={
                          currently_selected_role === Auth0AccountRole.ME
                        }
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </Button>
                    </DialogActions>
                  )}
                </div>
                <div className="col-span-6 md:col-span-4 lg:col-span-3">
                  {isCreate ? (
                    <DialogActions>
                      <DialogClose asChild>
                        <Button
                          size={ButtonSize.SMALL}
                          color={ButtonColor.BLACK}
                          fill={ButtonFill.HOLLOW}
                          onClick={discardChanges}
                        >
                          Discard Changes
                        </Button>
                      </DialogClose>
                    </DialogActions>
                  ) : isEditing ? (
                    <MeetingDialogTrigger
                      label={"Discard Changes"}
                      dialog={
                        <DiscardDialog continueFunction={discardChanges} />
                      }
                      extraClasses="mt-5 w-full bg-white"
                      size={ButtonSize.SMALL}
                      color={ButtonColor.BLACK}
                      fill={ButtonFill.HOLLOW}
                    />
                  ) : (
                    event && (
                      <MeetingDialogTrigger
                        label={"Cancel Meeting"}
                        dialog={
                          <CancelDialog
                            event={event}
                            continueFunction={() => {
                              setIsEditing(false);
                              setEvents();
                            }}
                            allSessions={sessionsInSequence}
                          />
                        }
                        extraClasses="mt-5 w-full bg-white"
                        size={ButtonSize.SMALL}
                        color={ButtonColor.BLACK}
                        fill={ButtonFill.HOLLOW}
                        disabled={
                          currently_selected_role === Auth0AccountRole.ME
                        }
                      />
                    )
                  )}
                </div>
              </div>
            )}
          </div>

          {isAvailabilitySidebarOpen && availabilitySidebarDate ? (
            <AvailabilitySidebar
              availabilitySidebarDate={availabilitySidebarDate}
              event={event}
              tutors={tutors}
              role={role}
              editing={isEditing}
              creating={isCreate}
              removeAttendee={handleRemoveAttendee}
              updateAttendees={handleAttendeesUpdate}
              isAvailabilitySidebarOpen={isAvailabilitySidebarOpen}
              setIsAvailabilitySidebarOpen={setIsAvailabilitySidebarOpen}
              currentMeetingInfo={currentMeetingInfo}
              attendance={attendance}
            />
          ) : (
            <MeetingAttendees
              role={role}
              editing={isEditing}
              creating={isCreate}
              event={event}
              tutors={tutors}
              students={students}
              removeAttendee={handleRemoveAttendee}
              updateAttendees={handleAttendeesUpdate}
              attendance={attendance}
              setAttendance={setAttendance}
            />
          )}
        </div>
      </DialogContent>
    </>
  );
}
