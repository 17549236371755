/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterBy } from "../models/FilterBy";
import type { GroupBy } from "../models/GroupBy";
import type { SortBy } from "../models/SortBy";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ExportsService {
  /**
   * Export Students
   * Get CSV export of student account data and org notes
   * for all students in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudents({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/students",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Students Meetings
   * Get CSV export of all students' meetings in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudentsMeetings({
    orgId,
    start,
    until,
    tz = "UTC",
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/meetings/students",
      query: {
        org_id: orgId,
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Student
   * Get CSV export of student account data and org notes
   * for the given student in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudent({
    studentId,
    orgId,
  }: {
    studentId: number;
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Student Meetings
   * Get CSV export of all meetings with the given student in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudentMeetings({
    studentId,
    orgId,
    start,
    until,
    tz = "UTC",
  }: {
    studentId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/meetings/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Tutor Meetings
   * Get CSV export of all meetings with the given tutor in the given organization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportTutorMeetings({
    tutorId,
    orgId,
    start,
    until,
    tz = "UTC",
  }: {
    tutorId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/meetings/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
        tz: tz,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Payroll Data
   * Get CSV export of sessions/payroll with the given organization with filters.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportPayrollData({
    orgId,
    start,
    until,
    tz = "UTC",
    sortBy,
    filterBy,
    groupBy,
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
    tz?: string;
    sortBy?: SortBy;
    filterBy?: FilterBy;
    groupBy?: GroupBy;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/payroll",
      query: {
        org_id: orgId,
        start: start,
        until: until,
        tz: tz,
        sort_by: sortBy,
        filter_by: filterBy,
        group_by: groupBy,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Student Transactions
   * Export all transactions a student has with the given org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportStudentTransactions({
    studentId,
    orgId,
    start,
    until,
  }: {
    studentId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/transactions/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Export Organization Transactions
   * Export all transactions given an org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static exportOrganizationTransactions({
    orgId,
    start,
    until,
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/exports/transactions/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
