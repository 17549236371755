import {
  ApiError,
  Auth0AccountRole,
  NoteResponse,
  NotesService,
} from "client/openapi";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import { getButtonRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import "../NotesDialog/index.css";

function EditNoteDialog({
  role,
  note,
  notes,
  setOpen,
  setNotes,
}: {
  role: Auth0AccountRole | null;
  note: NoteResponse;
  notes: NoteResponse[];
  setOpen: Function;
  setNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [updatedNote, setUpdatedNote] = useState<NoteResponse>(note);

  async function editNote() {
    const updatednotes = notes;
    updatednotes.map((n) => {
      if (n.id === updatedNote.id) {
        n.content = updatedNote.content;
      }
    });
    setNotes(updatednotes);
  }

  async function updateNote() {
    await NotesService.updateNote({
      noteId: note.id,
      requestBody: {
        content: updatedNote.content,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Note updated!`);
        editNote();
        setOpen(undefined);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to save edited note" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleUpdate(ev) {
    setUpdatedNote({ ...updatedNote, content: ev.target.value });
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    await updateNote();
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent
        className="dialog-content"
        onClose={() => setOpen(undefined)}
      >
        <div className="account-notes--dialog">
          <h2 className="account-notes--title">Edit Note</h2>

          <form onSubmit={handleSubmit}>
            <label htmlFor="new_note" aria-label="Note">
              <textarea
                id="new_note"
                name="new_note"
                className="input"
                value={updatedNote.content}
                onChange={handleUpdate}
              />
            </label>

            <DialogActions>
              <>
                <DialogAction
                  primary={true}
                  color={getButtonRoleColor(role)}
                  type="submit"
                  disabled={status === PageStatus.LOADING}
                >
                  Save Note &rarr;
                </DialogAction>

                <DialogClose asChild>
                  <DialogAction
                    color={getButtonRoleColor(role)}
                    onClick={() => setOpen(undefined)}
                    disabled={status === PageStatus.LOADING}
                  >
                    Cancel
                  </DialogAction>
                </DialogClose>
              </>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </>
  );
}

export default EditNoteDialog;
