export enum SubjectCategory {
  ENGLISH = "English",
  FOREIGN_LANGUAGE = "Foreign Language",
  MATH = "Math",
  SCIENCE = "Science",
  SOCIAL_STUDIES = "Social Studies",
  OTHER = "Other",
}

export enum SubjectLevel {
  HONORS = "Honors",
  AP = "AP",
  IB = "IB",
}

export interface SubjectBase {
  name: string | null;
  category: SubjectCategory | null;
  level: SubjectLevel | null;
  default_price_for_students: number;
  default_pay_for_tutors: number;
}

export interface Subject {
  id: number;
  name: string;
  category: SubjectCategory;
  level: SubjectLevel | null;
  active: boolean;

  created_at: Date;
  modified_at: Date;
}
