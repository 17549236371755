import FeatureItem from "./FeatureItem";
import "styles/LandingPage/index.css";

export default function FeatureList() {
  return (
    <div className="content">
      <div className="py-12 font-bold text-center">
        <h2
          className="text-7xl md:text-8xl landing-page--text-gradient"
          style={{ fontFamily: "Montserrat" }}
        >
          Features
        </h2>
        <p
          className="mt-6 text-3xl md:text-4xl"
          style={{ fontFamily: "Montserrat" }}
        >
          Flexible. Configurable. Fit to your needs.
        </p>
      </div>

      <div className="grid mx-auto max-w-6xl md:grid-cols-2 px-5 md:py-10 gap-x-40 gap-y-20">
        <FeatureItem title="Manage Scheduling">
          Book and manage tutoring sessions; see all activities in your
          organization in one place. Sync data across Google Calendar, Outlook,
          and Apple Calendar.
        </FeatureItem>

        <FeatureItem title="Streamline Communication">
          Message parents, students, and tutors at the click of a button. Send
          texts and emails directly to their phone and computer.
        </FeatureItem>

        <FeatureItem title="Prove your program has impact">
          Track grades, attendance, or any other metric of your choosing.
          Generate line charts, scatter plots, and regression analyses
          effortlessly.
        </FeatureItem>

        <FeatureItem title="Run Billing and Payroll">
          Bill customers, store payment information, track revenue, and export
          employee hours to your payroll provider of choice.
        </FeatureItem>

        <FeatureItem title="Track more than tutoring">
          Log parent check-ins, sporting events, consulting sessions,
          administrative tasks - any activity that your organization
          facilitates.
        </FeatureItem>

        <FeatureItem title="Work with multiple students">
          Schedule a session with one student or the entire classroom. Manage
          group tutoring sessions, mark and record attendance as needed.
        </FeatureItem>
      </div>
    </div>
  );
}
