import { useAuth0 } from "@auth0/auth0-react";
import { useAPI } from "api";
import {
  AccountsService,
  ApiError,
  BooleanResponse,
  OpenAPI,
  OrganizationsService,
  USState,
} from "client/openapi";
import SignupWizard from "components/Signup/SignupWizard";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  APIResponse,
  AccountRoles,
  AccountStatus,
  Signup as GeneralSignup,
  PageStatus,
} from "types";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import Notifications from "util/notifications";
import nullIfEmpty from "util/nullIfEmpty";

const emptySignup: GeneralSignup = {
  email: "",
  password: "",
  confirm_password: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  name: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  zip: "",
};

function Signup({ accountType }) {
  // set up state
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const { account, updateOrgRolesAndAccount } = useContext(
    OrgRolesAndAccountContext
  );
  const [status, setStatus] = useState<PageStatus>(PageStatus.SUCCESS);
  const [type, setType] = useState<AccountRoles>(
    accountType || AccountRoles.DEFAULT
  );
  const [step, setStep] = useState(
    accountType === AccountRoles.DEFAULT ? 0 : 1
  );
  const [signupInfo, setSignupInfo] = useState<GeneralSignup>(
    structuredClone(emptySignup)
  );
  const [errors, setErrors] = useState<GeneralSignup>(
    structuredClone(emptySignup)
  );
  const [noErrors, setNoErrors] = useState<boolean>(true);

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [error, setError] = useState<APIResponse>();
  const { data: registered } = useAPI<BooleanResponse>({
    endpoint: `accounts/registered`,
    authenticate: true,
    usesAcct: false,
  });
  const navigate = useNavigate();

  async function createAccount() {
    console.log(noErrors);
    if (!noErrors) {
      return;
    }
    setStatus(PageStatus.LOADING);

    AccountsService.createAccount({
      requestBody: {
        email: user?.email as string,
        phone_number: nullIfEmpty(signupInfo.phone_number),
        first_name: signupInfo.first_name,
        last_name: signupInfo.last_name,
        reference_id: user?.sub,
        status: AccountStatus.ACTIVE,
      },
    })
      .then((accountResult) => {
        setStatus(PageStatus.SUCCESS);
        updateOrgRolesAndAccount({ account: accountResult });

        switch (type) {
          case AccountRoles.STUDENT:
            // This function is broken, see comment below.
            createStudent(accountResult.reference_id);
            break;
          case AccountRoles.TUTOR:
            setStep(4);
            break;
          case AccountRoles.ORGANIZATION:
            setStep(2);
            break;
        }
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({
          error: "Unable to complete creating your account at this time!",
        });
        console.error(`Error: ${e.body}`);
      });
  }

  // This function is totally deprecated and does not work at all.
  // It's also not called in the Sign Up flow, as right now
  // users can only sign up as Organizations.
  async function createStudent(accountId: string) {
    setStatus(PageStatus.LOADING);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_DOMAIN}/students`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            account_id: accountId,
          }),
        }
      );

      const studentResult = await response.json();

      if (studentResult?.detail) {
        studentResult.message =
          typeof studentResult?.detail === "string"
            ? studentResult.detail
            : "Unable to create student";

        setStatus(PageStatus.ERROR);
        setError(studentResult as APIResponse);
      } else {
        getAccessTokenSilently({
          cacheMode: "off",
        });
        setStatus(PageStatus.SUCCESS);
        setStep(4);
        Notifications.success(`Welcome, ${signupInfo.first_name}!`);
      }
    } catch (e: any) {
      setStatus(PageStatus.ERROR);
      setError({ error: "An unexpected error occurred" });
      console.error(`Error (#${e?.code}): ${e?.message}`);
    }
  }

  // This function is totally deprecated and does not work at all.
  // It's also not called in the Sign Up flow, as right now
  // users can only sign up as Organizations.
  async function createTutor(organizationId: number | null) {
    if (!account) {
      return;
    }

    setStatus(PageStatus.LOADING);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_DOMAIN}/tutors`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            account_id: account.reference_id,
            organization_id:
              type === AccountRoles.ORGANIZATION ? organizationId : null,
            address_line_1: signupInfo.address_line_1,
            address_line_2: signupInfo.address_line_2,
            city: signupInfo.city,
            state: signupInfo.state,
            zip: signupInfo.zip,
          }),
        }
      );

      const tutorResult = await response.json();

      if (tutorResult?.detail) {
        tutorResult.message =
          typeof tutorResult?.detail === "string"
            ? tutorResult.detail
            : "Unable to create tutor";

        setStatus(PageStatus.ERROR);
        setError(tutorResult as APIResponse);
      } else {
        getAccessTokenSilently({
          cacheMode: "off",
        });
        setStatus(PageStatus.SUCCESS);
        setStep(4);
        Notifications.success(`Welcome, ${signupInfo.first_name}!`);
      }
    } catch (e: any) {
      setStatus(PageStatus.ERROR);
      setError({ error: "An unexpected error occurred" });
      console.error(`Error (#${e?.code}): ${e?.message}`);
    }
  }

  async function createOrganization() {
    if (!account) {
      return;
    }

    setStatus(PageStatus.LOADING);

    OrganizationsService.createOrganization({
      requestBody: {
        contact_id: account.reference_id,
        name: signupInfo.name,
        email: account.email,
        address_line_1: signupInfo.address_line_1,
        address_line_2: signupInfo.address_line_2,
        city: signupInfo.city,
        state: signupInfo.state as USState,
        zip: signupInfo.zip,
      },
    })
      .then(() => {
        setStep(4);
        Notifications.success(`Welcome, ${signupInfo.first_name}!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to create organization at this time!" });
        console.error(`Error: ${e.body}`);
      });
  }

  function validAddress(info: GeneralSignup): boolean {
    setErrors(structuredClone(emptySignup));

    if (!Object.values(USState).includes(info.state as USState)) {
      setErrors({
        ...errors,
        state:
          "Please enter a valid US State using its two-letter abbreviation, such as CO or MA.",
      });

      return false;
    }

    return true;
  }

  function validatePhoneNumber(phone_number: string | undefined) {
    const regex = new RegExp(
      "^(\\+?\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
    );
    const valid =
      !phone_number || phone_number.length === 0 || regex.test(phone_number);
    setErrors({
      ...errors,
      phone_number: valid
        ? ""
        : "Please enter a valid 10-digit phone number (country code optional)",
    });
  }

  let handleUpdate = (ev: ChangeEvent<HTMLInputElement>) => {
    const currentSignupInfo = { ...signupInfo };
    currentSignupInfo[ev.target.name] = ev.target.value;
    setSignupInfo(currentSignupInfo);

    validatePhoneNumber(currentSignupInfo.phone_number);

    if (submitAttempted) {
      switch (step) {
        case 1:
          break;
        case 4:
          validAddress(currentSignupInfo);
          break;
        default:
          break;
      }
    }
  };

  let submitCredentials = async (ev: SubmitEvent) => {
    ev.preventDefault();
    setSubmitAttempted(true);
  };

  let submitContact = async (ev: SubmitEvent) => {
    ev.preventDefault();
    setSubmitAttempted(true);
    await createAccount();
    setSubmitAttempted(false);
  };

  let submitOrgDetails = async (ev: SubmitEvent) => {
    ev.preventDefault();
    setStep(3);
  };

  let submitAddress = async (ev: SubmitEvent) => {
    ev.preventDefault();
    setSubmitAttempted(true);

    if (validAddress(signupInfo)) {
      if (type === AccountRoles.ORGANIZATION) {
        await createOrganization();
      } else {
        await createTutor(null);
      }
    }

    setSubmitAttempted(false);
  };

  useEffect(() => {
    if (account && step <= 1 && account?.email !== account.email) {
      updateOrgRolesAndAccount({ account: account });
      setStep(0);
    }
  }, [account]);

  useEffect(() => {
    if (registered?.response) {
      navigate("/dashboard");
    }
  }, [registered]);

  useEffect(() => {
    (async () => {
      if (!isLoading && !isAuthenticated) {
        await loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
            redirect_uri: `${process.env.REACT_APP_FRONTEND_DOMAIN}/signup`,
          },
        });
      }
    })();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (step === 1 && account && !registered?.response) {
      setStep(2);
    }
  }, [step]);

  useEffect(() => {
    error?.message && Notifications.error(error.message);
  }, [error]);

  useEffect(() => {
    // Log each error
    Object.entries(errors).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        console.log(`Error in ${key}: ${value}`);
      }
    });

    // Check if there are no errors
    const noErrors = Object.values(errors).every((e) => e === null || e === "");
    setNoErrors(noErrors);
  }, [errors]);

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>

      <SignupWizard
        account={account}
        step={step}
        setStep={setStep}
        type={type}
        setType={setType}
        values={signupInfo}
        handleUpdate={handleUpdate}
        submitCredentials={submitCredentials}
        submitOrgDetails={submitOrgDetails}
        submitContact={submitContact}
        submitAddress={submitAddress}
        loading={status === PageStatus.LOADING}
        errors={errors}
      />
    </>
  );
}

Signup.defaultProps = {
  accountType: AccountRoles.DEFAULT,
};

export default Signup;
