/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TutorTestScoreCreate } from "../models/TutorTestScoreCreate";
import type { TutorTestScoreResponse } from "../models/TutorTestScoreResponse";
import type { TutorTestScoreUpdate } from "../models/TutorTestScoreUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TutorTestScoresService {
  /**
   * Get Scores By Org
   * Get all tutor scores for a specified organization from the database.
   * @returns TutorTestScoreResponse Successful Response
   * @throws ApiError
   */
  public static getScoresByOrg({
    orgId,
    skip,
    limit = 100,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<TutorTestScoreResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor_test_scores/by_organization/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Scores By Tutor
   * Get all tutor scores for a specified tutor from the database.
   * @returns TutorTestScoreResponse Successful Response
   * @throws ApiError
   */
  public static getScoresByTutor({
    tutorId,
    skip,
    limit = 100,
  }: {
    tutorId: number;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<TutorTestScoreResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor_test_scores/by_tutor/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Score
   * Create a new score and returns the resulting score from the database.
   * @returns TutorTestScoreResponse Successful Response
   * @throws ApiError
   */
  public static createScore({
    requestBody,
  }: {
    requestBody: TutorTestScoreCreate;
  }): CancelablePromise<TutorTestScoreResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutor_test_scores",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Score
   * Update a score by its ID using specified attributes
   * within TutorTestScoreUpdate to update the database and
   * return the updated score.
   * @returns TutorTestScoreResponse Successful Response
   * @throws ApiError
   */
  public static updateScore({
    scoreId,
    requestBody,
  }: {
    scoreId: number;
    requestBody: TutorTestScoreUpdate;
  }): CancelablePromise<TutorTestScoreResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutor_test_scores/{score_id}",
      path: {
        score_id: scoreId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
