import { useAuth0 } from "@auth0/auth0-react";
import { StudentResponse, StudentsService } from "client/openapi";
import {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

interface StudentState {
  current_student: StudentResponse | null | undefined;
  available_students: StudentResponse[] | null | undefined;
  need_to_get_latest_student_info: boolean;
}

type CurrStudentContextInterface = StudentState & {
  update_student_state: (updates: Partial<StudentState>) => void;
};

const defaultStudentState: StudentState = {
  current_student: null,
  available_students: null,
  need_to_get_latest_student_info: false,
};

export const StudentContext = createContext<CurrStudentContextInterface>({
  ...defaultStudentState,
  update_student_state: () => {},
});

function getStudentByCurrentlySelectedOrg(
  students: StudentResponse[],
  orgId: number
): StudentResponse | undefined {
  return students.find((student) => student.org_id === orgId);
}

export const StudentProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0();
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const [available_students, set_available_students] =
    useState<StudentResponse[]>();
  const [current_student, set_current_student] = useState<StudentResponse>();
  const [studentState, setStudentState] =
    useState<StudentState>(defaultStudentState);

  const update_student_state = (updates: Partial<StudentState>) => {
    setStudentState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  // fetch the current account's students
  const fetchStudents = useCallback(() => {
    StudentsService.getStudentsMe()
      .then((students) => {
        set_available_students(students);
        update_student_state({ need_to_get_latest_student_info: false });
      })
      .catch((err) => {
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  }, []);

  const setCurrentStudent = useCallback(
    (students: StudentResponse[]) => {
      set_current_student(
        getStudentByCurrentlySelectedOrg(
          students,
          currently_selected_organization as number
        )
      );
    },
    [currently_selected_organization]
  );

  useEffect(() => {
    if (user) {
      if (!available_students || studentState.need_to_get_latest_student_info) {
        fetchStudents();
      }

      if (
        available_students &&
        currently_selected_organization &&
        !studentState.need_to_get_latest_student_info
      ) {
        setCurrentStudent(available_students);
      }

      if (
        available_students &&
        current_student &&
        !studentState.need_to_get_latest_student_info
      ) {
        const newStudentState: StudentState = {
          current_student: current_student,
          available_students: available_students,
          need_to_get_latest_student_info: false,
        };

        setStudentState(newStudentState);
      }
    }
  }, [
    available_students,
    current_student,
    currently_selected_organization,
    fetchStudents,
    setCurrentStudent,
    studentState.need_to_get_latest_student_info,
    user,
  ]);

  return (
    <StudentContext.Provider
      value={{ ...studentState, update_student_state: update_student_state }}
    >
      {children}
    </StudentContext.Provider>
  );
};
