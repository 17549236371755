import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { useState } from "react";

// Generic checkbox
export default function CheckboxInput({
  id,
  checked,
  onCheck,
}: {
  id: string;
  checked: boolean;
  onCheck: (checked: boolean) => void;
}) {
  const [isChecked, setIsChecked] = useState(checked);

  // Handle click to update internal state and call the onCheck callback
  const handleClick = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onCheck(newCheckedState); // Call onCheck only when user clicks
  };

  return (
    <>
      {isChecked ? (
        <div
          id={id}
          className="text-green cursor-pointer"
          onClick={handleClick}
        >
          <CheckCircledIcon width="25" height="25" />
        </div>
      ) : (
        <div
          id={id}
          className="text-red-500 cursor-pointer"
          onClick={handleClick}
        >
          <CrossCircledIcon width="25" height="25" />
        </div>
      )}
    </>
  );
}
