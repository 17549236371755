import "./index.css";

import { IoMdInformationCircleOutline } from "react-icons/io";
import { LuLineChart } from "react-icons/lu";
import { Dialog, DialogTrigger } from "components/Dialog";
import Trends from "../Modals/Trends";
import { StudentOrgTransactionResponse, StudentResponse } from "client/openapi";
import { LuClipboardCheck } from "react-icons/lu";
import PaymentPolicies from "../Modals/Policies";

function Buttons({
  transactions,
  orgId,
}: {
  transactions: StudentOrgTransactionResponse[];
  orgId: number;
}) {
  return (
    <div className="flex md:grid mx-4 md:grid-cols-1 grid-cols-1 lg:gap-8 md:gap-6">
      {/* <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <IoMdInformationCircleOutline color="black" size="42" />
          <span className="mt-2">Banking information</span>
        </DialogTrigger>
      </Dialog> */}
      {/* <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <LuLineChart color="black" size="42" />
          <span className="mt-2">View payment trends</span>
        </DialogTrigger>
        <Trends transactions={transactions} />
      </Dialog> */}
      <Dialog>
        <DialogTrigger className="payments-button col-span-1 m-auto">
          <LuClipboardCheck color="black" size="42" />
          <span className="mt-2">Set payment policies</span>
        </DialogTrigger>
        <PaymentPolicies orgId={orgId} />
      </Dialog>
    </div>
  );
}
export default Buttons;
