export enum RawAccountRoles {
  STUDENT = 25,
  TUTOR = 20,
  ADMIN = 15,
  OWNER = 10,
  MODERATOR = 5,
}

export enum AccountRoles {
  DEFAULT = "default",
  STUDENT = "student",
  TUTOR = "tutor",
  ORGANIZATION = "organization",
}

export enum UserTypes {
  STUDENT = "Student",
  TUTOR = "Tutor",
}
