import React from "react";
import "./index.css";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import moment from "moment";
import { FaClock } from "react-icons/fa6";
import { Dialog, DialogTrigger } from "components/Dialog";
import RefundModal from "../Modals/Actions/Refund";
import RetryModal from "../Modals/Actions/Retry";
import CancelModal from "../Modals/Actions/Cancel";
import {
  ACHResponse,
  BillingType,
  CreditCardResponse,
  OrgTransactionType,
  StudentOrgTransactionResponse,
  StudentResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";

export const billingType = [
  { value: "REFUND", label: "Refund" },
  { value: "UPFRONT", label: "Upfront charge" },
  { value: "DIRECT", label: "Direct charge" },
  { value: "RESCHEDULING_FEE", label: "Rescheduling Fee" },
  { value: "CANCELLATION_FEE", label: "Cancellation Fee" },
  { value: "ABSENCE_FEE", label: "Absence Fee" },
  { value: "BUNDLE", label: "Auto-pay charge, bundled meetings" },
  {
    value: "MEETING_CHARGED_INDIVIDUALLY",
    label: "Auto-pay charge, individual meeting",
  },
];

const statusToAction = [
  { value: "SUCCESSFUL", label: "Refund" },
  { value: "FAILED", label: "Retry" },
];

function Transactions({
  transactions,
  student,
  orgId,
}: {
  transactions: StudentOrgTransactionResponse[];
  student: StudentResponse;
  orgId: number;
}) {
  return (
    <div className="transactions-container">
      <table className="table">
        <thead className="tableHead border-2 border-b-0 border-gray-300">
          <tr>
            <th
              style={{ width: "27.5%", paddingLeft: "20px" }}
              className="text-xs md:text-sm lg:text-base p-2"
            >
              Date
            </th>
            <th
              style={{ width: "18.5%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Amount
            </th>
            <th
              style={{ width: "20.5%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Service
            </th>
            <th
              style={{ width: "14.0%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Type
            </th>
            <th
              style={{ width: "10.5%" }}
              className="centeredTableData text-xs md:text-sm lg:text-base"
            >
              Status
            </th>
            <th
              style={{ width: "10.5%" }}
              className="centeredTableData text-xs md:text-sm lg:text-base"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => {
            if (
              transaction.transaction_type ===
                OrgTransactionType.STUDENT_ORG_TRANSACTION &&
              transaction.billing_type !== BillingType.MEETING_UNCHARGED &&
              transaction.billing_type !==
                BillingType.MEETING_CHARGED_IN_BUNDLE &&
              transaction.billing_type !== BillingType.MEETING_PAID_WITH_CREDITS
            ) {
              const action = statusToAction.find(
                (s) =>
                  transaction.status.includes(s.value) &&
                  !transaction.status.includes("REFUND_SUCCESSFUL")
              );
              return (
                <tr
                  key={index}
                  className={`tableRow border-2 border-gray-300 ${
                    index === transactions.length - 1 && "!border-b-2 rounded-b"
                  } ${index === 0 && "border-t-0"}`}
                >
                  <td
                    className="text-xs md:text-sm "
                    style={{ paddingLeft: "20px" }}
                  >
                    {moment(transaction.timestamp).format("MM/DD/YYYY")}
                  </td>
                  <td className="text-xs md:text-sm ">
                    $
                    {parseFloat((transaction.amount / 100).toString()).toFixed(
                      2
                    )}
                  </td>
                  <td className="text-xs md:text-sm ">{transaction.reason}</td>
                  <td className="text-xs md:text-sm">
                    {billingType.find(
                      (t) => transaction.billing_type === t.value
                    )?.label + ` (${transaction.payment_method_type})`}
                  </td>
                  <td>
                    {transaction.status.includes("SUCCESSFUL") ? (
                      transaction.status.includes("REFUND") ? (
                        <IoCheckmarkCircle className="mx-auto purple-mark" />
                      ) : (
                        <IoCheckmarkCircle className="mx-auto green-mark" />
                      )
                    ) : transaction.status.includes("PENDING") ? (
                      <FaClock
                        className="mx-auto"
                        style={{ color: "#FFB341", fontSize: "20px" }}
                      />
                    ) : transaction.status.includes("FAILED") ? (
                      <IoCloseCircle className="mx-auto red-mark" />
                    ) : (
                      <IoCloseCircle className="mx-auto black" />
                    )}
                  </td>
                  <td className="text-xs md:text-sm centeredTableData">
                    {((action?.label === "Refund" && !transaction.refunded) ||
                      (action?.label === "Retry" &&
                        !transaction.retried &&
                        transaction.billing_type !== "REFUND")) && (
                      <Dialog>
                        <DialogTrigger>
                          <Button
                            color={ButtonColor.SKYBLUE}
                            size={ButtonSize.EXTRA_SMALL}
                            fill={ButtonFill.HOLLOW}
                          >
                            {action?.label}
                          </Button>
                        </DialogTrigger>

                        {action?.label === "Refund" && (
                          <RefundModal
                            transaction={transaction}
                            student={student}
                            orgId={orgId}
                          />
                        )}

                        {action?.label === "Retry" && (
                          <RetryModal
                            transaction={transaction}
                            student={student}
                            orgId={orgId}
                          />
                        )}
                      </Dialog>
                    )}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Transactions;
