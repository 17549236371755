export default function TextInput({
  id,
  name,
  placeholder,
  value,
  setValue,
  disabled,
  required,
  classes,
}: {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  setValue: (value: any) => void;
  disabled?: boolean;
  required?: boolean;
  classes?: string;
}) {
  return (
    <>
      <label htmlFor={name} className="input-label">
        <input
          id={id}
          name={name}
          type="text"
          className={`p-2 m-0 input w-full ${classes}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e)}
          disabled={disabled}
          required={required}
        />
      </label>
    </>
  );
}
