/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the status of an invitation to an org.
 */
export enum InviteStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DENIED = "denied",
}
