import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import PaymentMethods from "./PaymentMethods";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { ParentResponse, StudentResponse } from "client/openapi";

export default function PaymentInformationModal({
  studentIfInitiatedByAdmin,
  parentIfInitiatedByAdmin,
  initiatedByStudentOrParentDirectly,
}: {
  studentIfInitiatedByAdmin?: StudentResponse;
  parentIfInitiatedByAdmin?: ParentResponse;
  initiatedByStudentOrParentDirectly?: boolean;
}) {
  return (
    <DialogContent className="dialog-content max-w-[800px]">
      <div className="mt-3 font-semibold text-center header text-lg">
        Payment Information
      </div>

      <div className="border rounded-lg border-gray-400 px-6 py-4 mt-6">
        <PaymentMethods
          showAddCardButton
          showAction
          studentIfInitiatedByAdmin={studentIfInitiatedByAdmin}
          parentIfInitiatedByAdmin={parentIfInitiatedByAdmin}
          initiatedByStudentOrParentDirectly={
            initiatedByStudentOrParentDirectly
          }
        />
      </div>

      <DialogActions>
        <DialogClose asChild>
          <DialogAction color={ButtonColor.PURPLE}>Close</DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
