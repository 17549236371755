import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import moment from "moment";
import { useEffect, useState } from "react";
import { RRule } from "rrule";
import RadioInput from "components/Inputs/RadioInput";
import { APIResponse, PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import {
  BookmarkFilledIcon,
  BookmarkIcon,
  CalendarIcon,
  ClockIcon,
  SewingPinFilledIcon,
  TextIcon,
} from "@radix-ui/react-icons";
import {
  ApiError,
  CancelablePromise,
  MeetingResponse,
  MeetingsService,
  SessionCreate,
  SessionMeta,
  SessionResponse,
  StudentResponse,
  TutorMeetingResponse,
  TutorSubjectResponse,
} from "client/openapi";
import DateInput from "components/Inputs/DateInput";
import { CHANGE_OPTIONS } from "./changeOptions";
import Notifications from "util/notifications";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import { WEEKDAYS } from "../index";
import {
  parseRecurrencePattern,
  parseRecurrencePatternForUntil,
} from "util/parseRecurrencePattern";
import { concatenateTutorSubject } from "util/concatenateSubject";
import { convertLocaltoUTC } from "util/timezone";

const TIME_FORMAT = "h:mma";
const FULL_TIME_FORMAT = "HH:mm:ss";

export default function SaveChangesDialog({
  event,
  originalMeetingInfo,
  currentMeetingInfo,
  originalTutors,
  originalStudents,
  tutors,
  students,
  continueFunction,
  allSessions,
  reccurencePattern,
  subject,
}: {
  event: MeetingResponse;
  originalMeetingInfo: SessionCreate;
  currentMeetingInfo: SessionCreate;
  originalTutors: TutorMeetingResponse[];
  originalStudents: StudentResponse[];
  tutors: TutorMeetingResponse[];
  students: StudentResponse[];
  continueFunction: () => void;
  allSessions: SessionResponse[];
  reccurencePattern?: string;
  subject?: TutorSubjectResponse;
}) {
  const [status, setStatus] = useState<PageStatus>();

  // scheduling changes
  const [schedulingChangeMade, setSchedulingChangeMade] =
    useState<boolean>(false);
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false);
  const [nonEndDateChanged, setNonEndDateChanged] = useState<boolean>(false);
  const [frequencyChanged, setFrequencyChanged] = useState<boolean>(false);
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [timeChanged, setTimeChanged] = useState<boolean>(false);
  const [originalEndTime, setOriginalEndTime] = useState<string>("");
  const [newEndTime, setNewEndTime] = useState<string>("");
  const [originalDate, setOriginalDate] = useState<string>("");
  const [newDate, setNewDate] = useState<string>("");
  const [originalTime, setOriginalTime] = useState<string>("");
  const [newTime, setNewTime] = useState<string>("");

  // meeting changes
  const [meetingChangeMade, setMeetingChangeMade] = useState<boolean>(false);
  const [locationChanged, setLocationChanged] = useState<boolean>(false);
  const [subjectChanged, setSubjectChanged] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState<boolean>(false);
  const [studentsChanged, setStudentsChanged] = useState<boolean>(false);
  const [tutorsChanged, setTutorsChanged] = useState<boolean>(false);

  // grab the session representing the event
  const [originalSession, setOriginalSession] = useState<SessionMeta>(
    originalMeetingInfo?.sessions[0]
  );
  const [newSession, setNewSession] = useState<SessionMeta>(
    currentMeetingInfo?.sessions[0]
  );

  const [date, setDate] = useState<string>("");
  const [changeType, setChangeType] = useState<CHANGE_OPTIONS>(
    CHANGE_OPTIONS.JUST_THIS_SESSION
  );
  const [schedulingType, setSchedulingType] = useState<CHANGE_OPTIONS>(
    CHANGE_OPTIONS.JUST_THIS_SESSION
  );
  const [selectedSessions, setSelectedSessions] = useState<SessionResponse[]>([
    { ...currentMeetingInfo.sessions[0] } as SessionResponse,
  ]);

  async function makeMeetingChanges() {
    const originalTutorIds = originalTutors.map((t) => t.id);
    const addedTutors = currentMeetingInfo.tutor_ids.filter((t) => {
      return !originalTutorIds.includes(t);
    });
    const removedTutors = originalTutorIds.filter((t) => {
      return !currentMeetingInfo.tutor_ids.includes(t);
    });

    const originalStudentIds = originalStudents.map((t) => t.id);
    const addedStudents = currentMeetingInfo.student_ids.filter((t) => {
      return !originalStudentIds.includes(t);
    });
    const removedStudents = originalStudentIds.filter((t) => {
      return !currentMeetingInfo.student_ids.includes(t);
    });

    await MeetingsService.editSession({
      sessionId: event.session_id,
      requestBody: {
        session_id: event.session_id,
        make_new_block: false,
        cancel: false,
        name: currentMeetingInfo.name,
        location: currentMeetingInfo.sessions[0].location,
        target_start_time:
          changeType === CHANGE_OPTIONS.JUST_THIS_SESSION
            ? moment.utc(originalMeetingInfo.sessions[0].start).format()
            : undefined,
        target_times_after:
          changeType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
            ? moment.utc(originalMeetingInfo.sessions[0].start).format()
            : undefined,
        target_block: event.block,
        add_students: addedStudents,
        add_tutors: addedTutors,
        remove_students: removedStudents,
        remove_tutors: removedTutors,
        new_tutor_subject: currentMeetingInfo.tutor_subject_id,
      },
    });
  }

  async function GivenSchedulingChangeAddOrDeleteMeetingsToAlignWithEndDate() {
    const newStart = moment.utc(currentMeetingInfo.sessions[0].start);
    const oldStart = moment.utc(originalMeetingInfo.sessions[0].start);
    const daysDifference = newStart.diff(oldStart, "days");

    const end = moment.utc(originalMeetingInfo.sessions[0].until);
    const interval = originalMeetingInfo.sessions[0].interval as number;

    if (daysDifference > 0) {
      const targetTimesAfter = moment.utc(end).subtract(daysDifference, "days");

      await MeetingsService.editSession({
        sessionId: event.session_id,
        requestBody: {
          session_id: event.session_id,
          make_new_block: false,
          cancel: true,
          target_times_after: moment.max(targetTimesAfter, oldStart).format(),
          target_block: event.block,
        },
      });
    }

    if (daysDifference < 0) {
      var findLastMeeting = oldStart.clone();
      var meetingAfter = moment.utc(findLastMeeting).add(interval * 7, "days");

      while (meetingAfter.isBefore(end)) {
        findLastMeeting = meetingAfter.clone();
        meetingAfter = moment.utc(meetingAfter).add(interval * 7, "days");
      }

      const adjustedLastMeeting = moment.utc(
        findLastMeeting.subtract(Math.abs(daysDifference), "days")
      );

      if (end.diff(adjustedLastMeeting, "days") >= interval * 7) {
        const addedMeetings = await MeetingsService.createSession({
          requestBody: {
            tutor_subject_id: originalMeetingInfo.tutor_subject_id,
            org_id: originalMeetingInfo.org_id,
            rate: originalMeetingInfo.rate,
            name: originalMeetingInfo.name,
            student_ids: originalMeetingInfo.student_ids,
            tutor_ids: originalMeetingInfo.tutor_ids,
            sessions: [
              {
                start: adjustedLastMeeting.add(interval * 7, "days").format(),
                until: end.format(),
                duration: originalMeetingInfo.sessions[0].duration,
                location: originalMeetingInfo.sessions[0].location,
                frequency: originalMeetingInfo.sessions[0].frequency,
                recurrence_pattern:
                  originalMeetingInfo.sessions[0].recurrence_pattern,
                interval: originalMeetingInfo.sessions[0].interval,
                is_recurring: originalMeetingInfo.sessions[0].is_recurring,
              },
            ],
          },
        });

        await MeetingsService.editSession({
          sessionId: event.session_id,
          requestBody: {
            session_id: event.session_id,
            make_new_block: false,
            cancel: false,
            add_to_block: event.block,
            inserted_session_id: addedMeetings[0].session_id,
          },
        });
      }
    }
  }

  async function makeSchedulingChanges() {
    let rescheduledMeetings;

    if (nonEndDateChanged) {
      if (dateChanged && reccurencePattern) {
        await GivenSchedulingChangeAddOrDeleteMeetingsToAlignWithEndDate();
      }

      rescheduledMeetings = await MeetingsService.editSession({
        sessionId: event.session_id,
        requestBody: {
          session_id: event.session_id,
          make_new_block: true,
          // schedulingType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
          //   ? true
          //   : false,
          cancel: false,
          duration: currentMeetingInfo.sessions[0].duration,
          target_start_time:
            schedulingType === CHANGE_OPTIONS.JUST_THIS_SESSION
              ? moment.utc(originalMeetingInfo.sessions[0].start).format()
              : undefined,
          target_times_after:
            schedulingType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
              ? moment.utc(originalMeetingInfo.sessions[0].start).format()
              : undefined,
          target_block: event.block,
          reschedule_meeting_to:
            schedulingType === CHANGE_OPTIONS.JUST_THIS_SESSION
              ? moment.utc(currentMeetingInfo.sessions[0].start).format()
              : undefined,
          reschedule_recurring_meetings_to:
            schedulingType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
              ? new RRule({
                  freq: RRule.WEEKLY,
                  interval: currentMeetingInfo.sessions[0].interval,
                  dtstart: moment
                    .utc(currentMeetingInfo.sessions[0].start)
                    .toDate(),
                  until: moment
                    .utc(originalMeetingInfo.sessions[0].until)
                    .toDate(),
                }).toString()
              : undefined,
        },
      });
    }

    if (endDateChanged) {
      const newEndDate = moment(currentMeetingInfo.sessions[0].until).endOf(
        "day"
      );
      const oldEndDate = moment(originalMeetingInfo.sessions[0].until).endOf(
        "day"
      );

      if (newEndDate.isBefore(oldEndDate)) {
        await MeetingsService.editSession({
          sessionId: event.session_id,
          requestBody: {
            session_id: event.session_id,
            make_new_block: false,
            cancel: true,
            target_times_after: moment.utc(newEndDate).format(),
            target_block: nonEndDateChanged
              ? rescheduledMeetings[0].block
              : event.block,
            changes_end_date: true,
          },
        });
      }

      if (newEndDate.isAfter(oldEndDate)) {
        const weeksBetween = moment
          .utc(originalSession.until)
          .diff(moment.utc(originalSession.start), "weeks");
        const newStartDate = moment
          .utc(originalSession.start)
          .add(weeksBetween + 1, "weeks")
          .format();

        const addedMeetings = await MeetingsService.createSession({
          requestBody: {
            tutor_subject_id: currentMeetingInfo.tutor_subject_id,
            org_id: currentMeetingInfo.org_id,
            rate: currentMeetingInfo.rate,
            name: currentMeetingInfo.name,
            student_ids: currentMeetingInfo.student_ids,
            tutor_ids: currentMeetingInfo.tutor_ids,
            sessions: [
              {
                start: newStartDate,
                until: currentMeetingInfo.sessions[0].until,
                duration: originalMeetingInfo.sessions[0].duration,
                location: currentMeetingInfo.sessions[0].location,
                frequency: currentMeetingInfo.sessions[0].frequency,
                recurrence_pattern:
                  currentMeetingInfo.sessions[0].recurrence_pattern,
                interval: currentMeetingInfo.sessions[0].interval,
                is_recurring: true,
              },
            ],
          },
        });

        await MeetingsService.editSession({
          sessionId: event.session_id,
          requestBody: {
            session_id: event.session_id,
            make_new_block: false,
            cancel: false,
            add_to_block: nonEndDateChanged
              ? rescheduledMeetings[0].block
              : event.block,
            inserted_session_id: addedMeetings[0].session_id,
            changes_end_date: true,
          },
        });
      }
    }
  }

  async function updateMeeting() {
    setStatus(PageStatus.LOADING);

    try {
      if (meetingChangeMade) {
        await makeMeetingChanges();
      }

      if (schedulingChangeMade) {
        await makeSchedulingChanges();
      }
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        setStatus(PageStatus.ERROR);
        Notifications.error("Error: " + error.message + ". Please try again.");
        console.error(`Error (#${error.status}): ${error.message}`);
      } else {
        // Handle unexpected error types
        setStatus(PageStatus.ERROR);
        Notifications.error("An unexpected error occurred. Please try again.");
        console.error(`Unexpected error: ${error}`);
      }
      return;
    }

    setStatus(PageStatus.SUCCESS);
  }

  const handleUpdateApplyChanges = (ev) => {
    setChangeType(ev.target.value);
  };

  const handleUpdateSchedulingChangeType = (ev) => {
    setSchedulingType(ev.target.value);
  };

  const handleUpdateFutureOccurrencesAfter = (ev) => {
    setDate(ev.target.value);
  };

  const selectSession = (session: SessionResponse) => {
    if (selectedSessions.includes(session)) {
      setSelectedSessions(selectedSessions.filter((s) => s !== session));
    } else {
      setSelectedSessions([...selectedSessions, session]);
    }
  };

  const canSave = () => {
    if (changeType === CHANGE_OPTIONS.FUTURE_OCCURRENCES_AFTER) {
      return date !== "";
    } else if (changeType === CHANGE_OPTIONS.ALL_SESSIONS_IN_SEQUENCE) {
      return selectedSessions.length > 0;
    }
    return true;
  };

  const resetDialog = () => {
    setStatus(undefined);
    setDate("");
    setChangeType(CHANGE_OPTIONS.JUST_THIS_SESSION);
    setSchedulingType(CHANGE_OPTIONS.JUST_THIS_SESSION);
    setSelectedSessions([]);

    setSchedulingChangeMade(false);
    setEndDateChanged(false);
    setNonEndDateChanged(false);
    setFrequencyChanged(false);
    setDateChanged(false);
    setTimeChanged(false);
    setOriginalEndTime("");
    setNewEndTime("");
    setOriginalDate("");
    setNewDate("");
    setOriginalTime("");
    setNewTime("");

    setMeetingChangeMade(false);
    setLocationChanged(false);
    setSubjectChanged(false);
    setNameChanged(false);
    setStudentsChanged(false);
    setTutorsChanged(false);

    continueFunction();
  };

  const getAddedStudentsAndTutors = () => {
    const addedStudentIds =
      currentMeetingInfo?.student_ids.filter(
        (updatedStudent) =>
          !originalMeetingInfo?.student_ids?.includes(updatedStudent)
      ) || [];
    const addedStudents = students.filter((student) => {
      return addedStudentIds.includes(student.id);
    });
    const addedTutorIds =
      currentMeetingInfo?.tutor_ids.filter(
        (updatedTutor) =>
          !originalMeetingInfo?.tutor_ids?.includes(updatedTutor)
      ) || [];
    const addedTutors = tutors.filter((student) => {
      return addedTutorIds.includes(student.id);
    });

    return addedStudents.length > 0 || addedTutors.length > 0 ? (
      <div className="flex flex-row mb-2">
        <span className="text-sm font-bold">Added</span>
        <div className="ml-4">
          {addedStudents.map((addedStudent, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-green-500 items-center py-0.5 px-2 gap-1.5"
              >
                {`${addedStudent.first_name} ${addedStudent.last_name}`}
              </span>
            );
          })}

          {addedTutors.map((addedTutor, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-indigo-400 items-center py-0.5 px-2 gap-1.5"
              >
                {`${addedTutor.first_name} ${addedTutor.last_name}`}
              </span>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  const getRemovedStudentsAndTutors = () => {
    const removedStudentIds =
      originalMeetingInfo?.student_ids.filter(
        (updatedStudent) =>
          !currentMeetingInfo?.student_ids?.includes(updatedStudent)
      ) || [];
    const removedStudents = originalStudents.filter((student) => {
      return removedStudentIds.includes(student.id);
    });

    const removedTutorIds =
      originalMeetingInfo?.tutor_ids.filter(
        (updatedTutor) => !currentMeetingInfo?.tutor_ids?.includes(updatedTutor)
      ) || [];
    const removedTutors = originalTutors.filter((tutor) => {
      return removedTutorIds.includes(tutor.id);
    });

    return removedStudents.length > 0 || removedTutors.length > 0 ? (
      <div className="flex flex-row">
        <span className="text-sm font-bold">Removed</span>
        <div className="ml-4">
          {removedStudents.map((removedStudent, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-green-500 items-center py-0.5 px-2 gap-1.5"
              >
                {`${removedStudent.first_name} ${removedStudent.last_name}`}
              </span>
            );
          })}

          {removedTutors.map((removedTutor, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-indigo-400 items-center py-0.5 px-2 gap-1.5"
              >
                {`${removedTutor.first_name} ${removedTutor.last_name}`}
              </span>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  // Get sessions
  useEffect(() => {
    setOriginalSession(originalMeetingInfo?.sessions[0]);
    setNewSession(currentMeetingInfo?.sessions[0]);
  }, [currentMeetingInfo?.sessions, originalMeetingInfo?.sessions]);

  // As a user makes modifications to a meeting, detect all scheduling related changes.
  // This will later be used to render JSX as needed
  useEffect(() => {
    setOriginalDate(
      moment(originalSession?.start.split("T")[0]).format("ddd, MMM Do")
    );

    setNewDate(moment(newSession?.start.split("T")[0]).format("ddd, MMM Do"));

    setOriginalTime(
      moment(
        originalSession?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      ).format(TIME_FORMAT)
    );

    setNewTime(
      moment(
        newSession?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      ).format(TIME_FORMAT)
    );

    setOriginalEndTime(
      moment(
        originalSession?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      )
        .add(originalSession?.duration, "minutes")
        .format(TIME_FORMAT)
    );

    setNewEndTime(
      moment(newSession?.start.split("T")[1].substring(0, 8), FULL_TIME_FORMAT)
        .add(newSession?.duration, "minutes")
        .format(TIME_FORMAT)
    );

    setTimeChanged(
      originalTime !== newTime ||
        originalSession?.duration !== newSession?.duration
    );

    setDateChanged(originalDate !== newDate);

    setFrequencyChanged(newSession.interval !== originalSession.interval);

    setEndDateChanged(
      newSession.until ? newSession.until !== originalSession.until : false
    );

    setSchedulingChangeMade(
      dateChanged || timeChanged || frequencyChanged || endDateChanged
    );

    setNonEndDateChanged(dateChanged || timeChanged || frequencyChanged);
  }, [
    dateChanged,
    endDateChanged,
    frequencyChanged,
    newDate,
    newSession?.duration,
    newSession.interval,
    newSession.recurrence_pattern,
    newSession?.start,
    newSession.until,
    newTime,
    originalDate,
    originalMeetingInfo.sessions,
    originalSession?.duration,
    originalSession.interval,
    originalSession?.start,
    originalSession.until,
    originalTime,
    reccurencePattern,
    timeChanged,
  ]);

  function arraysContainSameElements(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    const set1 = new Set(arr1);
    for (const item of arr2) {
      if (!set1.has(item)) return false;
    }
    return true;
  }

  // As a user makes modifications to a meeting, detect all non-scheduling related changes.
  // This will later be used to render JSX as needed
  useEffect(() => {
    setLocationChanged(originalSession?.location !== newSession?.location);
    setSubjectChanged(
      originalMeetingInfo?.tutor_subject_id !==
        currentMeetingInfo?.tutor_subject_id
    );
    setNameChanged(originalMeetingInfo?.name !== currentMeetingInfo?.name);

    const studentsChanged = !arraysContainSameElements(
      originalMeetingInfo.student_ids,
      currentMeetingInfo.student_ids
    );
    setStudentsChanged(studentsChanged);

    const tutorsChanged = !arraysContainSameElements(
      originalMeetingInfo.tutor_ids,
      currentMeetingInfo.tutor_ids
    );
    setTutorsChanged(tutorsChanged);

    setMeetingChangeMade(
      locationChanged ||
        subjectChanged ||
        nameChanged ||
        studentsChanged ||
        tutorsChanged
    );
  }, [
    currentMeetingInfo?.name,
    currentMeetingInfo.sessions,
    currentMeetingInfo.student_ids,
    currentMeetingInfo.tutor_ids,
    currentMeetingInfo?.tutor_subject_id,
    locationChanged,
    nameChanged,
    newSession?.location,
    originalMeetingInfo?.name,
    originalMeetingInfo.sessions,
    originalMeetingInfo.student_ids,
    originalMeetingInfo.tutor_ids,
    originalMeetingInfo?.tutor_subject_id,
    originalSession?.location,
    subjectChanged,
  ]);

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message="Changes Saved!" onClose={resetDialog} />;
  }

  return (
    <>
      <div className="grid grid-cols-12 justify-self-end">
        <DialogContent
          className="dialog-content dialog-content--left wide h-fit max-h-[85vh] lg:max-w-[33vw] md:w-1/2 w-3/4 bg-neutral-50 font-montserrat pb-4 overflow-scroll"
          alignLeft={true}
          showClose={false}
        >
          <div className="meeting-dialog-new--title pt-4">
            <span>Save Changes?</span>
          </div>

          <p className="font-bold text-md border-b-2 pb-2 text-green-500">
            Scheduling Changes
          </p>
          {schedulingChangeMade ? (
            <>
              {(dateChanged || timeChanged) && (
                <div className="flex flex-col mt-2">
                  <span className="font-semibold text-sm mb-1">Old Time</span>
                  <span className="flex items-center text-sm mb-3">
                    <CalendarIcon height="20" width="20" />
                    &nbsp;{originalDate} from {originalTime}&nbsp;to&nbsp;
                    {originalEndTime}
                  </span>
                  <span className="font-semibold text-sm mb-1">New Time</span>
                  <span className="flex items-center text-sm mb-3">
                    <CalendarIcon height="20" width="20" />
                    &nbsp;{newDate} from {newTime}&nbsp;to&nbsp;{newEndTime}
                  </span>

                  {event.associated_recurrence_string && (
                    <>
                      <div className="font-bold mb-2 text-md">
                        Apply scheduling changes to
                      </div>
                      <div className="flex flex-row items-center gap-2 mb-2">
                        <RadioInput
                          id={CHANGE_OPTIONS.JUST_THIS_SESSION}
                          name={`sched-${CHANGE_OPTIONS.JUST_THIS_SESSION}`}
                          value={CHANGE_OPTIONS.JUST_THIS_SESSION}
                          label="Just this meeting"
                          setValue={handleUpdateSchedulingChangeType}
                          checked={
                            schedulingType === CHANGE_OPTIONS.JUST_THIS_SESSION
                          }
                          required
                        />
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <RadioInput
                          id={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                          name={`sched-${CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}`}
                          value={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                          label={`Switch all future occurrences of this meeting to 
                        ${moment(
                          dateChanged
                            ? newSession?.start
                            : originalSession?.start
                        ).format("dddd")}s from 
                        ${moment(
                          timeChanged ? newTime : originalTime,
                          TIME_FORMAT
                        ).format(TIME_FORMAT)} to
                        ${moment(
                          timeChanged ? newEndTime : originalEndTime,
                          TIME_FORMAT
                        ).format(TIME_FORMAT)}
                      `}
                          setValue={handleUpdateSchedulingChangeType}
                          checked={
                            schedulingType ===
                            CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
                          }
                          required
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              {frequencyChanged && (
                <div className="text-sm mt-2">
                  <b>Frequency -</b> This meeting will now occur
                  {newSession.interval
                    ? ` every ${newSession.interval * 7} days `
                    : " never"}
                  instead of
                  {parseRecurrencePattern(reccurencePattern) !== 0
                    ? ` every ${parseRecurrencePattern(
                        reccurencePattern
                      )} days.`
                    : " never."}
                </div>
              )}
              {endDateChanged && (
                <div className="text-sm mt-2">
                  <b>Until -</b> This meeting will now end on or before{" "}
                  {moment(newSession.until).format("ddd, MMM Do")}.
                </div>
              )}
            </>
          ) : (
            <>No scheduling changes</>
          )}

          <p className="font-bold text-md border-b-2 pb-2 mt-4 text-green-500">
            Meeting Changes
          </p>
          {meetingChangeMade ? (
            <>
              <div className="flex flex-col text-sm">
                {nameChanged && (
                  <div className="flex w-full mt-2">
                    <span className="flex flex-col w-1/2 border-b pb-2">
                      <div>
                        <b>Current Name</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <TextIcon />
                        &nbsp;{originalMeetingInfo?.name}
                      </div>
                    </span>
                    <span className="flex flex-col w-fit border-b pb-2">
                      <div>
                        <b>New Name</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <TextIcon />
                        &nbsp;{currentMeetingInfo?.name}
                      </div>
                    </span>
                  </div>
                )}
                {locationChanged && (
                  <div className="flex w-full mt-2">
                    <span className="flex flex-col w-1/2 border-b pb-2">
                      <div>
                        <b>Current Location</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <SewingPinFilledIcon />
                        &nbsp;{originalSession?.location}
                      </div>
                    </span>
                    <span className="flex flex-col w-fit border-b pb-2">
                      <div>
                        <b>New Location</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <SewingPinFilledIcon />
                        &nbsp;{newSession?.location}
                      </div>
                    </span>
                  </div>
                )}
                {subjectChanged && subject && (
                  <div className="flex w-full mt-2">
                    <span className="flex flex-col w-1/2 border-b pb-2">
                      <div>
                        <b>Current Subject</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <BookmarkIcon height="20" width="20" />
                        &nbsp;{concatenateTutorSubject(event.subject)}
                      </div>
                    </span>
                    <span className="flex flex-col w-fit border-b pb-2">
                      <div>
                        <b>New Subject</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <BookmarkIcon height="20" width="20" />
                        &nbsp;{concatenateTutorSubject(subject)}
                      </div>
                    </span>
                  </div>
                )}
                <div className="mt-3 mb-1">
                  {getAddedStudentsAndTutors()}
                  {getRemovedStudentsAndTutors()}
                </div>
              </div>
              {event.associated_recurrence_string && (
                <div className="mt-4">
                  <div className="font-bold mb-2 text-md">
                    Apply meeting changes to:
                  </div>
                  <div className="flex flex-row items-center gap-2 mb-2">
                    <RadioInput
                      id={CHANGE_OPTIONS.JUST_THIS_SESSION}
                      name={CHANGE_OPTIONS.JUST_THIS_SESSION}
                      value={CHANGE_OPTIONS.JUST_THIS_SESSION}
                      label="Just this meeting"
                      setValue={handleUpdateApplyChanges}
                      checked={changeType === CHANGE_OPTIONS.JUST_THIS_SESSION}
                      required
                    />
                  </div>

                  <div className="flex flex-row items-center gap-2 mb-2">
                    <RadioInput
                      id={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                      name={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                      value={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                      label="All future occurrences of this meeting"
                      setValue={handleUpdateApplyChanges}
                      checked={
                        changeType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES
                      }
                      required
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>No meeting changes</>
          )}
          <div>
            <DialogActions>
              <DialogAction
                size={ButtonSize.SMALL}
                color={!canSave() ? ButtonColor.GRAY : ButtonColor.GREEN}
                fill={ButtonFill.DEFAULT}
                disabled={!canSave()}
                onClick={updateMeeting}
              >
                {status === PageStatus.LOADING ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>Confirm</>
                )}
              </DialogAction>
              <DialogClose asChild>
                <Button
                  size={ButtonSize.SMALL}
                  color={ButtonColor.BLACK}
                  fill={ButtonFill.HOLLOW}
                >
                  Back
                </Button>
              </DialogClose>
            </DialogActions>
          </div>
        </DialogContent>
      </div>
    </>
  );
}
