/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from "../models/DeleteResponse";
import type { OrganizationNoteKeyCreate } from "../models/OrganizationNoteKeyCreate";
import type { OrganizationNoteKeyResponse } from "../models/OrganizationNoteKeyResponse";
import type { OrganizationNoteKeyUpdate } from "../models/OrganizationNoteKeyUpdate";
import type { OrganizationNoteValueCreate } from "../models/OrganizationNoteValueCreate";
import type { OrganizationNoteValueResponse } from "../models/OrganizationNoteValueResponse";
import type { OrganizationNoteValueUpdate } from "../models/OrganizationNoteValueUpdate";
import type { OrganizationStudentNoteResponse } from "../models/OrganizationStudentNoteResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OrgNotesService {
  /**
   * Get Org Keys
   * Get all org note keys from the database.
   * @returns OrganizationNoteKeyResponse Successful Response
   * @throws ApiError
   */
  public static getOrgKeys({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<OrganizationNoteKeyResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/org_notes/keys/by_org/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Notes
   * Get all org notes on a student from the database.
   * @returns OrganizationStudentNoteResponse Successful Response
   * @throws ApiError
   */
  public static getOrgNotes({
    orgId,
    studentId,
  }: {
    orgId: number;
    studentId: number;
  }): CancelablePromise<Array<OrganizationStudentNoteResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/org_notes",
      query: {
        org_id: orgId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Note Key
   * Create a new org note key and returns the resulting key from the database.
   * @returns OrganizationNoteKeyResponse Successful Response
   * @throws ApiError
   */
  public static createOrgNoteKey({
    requestBody,
  }: {
    requestBody: OrganizationNoteKeyCreate;
  }): CancelablePromise<OrganizationNoteKeyResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/org_notes/keys",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Org Note Key
   * Update a org note key by its ID using specified attributes
   * within OrganizationNoteKeyUpdate to update the database and
   * return the updated org note key.
   * @returns OrganizationNoteKeyResponse Successful Response
   * @throws ApiError
   */
  public static updateOrgNoteKey({
    orgNoteKeyId,
    requestBody,
  }: {
    orgNoteKeyId: number;
    requestBody: OrganizationNoteKeyUpdate;
  }): CancelablePromise<OrganizationNoteKeyResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/org_notes/keys/{org_note_key_id}",
      path: {
        org_note_key_id: orgNoteKeyId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Org Note Key
   * Delete an org note key by its ID from the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteOrgNoteKey({
    orgNoteKeyId,
  }: {
    orgNoteKeyId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/org_notes/keys/{org_note_key_id}",
      path: {
        org_note_key_id: orgNoteKeyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Note Value
   * Create a new org note value and returns the resulting value from the database.
   * @returns OrganizationNoteValueResponse Successful Response
   * @throws ApiError
   */
  public static createOrgNoteValue({
    requestBody,
  }: {
    requestBody: OrganizationNoteValueCreate;
  }): CancelablePromise<OrganizationNoteValueResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/org_notes/values",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Org Note Value
   * Update a org note value by its ID using specified attributes
   * within OrganizationNoteValueResponse to update the database and
   * return the updated org note value.
   * @returns OrganizationNoteValueResponse Successful Response
   * @throws ApiError
   */
  public static updateOrgNoteValue({
    orgNoteValueId,
    requestBody,
  }: {
    orgNoteValueId: number;
    requestBody: OrganizationNoteValueUpdate;
  }): CancelablePromise<OrganizationNoteValueResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/org_notes/values/{org_note_value_id}",
      path: {
        org_note_value_id: orgNoteValueId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Org Note Value
   * Delete an org note value by its ID from the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteOrgNoteValue({
    orgNoteValueId,
  }: {
    orgNoteValueId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/org_notes/values/{org_note_value_id}",
      path: {
        org_note_value_id: orgNoteValueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
