/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StudentSubjectResponse } from "../models/StudentSubjectResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StudentSubjectsService {
  /**
   * Get Student Subject
   * Retrieve a student subject.
   * @returns StudentSubjectResponse Successful Response
   * @throws ApiError
   */
  public static getStudentSubject({
    studentId,
    orgSubjectId,
  }: {
    studentId: number;
    orgSubjectId: number;
  }): CancelablePromise<StudentSubjectResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_subjects",
      query: {
        student_id: studentId,
        org_subject_id: orgSubjectId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Subjects By Student Id
   * Retrieve a student subject.
   * @returns StudentSubjectResponse Successful Response
   * @throws ApiError
   */
  public static getStudentSubjectsByStudentId({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<StudentSubjectResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_subjects/student/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Subjects By Org
   * Get all student subjects by org.
   * @returns StudentSubjectResponse Successful Response
   * @throws ApiError
   */
  public static getStudentSubjectsByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<StudentSubjectResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_subjects/organization/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
