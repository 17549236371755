import { Auth0AccountRole } from "client/openapi";
import { UserType } from "types";
import { getRoleColor } from "util/contextColor";
import { getUserString } from "../helpers";

function UserSearchBar({
  userType,
  role,
  debouncedPhrase,
  setDebouncedPhrase,
}: {
  userType: Auth0AccountRole;
  role: Auth0AccountRole;
  debouncedPhrase: string;
  setDebouncedPhrase: (value: string) => void;
}) {
  return (
    <>
      <label
        htmlFor="name"
        className="input-label"
        aria-label={`Search for ${getUserString(userType)} by Name`}
      >
        <input
          id="name"
          name="name"
          type="text"
          className={`py-1.5 m-0 input list--search-input ${getRoleColor(
            role
          )}`}
          placeholder="Search..."
          value={debouncedPhrase}
          maxLength={25}
          onChange={(ev) => setDebouncedPhrase(ev.target.value)}
        />
      </label>
    </>
  );
}

export default UserSearchBar;
