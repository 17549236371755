import {
  Auth0AccountRole,
  OrganizationSettingsResponse,
  ParentResponse,
  StudentResponse,
  TutorResponse,
} from "client/openapi";
import { ButtonColor } from "components/Button";
import InviteUserDialog from "./InviteUserDialog";
import MessageUserDialog from "./MessageUserDialog";
import UserSearchBar from "./UserSearchBar";
import "./index.css";
import { TutorContext } from "../TutorDashboard/tutorContext";
import { useContext, useEffect, useState } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

// eslint-disable-next-line no-empty-pattern
function UserListActions({
  users,
  userType,
  buttonColor,
  debouncedPhrase,
  setDebouncedPhrase,
  role,
  fetchUsers,
  currentPage,
  orgId,
  settings,
}: {
  users: StudentResponse[] | TutorResponse[] | ParentResponse[];
  userType: Auth0AccountRole;
  buttonColor: ButtonColor;
  debouncedPhrase: string;
  setDebouncedPhrase: (value: string) => void;
  role: Auth0AccountRole;
  fetchUsers: (page: number) => Promise<void>;
  currentPage: number;
  orgId: number;
  settings?: OrganizationSettingsResponse;
}) {
  const { available_tutors } = useContext(TutorContext);
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  const [students_of_corresponding_tutor, set_students_of_corresponding_tutor] =
    useState<StudentResponse[]>();

  useEffect(() => {
    if (
      available_tutors &&
      currently_selected_role === Auth0AccountRole.ORG_TUTOR
    ) {
      set_students_of_corresponding_tutor(
        available_tutors.find((tutor) => tutor.org_id === orgId)?.students
      );
    }
  }, [available_tutors, currently_selected_role, orgId]);

  return (
    <div className="grid grid-rows-2 grid-cols-2 lg:grid-cols-3 gap-y-2 lg:space-x-2">
      <div className="row-span-1 col-span-3 lg:col-span-2">
        <div className="grid grid-cols-2 space-x-2 message-invite-buttons">
          <div className="col-span-1">
            <MessageUserDialog
              messageAllStudents={
                currently_selected_role === Auth0AccountRole.ORG_TUTOR &&
                !settings?.tutors_can_view_unassigned_students
                  ? undefined
                  : userType === Auth0AccountRole.ME
              }
              onlyMessageAllOfATutorsStudents={
                currently_selected_role === Auth0AccountRole.ORG_TUTOR &&
                !settings?.tutors_can_view_unassigned_students
                  ? students_of_corresponding_tutor
                    ? students_of_corresponding_tutor
                    : []
                  : undefined
              }
              messageAllTutors={userType === Auth0AccountRole.ORG_TUTOR}
              messageAllParents={userType === Auth0AccountRole.PARENT}
              buttonColor={buttonColor}
              org_id={orgId}
            />
          </div>
          <div className="col-span-1 invite-button">
            <InviteUserDialog
              buttonColor={buttonColor}
              type_of_user_being_invited={userType}
              fetchUsers={fetchUsers}
              currentPage={currentPage}
              orgId={orgId}
            />
          </div>
        </div>
      </div>
      <div className="row-span-2 col-span-3 lg:col-span-1">
        <UserSearchBar
          userType={userType}
          role={role}
          debouncedPhrase={debouncedPhrase}
          setDebouncedPhrase={setDebouncedPhrase}
        />
      </div>
    </div>
  );
}

export default UserListActions;
