/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a kind of payment plan for a pay-per-session agreement
 * in a Student.
 */
export enum PaymentPlanFrequency {
  NONE = "NONE",
  CHARGE_AUTOMATICALLY = "CHARGE_AUTOMATICALLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}
