import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor } from "components/Button";
import { ChangeEventHandler, FormEventHandler } from "react";
import { Signup } from "types";

function SignupAddress({
  values,
  handleUpdate,
  handleSubmit,
  loading,
  errors,
}: {
  values: Signup;
  handleUpdate: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  errors: Signup;
}) {
  return (
    <form
      action="#"
      onSubmit={handleSubmit}
      className="flex items-center justify-center"
      style={{ height: "75vh" }}
    >
      <div>
        <h1 className="mt-5 headline-9">Mailing Address</h1>

        <div className="mt-2 mb-2 signup_text">
          We are required to collect your mailing address.
        </div>

        <label htmlFor="address_line_1" className="mt-4 input-label">
          Street Address
          <input
            id="address_line_1"
            name="address_line_1"
            type="text"
            className="input"
            placeholder="10 Example St."
            value={values.address_line_1}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        <label htmlFor="address_line_2" className="mt-4 input-label">
          Street Address - Line 2
          <input
            id="address_line_2"
            name="address_line_2"
            type="text"
            className="input"
            placeholder="Apt. 123"
            value={values.address_line_2 || ""}
            onChange={handleUpdate}
            disabled={loading}
          />
        </label>

        <label htmlFor="city" className="mt-4 input-label">
          City
          <input
            id="city"
            name="city"
            type="text"
            className="input"
            placeholder="Boston"
            value={values.city}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        <label htmlFor="state" className="mt-4 input-label">
          State
          <input
            id="state"
            name="state"
            type="text"
            className="input"
            placeholder="MA"
            value={values.state}
            pattern="[a-z,A-Z]*"
            minLength={2}
            maxLength={2}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        {errors.state && (
          <span className="block mt-2 text-center reg__error_mess">
            {errors.state}
          </span>
        )}

        <label htmlFor="zip" className="mt-4 input-label">
          ZIP Code
          <input
            id="zip"
            name="zip"
            type="text"
            className="input"
            placeholder="02120"
            value={values.zip}
            pattern="[0-9]*"
            minLength={5}
            maxLength={5}
            onChange={handleUpdate}
            disabled={loading}
            required
          />
        </label>

        <div className="mt-4">
          <Button color={ButtonColor.GREEN} block={true} type="submit">
            Next <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </form>
  );
}

export default SignupAddress;
