import {
  OrganizationSubjectResponse,
  StudentResponse,
  TutorMeetingResponse,
  TutorSubjectResponse,
  TutorSubjectReviewResponse,
} from "client/openapi";

export function concatenateTutorSubject(
  subject: TutorSubjectResponse | TutorSubjectReviewResponse | undefined
): string {
  if (!subject) {
    return "";
  }
  return subject.org_subject.level
    ? `${subject.org_subject.level} ${subject.org_subject.name}`
    : subject.org_subject.name;
}

export function concatenateSubject(
  subject: OrganizationSubjectResponse
): string {
  return subject.level ? `${subject.level} ${subject.name}` : subject.name;
}

export function concatenateMeetingDetails(
  subject: TutorSubjectResponse,
  tutors: TutorMeetingResponse[],
  students: StudentResponse[]
): string {
  const tutorNames =
    tutors
      .slice(0, 3)
      .map((tutor) => tutor.first_name)
      .join(", ") + (tutors.length > 3 ? ", ..." : "");
  const studentNames =
    students
      .slice(0, 3)
      .map((student) => student.first_name)
      .join(", ") + (students.length > 3 ? ", ..." : "");

  return `${subject.org_subject.name} · ${tutorNames} · ${studentNames}`;
}
