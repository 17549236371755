import { withAuthenticationRequired } from "@auth0/auth0-react";
import StatusBlock, { StatusBlockDisplay } from "components/StatusBlock";
import "./index.css";

function PageLoader() {
  return (
    <div className="page--loader">
      <StatusBlock
        title="Redirecting..."
        message="You are not authorized to access this page."
        display={StatusBlockDisplay.STANDALONE}
      />
    </div>
  );
}

export const ProtectedRoute = ({
  component,
  componentProps,
}: {
  component: React.ComponentType<any>;
  componentProps?: any;
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component {...componentProps} />;
};
