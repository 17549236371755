import {
  linearRegression,
  linearRegressionLine,
  rSquared,
  sum,
  mean,
} from "simple-statistics";

import * as jStat from "jstat";

export function calculateLinearRegression(
  data: Array<{ x: number; y: number }>
): [number, number, number, number[]] {
  let list_of_x_values: number[] = [];
  let list_of_y_values: number[] = [];

  for (const { x, y } of data) {
    list_of_x_values.push(x);
    list_of_y_values.push(y);
  }

  const line_of_best_fit = linearRegression(data.map(({ x, y }) => [x, y]));

  const slope = line_of_best_fit.m;
  const intercept = line_of_best_fit.b;

  const line_of_best_fit_function = linearRegressionLine(line_of_best_fit);

  // Calculate R-squared value
  const rSquaredValue = rSquared(
    list_of_x_values.map((xi, i) => [xi, list_of_y_values[i]]),
    line_of_best_fit_function
  );

  // Calculate residuals
  const residuals = data.map(
    ({ x, y }) => y - (line_of_best_fit.m * x + line_of_best_fit.b)
  );

  // Calculate Residual Standard Error (RSE)
  const RSE = Math.sqrt(sum(residuals.map((r) => r * r)) / (data.length - 2));

  // Sum of Squares of x (SSX)
  const meanX = mean(data.map((d) => d.x));
  const SSX = sum(data.map(({ x }) => Math.pow(x - meanX, 2)));

  // Standard Error of the slope
  const SE = RSE / Math.sqrt(SSX);

  const confidenceLevel = 0.95;
  const alpha = 1 - confidenceLevel;
  const degreesOfFreedom = data.length - 1;
  const tScore = jStat.studentt.inv(1 - alpha / 2, degreesOfFreedom);

  const confidenceInterval = [slope - tScore * SE, slope + tScore * SE];

  return [slope, intercept, rSquaredValue, confidenceInterval];
}
