import { Auth0AccountRole } from "client/openapi";
import { ButtonColor } from "components/Button";
import { AccountRoles, UserTypes } from "types";

export function getUserColor(types: Auth0AccountRole | undefined): string {
  switch (types) {
    case Auth0AccountRole.ME:
      return "green";
    case Auth0AccountRole.ORG_TUTOR:
      return "orange";
    case Auth0AccountRole.ORG_ADMIN:
      return "purple";
    case Auth0AccountRole.PARENT:
      return "dark-blue";
    default:
      return "";
  }
}

export function getContextColor(context: Auth0AccountRole): string {
  switch (context) {
    case Auth0AccountRole.ORG_ADMIN:
      return "purple";
    case Auth0AccountRole.ORG_TUTOR:
      return "orange";
    case Auth0AccountRole.ME:
      return "green";
    case Auth0AccountRole.PARENT:
      return "dark-blue";
    default:
      return "";
  }
}

/**
 * Gets the color associated with the currently authenticated user.
 * @param roles Auth0 account roles assigned to the current user
 * @returns the color for the user's role
 */
export function getRoleColor(roles: Auth0AccountRole | null): string {
  if (roles && roles.includes(Auth0AccountRole.ORG_ADMIN)) {
    return "skyblue";
  } else if (roles && roles.includes(Auth0AccountRole.ORG_TUTOR)) {
    return "orange";
  } else if (roles && roles.includes(Auth0AccountRole.ME)) {
    return "green";
  } else if (roles && roles.includes(Auth0AccountRole.PARENT)) {
    return "dark-blue";
  } else {
    return "";
  }
}

export function getButtonRoleColor(role: Auth0AccountRole | null): ButtonColor {
  if (role === Auth0AccountRole.TADPOLE_ADMIN) {
    return ButtonColor.PURPLE;
  } else if (role === Auth0AccountRole.ORG_ADMIN) {
    return ButtonColor.PURPLE;
  } else if (role === Auth0AccountRole.ORG_TUTOR) {
    return ButtonColor.ORANGE;
  } else if (role === Auth0AccountRole.ME) {
    return ButtonColor.GREEN;
  } else if (role === Auth0AccountRole.PARENT) {
    return ButtonColor.DARK_BLUE;
  }

  return ButtonColor.DEFAULT;
}

export function getUserTypeColor(role: Auth0AccountRole): string {
  if (role === Auth0AccountRole.ORG_ADMIN) return "skyblue";
  if (role === Auth0AccountRole.ORG_TUTOR) return "orange";
  if (role === Auth0AccountRole.ME) return "green";
  if (role === Auth0AccountRole.PARENT) return "dark-blue";
  return "";
}

export function makeRoleHumanReadable(role: Auth0AccountRole): string {
  if (role === Auth0AccountRole.ORG_ADMIN) return "admin";
  if (role === Auth0AccountRole.ORG_TUTOR) return "tutor";
  if (role === Auth0AccountRole.ME) return "student";
  if (role === Auth0AccountRole.PARENT) return "parent";
  return "";
}
