export default function EditableContentHeaderInput({
  id,
  name,
  placeholder,
  value,
  setValue,
  disabled,
  required,
  classes,
}: {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  setValue: (value: any) => void;
  disabled?: boolean;
  required?: boolean;
  classes?: string;
}) {
  return (
    <input
      id={id}
      name={name}
      type="text"
      className={`p-2 m-0 text-3xl rounded-md font-bold border-blue-500 ${classes}`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e)}
      disabled={disabled}
      required={required}
    />
  );
}
