import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Auth0AccountRole } from "client/openapi";
import { Button, ButtonFill, ButtonShape, ButtonSize } from "components/Button";
import { getButtonRoleColor } from "util/contextColor";

function PageNav({
  currentPage,
  numPages,
  limit,
  totalUsers,
  role,
  fetchUsers,
  setCurrentPage,
}: {
  currentPage: number;
  numPages: number;
  limit: number;
  totalUsers: number;
  role: Auth0AccountRole;
  fetchUsers: (page: number) => Promise<void>;
  setCurrentPage: (page: number) => void;
}) {
  return (
    <div className="flex flex-row items-center gap-x-1 py-2">
      <div className="flex flex-row">
        <Button
          disabled={!(currentPage > 0)}
          shape={ButtonShape.CIRCLE}
          size={ButtonSize.SMALL}
          fill={ButtonFill.HOLLOW}
          color={getButtonRoleColor(role)}
          onClick={() => {
            const previousPage = currentPage - 1;
            fetchUsers(previousPage);
            setCurrentPage(previousPage);
          }}
        >
          <ChevronLeftIcon />
        </Button>

        <Button
          disabled={limit * (currentPage + 1) >= totalUsers}
          shape={ButtonShape.CIRCLE}
          size={ButtonSize.SMALL}
          fill={ButtonFill.HOLLOW}
          color={getButtonRoleColor(role)}
          onClick={() => {
            const nextPage = currentPage + 1;
            fetchUsers(nextPage);
            setCurrentPage(nextPage);
          }}
        >
          <ChevronRightIcon />
        </Button>
      </div>

      <span className="font-thin">
        Page {currentPage + 1} of {numPages}
      </span>
    </div>
  );
}

export default PageNav;
