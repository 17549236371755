import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { ParentResponse, StudentResponse, TutorResponse } from "client/openapi";
import { Button, ButtonColor, ButtonFill } from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";

function MessageUserDialog({
  messageAllStudents,
  onlyMessageAllOfATutorsStudents,
  messageAllTutors,
  messageAllParents,
  org_id,
  buttonColor,
}: {
  messageAllStudents?: boolean;
  onlyMessageAllOfATutorsStudents?: StudentResponse[];
  messageAllTutors?: boolean;
  messageAllParents?: boolean;
  org_id: number;
  buttonColor: ButtonColor;
}) {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            color={buttonColor}
            fill={ButtonFill.HOLLOW}
            extraClasses="w-full"
          >
            Message All
          </Button>
        </DialogTrigger>

        <ComposeMessageDialog
          messageAllStudents={messageAllStudents}
          students={onlyMessageAllOfATutorsStudents}
          messageAllTutors={messageAllTutors}
          messageAllParents={messageAllParents}
          org_id={org_id}
        />
      </Dialog>
    </>
  );
}

export default MessageUserDialog;
