import { useState, useEffect, useContext } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import SuccessDialog from "components/SuccessDialog";
import WaitingDialog from "components/WaitingDialog";
import ErrorDialog from "components/ErrorDialog";
import { PageStatus } from "types";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "@radix-ui/react-dialog";

function PaymentStatusInfo({
  setupIntentClientSecret,
  studentIdIfInitiatedByAdminFromOrgDashboard,
  parentIdIfInitiatedByAdminFromOrgDashboard,
}: {
  setupIntentClientSecret: string;
  studentIdIfInitiatedByAdminFromOrgDashboard?: string;
  parentIdIfInitiatedByAdminFromOrgDashboard?: string;
}) {
  const stripe = useStripe();
  const [status, setStatus] = useState<PageStatus>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(setupIntentClientSecret)
      .then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        if (setupIntent) {
          switch (setupIntent.status) {
            case "succeeded":
              setStatus(PageStatus.SUCCESS);
              break;

            case "processing":
            case "requires_action":
            case "requires_confirmation":
              setStatus(PageStatus.LOADING);
              break;

            case "requires_payment_method":
            case "canceled":
              setStatus(PageStatus.ERROR);
              break;
          }
        }
      });

    if (status !== undefined) {
      // Add a delay before navigation

      const timer = setTimeout(() => {
        studentIdIfInitiatedByAdminFromOrgDashboard
          ? navigate(
              `/manage/students/${studentIdIfInitiatedByAdminFromOrgDashboard}#billing`
            )
          : parentIdIfInitiatedByAdminFromOrgDashboard
          ? navigate(
              `/manage/parents/${parentIdIfInitiatedByAdminFromOrgDashboard}#billing`
            )
          : navigate(`/dashboard`);
      }, 3000); // 5000 milliseconds = 3 seconds

      // Clean up the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [
    parentIdIfInitiatedByAdminFromOrgDashboard,
    setupIntentClientSecret,
    status,
    stripe,
    studentIdIfInitiatedByAdminFromOrgDashboard,
  ]);

  const renderDialog = () => {
    switch (status) {
      case PageStatus.SUCCESS:
        return (
          <SuccessDialog message="Success! Your payment method has been saved. You will be automatically redirected in 5 seconds." />
        );
      case PageStatus.LOADING:
        return (
          <WaitingDialog message="Processing payment details. We'll update you when processing is complete. You will be automatically redirected in 5 seconds." />
        );
      case PageStatus.ERROR:
        return (
          <ErrorDialog message="Failed to process payment details. Please try another payment method. You will be automatically redirected in 5 seconds." />
        );
      default:
        return <div>Preparing payment details...</div>; // Default case for no status or initial loading
    }
  };

  return (
    <Dialog open={status !== undefined}>
      <DialogContent>
        {renderDialog()}
        <div style={{ height: "1000px" }}></div>
      </DialogContent>
    </Dialog>
  );
}

export default PaymentStatusInfo;
