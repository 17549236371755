import { DialogContent } from "components/Dialog";
import "./index.css";
import { FaExclamation } from "react-icons/fa6";

function ErrorDialog({
  message,
  onClose,
}: {
  message: string;
  onClose?: () => void;
}) {
  return (
    <DialogContent
      onClose={onClose}
      className="dialog-content error-dialog-content text-center flex flex-col justify-center align-center"
    >
      <h2 className="error-dialog--title font-md w-1/2 mx-auto">
        <FaExclamation
          className="mx-auto mb-3"
          style={{ width: "6em", height: "6em", color: "#ffffff" }}
        />
      </h2>
      <h2 className="error-dialog-text">{message}</h2>
    </DialogContent>
  );
}

export default ErrorDialog;
