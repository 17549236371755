import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  Scatter,
  Dot,
  ScatterChart,
} from "recharts";

const topMargin = 10;
const leftMargin = 3;
const bottomMargin = 20;
const rightMargin = 15;

export default function LineChart({
  data,
  xKey,
  xAxisLabel,
  yKey,
  yAxisLabel,
  formatter,
  xDomain,
  yDomain,
}: {
  data: any[];
  xKey: string;
  xAxisLabel: string;
  yKey: string;
  yAxisLabel: string;
  formatter?: (value: number) => string;
  xDomain?: number[];
  yDomain?: number[];
}) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        data={data}
        margin={{
          top: topMargin,
          left: leftMargin,
          bottom: bottomMargin,
          right: rightMargin,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xKey}
          axisLine={{ stroke: "black" }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          domain={data.length === 0 ? [0, 0] : xDomain}
          style={{ fontSize: "14px" }}
          tickFormatter={formatter}
          type="number"
          name={yAxisLabel}
        >
          <Label
            value={xAxisLabel}
            position="insideBottom"
            style={{ textAnchor: "middle", fontSize: "14px" }}
            fill="black"
            fontWeight="bold"
            dy={bottomMargin}
          />
        </XAxis>
        <YAxis
          dataKey={yKey}
          axisLine={{ stroke: "black" }}
          tick={{ fill: "black" }}
          tickLine={{ stroke: "black" }}
          style={{ fontSize: "14px" }}
          domain={yDomain}
          type="number"
        >
          <Label
            value={yAxisLabel}
            position="insideLeft"
            angle={-90}
            fill="black"
            fontWeight="bold"
            style={{ textAnchor: "middle", fontSize: "14px" }}
          />
        </YAxis>
        {data.length > 0 && (
          <Tooltip
            content={({ active, payload, label }) => {
              if (active) {
                return (
                  <div className="bg-white border border-gray-400 rounded-lg p-2">
                    <p className="text-black text-sm">
                      {xAxisLabel}:{" "}
                      {payload && payload[0].payload[xKey] && formatter
                        ? formatter(payload[0].payload[xKey])
                        : payload && payload[0].payload[xKey]}
                    </p>
                    <p className="text-black text-sm">
                      {yAxisLabel}: {payload && payload[0].payload[yKey]}
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
        )}
        <Scatter
          data={data}
          dataKey={yKey}
          shape={<Dot r={3} fill="#7ABFFF" />}
          line={{ stroke: "#7ABFFF" }}
          activeShape={<Dot r={3} fill="#0085FF" />}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
