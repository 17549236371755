import { MeetingDatapoint } from "client/openapi";
import moment from "moment";
import {
  DateRange,
  SEVEN,
  DAYS,
  MONTHS,
  TWELVE,
  YEAR_MONTH_DAY,
} from "./groupMeetingStats";

type AttendanceChartDatapoint = {
  time: number;
  hours: number;
};

export function generateAttendanceChartDPByDateRange(
  data: MeetingDatapoint[],
  range: string,
  start: string,
  until: string
): AttendanceChartDatapoint[] {
  data = filterDataByRange(data, start, until);
  switch (range) {
    case DateRange.WEEK:
      return generateAttendanceChartDPByDayOfWeek(data);
    case DateRange.MONTH:
      return generateAttendanceChartDPByDay(data);
    case DateRange.YEAR:
      return generateAttendanceChartDPByMonth(data);
    default:
      return generateAttendanceChartDPByMonth(data);
  }
}

function filterDataByRange(
  data: MeetingDatapoint[],
  start: string,
  now: string
): MeetingDatapoint[] {
  start = moment(start).format(YEAR_MONTH_DAY);
  now = moment(now).format(YEAR_MONTH_DAY);
  return data.filter((d) => {
    const date = moment(d.date).format(YEAR_MONTH_DAY);
    return date >= start && date <= now;
  });
}

function generateAttendanceChartDPByDayOfWeek(
  data: MeetingDatapoint[]
): AttendanceChartDatapoint[] {
  return generateAttendanceChartDP(data, "YYYY - MM - DD");
}

function generateAttendanceChartDPByDay(
  data: MeetingDatapoint[]
): AttendanceChartDatapoint[] {
  return generateAttendanceChartDP(data, "YYYY - MM - DD");
}

function generateAttendanceChartDPByMonth(
  data: MeetingDatapoint[]
): AttendanceChartDatapoint[] {
  return generateAttendanceChartDP(data, "YYYY - MM");
}

function generateAttendanceChartDP(
  data: MeetingDatapoint[],
  keyFormat: string
): any[] {
  const groupedDates = Object.values(data).reduce((acc, obj) => {
    const date = moment(obj.date);
    const key = `${date.format(keyFormat)}`;
    let hours = obj.hours;

    if (acc[key]) {
      hours += acc[key].hours;
    }

    hours = Math.round(hours * 100) / 100;

    acc[key] = {
      time: date.valueOf(),
      hours: hours,
    };

    return acc;
  }, {});

  return Object.values(groupedDates);
}
