import { Button, ButtonSize } from "components/Button";
import { getButtonRoleColor } from "util/contextColor";
import { getHighestRole } from "util/accounts";
import { DownloadIcon } from "@radix-ui/react-icons";
import StudentDataDialog from "components/Dashboard/StudentDataDialog";
import { Auth0AccountRole, StudentResponse, ApiError } from "client/openapi";
import Notifications from "util/notifications";
import { ExportsService } from "client/openapi";
import { saveAs } from "file-saver";
import { concatenateName } from "util/concatenateName";
import { PageStatus } from "types";
import "../index.css";
import { useContext } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function AdministrativeInfo({
  exportingData,
  setExportingData,
  student,
  organization_id,
  exportingMeetings,
  setStatus,
  setError,
}: {
  exportingData: boolean;
  setExportingData: (boolean) => void;
  student: StudentResponse;
  organization_id: number;
  exportingMeetings: boolean;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);
  async function exportStudent() {
    if (!student) {
      return Notifications.error("Unable to export data.");
    }

    if (exportingMeetings) {
      return Notifications.error("Please wait for other export to complete.");
    }

    setExportingData(true);

    await ExportsService.exportStudent({
      studentId: student.id,
      orgId: organization_id,
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(
            `${student.first_name}'s data export has been saved to your computer!`
          );
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save file.");
        }
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to export student's data" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    setExportingData(false);
  }

  return (
    <section className="student-profile--section" id="student-about">
      <div className="student-profile--section-header">
        <h2>Administrative Info</h2>

        <Button
          color={getButtonRoleColor(
            currently_selected_role
              ? currently_selected_role
              : Auth0AccountRole.ME
          )}
          size={ButtonSize.SMALL}
          onClick={() => exportStudent()}
        >
          Export{" "}
          {exportingData ? (
            <span
              className="spinner-border spinner-border-square"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <DownloadIcon aria-label="Export Data" />
          )}
        </Button>
      </div>

      <div className="student-profile--section-items">
        <div className="student-profile--section-item">
          <StudentDataDialog
            role={currently_selected_role ? currently_selected_role : null}
            student={student}
            organizationId={organization_id}
          />
        </div>
      </div>
    </section>
  );
}
