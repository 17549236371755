import moment from "moment";
import PieChart from "components/Statistics/PieChart";
import {
  OrganizationResponse,
  ChartSubscriptionResponse,
  MeetingStats,
} from "client/openapi";
import OrgAttendance from "components/OrgAttendance";
import PerformanceChart from "components/OrgPerformanceChart";

export default function Metrics({
  charts,
  meetingStats,
  meetingStatsMonth,
  organization,
  updateCharts,
  setUpdateCharts,
}: {
  charts: ChartSubscriptionResponse[];
  meetingStats: MeetingStats;
  meetingStatsMonth: MeetingStats;
  organization: OrganizationResponse;
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
}) {
  return (
    <>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-14 gap-y-8 mx-5 md:mx-0">
        <div className="h-80">
          <OrgAttendance org={organization} meetings={meetingStats} />
        </div>

        {charts &&
          charts
            .slice()
            .sort((a, b) => (a.pinned && !b.pinned ? -1 : 1))
            .map((chart, index) => (
              <div className="h-80" key={chart.id}>
                <PerformanceChart
                  chart={chart}
                  organization={organization}
                  updateCharts={updateCharts}
                  setUpdateCharts={setUpdateCharts}
                />
              </div>
            ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-5 md:mx-0">
        <div className="h-80">
          <div className="flex flex-row items-center justify-between h-1/5">
            <h2 className="font-bold text-xl">
              Meetings This {moment().format("MMMM")}
            </h2>
          </div>

          {meetingStatsMonth && meetingStatsMonth.count > 0 ? (
            <div className="w-full h-4/5 flex flex-row items-center justify-center">
              <PieChart
                data={meetingStatsMonth.summary}
                dataKey="count"
                nameKey="name"
              />
            </div>
          ) : (
            <p>No meetings during this period.</p>
          )}
        </div>
      </div>
    </>
  );
}
