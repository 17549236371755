import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import {
  TutorMeetingResponse,
  StudentResponse,
  Auth0AccountRole,
  TutorsService,
  StudentsService,
  TutorResponse,
  StudentAttendanceResponse,
  StudentAttendanceService,
  Attendance,
  ApiError,
  MeetingResponse,
  OrganizationsService,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import GenericAddButton from "components/GenericAddButton";
import { Tutor, UserTypes } from "types";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import Select from "components/Select";
import { concatenateName } from "util/concatenateName";
import { useAuth0 } from "@auth0/auth0-react";
import { Cross2Icon } from "@radix-ui/react-icons";
import CheckboxInput from "components/Inputs/CheckboxInput";
import MeetingAttendee from "components/MeetingAttendee";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { TutorContext } from "components/Dashboard/TutorDashboard/tutorContext";

export function AttendeesList({
  displayAttendees = true,
  attendees,
  typeOfAttendees,
  editing,
  creating,
  event,
  linkPrefix,
  remove,
  updateAttendees,
  attendance,
  setAttendance,
  append,
}: {
  displayAttendees?: boolean;
  attendees: TutorMeetingResponse[] | StudentResponse[];
  typeOfAttendees: Auth0AccountRole;
  editing: boolean;
  creating: boolean;
  event?: MeetingResponse;
  linkPrefix: string;
  remove: (userType: Auth0AccountRole, id: number) => void;
  updateAttendees: (
    userType: Auth0AccountRole,
    attendees: TutorMeetingResponse[] | StudentResponse[]
  ) => void;
  attendance: StudentAttendanceResponse[];
  setAttendance?: (attendance: StudentAttendanceResponse[]) => void;
  append?: ReactNode[];
}) {
  const { user } = useAuth0();
  const { currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  const { current_tutor, available_tutors } = useContext(TutorContext);
  const [showAddButton, setShowAddButton] = useState(true);
  const [possibleAttendees, setPossibleAttendees] = useState<
    (TutorResponse | StudentResponse)[]
  >([]);
  const [newAttendees, setNewAttendees] = useState(attendees);
  const [tutorCorrespondingToThisMeeting, setTutorCorrespondingToThisMeeting] =
    useState<TutorResponse>();

  useEffect(() => {
    if (available_tutors && current_tutor) {
      setTutorCorrespondingToThisMeeting(
        event
          ? available_tutors.find(
              (tutor) => tutor.org_id === event.subject.org_subject.org_id
            )
          : current_tutor
      );
    }
  }, [available_tutors, current_tutor, event]);

  const handleAttendeesUpdate = useCallback(
    (options) => {
      const a = [...newAttendees, options];
      setNewAttendees(a);
      updateAttendees(typeOfAttendees, a);
    },
    [newAttendees, updateAttendees, typeOfAttendees]
  );

  const handleAttendeeRemove = (id) => {
    let castedAttendees;
    if (typeOfAttendees === Auth0AccountRole.ME) {
      castedAttendees = newAttendees as StudentResponse[];
    } else {
      castedAttendees = newAttendees as TutorMeetingResponse[];
    }

    setNewAttendees(
      castedAttendees.filter((a) => {
        return a.id !== id;
      })
    );

    remove(typeOfAttendees, id);
  };

  async function getUsers() {
    if (!user) {
      return null;
    }
    const newAttendeeIds = newAttendees.map((a) => a.id);

    if (typeOfAttendees === Auth0AccountRole.ORG_TUTOR) {
      await TutorsService.getTutorsByOrganization({
        orgId: event
          ? event.subject.org_subject.org_id
          : (currently_selected_organization as number),
      }).then((allTutors) => {
        const availableTutors = allTutors.filter(
          (t) => !newAttendeeIds.includes(t.id)
        );
        setPossibleAttendees(availableTutors);
      });
    } else if (typeOfAttendees === Auth0AccountRole.ME) {
      if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
        await StudentsService.getStudentsByOrganizationIfAdmin({
          orgId: event
            ? event.subject.org_subject.org_id
            : (currently_selected_organization as number),
        }).then((allStudents) => {
          const availableStudents = allStudents.filter(
            (s) => !newAttendeeIds.includes(s.id)
          );
          setPossibleAttendees(availableStudents);
        });
      }

      if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
        await StudentsService.getStudentsByOrganizationIfTutor({
          orgId: event
            ? event.subject.org_subject.org_id
            : (currently_selected_organization as number),
        }).then((allStudents) => {
          const availableStudents = allStudents.filter(
            (s) => !newAttendeeIds.includes(s.id)
          );
          setPossibleAttendees(availableStudents);
        });
      }

      if (currently_selected_role === Auth0AccountRole.PARENT) {
        await StudentsService.getStudentsByOrganizationIfParent({
          orgId: event
            ? event.subject.org_subject.org_id
            : (currently_selected_organization as number),
        }).then((allStudents) => {
          const availableStudents = allStudents.filter(
            (s) => !newAttendeeIds.includes(s.id)
          );
          setPossibleAttendees(availableStudents);
        });
      }

      if (currently_selected_role === Auth0AccountRole.ME) {
        await StudentsService.getStudentsByOrganizationIfStudent({
          orgId: event
            ? event.subject.org_subject.org_id
            : (currently_selected_organization as number),
        }).then((allStudents) => {
          const availableStudents = allStudents.filter(
            (s) => !newAttendeeIds.includes(s.id)
          );
          setPossibleAttendees(availableStudents);
        });
      }
    }
  }

  async function updateStudentAttendance(student_id: number, checked: boolean) {
    const id = attendance.find((a) => a.student_id === student_id)?.id;
    if (!id) {
      return;
    }
    StudentAttendanceService.updateStudentAttendance({
      studentAttendanceId: id,
      requestBody: {
        status: checked ? Attendance.PRESENT : Attendance.ABSENT,
      },
    })
      .then(() => Notifications.success("Attendance updated!"))
      .catch((e: ApiError) => {
        Notifications.error("Attendance could not be updated at this time!");
      });
  }

  const filterPossibleAttendees = (attendees) => {
    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      return attendees;
    }

    if (typeOfAttendees === Auth0AccountRole.ME) {
      return attendees.filter((student) =>
        tutorCorrespondingToThisMeeting?.students
          .map((tutorStudent) => tutorStudent.id)
          .includes(student.id)
      );
    } else {
      return possibleAttendees.filter(
        (attendee) => attendee.id !== tutorCorrespondingToThisMeeting?.id
      );
    }
  };

  useEffect(() => {
    if (event || currently_selected_organization) {
      getUsers();
    }
  }, [attendees, currently_selected_organization, event]);

  // If you are on the tutor dashboard or student dashboard, the tutor object or student object
  // asosciated with your account and currently selected organization is auto added when
  // creating a new Meeting.
  useEffect(() => {
    if (creating) {
      if (
        current_tutor &&
        currently_selected_role === Auth0AccountRole.ORG_TUTOR &&
        typeOfAttendees === Auth0AccountRole.ORG_TUTOR
      ) {
        updateAttendees(typeOfAttendees, [current_tutor]);
        setNewAttendees([current_tutor]);
      }

      // if (
      //   current_student &&
      //   currently_selected_role === Auth0AccountRole.ME &&
      //   userType === Auth0AccountRole.ME
      // ) {
      //   updateAttendees(userType, [current_student]);
      //   setNewAttendees([current_student]);
      // }
    }
  }, [
    currently_selected_role,
    editing,
    current_tutor,
    typeOfAttendees /*,current_student */,
  ]);

  // Everytime there is an update to attendees or the currently selected org, check that all attendees'
  // org_ids correspond with the currently selected org and that there are no duplicate attendees.
  // Meeting attendees that don't belong are cleared.
  useEffect(() => {
    if (editing || creating) {
      const current_org = event
        ? event.subject.org_subject.org_id
        : (currently_selected_organization as number);

      let castedAttendees;
      if (typeOfAttendees === Auth0AccountRole.ME) {
        castedAttendees = newAttendees as StudentResponse[];
      } else {
        castedAttendees = newAttendees as TutorMeetingResponse[];
      }

      // Make sure all attendees have the correct org id
      const attendeesThatBelong = castedAttendees.filter(
        (attendee) => attendee.org_id === current_org
      );

      // Remove duplicates: track seen IDs and filter out repeated ones
      const seenIds = new Set();
      const uniqueAttendees = attendeesThatBelong.filter((attendee) => {
        const isDuplicate = seenIds.has(attendee.id);
        seenIds.add(attendee.id);
        return !isDuplicate;
      });

      if (uniqueAttendees.length !== newAttendees.length) {
        if (typeOfAttendees === Auth0AccountRole.ORG_TUTOR) {
          updateAttendees(
            typeOfAttendees,
            uniqueAttendees as TutorMeetingResponse[]
          );
          setNewAttendees(uniqueAttendees as TutorMeetingResponse[]);
        } else if (typeOfAttendees === Auth0AccountRole.ME) {
          updateAttendees(
            typeOfAttendees,
            uniqueAttendees as StudentResponse[]
          );
          setNewAttendees(uniqueAttendees as StudentResponse[]);
        }
      }
    }
  }, [
    currently_selected_organization,
    newAttendees,
    typeOfAttendees,
    attendees,
    event,
    editing,
    creating,
  ]);

  const handleLinkToStudentOrTutorPage = (attendee) => {
    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      return window.open(
        `${window.location.origin}${linkPrefix}${attendee.id}`
      );
    }

    if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
      if (linkPrefix === "/manage/students/") {
        return window.open(
          `${window.location.origin}${linkPrefix}${attendee.id}`
        );
      }
    }
  };

  return (
    <>
      {displayAttendees &&
        attendees.map((attendee, index) => (
          <div className="pb-3" key={attendee.id}>
            <MeetingAttendee
              userType={typeOfAttendees}
              key={attendee.id}
              attendee={attendee}
              newView={true}
              onClick={() => {
                handleLinkToStudentOrTutorPage(attendee);
              }}
              append={append && append[index]}
            >
              {typeOfAttendees === Auth0AccountRole.ME && !editing && (
                <CheckboxInput
                  id={attendee.id}
                  checked={
                    attendance?.find((a) => a.student_id === attendee.id)
                      ?.status === "present"
                  }
                  onCheck={(checked: boolean) => {
                    updateStudentAttendance(attendee.id, checked);
                    const newAttendance = attendance.map((a) => {
                      if (a.student_id === attendee.id) {
                        return {
                          ...a,
                          status: checked
                            ? Attendance.PRESENT
                            : Attendance.ABSENT,
                        };
                      }
                      return a;
                    });
                    setAttendance && setAttendance(newAttendance);
                  }}
                />
              )}
              {editing &&
                (typeOfAttendees === Auth0AccountRole.ME ||
                  (typeOfAttendees === Auth0AccountRole.ORG_TUTOR &&
                    tutorCorrespondingToThisMeeting?.id !== attendee.id)) && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleAttendeeRemove(attendee.id);
                    }}
                  >
                    <Cross2Icon />
                  </div>
                )}
            </MeetingAttendee>
          </div>
        ))}

      {editing && (
        <div className="pb-1">
          {showAddButton ? (
            <GenericAddButton
              userType={typeOfAttendees}
              newView={true}
              onClick={() => setShowAddButton(false)}
            ></GenericAddButton>
          ) : (
            <>
              <Select
                id={`add-${typeOfAttendees}`}
                name={`add-${typeOfAttendees}`}
                options={filterPossibleAttendees(possibleAttendees)}
                value={null}
                getOptionLabel={(a) => `${a ? concatenateName(a) : ""}`}
                getOptionValue={(a) => (a ? a.id.toString() : "")}
                onChange={handleAttendeesUpdate}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default function MeetingAttendees({
  tutors,
  students,
  role,
  editing,
  creating,
  event,
  removeAttendee,
  updateAttendees,
  attendance,
  setAttendance,
}: {
  tutors: TutorMeetingResponse[];
  students: StudentResponse[];
  role: Auth0AccountRole;
  editing: boolean;
  creating: boolean;
  event?: MeetingResponse;
  removeAttendee: (userType: Auth0AccountRole, id: number) => void;
  updateAttendees: (
    userType: Auth0AccountRole,
    attendees: TutorMeetingResponse[] | StudentResponse[]
  ) => void;
  attendance: StudentAttendanceResponse[];
  setAttendance: (attendance: StudentAttendanceResponse[]) => void;
}) {
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  return (
    <div className="bg-gray-100 rounded-lg col-span-12 md:col-span-3 m-0">
      <div className="bg-white p-5 rounded-t-lg">
        <h1 className="font-bold text-2xl">People</h1>
      </div>
      <div className="p-5">
        <div className="pb-2">
          <h2 className="font-bold mb-1">Tutors</h2>
          <AttendeesList
            attendees={tutors ?? []}
            typeOfAttendees={Auth0AccountRole.ORG_TUTOR}
            editing={editing}
            creating={creating}
            event={event}
            linkPrefix={"/manage/tutors/"}
            remove={removeAttendee}
            updateAttendees={updateAttendees}
            attendance={[]}
          />
        </div>
        <div className="flex justify-between">
          <h2 className="font-bold mb-1">Students</h2>
          {!editing && <h2 className="mb-1 mt-4 text-sm">Attendance</h2>}
        </div>
        <AttendeesList
          attendees={students ?? []}
          typeOfAttendees={Auth0AccountRole.ME}
          editing={editing}
          creating={creating}
          event={event}
          linkPrefix={"/manage/students/"}
          remove={removeAttendee}
          updateAttendees={updateAttendees}
          attendance={attendance}
          setAttendance={setAttendance}
        />
        {!editing ? (
          <Dialog>
            <DialogTrigger asChild>
              <Button
                extraClasses="mt-5 w-full bg-white"
                color={ButtonColor.GREEN}
                fill={ButtonFill.DEFAULT}
                size={ButtonSize.SMALL}
              >
                <p className="font-semibold">Message</p>
              </Button>
            </DialogTrigger>
            {!editing && (
              <ComposeMessageDialog
                students={students}
                tutors={tutors}
                org_id={
                  event
                    ? event.subject.org_subject.org_id
                    : (currently_selected_organization as number)
                }
              />
            )}
          </Dialog>
        ) : null}
      </div>
    </div>
  );
}
