import {
  ApiError,
  ExportsService,
  FilterBy,
  GroupBy,
  SortBy,
} from "client/openapi";
import { ButtonColor } from "components/Button";
import {
  DialogActions,
  DialogAction,
  DialogContent,
  DialogClose,
} from "components/Dialog";
import moment from "moment";
import { useState, useRef } from "react";
import { DatePicker, InputGroup } from "rsuite";
import "./index.css";
import Notifications from "util/notifications";
import { saveAs } from "file-saver";

function ExportDialog({ organizationId }: { organizationId: number }) {
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"));
  const [endDate, setEndDate] = useState(moment());

  const [exportOption, setExportOption] = useState("group-tutor");
  const [filterOption, setFilterOption] = useState("all");

  const dialogRef = useRef<HTMLDivElement>(null);

  function exportData() {
    ExportsService.exportPayrollData({
      orgId: organizationId,
      start: startDate.format("YYYY-MM-DD"),
      until: endDate.format("YYYY-MM-DD"),
      sortBy:
        exportOption === "date"
          ? SortBy.DATE
          : exportOption === "tutor"
          ? SortBy.TUTOR
          : exportOption === "student"
          ? SortBy.STUDENT
          : undefined,
      filterBy:
        filterOption === "all"
          ? FilterBy.ALL_SESSIONS
          : filterOption === "approved"
          ? FilterBy.APPROVED_SESSIONS
          : filterOption === "denied"
          ? FilterBy.DENIED_SESSIONS
          : filterOption === "neither"
          ? FilterBy.NEITHER_APPROVED_NOR_DENIED
          : undefined,
      groupBy:
        exportOption === "group-tutor"
          ? GroupBy.TUTOR
          : exportOption === "group-student"
          ? GroupBy.STUDENT
          : undefined,
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(`Meetings have been saved to your computer!`);
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save meetings to your computer.");
        }
      })
      .catch((e: ApiError) => {
        Notifications.error("Unable to get meetings.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  const handleExportChange = (value) => {
    setExportOption((current) => (current === value ? "" : value)); // Toggle the export option
  };

  const handleFilterChange = (value) => {
    setFilterOption((current) => (current === value ? "" : value)); // Toggle the filter option
  };

  return (
    <>
      <DialogContent ref={dialogRef} className="export-dialog">
        <h2 className="invite-dialog--title">Export Data</h2>
        <span className="font-bold">Date</span>
        <InputGroup style={{ width: "350px" }}>
          <DatePicker
            oneTap
            defaultValue={startDate.toDate()}
            onChange={(value) => setStartDate(moment.utc(value as Date))}
            onKeyDown={(e) => e.preventDefault()}
            format="yyyy-MM-dd "
            size="xs"
            cleanable={false}
            container={() => dialogRef.current || document.body}
            block
          />
          <InputGroup.Addon>to</InputGroup.Addon>
          <DatePicker
            oneTap
            defaultValue={endDate.toDate()}
            onChange={(value) => setEndDate(moment.utc(value as Date))}
            onKeyDown={(e) => e.preventDefault()}
            format="yyyy-MM-dd"
            size="xs"
            cleanable={false}
            container={() => dialogRef.current || document.body}
            block
          />
        </InputGroup>
        <h3 className="font-bold">Sort or Group By</h3>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "group-tutor" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "group-tutor"}
            onChange={() => handleExportChange("group-tutor")}
          />
          &nbsp;
          <span className="text-sm">Group by tutor</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "group-student" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "group-student"}
            onChange={() => handleExportChange("group-student")}
          />
          &nbsp;
          <span className="text-sm">Group by student</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "date" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "date"}
            onChange={() => handleExportChange("date")}
          />
          &nbsp;
          <span className="text-sm">Sort by date</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "tutor" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "tutor"}
            onChange={() => handleExportChange("tutor")}
          />
          &nbsp;
          <span className="text-sm">Sort by tutor</span>
        </div>
        <div>
          <input
            type="radio"
            name="sortOrGroup"
            className={`!border-black ml-2 rounded ${
              exportOption === "student" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={exportOption === "student"}
            onChange={() => handleExportChange("student")}
          />
          &nbsp;
          <span className="text-sm">Sort by student</span>
        </div>
        <h3 className="font-bold">Filter By</h3>
        <div>
          <input
            type="radio"
            name="filter"
            className={`!border-black ml-2 rounded ${
              filterOption === "all" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={filterOption === "all"}
            onChange={() => handleFilterChange("all")}
          />
          &nbsp;
          <span className="text-sm">All meetings</span>
        </div>
        <div>
          <input
            type="radio"
            name="filter"
            className={`!border-black ml-2 rounded ${
              filterOption === "approved" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={filterOption === "approved"}
            onChange={() => handleFilterChange("approved")}
          />
          &nbsp;
          <span className="text-sm">Approved meetings</span>
        </div>
        <div>
          <input
            type="radio"
            name="filter"
            className={`!border-black ml-2 rounded ${
              filterOption === "denied" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={filterOption === "denied"}
            onChange={() => handleFilterChange("denied")}
          />
          &nbsp;
          <span className="text-sm">Denied meetings</span>
        </div>
        <div>
          <input
            type="radio"
            name="filter"
            className={`!border-black ml-2 rounded ${
              filterOption === "neither" ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={filterOption === "neither"}
            onChange={() => handleFilterChange("neither")}
          />
          &nbsp;
          <span className="text-sm">Meetings yet to be appoved or denied</span>
        </div>
        <DialogActions>
          <DialogClose asChild>
            <DialogAction
              primary={true}
              color={ButtonColor.GREEN}
              type="submit"
              onClick={exportData}
            >
              Export
            </DialogAction>
          </DialogClose>

          <DialogClose asChild>
            <DialogAction color={ButtonColor.BLACK}>Close</DialogAction>
          </DialogClose>
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default ExportDialog;
