import Buttons from "./Buttons";
import Credit from "./Credit";
import Statistics from "./Statistics";
import Transactions from "./Transactions";
import "./PaymentsTab.css";
import { useCallback, useEffect, useState } from "react";
import {
  OrganizationsService,
  StudentResponse,
  StudentsService,
} from "client/openapi";
import moment from "moment";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

function Payments({
  student,
  orgId,
}: {
  student: StudentResponse;
  orgId: number;
}) {
  const [clientSecret, setClientSecret] = useState<string>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  let currentDate = new Date(); // Get the current date and time
  let pastDate = new Date(currentDate); // Create a new Date object based on the current date
  pastDate.setMonth(pastDate.getMonth() - 6);

  const [startDate, setStartDate] = useState(pastDate.toISOString());
  const [endDate, setEndDate] = useState(currentDate.toISOString());

  useEffect(() => {
    const getClientSecretForSetupIntent = async () => {
      setStatus(PageStatus.LOADING);
      try {
        const clientSecret = await StudentsService.addPaymentMethodStudent({
          studentId: student.id,
        });
        setStatus(PageStatus.SUCCESS);
        setClientSecret(clientSecret);
      } catch (e: any) {
        setStatus(PageStatus.ERROR);
        setError({ error: "Could not get student payment details." });
        console.error(`Error (#${e.status}): ${e.message}`);
      }
    };

    getClientSecretForSetupIntent();
  }, [orgId, student.id]);

  const options = { clientSecret: clientSecret };

  const [transactions, setTransactions] = useState([]);

  const getTransactions = useCallback(async () => {
    const response =
      await OrganizationsService.getOrganizationTransactionHistoryByStudent({
        studentId: student.id,
        start: moment.utc(startDate).format("YYYY-MM-DD"),
        until: moment.utc(endDate).add(1, "days").format("YYYY-MM-DD"),
        limit: 1000,
      });
    setTransactions(
      response.sort((a, b) => moment(b.timestamp).diff(moment(a.timestamp)))
    );
  }, [endDate, startDate, student.id]);

  useEffect(() => {
    getTransactions();
  }, [endDate, getTransactions, startDate]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (!options.clientSecret) {
    if (status === PageStatus.LOADING) {
      return <LoadingBlock status={status} />;
    }

    if (status === PageStatus.ERROR) {
      return <ErrorBlock status={status} message={error?.message} />;
    }
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div>
            <Credit student={student} orgId={orgId} />
          </div>
          <div className="mt-5 lg:mt-0">
            <Buttons
              transactions={[]} //disable trends chart for now
              student={student}
              orgId={orgId}
            />
          </div>
        </div>
        <div className="mt-8">
          <Statistics
            student={student}
            orgId={orgId}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
        <div>
          <Transactions
            student={student}
            orgId={orgId}
            transactions={transactions}
          />
        </div>
      </div>
    </Elements>
  );
}
export default Payments;
