import { Auth0AccountRole, OrganizationResponse } from "client/openapi";
import { ButtonColor } from "components/Button";
import { ErrorBlock } from "components/StatusBlock";
import { Organization, PageStatus, Tutor, UserType } from "types";
import { getUserString } from "../UserListActions/helpers";
import InviteUserDialog from "../UserListActions/InviteUserDialog";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { useContext } from "react";

function EmptyState({
  type_of_user_being_invited,
  includeInviteUserDialog,
  title,
  message,
  buttonColor,
  fetchUsers,
  currentPage,
  orgId,
}: {
  type_of_user_being_invited: Auth0AccountRole;
  includeInviteUserDialog: boolean;
  title: string;
  message: string;
  buttonColor: ButtonColor;
  fetchUsers: (page: number) => Promise<void>;
  currentPage: number;
  orgId: number;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  return (
    <>
      <div className="py-6 px-7 mt-6 rounded-xl text-center bg-neutral-50 border border-dashed border-slate-400">
        <ErrorBlock status={PageStatus.ERROR} title={title} message={message} />

        {includeInviteUserDialog &&
        [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
          currently_selected_role as Auth0AccountRole
        ) ? (
          <InviteUserDialog
            buttonText={`Invite ${getUserString(null)}`}
            buttonColor={buttonColor}
            type_of_user_being_invited={type_of_user_being_invited}
            fetchUsers={fetchUsers}
            currentPage={currentPage}
            orgId={orgId}
          />
        ) : null}
      </div>
    </>
  );
}

export default EmptyState;
