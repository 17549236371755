import { TagColor, TagSize, TagFill } from "..";
import "./index.css";

export function Tag({
  size = TagSize.DEFAULT,
  item,
  color = TagColor.DEFAULT,
  fill = TagFill.DEFAULT,
}: {
  size?: TagSize;
  item: string;
  color?: TagColor;
  fill?: TagFill;
}) {
  return <span className={`tag ${size} ${color} ${fill}`}>{item}</span>;
}
