import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { ReactNode } from "react";
import "./index.css";

export function Tooltip({ children }: { children: ReactNode }) {
  return (
    <TooltipPrimitive.Provider delayDuration={200}>
      <TooltipPrimitive.Root>{children}</TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

export function TooltipTrigger({ children }: { children: ReactNode }) {
  return (
    <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
  );
}

export function TooltipContent({ children }: { children: ReactNode }) {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content className="TooltipContent z-50" sideOffset={5}>
        {children}
        <TooltipPrimitive.Arrow className="TooltipArrow" />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
}
