import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { OrganizationsService, StudentSubjectsService } from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import BalanceDetails from "./balanceDetails";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";

function Credit({ orgId }: { orgId: number }) {
  interface Credits {
    [key: number]: number;
  }

  const [creditsOwedToStudents, setCreditsOwedToStudents] = useState<number>();
  const [pendingBalance, setPendingBalance] = useState<number>(0);
  const [availableBalance, setAvailableBalance] = useState<number>(0);
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  const getOrgBalance = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrganizationsService.getBalanceByOrg({
      orgId: orgId,
    })
      .then((balance) => {
        setAvailableBalance(balance.available_amount);
        setPendingBalance(balance.pending_amount);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  const getCreditsOwedToStudents = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentSubjectsService.getStudentSubjectsByOrg({
      orgId: orgId,
    })
      .then((credits) => {
        let total = 0;

        credits.forEach((studentSubject) => {
          total += studentSubject.credits;
        });

        setCreditsOwedToStudents(total);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  useEffect(() => {
    getCreditsOwedToStudents();
    getOrgBalance();
  }, [getCreditsOwedToStudents, getOrgBalance]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <div className="credit-container pt-2.5 mx-2">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="balance-styling">{`Account Balance: ${new Intl.NumberFormat(
          "en-US",
          {
            style: "currency",
            currency: "USD",
          }
        ).format(availableBalance ? availableBalance / 100 : 0)}`}</div>
        <Dialog>
          <DialogTrigger>
            <div className="balance-button-styling text-sm rounded-full py-0.5 px-3 ml-3">
              Payout
            </div>
          </DialogTrigger>
          <BalanceDetails
            availableBalance={availableBalance}
            pendingBalance={pendingBalance}
            orgId={orgId}
          />
        </Dialog>
      </div>
      <div className="pay-per-session-styling flex items-center gap-2">
        Tutoring paid for by students but not yet completed:{" "}
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(creditsOwedToStudents ? creditsOwedToStudents / 100 : 0)}
      </div>
    </div>
  );
}
export default Credit;
