import {
  ChartSubscriptionResponse,
  MetricValueResponse,
  ChartXAxisType,
} from "client/openapi";
import { PerformanceChartDatapoint, filterChartDP } from ".";
import { useState, useEffect } from "react";
import { DialogContent, Dialog, DialogTrigger } from "components/Dialog";
import DayInput from "components/DayInput";
import { ButtonColor, Button } from "components/Button";
import ViewEditDataDialog from "./ViewEditDataDialog";
import LineChart from "components/LineChart";
import "./index.css";
import moment from "moment";

export function ExpandedChartDialog({
  studentId,
  xAxisLabel,
  yAxisLabel,
  chart,
  minMax,
  initialChartDP,
  updateCharts,
  setUpdateCharts,
  addMetricValueToRawData,
  deleteMetricValueFromRawData,
}: {
  studentId: number;
  xAxisLabel: string;
  yAxisLabel: string;
  chart: ChartSubscriptionResponse;
  minMax: number[];
  initialChartDP: PerformanceChartDatapoint[];
  updateCharts: boolean;
  setUpdateCharts: (updateCharts: boolean) => void;
  addMetricValueToRawData: (data: MetricValueResponse) => void;
  deleteMetricValueFromRawData: (data: number) => void;
}) {
  const [chartDP, setChartDP] =
    useState<PerformanceChartDatapoint[]>(initialChartDP);
  const [start, setStart] = useState<number>(minMax[0]);
  const [end, setEnd] = useState<number>(minMax[1]);

  let yDomain: number[] = [];
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_min);
  yDomain.push(chart.chart_prototype.metric_definition.y_axis_max);

  useEffect(() => {
    setStart(minMax[0]);
    setEnd(minMax[1]);
  }, [minMax]);

  useEffect(() => {
    const filteredDP = filterChartDP(initialChartDP, start, end);
    setChartDP(filteredDP);
  }, [start, end, initialChartDP]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left medium-wide pb-2 px-12 font-montserrat"
      style={{ height: "85vh" }}
      onClose={() => {
        setStart(minMax[0]);
        setEnd(minMax[1]);
        setChartDP(initialChartDP);
      }}
    >
      <div className="h-1/5 pb-5 pt-10">
        <h2 className="font-bold text-3xl">
          {chart.chart_prototype.chart_title}
        </h2>

        <div className="flex flex-row items-center justify-between">
          {chart.chart_prototype.chart_x_axis_type === ChartXAxisType.DATE && (
            <div className="flex flex-row gap-2 py-2">
              from
              <DayInput
                id="expanded-start-date"
                value={start}
                setValue={setStart}
                classes="border-b w-32 h-7 border-gray-300"
                required={true}
              />
              to
              <DayInput
                id="expanded-end-date"
                value={end}
                setValue={setEnd}
                min={start + 86400000}
                classes="border-b w-32 h-7 border-gray-300"
                required={true}
              />
            </div>
          )}

          {chart.chart_prototype.chart_x_axis_type ===
            ChartXAxisType.HOURS_OF_TUTORING && (
            <div className="flex flex-row gap-2 py-2">
              from
              <div className="w-20 h-7 border-b border-gray-300">
                <input
                  type="number"
                  id="expanded-start-hour"
                  value={isNaN(start) ? "" : start}
                  placeholder="Start"
                  onChange={(e) => setStart(parseFloat(e.target.value))}
                  className="border-transparent focus:border-transparent focus:ring-0 w-full h-full"
                />
              </div>
              to
              <div className="w-20 h-7 border-b border-gray-300">
                <input
                  type="number"
                  id="expanded-end-hour"
                  value={isNaN(end) ? "" : end}
                  min={isNaN(start) ? 0 : start}
                  placeholder="End"
                  onChange={(e) => setEnd(parseFloat(e.target.value))}
                  className="border-transparent focus:border-transparent focus:ring-0 w-full h-full"
                />
              </div>
              hours
            </div>
          )}

          <Dialog>
            <DialogTrigger asChild>
              <Button color={ButtonColor.SKYBLUE} extraClasses="rounded-xl">
                View/Edit Data
              </Button>
            </DialogTrigger>

            <ViewEditDataDialog
              studentId={studentId}
              xAxisLabel={xAxisLabel}
              yAxisLabel={yAxisLabel}
              chart={chart}
              minMax={minMax}
              initialChartDP={initialChartDP}
              updateCharts={updateCharts}
              setUpdateCharts={setUpdateCharts}
              addMetricValueToRawData={addMetricValueToRawData}
              deleteMetricValueFromRawData={deleteMetricValueFromRawData}
            />
          </Dialog>
        </div>
      </div>

      <div className="h-4/5 flex flex-row pt-3">
        <div className="h-full w-full">
          {chartDP && (
            <LineChart
              data={chartDP}
              xKey="xKey"
              xAxisLabel={xAxisLabel}
              yKey="score"
              yAxisLabel={yAxisLabel}
              formatter={
                chart.chart_prototype.chart_x_axis_type === ChartXAxisType.DATE
                  ? (timestamp) => moment(timestamp).format("MM/DD/YYYY")
                  : undefined
              }
              xDomain={[start, end]}
              yDomain={yDomain}
            />
          )}
        </div>
      </div>
    </DialogContent>
  );
}
