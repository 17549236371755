import { UserType } from "types";
import InviteStudentDialog from "../InviteStudentDialog";
import InviteTutorDialog from "../InviteTutorDialog";
import { Auth0AccountRole } from "client/openapi";
import InviteParentDialog from "../InviteParentDialog";

export const getUserString = (userType: Auth0AccountRole | null) => {
  switch (userType) {
    case Auth0AccountRole.ME:
      return "Student";
    case Auth0AccountRole.ORG_TUTOR:
      return "Tutor";
    case Auth0AccountRole.PARENT:
      return "Parent";
    case Auth0AccountRole.ORG_ADMIN:
      return "Admin";
    default:
      return "User";
  }
};

export const getDialog = ({
  type_of_user_being_invited,
  fetchUsers,
  currentPage,
  orgId,
}: {
  type_of_user_being_invited: Auth0AccountRole;
  fetchUsers: any;
  currentPage: number;
  orgId: number;
}) => {
  switch (type_of_user_being_invited) {
    case Auth0AccountRole.ORG_TUTOR:
      return (
        <InviteTutorDialog fetchTutors={fetchUsers} currentPage={currentPage} />
      );
    case Auth0AccountRole.ME:
      return (
        <InviteStudentDialog
          fetchStudents={fetchUsers}
          currentPage={currentPage}
          orgId={orgId}
        />
      );
    case Auth0AccountRole.PARENT:
      return (
        <InviteParentDialog
          fetchParents={fetchUsers}
          currentPage={currentPage}
        />
      );
    default:
      return null;
  }
};
