import { DialogContent } from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { OrganizationsService } from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import "./index.css";

export default function PaymentPolicies({ orgId }: { orgId: number }) {
  const [chargeForAbsence, setChargeForAbsence] = useState<boolean>();
  const [missedSessionRate, setMissedSessionRate] = useState<number>();
  const [invalidMissedSessionRate, setInvalidMissedSessionRate] =
    useState<boolean>(false);
  const [chargeForCancelledSession, setChargeForCancelledSession] =
    useState<boolean>();
  const [cancellationThreshold, setCancellationThreshold] = useState<number>();
  const [canceledInAdvanceRate, setCanceledInAdvanceRate] = useState<number>();
  const [canceledAfterRate, setCanceledAfterRate] = useState<number>();
  const [invalidCancellationThreshold, setInvalidCancellationThreshold] =
    useState<boolean>(false);
  const [invalidCanceledInAdvanceRate, setInvalidCanceledInAdvanceRate] =
    useState<boolean>(false);
  const [invalidCanceledAfterRate, setInvalidCanceledAfterRate] =
    useState<boolean>(false);
  const [chargeForRescheduledSession, setChargeForRescheduledSession] =
    useState<boolean>();
  const [reschedulingThreshold, setReschedulingThreshold] = useState<number>();
  const [rescheduledInAdvanceRate, setRescheduledInAdvanceRate] =
    useState<number>();
  const [rescheduledAfterRate, setRescheduledAfterRate] = useState<number>();
  const [invalidReschedulingThreshold, setInvalidReschedulingThreshold] =
    useState<boolean>(false);
  const [invalidRescheduledInAdvanceRate, setInvalidRescheduledInAdvanceRate] =
    useState<boolean>(false);
  const [invalidRescheduledAfterRate, setInvalidRescheduledAfterRate] =
    useState<boolean>(false);

  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  async function editPaymentPolicy() {
    if (
      (chargeForAbsence && invalidMissedSessionRate) ||
      (chargeForCancelledSession &&
        (invalidCancellationThreshold ||
          invalidCanceledInAdvanceRate ||
          invalidCanceledAfterRate)) ||
      (chargeForRescheduledSession &&
        (invalidReschedulingThreshold ||
          invalidRescheduledInAdvanceRate ||
          invalidRescheduledAfterRate))
    ) {
      setError({
        message: "Please ensure all fields are valid.",
      });
      return;
    }

    await OrganizationsService.updateOrgPricingConditions({
      orgId: orgId,
      requestBody: {
        org_id: orgId,
        charge_for_absence: chargeForAbsence,
        absence_percentage_charged: chargeForAbsence
          ? missedSessionRate
          : undefined,
        charge_for_cancelled_session: chargeForCancelledSession,
        cancellation_threshold: chargeForCancelledSession
          ? cancellationThreshold
          : undefined,
        early_cancellation_percentage_charged: chargeForCancelledSession
          ? canceledInAdvanceRate
          : undefined,
        late_cancellation_percentage_charged: chargeForCancelledSession
          ? canceledAfterRate
          : undefined,
        charge_for_rescheduled_session: chargeForRescheduledSession,
        rescheduling_threshold: chargeForRescheduledSession
          ? reschedulingThreshold
          : undefined,
        early_rescheduling_percentage_charged: chargeForRescheduledSession
          ? rescheduledInAdvanceRate
          : undefined,
        late_rescheduling_percentage_charged: chargeForRescheduledSession
          ? rescheduledAfterRate
          : undefined,
      },
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Student payment policies updated!`);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  }
  const verifyMissedSessionRate: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) <= 0) {
      setInvalidMissedSessionRate(true);
      setError({
        message: "Please enter a value greater than %0",
      });
      return;
    }
    // value is under limit
    if (Number(value) > 100) {
      setInvalidMissedSessionRate(true);
      setError({
        message: "Please enter a value less than or equal to 100%",
      });
      return;
    }
    // value is under limit
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidMissedSessionRate(true);
      setError({
        message: "Please enter a non empty %",
      });
      return;
    }
    setInvalidMissedSessionRate(false);
    setMissedSessionRate(Number(value));
  };
  const verifyCancelledInAdvanceRate: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) < 0 || Number(value) > 100) {
      setInvalidCanceledInAdvanceRate(true);
      setError({
        message:
          "Please enter a value greater than or equal to %0 and less than or equal to 100%",
      });
      return;
    }
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidCanceledInAdvanceRate(true);
      setError({
        message: "Please enter a non empty %",
      });
      return;
    }
    setInvalidCanceledInAdvanceRate(false);
    setCanceledInAdvanceRate(Number(value));
  };
  const verifyCancelledAfterRate: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) < 0 || Number(value) > 100) {
      setInvalidCanceledAfterRate(true);
      setError({
        message:
          "Please enter a value greater than or equal to %0 and less than or equal to 100%",
      });
      return;
    }
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidCanceledAfterRate(true);
      setError({
        message: "Please enter a non empty %",
      });
      return;
    }
    setInvalidCanceledAfterRate(false);
    setCanceledAfterRate(Number(value));
  };
  const verifyCancelledThreshhold: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) < 0 || Number(value) > 100) {
      setInvalidCancellationThreshold(true);
      setError({
        message:
          "Please enter a value greater than or equal to 0 and less than or equal to 168",
      });
      return;
    }
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidCancellationThreshold(true);
      setError({
        message: "Please enter a non empty number for hours",
      });
      return;
    }
    setInvalidCancellationThreshold(false);
    setCancellationThreshold(Number(value));
  };
  const verifyRescheduledInAdvanceRate: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) < 0 || Number(value) > 100) {
      setInvalidRescheduledInAdvanceRate(true);
      setError({
        message:
          "Please enter a value greater than or equal to %0 and less than or equal to 100%",
      });
      return;
    }
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidRescheduledInAdvanceRate(true);
      setError({
        message: "Please enter a non empty %",
      });
      return;
    }
    setInvalidRescheduledInAdvanceRate(false);
    setRescheduledInAdvanceRate(Number(value));
  };
  const verifyRescheduledAfterRate: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) < 0 || Number(value) > 100) {
      setInvalidRescheduledAfterRate(true);
      setError({
        message:
          "Please enter a value greater than or equal to %0 and less than or equal to 100%",
      });
      return;
    }
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidRescheduledAfterRate(true);
      setError({
        message: "Please enter a non empty %",
      });
      return;
    }
    setInvalidRescheduledAfterRate(false);
    setRescheduledAfterRate(Number(value));
  };
  const verifyReschedulingThreshold: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) < 0 || Number(value) > 100) {
      setInvalidReschedulingThreshold(true);
      setError({
        message:
          "Please enter a value greater than or equal to 0 and less than or equal to 168",
      });
      return;
    }
    if (
      Number.isNaN(Number(value)) ||
      Number(value) === null ||
      Number(value) === undefined
    ) {
      setInvalidReschedulingThreshold(true);
      setError({
        message: "Please enter a non empty # of hours",
      });
      return;
    }
    setInvalidReschedulingThreshold(false);
    setReschedulingThreshold(Number(value));
  };

  const getExistingPaymentPolicy = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrganizationsService.getOrgPricingConditions({
      orgId: orgId,
    })
      .then((policy) => {
        setChargeForAbsence(policy.charge_for_absence);
        setMissedSessionRate(
          policy.absence_percentage_charged as number | undefined
        );
        setChargeForCancelledSession(policy.charge_for_cancelled_session);
        setCancellationThreshold(
          policy.cancellation_threshold as number | undefined
        );
        setCanceledInAdvanceRate(
          policy.early_cancellation_percentage_charged as number | undefined
        );
        setCanceledAfterRate(
          policy.late_cancellation_percentage_charged as number | undefined
        );
        setChargeForRescheduledSession(policy.charge_for_rescheduled_session);
        setReschedulingThreshold(
          policy.rescheduling_threshold as number | undefined
        );
        setRescheduledInAdvanceRate(
          policy.early_rescheduling_percentage_charged as number | undefined
        );
        setRescheduledAfterRate(
          policy.late_rescheduling_percentage_charged as number | undefined
        );
        setStatus(PageStatus.SUCCESS);
      })
      .catch((error) => {
        setError(error);
        setStatus(PageStatus.ERROR);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  useEffect(() => {
    if (
      chargeForCancelledSession &&
      (cancellationThreshold === null ||
        cancellationThreshold === undefined ||
        Number.isNaN(cancellationThreshold))
    ) {
      setInvalidCancellationThreshold(true);
    } else {
      setInvalidCancellationThreshold(false);
    }
    if (
      chargeForCancelledSession &&
      (canceledInAdvanceRate === null ||
        canceledInAdvanceRate === undefined ||
        Number.isNaN(canceledInAdvanceRate))
    ) {
      setInvalidCanceledInAdvanceRate(true);
    } else {
      setInvalidCanceledInAdvanceRate(false);
    }
    if (
      chargeForCancelledSession &&
      (canceledAfterRate === null ||
        canceledAfterRate === undefined ||
        Number.isNaN(canceledAfterRate))
    ) {
      setInvalidCanceledAfterRate(true);
    } else {
      setInvalidCanceledAfterRate(false);
    }
    if (
      chargeForRescheduledSession &&
      (reschedulingThreshold === null ||
        reschedulingThreshold === undefined ||
        Number.isNaN(reschedulingThreshold))
    ) {
      setInvalidReschedulingThreshold(true);
    } else {
      setInvalidReschedulingThreshold(false);
    }
    if (
      chargeForRescheduledSession &&
      (rescheduledInAdvanceRate === null ||
        rescheduledInAdvanceRate === undefined ||
        Number.isNaN(rescheduledInAdvanceRate))
    ) {
      setInvalidRescheduledInAdvanceRate(true);
    } else {
      setInvalidRescheduledInAdvanceRate(false);
    }
    if (
      chargeForRescheduledSession &&
      (rescheduledAfterRate === null ||
        rescheduledAfterRate === undefined ||
        Number.isNaN(rescheduledAfterRate))
    ) {
      setInvalidRescheduledAfterRate(true);
    } else {
      setInvalidRescheduledAfterRate(false);
    }
    if (
      chargeForAbsence &&
      (missedSessionRate === null ||
        missedSessionRate === undefined ||
        Number.isNaN(missedSessionRate))
    ) {
      setInvalidMissedSessionRate(true);
    } else {
      setInvalidMissedSessionRate(false);
    }
  }, [
    canceledAfterRate,
    canceledInAdvanceRate,
    cancellationThreshold,
    chargeForAbsence,
    chargeForCancelledSession,
    chargeForRescheduledSession,
    missedSessionRate,
    rescheduledAfterRate,
    rescheduledInAdvanceRate,
    reschedulingThreshold,
  ]);

  useEffect(() => {
    getExistingPaymentPolicy();
  }, [getExistingPaymentPolicy]);

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <DialogContent
      onClose={getExistingPaymentPolicy}
      className="custom-payment-policies-dialog"
    >
      <div className="mt-3 font-semibold text-center header text-lg">
        Set Payment Policies
      </div>
      <div className="mt-4">
        <div>
          <input
            type="checkbox"
            className={`!border-black ml-2 rounded ${
              chargeForAbsence ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={chargeForAbsence}
            onChange={(e) => setChargeForAbsence(e.target.checked)}
          />
          &nbsp;
          <span className="font-semibold text-sm">Charge for absence.</span>
        </div>
        {chargeForAbsence && (
          <div className="ml-2 mt-2 text-xs">
            If a student is marked as absent, charge for{" "}
            <CurrencyInput
              className="ml-1 mr-1 input"
              style={{ maxHeight: "20px", maxWidth: "50px" }}
              id="amount"
              name="amount"
              placeholder={`10`}
              decimalsLimit={0}
              allowNegativeValue={false}
              defaultValue={missedSessionRate ? missedSessionRate : ""}
              onValueChange={verifyMissedSessionRate}
            />{" "}
            % of the price of the missed session.
          </div>
        )}
        <hr className="mt-3" />
      </div>
      <div className="mt-4">
        <div>
          <input
            type="checkbox"
            className={`!border-black ml-2 rounded ${
              chargeForCancelledSession ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={chargeForCancelledSession}
            onChange={(e) => setChargeForCancelledSession(e.target.checked)}
          />
          &nbsp;
          <span className="font-semibold text-sm">
            Charge for cancellation.
          </span>
        </div>
        {chargeForCancelledSession && (
          <div>
            <div className="ml-2 mt-2 text-xs">
              If a meeting is cancelled at least{" "}
              <CurrencyInput
                className="ml-1 mr-1 input"
                style={{ maxHeight: "20px", maxWidth: "50px" }}
                id="amount"
                name="amount"
                placeholder={`24`}
                decimalsLimit={0}
                allowNegativeValue={false}
                defaultValue={
                  cancellationThreshold ? cancellationThreshold : ""
                }
                onValueChange={verifyCancelledThreshhold}
              />{" "}
              hours in advance, charge for{" "}
              <CurrencyInput
                className="ml-1 mr-1 input"
                style={{ maxHeight: "20px", maxWidth: "50px" }}
                id="amount"
                name="amount"
                placeholder={`10`}
                decimalsLimit={0}
                allowNegativeValue={false}
                defaultValue={
                  canceledInAdvanceRate ? canceledInAdvanceRate : ""
                }
                onValueChange={verifyCancelledInAdvanceRate}
              />{" "}
              % of the price of the cancelled meeting.
            </div>
            <div className="ml-2 mt-2 text-xs">
              If a meeting is not cancelled at least {cancellationThreshold}{" "}
              hours in advance, charge for{" "}
              <CurrencyInput
                className="ml-1 mr-1 input"
                style={{ maxHeight: "20px", maxWidth: "50px" }}
                id="amount"
                name="amount"
                placeholder={`10`}
                decimalsLimit={0}
                allowNegativeValue={false}
                defaultValue={canceledAfterRate ? canceledAfterRate : ""}
                onValueChange={verifyCancelledAfterRate}
              />{" "}
              % of the price of the cancelled meeting.
            </div>
          </div>
        )}
        <hr className="mt-3" />
      </div>
      <div className="mt-4">
        <div>
          <input
            type="checkbox"
            className={`!border-black ml-2 rounded ${
              chargeForRescheduledSession ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={chargeForRescheduledSession}
            onChange={(e) => setChargeForRescheduledSession(e.target.checked)}
          />
          &nbsp;
          <span className="font-semibold text-sm">
            Charge for rescheduling.
          </span>
        </div>
        {chargeForRescheduledSession && (
          <div>
            <div className="ml-2 mt-2 text-xs">
              If a meeting is rescheduled at least{" "}
              <CurrencyInput
                className="ml-1 mr-1 input"
                style={{ maxHeight: "20px", maxWidth: "50px" }}
                id="amount"
                name="amount"
                placeholder={`24`}
                decimalsLimit={0}
                allowNegativeValue={false}
                defaultValue={
                  reschedulingThreshold ? reschedulingThreshold : ""
                }
                onValueChange={verifyReschedulingThreshold}
              />{" "}
              hours in advance, charge for{" "}
              <CurrencyInput
                className="ml-1 mr-1 input"
                style={{ maxHeight: "20px", maxWidth: "50px" }}
                id="amount"
                name="amount"
                placeholder={`10`}
                decimalsLimit={0}
                allowNegativeValue={false}
                defaultValue={
                  rescheduledInAdvanceRate ? rescheduledInAdvanceRate : ""
                }
                onValueChange={verifyRescheduledInAdvanceRate}
              />{" "}
              % of the price of the rescheduled meeting.
            </div>
            <div className="ml-2 mt-2 text-xs">
              If a meeting is not rescheduled at least {reschedulingThreshold}{" "}
              hours in advance, charge for{" "}
              <CurrencyInput
                className="ml-1 mr-1 input"
                style={{ maxHeight: "20px", maxWidth: "50px" }}
                id="amount"
                name="amount"
                placeholder={`10`}
                decimalsLimit={0}
                allowNegativeValue={false}
                defaultValue={rescheduledAfterRate ? rescheduledAfterRate : ""}
                onValueChange={verifyRescheduledAfterRate}
              />{" "}
              % of the price of the rescheduled meeting.
            </div>
          </div>
        )}
        <hr className="mt-3" />
      </div>
      <div className="w-full text-center mt-6">
        <Button
          color={
            (chargeForAbsence && invalidMissedSessionRate) ||
            (chargeForCancelledSession &&
              (invalidCancellationThreshold ||
                invalidCanceledInAdvanceRate ||
                invalidCanceledAfterRate)) ||
            (chargeForRescheduledSession &&
              (invalidReschedulingThreshold ||
                invalidRescheduledInAdvanceRate ||
                invalidRescheduledAfterRate))
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          size={ButtonSize.DEFAULT}
          onClick={editPaymentPolicy}
          disabled={
            (chargeForAbsence && invalidMissedSessionRate) ||
            (chargeForCancelledSession &&
              (invalidCancellationThreshold ||
                invalidCanceledInAdvanceRate ||
                invalidCanceledAfterRate)) ||
            (chargeForRescheduledSession &&
              (invalidReschedulingThreshold ||
                invalidRescheduledInAdvanceRate ||
                invalidRescheduledAfterRate))
          }
        >
          Save
        </Button>
      </div>
    </DialogContent>
  );
}
