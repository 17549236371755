import {
  ChartSubscriptionResponse,
  OrganizationResponse,
} from "client/openapi";
import { PerformanceChartDot } from ".";
import { useState, useEffect } from "react";
import { DialogContent } from "components/Dialog";
import {
  MagnifyingGlassIcon,
  EyeOpenIcon,
  EyeNoneIcon,
  ExternalLinkIcon,
} from "@radix-ui/react-icons";
import { saveAs } from "file-saver";
import Notifications from "util/notifications";
import { Button, ButtonColor } from "components/Button";
import { Tooltip, TooltipTrigger, TooltipContent } from "components/Tooltip";
import { useNavigate } from "react-router-dom";

export default function ViewEditDataDialog({
  xAxisLabel,
  yAxisLabel,
  organization,
  chart,
  defaultDotsDP,
  displayDotsDP,
  setDisplayDotsDP,
}: {
  xAxisLabel: string;
  yAxisLabel: string;
  organization: OrganizationResponse;
  chart: ChartSubscriptionResponse;
  defaultDotsDP: PerformanceChartDot[];
  displayDotsDP: PerformanceChartDot[];
  setDisplayDotsDP: React.Dispatch<
    React.SetStateAction<PerformanceChartDot[] | undefined>
  >;
}) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredDP, setFilteredDP] =
    useState<PerformanceChartDot[]>(defaultDotsDP);
  const [excludedStudent, setExcludedStudent] = useState<boolean[]>(
    new Array(defaultDotsDP.length).fill(false)
  );
  const navigate = useNavigate();

  function exportData() {
    let exportDP = defaultDotsDP.filter(
      (dot, index) => !excludedStudent[index]
    );
    exportDP = exportDP.filter((dot) =>
      filteredDP.find(
        (filteredDot) => filteredDot.student.id === dot.student.id
      )
    );
    const csv = [
      ["Student", xAxisLabel, yAxisLabel],
      ...exportDP.map((dot) => [
        `${dot.student.first_name} ${dot.student.last_name}`,
        dot.x,
        dot.score,
      ]),
    ].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });
    saveAs(
      blob,
      `${chart.chart_prototype.chart_title} - ${organization.name} performance.csv`
    );
    Notifications.success("Successfully exported student meetings.");
  }

  useEffect(() => {
    setExcludedStudent(
      defaultDotsDP.map(
        (dot) => !displayDotsDP.find((displayDot) => displayDot === dot)
      )
    );
  }, [displayDotsDP]);

  useEffect(() => {
    setFilteredDP(
      defaultDotsDP.filter((dot) =>
        `${dot.student.first_name} ${dot.student.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left medium-wide pb-2 px-12 font-montserrat"
      style={{ height: "85vh" }}
      onClose={() => {}}
    >
      <div className="h-1/5 pb-5 pt-10">
        <h2 className="font-bold text-3xl">
          {chart.chart_prototype.metric_definition.y_axis_name}
        </h2>

        <div className="flex flex-row items-center justify-between">
          <div className="w-80 h-9 border border-black flex flex-row justify-between items-center rounded-lg mt-5">
            <input
              type="text"
              className="w-11/12 border-transparent focus:border-transparent focus:ring-0 p-0 pl-1"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <MagnifyingGlassIcon className="w-1/12" />
          </div>

          <Button
            color={ButtonColor.SKYBLUE}
            extraClasses="rounded-xl"
            onClick={() => exportData()}
          >
            Export
          </Button>
        </div>

        <div className="h-4/5 w-full mt-12">
          <div className="grid grid-cols-12">
            <span className="col-span-2 ml-0.5 font-bold">Student</span>
            <span className="col-span-3 font-bold flex justify-center items-center">
              {xAxisLabel}
            </span>
            <span className="col-span-3 font-bold flex justify-center items-center">
              {yAxisLabel}
            </span>
            <span className="col-span-4 font-bold flex justify-center items-center">
              Actions
            </span>
          </div>

          <div
            className="border-2 rounded-md overflow-y-scroll"
            style={{
              maxHeight: "384px",
            }}
          >
            {filteredDP.length === 0 && (
              <div className="h-11 ml-0.5 flex items-center">
                No data to display.
              </div>
            )}

            {filteredDP.length > 0 &&
              filteredDP.map((datapoint: PerformanceChartDot, index) => (
                <div
                  className={`grid grid-cols-12 h-11 items-center ${
                    index !== filteredDP.length - 1 && `border-b-2`
                  }`}
                  key={index}
                >
                  <span className="col-span-2 ml-1">
                    {datapoint.student.first_name} {datapoint.student.last_name}
                  </span>
                  <span className="col-span-3 flex justify-center items-center">
                    {datapoint.x}
                  </span>
                  <span className="col-span-3 flex justify-center items-center">
                    {datapoint.score}
                  </span>
                  <span className="col-span-4 flex justify-center items-center gap-2">
                    <Tooltip>
                      <TooltipTrigger>
                        <ExternalLinkIcon
                          className="cursor-pointer w-5 h-5"
                          onClick={() => {
                            navigate(
                              `/manage/students/${datapoint.student.id}`
                            );
                          }}
                        />
                      </TooltipTrigger>

                      <TooltipContent>View student.</TooltipContent>
                    </Tooltip>

                    {excludedStudent[index] ? (
                      <Tooltip>
                        <TooltipTrigger>
                          <EyeOpenIcon
                            className="cursor-pointer w-5 h-5"
                            onClick={() => {
                              setExcludedStudent((prev) => {
                                const copy = [...prev];
                                copy[index] = false;
                                return copy;
                              });
                              setDisplayDotsDP((prev) => {
                                if (prev) {
                                  const copy = [...prev];
                                  copy.push(datapoint);
                                  return copy;
                                }
                              });
                            }}
                          />
                        </TooltipTrigger>

                        <TooltipContent>
                          Include this student in the data set.
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <Tooltip>
                        <TooltipTrigger>
                          <EyeNoneIcon
                            className="cursor-pointer w-5 h-5"
                            onClick={() => {
                              setExcludedStudent((prev) => {
                                const copy = [...prev];
                                copy[index] = true;
                                return copy;
                              });
                              setDisplayDotsDP((prev) => {
                                if (prev) {
                                  const copy = [...prev];
                                  const i = copy.findIndex(
                                    (dot) =>
                                      dot.student.id === datapoint.student.id
                                  );
                                  copy.splice(i, 1);
                                  return copy;
                                }
                              });
                            }}
                          />
                        </TooltipTrigger>

                        <TooltipContent>
                          Exclude this student from the data set.
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
