/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteCreate } from "../models/InviteCreate";
import type { ReInviteCreate } from "../models/ReInviteCreate";
import type { TutorResponse } from "../models/TutorResponse";
import type { TutorSubjectResponse } from "../models/TutorSubjectResponse";
import type { TutorUpdate } from "../models/TutorUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TutorsService {
  /**
   * Invite Tutor
   * Create a new account/tutor for an invited user and sends an invite email to sign up.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteTutor({
    requestBody,
  }: {
    requestBody: InviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutors/invite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reinvite Tutor
   * Reinvite a previously invited tutor.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static reinviteTutor({
    requestBody,
  }: {
    requestBody: ReInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutors/invite/reinvite/",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors Me
   * Get all tutors for the currently authenticated user from the database.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static getTutorsMe(): CancelablePromise<Array<TutorResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Me By Org
   * Get student for the currently authenticated user from the database.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static getTutorMeByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<TutorResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/me/by_org",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor
   * Get a tutor by its ID from the database.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static getTutor({
    tutorId,
  }: {
    tutorId: number;
  }): CancelablePromise<TutorResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Tutor
   * Update a tutor by its ID using specified attributes
   * within TutorUpdate to update the database and
   * return the updated tutor.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static updateTutor({
    tutorId,
    requestBody,
  }: {
    tutorId: number;
    requestBody: TutorUpdate;
  }): CancelablePromise<TutorResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors By Organization
   * Get tutors by their organization id from the database.
   * @returns TutorResponse Successful Response
   * @throws ApiError
   */
  public static getTutorsByOrganization({
    orgId,
    phrase,
    skip,
    limit = 100,
  }: {
    orgId: number;
    phrase?: string | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<TutorResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/by_organization/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        phrase: phrase,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Set Custom Subject Pay Rate
   * Set a custom rate of a tutor for a given subject.
   * @returns TutorSubjectResponse Successful Response
   * @throws ApiError
   */
  public static setCustomSubjectPayRate({
    tutorId,
    orgSubjectId,
    customPayRate,
  }: {
    tutorId: any;
    orgSubjectId: number;
    customPayRate: number;
  }): CancelablePromise<TutorSubjectResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutors/set_custom_subject_pay_rate/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_subject_id: orgSubjectId,
        custom_pay_rate: customPayRate,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Revenue
   * Get the revenue of a tutor.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTutorRevenue({
    tutorId,
    orgId,
    start,
    until,
  }: {
    tutorId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/tutor_revenue/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Compensation
   * Get the compensation for a tutor.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTutorCompensation({
    tutorId,
    orgId,
    start,
    until,
  }: {
    tutorId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutors/tutor_compensation/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Tutor From Org
   * Flow for proper removal of a tutor from an org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static removeTutorFromOrg({
    tutorId,
    orgId,
  }: {
    tutorId: number;
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutors/remove_from_org/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Make Tutor Admin
   * Make a tutor an admin in the specified org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static makeTutorAdmin({
    tutorId,
    orgId,
  }: {
    tutorId: number;
    orgId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutors/make-org-admin/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
