import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Button, ButtonColor } from "components/Button";
import { useNavigate } from "react-router-dom";
import { AccountRoles } from "types";

function SignupType({
  setStep,
  setType,
}: {
  setStep: Function;
  setType: Function;
}) {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "75vh" }}
    >
      <div className="w-1/2">
        <h1 className="headline-9 mb-4">Sign up as...</h1>

        <div className="d-flex">
          <Button
            type="button"
            color={ButtonColor.GREEN}
            block={true}
            onClick={() => {
              if (!isAuthenticated) {
                loginWithRedirect({
                  authorizationParams: {
                    redirect_uri: `${process.env.REACT_APP_FRONTEND_DOMAIN}/signup/organization`,
                  },
                });
              } else {
                setType(AccountRoles.ORGANIZATION);
                setStep(1);
                navigate("/signup/organization");
              }
            }}
          >
            Organization <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SignupType;
