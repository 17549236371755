import Buttons from "./Buttons";
import "./PaymentsTab.css";
import { useCallback, useEffect, useState } from "react";
import {
  OrganizationsService,
  ParentResponse,
  ParentsService,
  StudentResponse,
  StudentsService,
} from "client/openapi";
import moment from "moment";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

function Payments({ parent }: { parent: ParentResponse }) {
  const [clientSecret, setClientSecret] = useState<string>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  let currentDate = new Date(); // Get the current date and time
  let pastDate = new Date(currentDate); // Create a new Date object based on the current date
  pastDate.setMonth(pastDate.getMonth() - 6);

  const [startDate, setStartDate] = useState(pastDate.toISOString());
  const [endDate, setEndDate] = useState(currentDate.toISOString());

  useEffect(() => {
    const getClientSecretForSetupIntent = async () => {
      setStatus(PageStatus.LOADING);
      try {
        const clientSecret = await ParentsService.addPaymentMethodParent({
          parentId: parent.id,
        });
        setStatus(PageStatus.SUCCESS);
        setClientSecret(clientSecret);
      } catch (e: any) {
        setStatus(PageStatus.ERROR);
        setError({ error: "Could not get student payment details." });
        console.error(`Error (#${e.status}): ${e.message}`);
      }
    };

    getClientSecretForSetupIntent();
  }, [parent.id]);

  const options = { clientSecret: clientSecret };

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  if (!options.clientSecret) {
    if (status === PageStatus.LOADING) {
      return <LoadingBlock status={status} />;
    }

    if (status === PageStatus.ERROR) {
      return <ErrorBlock status={status} message={error?.message} />;
    }
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <div>
        <div className="flex flex-col lg:flex-row">
          <div className="mt-5 lg:mt-0">
            <Buttons parent={parent} />
          </div>
        </div>
      </div>
    </Elements>
  );
}
export default Payments;
