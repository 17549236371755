import {
  ApiError,
  Auth0AccountRole,
  InviteResponse,
  InvitesService,
  InviteStatus,
  OrganizationNameResponse,
  OrganizationSettingsResponse,
  OrganizationsService,
  TutorResponse,
  TutorsService,
  TutorSubjectResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, LinkButton } from "components/Button";
import ManageTutorSubjectsDialog from "components/Dashboard/ManageTutorSubjectsDialog";
import StudentsList, {
  StudentDashboardAction,
} from "components/Dashboard/StudentsList";
import UpcomingMeetings from "components/Dashboard/UpcomingMeetings";
import { Dialog, DialogTrigger } from "components/Dialog";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { APIResponse, AccountRoles, PageStatus, Student, Tutor } from "types";
import { getButtonRoleColor, getContextColor } from "util/contextColor";
import "./index.css";
import Notifications from "util/notifications";
import { OrganizationTabProps } from "components/UserSettingsDialog/components/content/Organization";
import MeetingDialog from "components/MeetingDialog";
import { TutorContext, TutorProvider } from "./tutorContext";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import Invites from "../InvitesModal/Invites";

export default function TutorDashboard() {
  const { organizations_available_to_role, account } = useContext(
    OrgRolesAndAccountContext
  );

  const { available_tutors, current_tutor } = useContext(TutorContext);

  const [invites, setInvites] = useState<InviteResponse[]>();

  // set up state
  const [error, setError] = useState<APIResponse>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [settings, setSettings] = useState<OrganizationSettingsResponse[]>();
  const [tutorsWithEmptySubjects, setTutorsWithEmptySubjects] =
    useState<TutorResponse[]>();
  const [addedStart, setAddedStart] = useState<Date>();
  const [orgNames, setOrgNames] = useState<OrganizationNameResponse[]>();

  const getSettings = useCallback(() => {
    let incomingSettings: OrganizationSettingsResponse[] = [];

    organizations_available_to_role?.forEach((orgId) => {
      OrganizationsService.getOrganizationSettings({
        orgId: orgId,
      })
        .then((res) => {
          incomingSettings.push(res);
        })
        .catch((err) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${err.code}): ${err.message}`);
        });
    });

    setSettings(incomingSettings);
  }, [organizations_available_to_role]);

  const getOrgNames = useCallback(() => {
    OrganizationsService.getOrganizationNames({
      requestBody: organizations_available_to_role as number[],
    })
      .then((orgs) => {
        setOrgNames(orgs);
      })
      .catch((err) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  }, [organizations_available_to_role]);

  useEffect(() => {
    if (organizations_available_to_role) {
      getSettings();
      getOrgNames();
    }
  }, [getOrgNames, getSettings, organizations_available_to_role]);

  useEffect(() => {
    if (available_tutors) {
      setTutorsWithEmptySubjects(
        available_tutors.filter((tutor) => tutor.subjects.length === 0)
      );
    }

    if (available_tutors && current_tutor && settings && orgNames) {
      setStatus(PageStatus.SUCCESS);
    }
  }, [available_tutors, current_tutor, orgNames, settings]);

  function getOrganizationNameById(id: number): string | undefined {
    const organization = orgNames?.find((org) => org.id === id);
    return organization
      ? organization.name
      : "Can't find org name at this time!";
  }

  function getOrganizationSettingsById(
    id: number
  ): OrganizationSettingsResponse | undefined {
    return settings?.find((org) => org.id === id);
  }

  const getOrgInvites = async () => {
    InvitesService.getInvitesByAccountAndStatus({
      status: InviteStatus.PENDING,
    })
      .then((invites) => {
        setInvites(invites);
      })
      .catch((err) => {
        setError({ error: "Could not get invites!" });
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  };

  useEffect(() => {
    if (account) {
      getOrgInvites();
    }
  }, [account]);

  return (
    <section>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <LoadingBlock status={status} />

      <ErrorBlock
        status={status}
        message={error?.error || "Error encountered"}
      />

      {status === PageStatus.SUCCESS &&
        available_tutors &&
        current_tutor &&
        settings &&
        orgNames && (
          <>
            {tutorsWithEmptySubjects && tutorsWithEmptySubjects.length > 0 && (
              <div className="container mx-auto mb-4">
                <div className="mt-4 tutor__profile_block">
                  <div className="tutor__profile_block-text ">
                    <span className="primsecond-text">
                      You have no subjects!
                    </span>
                    <p className="mt-1 secondary-text">
                      The following organizations need you to add subjects to
                      your profile so students can start booking with you:
                      <ul>
                        {tutorsWithEmptySubjects?.map((tutor) => (
                          <li className="mt-2 font-bold" key={tutor.id}>
                            {getOrganizationNameById(tutor.org_id)}
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                  <Dialog>
                    <DialogTrigger asChild>
                      <button className="button button--orange">
                        Add Subjects &rarr;
                      </button>
                    </DialogTrigger>

                    <ManageTutorSubjectsDialog />
                  </Dialog>
                </div>
              </div>
            )}
            {invites && invites.length > 0 && (
              <div className="container mx-auto mb-4">
                <div className="mt-4 invite_block">
                  <div className="tutor__profile_block-text">
                    <span className="primsecond-text">
                      You have been invited to an organization!
                    </span>
                    <p className="mt-1 secondary-text">
                      View invites and accept them if you'd like to receive
                      tutoring.
                    </p>
                  </div>

                  <Dialog>
                    <DialogTrigger asChild>
                      <button className="button button--green">
                        View Invites &rarr;
                      </button>
                    </DialogTrigger>
                    <Invites invites={invites} setInvites={setInvites} />
                  </Dialog>
                </div>
              </div>
            )}

            <div className="container mx-auto">
              <UpcomingMeetings addedStart={addedStart}>
                <div className="list--actions">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button color={ButtonColor.ORANGE}>Book Meeting</Button>
                    </DialogTrigger>

                    <MeetingDialog
                      isCreate={true}
                      role={Auth0AccountRole.ORG_TUTOR}
                      setEvents={(e?: string) =>
                        Promise.resolve(setAddedStart(moment(e).toDate()))
                      }
                    />
                  </Dialog>

                  <Button
                    color={ButtonColor.ORANGE}
                    fill={ButtonFill.HOLLOW}
                    disabled
                    title="Coming soon!"
                  >
                    Log Activity
                  </Button>

                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        color={getButtonRoleColor(Auth0AccountRole.ORG_TUTOR)}
                      >
                        Manage Subjects
                      </Button>
                    </DialogTrigger>

                    <ManageTutorSubjectsDialog />
                  </Dialog>

                  <LinkButton color={ButtonColor.ORANGE} to="/availability">
                    Manage Availability
                  </LinkButton>
                </div>
              </UpcomingMeetings>
            </div>

            {available_tutors?.map((tutor) => (
              <div className="container mx-auto mt-8" key={tutor.id}>
                <StudentsList
                  role={Auth0AccountRole.ORG_TUTOR}
                  tutorIfOnTutorDashboard={tutor}
                  org_name={getOrganizationNameById(tutor.org_id)}
                  settings={getOrganizationSettingsById(tutor.org_id)}
                />
              </div>
            ))}

            {/* <div className="container mx-auto">
            <div className="edit-profile-link">
              <Link
                to={`/tutors/${tutor.id}`}
                className={`button button--large button--${getContextColor(
                  AccountRoles.TUTOR
                )}`}
              >
                <>View Profile &rarr;</>
              </Link>

              <Link
                to="/tutors/profile"
                className={`button button--large button--hollow button--${getContextColor(
                  AccountRoles.TUTOR
                )}`}
              >
                <>Edit Profile &rarr;</>
              </Link>
            </div>
          </div> */}
          </>
        )}
    </section>
  );
}
