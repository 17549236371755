import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import "./index.css";

export enum ToggleColor {
  GREEN = "toggle-group--green",
  PURPLE = "toggle-group--purple",
  SKYBLUE = "toggle-group--skyblue",
  DEFAULT = "",
}

export enum ToggleType {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export const ToggleGroupRoot = ({
  id,
  defaultValue,
  ariaLabel,
  value,
  setValue,
  color,
  fullWidth,
  children,
  allowDeselect,
}: {
  id?: string;
  defaultValue: string;
  ariaLabel: string;
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  color?: ToggleColor;
  fullWidth?: boolean;
  children: React.ReactNode;
  allowDeselect?: boolean;
}) => (
  <ToggleGroupPrimitive.Root
    id={id}
    className={`toggle-group ${fullWidth ? "toggle-group--full-width" : ""} ${
      color ? color : ""
    }`}
    type="single"
    defaultValue={defaultValue}
    aria-label={ariaLabel}
    value={value}
    onValueChange={(value: string) => {
      if (value) setValue(value);
      else {
        if (allowDeselect) setValue("");
      }
    }}
  >
    {children}
  </ToggleGroupPrimitive.Root>
);

export const ToggleGroupMultipleRoot = ({
  id,
  ariaLabel,
  value,
  setValue,
  color,
  fullWidth,
  children,
}: {
  id?: string;
  ariaLabel: string;
  value?: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
  color?: ToggleColor;
  fullWidth?: boolean;
  children: React.ReactNode;
}) => (
  <ToggleGroupPrimitive.Root
    id={id}
    className={`toggle-group ${fullWidth ?? "toggle-group--full-width"} ${
      color ? color : ""
    }`}
    type="multiple"
    aria-label={ariaLabel}
    value={value}
    onValueChange={(value: string[]) => {
      if (value) setValue(value);
    }}
  >
    {children}
  </ToggleGroupPrimitive.Root>
);

export const ToggleGroupItem = ({
  value,
  ariaLabel,
  children,
  className,
}: {
  value: string;
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <ToggleGroupPrimitive.Item
    className={`toggle-group--item ${className ? className : ""}`}
    value={value}
    aria-label={ariaLabel || value}
  >
    {children}
  </ToggleGroupPrimitive.Item>
);
