import {
  ApiError,
  OrgNotesService,
  OrganizationNoteKeyResponse,
} from "client/openapi";
import { DialogClose, DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "../index.css";

function DeleteOrgNoteKeyDialog({
  noteKey,
  keys,
  setKeys,
}: {
  noteKey: OrganizationNoteKeyResponse;
  keys: OrganizationNoteKeyResponse[];
  setKeys: React.Dispatch<React.SetStateAction<OrganizationNoteKeyResponse[]>>;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  async function deleteKey() {
    await OrgNotesService.deleteOrgNoteKey({ orgNoteKeyId: noteKey.id })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        setKeys(keys.filter((k) => k.id !== noteKey.id));
        Notifications.success(`Field deleted!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="new-org-note-key--dialog">
          <div className="new-org-note-key--dialog-header">
            <h2 className="new-org-note-key--title">Are you sure?</h2>
            <p>
              All student data associated with this "{noteKey.name}" field will
              be lost. This action is irreversible.
            </p>
          </div>

          <div className="mt-4 clear-availability--actions">
            <button
              className="button button--small button--red"
              type="submit"
              disabled={status === PageStatus.LOADING}
              onClick={() => deleteKey()}
            >
              Delete Field &rarr;
            </button>
            <DialogClose asChild>
              <button
                className="button button--small button--red button--hollow"
                disabled={status === PageStatus.LOADING}
              >
                Cancel
              </button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </>
  );
}

export default DeleteOrgNoteKeyDialog;
