export default function FeatureItem({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div>
      <h3
        className="mb-5 text-2xl font-bold"
        style={{ fontFamily: "Montserrat" }}
      >
        {title}
      </h3>
      <p
        className="leading-relaxed text-xl"
        style={{ fontFamily: "Futura", textAlign: "justify" }}
      >
        {children}
      </p>
    </div>
  );
}
