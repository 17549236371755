/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from "../models/DeleteResponse";
import type { StudentContactCreate } from "../models/StudentContactCreate";
import type { StudentContactResponse } from "../models/StudentContactResponse";
import type { StudentContactUpdate } from "../models/StudentContactUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StudentContactsService {
  /**
   * Create Student Contact
   * Create a student_contact in the database.
   * @returns StudentContactResponse Successful Response
   * @throws ApiError
   */
  public static createStudentContact({
    requestBody,
  }: {
    requestBody: StudentContactCreate;
  }): CancelablePromise<StudentContactResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/student_contacts",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Student Contact
   * Delete a student_contact in the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteStudentContact({
    studentContactId,
  }: {
    studentContactId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/student_contacts/{student_contact_id}",
      path: {
        student_contact_id: studentContactId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Contact
   * Get a student_contact in the database.
   * @returns StudentContactResponse Successful Response
   * @throws ApiError
   */
  public static getStudentContact({
    studentContactId,
  }: {
    studentContactId: number;
  }): CancelablePromise<StudentContactResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_contacts/{student_contact_id}",
      path: {
        student_contact_id: studentContactId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Student Contact
   * Update a student_contact record in the database.
   * @returns StudentContactResponse Successful Response
   * @throws ApiError
   */
  public static updateStudentContact({
    studentContactId,
    requestBody,
  }: {
    studentContactId: number;
    requestBody: StudentContactUpdate;
  }): CancelablePromise<StudentContactResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/student_contacts/{student_contact_id}",
      path: {
        student_contact_id: studentContactId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Contacts By Student
   * Get all student_contact records in the database corresponding to a given student.
   * @returns StudentContactResponse Successful Response
   * @throws ApiError
   */
  public static getStudentContactsByStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<StudentContactResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/student_contacts/student/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
