import moment from "moment";
import { WeeklyAvailability } from "types";

export default function parseAvailability(availabilityList: WeeklyAvailability, timezone: string): boolean[] {
    let rawAvails: boolean[] = [];
    availabilityList.mon.split("").forEach((a) => rawAvails.push(!!+a));
    availabilityList.tue.split("").forEach((a) => rawAvails.push(!!+a));
    availabilityList.wed.split("").forEach((a) => rawAvails.push(!!+a));
    availabilityList.thu.split("").forEach((a) => rawAvails.push(!!+a));
    availabilityList.fri.split("").forEach((a) => rawAvails.push(!!+a));
    availabilityList.sat.split("").forEach((a) => rawAvails.push(!!+a));
    availabilityList.sun.split("").forEach((a) => rawAvails.push(!!+a));

    const tzOffset: number = moment().tz(timezone).utcOffset();
    const arrayOffset: number = Math.floor(tzOffset / 15); // availabilities are in 15-min chunks

    let beginningAvails: boolean[] = [];
    let endingAvails: boolean[] = [];
    let avails: boolean[] = [];

    if (tzOffset < 0) {
        beginningAvails = [...rawAvails].slice(Math.abs(arrayOffset));
        endingAvails = [...rawAvails].slice(0, Math.abs(arrayOffset));
        avails = [...beginningAvails, ...endingAvails];
    } else if (tzOffset > 0) {
        beginningAvails = [...rawAvails].slice(-arrayOffset);
        endingAvails = [...rawAvails].slice(0, -arrayOffset);
        avails = [...beginningAvails, ...endingAvails];
    } else {
        avails = rawAvails;
    }
    return avails;
}
