/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCreate } from "../models/AccountCreate";
import type { AccountResponse } from "../models/AccountResponse";
import type { AccountUpdate } from "../models/AccountUpdate";
import type { ACHResponse } from "../models/ACHResponse";
import type { BooleanResponse } from "../models/BooleanResponse";
import type { CreditCardResponse } from "../models/CreditCardResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AccountsService {
  /**
   * Get Current Account
   * Get the details of the currently-authenticated account.
   * @returns AccountResponse Successful Response
   * @throws ApiError
   */
  public static getCurrentAccount(): CancelablePromise<AccountResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/accounts/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Account
   * Create a new account and return the resulting account from the database.
   * @returns AccountResponse Successful Response
   * @throws ApiError
   */
  public static createAccount({
    requestBody,
  }: {
    requestBody: AccountCreate;
  }): CancelablePromise<AccountResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/accounts",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Registered
   * Determine if an account has completed signup.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static getRegistered(): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/accounts/registered",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Account
   * Update an account by its ID using specified attributes
   * within AccountUpdate to update the database.
   * @returns AccountResponse Successful Response
   * @throws ApiError
   */
  public static updateAccount({
    requestBody,
  }: {
    requestBody: AccountUpdate;
  }): CancelablePromise<AccountResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/accounts/update",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Tutor Account
   * Delete all of tutor's data from the databse.
   * TutorSubject's get preserved and are set to inactive, their tutor id becomes null.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static deleteTutorAccount({
    tutorId,
  }: {
    tutorId: number;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/accounts/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Student Account
   * Delete a student from the database.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static deleteStudentAccount({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/accounts/students/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Account
   * Completely delete an account from the system.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static deleteAccount({
    accountId,
  }: {
    accountId: string;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/accounts/{account_id}",
      path: {
        account_id: accountId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Cards
   * Get all cards belonging to an account.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getCards(): CancelablePromise<Array<CreditCardResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/accounts/cards",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Ach Accounts
   * Get all banks belonging to an account.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getAchAccounts(): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/accounts/ach",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Account
   * Add a payment method onto a student.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodAccount(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/accounts/add_payment_method",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Registered Payment Method
   * This can be used to remove a registered card from an account.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static removeRegisteredPaymentMethod({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/accounts/payment_methods/{payment_method_id}",
      path: {
        payment_method_id: paymentMethodId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
