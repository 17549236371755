import {
  Cell,
  Legend,
  Pie,
  PieChart as PieChartPrimitive,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { DataKey } from "recharts/types/util/types";

const COLORS = [
  "#945bc1",
  "#d86560",
  "#52b09e",
  "#953f3b",
  "#e6a25b",
  "#5293b0",
  "#000000",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieChart({
  data,
  dataKey,
  nameKey,
}: {
  data: any[];
  dataKey: DataKey<any>;
  nameKey: DataKey<any>;
}) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChartPrimitive width={400} height={400}>
        <Tooltip />
        <Pie
          data={data}
          dataKey={dataKey}
          nameKey={nameKey}
          cx="50%"
          cy="50%"
          outerRadius={100}
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend />
      </PieChartPrimitive>
    </ResponsiveContainer>
  );
}
