import { useCurrentAccount } from "api";
import {
  ApiError,
  Auth0AccountRole,
  NoteResponse,
  NotesService,
  ParentResponse,
  StudentResponse,
} from "client/openapi";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus, Student } from "types";
import { getButtonRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import { MessagesService, MessageType } from "client/openapi";
import "../NotesDialog/index.css";

function NewNoteDialog({
  role,
  authorType,
  student_recipient,
  parent_recipient,
  setOpen,
  notes,
  setNotes,
  text_parent_or_student_parents,
}: {
  role: Auth0AccountRole | null;
  authorType: Auth0AccountRole;
  student_recipient?: StudentResponse;
  parent_recipient?: ParentResponse;
  setOpen: Function;
  notes: NoteResponse[];
  setNotes: React.Dispatch<React.SetStateAction<NoteResponse[]>>;
  text_parent_or_student_parents: boolean;
}) {
  const { data: author } = useCurrentAccount();

  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [note, setNote] = useState<string>();

  async function createNote() {
    if (!author || !note) {
      return;
    }

    await NotesService.createNote({
      requestBody: {
        author_id: author.reference_id,
        author_type: authorType,
        student_recipient_id: student_recipient
          ? student_recipient.id
          : undefined,
        parent_recipient_id: parent_recipient ? parent_recipient.id : undefined,
        content: note,
        text_parent_or_student_parents: text_parent_or_student_parents,
      },
    })
      .then((noteResponse) => {
        setStatus(PageStatus.SUCCESS);
        const notesCopy: NoteResponse[] = [...notes, noteResponse];
        setNotes(notesCopy);
        Notifications.success(`Note created!`);
        setOpen(false);
        setNote("");
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to save note." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleUpdate(ev) {
    setNote(ev.target.value);
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    await createNote();
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="dialog-content" onClose={() => setOpen(false)}>
        <div className="account-notes--dialog">
          <h2 className="account-notes--title">New Note</h2>

          <form onSubmit={handleSubmit}>
            <label htmlFor="new_note" aria-label="Note">
              <textarea
                id="new_note"
                name="new_note"
                className="input"
                placeholder="Begin typing..."
                value={note || ""}
                onChange={handleUpdate}
              />
            </label>

            <DialogActions>
              <>
                <DialogAction
                  primary={true}
                  color={getButtonRoleColor(role)}
                  type="submit"
                  disabled={status === PageStatus.LOADING}
                >
                  Save Note &rarr;
                </DialogAction>

                <DialogClose asChild>
                  <DialogAction
                    color={getButtonRoleColor(role)}
                    onClick={() => setOpen(false)}
                    disabled={status === PageStatus.LOADING}
                  >
                    Cancel
                  </DialogAction>
                </DialogClose>
              </>
            </DialogActions>
          </form>
        </div>
      </DialogContent>
    </>
  );
}

export default NewNoteDialog;
