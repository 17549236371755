import { useAuth0 } from "@auth0/auth0-react";
import { Auth0AccountRole } from "client/openapi";

export async function getAccountMetadata(accessToken: string) {
  const namespace = process.env.REACT_APP_AUTH0_NAMESPACE as string;
  const metadataKey = `${namespace}/app_metadata`;

  try {
    // Fetch the user profile from the /userinfo endpoint
    const response = await fetch(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user profile");
    }

    const userProfile = await response.json();

    // Return the latest organizations metadata
    return (
      userProfile[metadataKey]?.organizations || { organizations: undefined }
    );
  } catch (error) {
    console.error("Error fetching account metadata:", error);
    return { organizations: undefined };
  }
}

export function getAccountRoles(
  organizations: Record<number, string[]>
): Auth0AccountRole[] {
  const roleDictionary = {
    "org-admin": Auth0AccountRole.ORG_ADMIN,
    "org-tutor": Auth0AccountRole.ORG_TUTOR,
    me: Auth0AccountRole.ME,
    parent: Auth0AccountRole.PARENT,
    "tadpole-admin": Auth0AccountRole.TADPOLE_ADMIN,
  };

  let roles = new Set<Auth0AccountRole>();

  for (const org of Object.keys(organizations)) {
    const rolesInOrg = organizations[org];

    rolesInOrg.forEach((role) => {
      const formattedRole = roleDictionary[role];
      if (formattedRole) {
        roles.add(formattedRole);
      }
    });
  }

  return Array.from(roles);
}

export function getHighestRole(
  roles: Auth0AccountRole[]
): Auth0AccountRole | null {
  if (roles.includes(Auth0AccountRole.TADPOLE_ADMIN)) {
    return Auth0AccountRole.TADPOLE_ADMIN;
  } else if (roles.includes(Auth0AccountRole.ORG_ADMIN)) {
    return Auth0AccountRole.ORG_ADMIN;
  } else if (roles.includes(Auth0AccountRole.ORG_TUTOR)) {
    return Auth0AccountRole.ORG_TUTOR;
  } else if (roles.includes(Auth0AccountRole.PARENT)) {
    return Auth0AccountRole.PARENT;
  } else if (roles.includes(Auth0AccountRole.ME)) {
    return Auth0AccountRole.ME;
  }

  return null;
}

export function findOrgIdsByRole(
  organizations: Record<number, Auth0AccountRole[]>,
  target_role: Auth0AccountRole
): number[] {
  const orgIdsByRole = new Set<number>();

  for (const [org, roles] of Object.entries(organizations)) {
    if (roles.includes(target_role)) {
      orgIdsByRole.add(Number(org));
    }
  }

  return Array.from(orgIdsByRole);
}
