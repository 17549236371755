import React, { useCallback, useState } from "react";
import { DialogContent } from "components/Dialog";
import { UserSettingsTabs } from "./components/tabs";
import { PersonalTabContent } from "./components/content/Personal";
import {
  OrganizationTabContent,
  OrganizationTabProps,
} from "./components/content/Organization";
import { DialogClose } from "components/Dialog";
import { useUserSettingsUpdateEndpoints } from "./useUserSettingsUpdateEndpoints";
import { AccountResponse } from "client/openapi";
import { Button, ButtonColor } from "components/Button";
import styles from "./styles.module.css";

export interface UserSettingsValue {
  Personal: Omit<AccountResponse, "changeHandler">;
  Organization: Omit<OrganizationTabProps, "changeHandler">;
}

interface UserSettingsDialogProps {
  inOrgSettings: boolean;
  values: UserSettingsValue;
}

export enum Tab {
  Personal = "Personal",
  Organization = "Organization",
}

const TabContent = {
  Personal: PersonalTabContent,
  Organization: OrganizationTabContent,
};

const ComponentWithProps = ({ Component, props }) => <Component {...props} />;

export const UserSettingsDialog: React.FC<UserSettingsDialogProps> = ({
  inOrgSettings,
  values,
}) => {
  const tabs = inOrgSettings ? [Tab.Organization] : [Tab.Personal];

  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);

  const [editableValues, setEditableValues] = useState<
    AccountResponse | Omit<OrganizationTabProps, "changeHandler">
  >(values[activeTab]);

  const handleValueChange = (values: AccountResponse) => {
    setEditableValues(values);
  };

  const handleTabChange = (tab: Tab) => {
    setActiveTab(tab);
  };

  const updateEndpoint = useUserSettingsUpdateEndpoints(activeTab);

  const handleSave = useCallback(() => {
    updateEndpoint(editableValues);
  }, [editableValues, updateEndpoint]);

  return (
    <DialogContent
      className="dialog-content dialog-content--left wide bg-neutral-50 font-montserrat pb-2 min-h-[85vh] overflow-x-hidden"
      alignLeft={true}
      gradientHeader={true}
      onClose={() => {}}
    >
      <div className={styles.body}>
        <div>
          <h1 className={styles.title}>
            {inOrgSettings && "Organization "}Settings
          </h1>
          <UserSettingsTabs
            tabs={tabs}
            activeTab={activeTab}
            handleClick={handleTabChange}
          />
          <ComponentWithProps
            Component={TabContent[activeTab]}
            props={{
              ...editableValues,
              changeHandler: handleValueChange,
            }}
          />
        </div>
        <div className="mt-8">
          <div className={styles.buttons}>
            <Button
              color={ButtonColor.GREEN}
              onClick={handleSave}
              extraClasses={styles.actionButton}
            >
              Save
            </Button>
            <DialogClose>
              <Button
                color={ButtonColor.GRAY}
                extraClasses={styles.actionButton}
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};
