export default function range(
  start: number,
  end: number | undefined = undefined,
  step = 1
) {
  let output: number[] = [];

  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }

  for (let i: number = start; i < end; i += step) {
    output.push(i);
  }

  return output;
}
