import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ButtonColor, ButtonSize, ExternalLinkButton } from "components/Button";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PageStatus } from "types";
import { ApiError, InviteResponse, InvitesService } from "client/openapi";

export default function InviteLookup() {
  const [error, setError] = useState<string>("");
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")?.replaceAll(" ", "+");

  useEffect(() => {
    if (!token || !email) {
      setError("The specified invite does not exist.");
    }
  }, []);

  return (
    <div className="container mx-auto page--student-data">
      <section className="student-profile--section">
        <div>
          <span className="student-profile--section-header">
            <h2 className="headline-9">
              Let's finish setting up your account.
            </h2>
          </span>
        </div>

        <div className="student-profile--section-item">
          {error || !email ? (
            <ErrorBlock status={PageStatus.ERROR} message={error} />
          ) : (
            <InviteTokenCredentials token={token as string} email={email} />
          )}
        </div>
      </section>
    </div>
  );
}

export function InviteTokenCredentials({
  token,
  email,
}: {
  token: string;
  email: string;
}) {
  const [invite, setInvite] = useState<InviteResponse>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<string>();

  async function getInvite() {
    await InvitesService.getInvite({ token, email })
      .then((invite) => {
        setInvite(invite);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);

        if (e.status === 409) {
          setError("This invite has already been accepted.");
        } else {
          setError("The specified invite does not exist.");
        }

        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    getInvite();
  }, []);

  return (
    <>
      <LoadingBlock status={status} />

      <ErrorBlock status={status} message={error} />

      {status === PageStatus.SUCCESS && invite ? (
        <>
          <h3>Welcome, {invite.invited_account.first_name}!</h3>
          <p className="">
            You've been invited to join <b>{invite.org_name}</b> as a{" "}
            <b>{invite.type}</b>. To accept this invite,{" "}
            {invite.a0_token ? "set" : "reset"} your password below.
          </p>

          {invite.a0_token ? (
            <ExternalLinkButton
              href={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/lo/reset?ticket=${invite.a0_token}`}
              size={ButtonSize.LARGE}
              color={ButtonColor.GREEN}
              extraClasses="mt-4"
            >
              {invite.a0_token ? "Set" : "Reset"} Password <ArrowRightIcon />
            </ExternalLinkButton>
          ) : null}

          {!invite.a0_token ? (
            <p className="mt-4 signup_text text_grey">
              By resetting your password, you accept this invitation to join{" "}
              <b>{invite.org_name}</b>. This acceptance will remove you from
              your current organization. While this will not remove any of your
              data from your current organization, you may lose access to
              certain information.
            </p>
          ) : null}
        </>
      ) : null}
    </>
  );
}
