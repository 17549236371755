import { ErrorBlock } from "components/StatusBlock";
import { Helmet } from "react-helmet";
import { PageStatus } from "types";

function Error404() {
  return (
    <div>
      <Helmet>
        <title>Error 404</title>
      </Helmet>

      <ErrorBlock
        status={PageStatus.ERROR}
        message="The resource you requested could not be found (404)."
      />
    </div>
  );
}

export default Error404;
