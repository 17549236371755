import { DialogClose } from "@radix-ui/react-dialog";
import { ButtonColor } from "components/Button";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";

function DiscardDialog({ continueFunction }: { continueFunction: () => void }) {
  return (
    <>
      <DialogContent className="dialog-content">
        <div className="invite-dialog">
          <h2 className="compose-msg-dialog--title">Discard all changes?</h2>
          <p>
            All the changes you have made will be discarded. Are you sure you
            want to continue?
          </p>
          <DialogActions>
            <DialogClose asChild>
              <DialogAction
                primary={true}
                color={ButtonColor.GREEN}
                onClick={continueFunction}
              >
                Yes
              </DialogAction>
            </DialogClose>
            <DialogClose asChild>
              <DialogAction color={ButtonColor.BLACK}>No</DialogAction>
            </DialogClose>
          </DialogActions>
        </div>
      </DialogContent>
    </>
  );
}

export default DiscardDialog;
