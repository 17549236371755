import {
  Auth0AccountRole,
  StudentResponse,
  TutorMeetingResponse,
} from "client/openapi";
import Avatar from "components/Avatar";
import { ReactNode } from "react";
import { Tutor, UserTypes } from "types";
import { concatenateName } from "util/concatenateName";
import { getUserColor } from "util/contextColor";
import { generateInitials } from "util/generateInitials";

export default function MeetingAttendee({
  attendee,
  userType,
  children,
  onClick,
  newView = false,
  append,
}: {
  attendee: StudentResponse | TutorMeetingResponse | Tutor;
  userType?: Auth0AccountRole;
  children: React.ReactNode;
  onClick?: () => void;
  newView?: boolean;
  append?: ReactNode;
}) {
  return (
    <div className="meeting-dialog--attendee">
      <div className={onClick && "cursor-pointer"} onClick={onClick}>
        <Avatar
          src={attendee.image_url}
          color={getUserColor(userType) ?? ""}
          alt={concatenateName(attendee)}
          fallback={generateInitials(concatenateName(attendee), 2)}
          append={append}
        />
      </div>

      <div
        className={`meeting-dialog${
          newView ? "-new" : ""
        }--attendee-details flex justify-between ${
          onClick && "cursor-pointer"
        }`}
      >
        <span
          className={children ? "col-span-5 break-words" : "col-span-12"}
          onClick={onClick}
        >
          {concatenateName(attendee)}
        </span>

        {children ? (
          <div
            className="meeting-dialog--attendee-actions col-span-1 max-w-[2rem]"
            onClick={(e) => e.preventDefault()}
          >
            {children}
          </div>
        ) : null}
      </div>
    </div>
  );
}
