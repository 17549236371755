import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment-timezone";
import {
  AvailabilitiesService,
  AvailabilityMessage,
  MeetingResponse,
  SessionCreate,
  TutorMeetingResponse,
  MeetingsService,
} from "client/openapi";
import { Cross1Icon } from "@radix-ui/react-icons";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";
import { useState, useEffect, ReactNode } from "react";
import { AttendeesList } from "components/MeetingAttendees";
import { UserTypes } from "types";
import {
  Auth0AccountRole,
  StudentResponse,
  StudentAttendanceResponse,
} from "client/openapi";
import {
  getDays,
  startOfDay,
  startOfNextDay,
  calculateEndTime,
  formatTimeToHMMA,
} from "util/time";
import { parseRecurrencePattern } from "util/parseRecurrencePattern";

const timezone = moment.tz.guess(true);

export function getBlockIndex(inputTime: string, duration: number): number {
  const date = moment.tz(inputTime, timezone);
  const hour = date.hours();
  const minute = date.minutes();
  const index = (hour * 60 + minute) / duration;
  return Math.floor(index);
}

export function getStartEndTime(
  inputTime: Date,
  duration: number,
  index: number
) {
  const startHour = Math.floor((index * 15) / 60);
  const startMinute = (index * 15) % 60;
  const startTime = moment(inputTime).tz(timezone);
  startTime.hours(startHour).minutes(startMinute).seconds(0).milliseconds(0);
  const endTime = moment(startTime).add(duration, "minutes");
  return { startTime: startTime.toDate(), endTime: endTime.toDate() };
}

interface CalendarEvent {
  title?: string;
  start: Date;
  end: Date;
  className: string;
  onClick?: () => void;
}

export default function AvailabilitySidebar({
  availabilitySidebarDate,
  event,
  tutors,
  role,
  editing,
  creating,
  removeAttendee,
  updateAttendees,
  currentMeetingInfo,
  isAvailabilitySidebarOpen,
  setIsAvailabilitySidebarOpen,
  attendance,
}: {
  availabilitySidebarDate: string;
  event: MeetingResponse | undefined;
  tutors: TutorMeetingResponse[];
  role: Auth0AccountRole;
  editing: boolean;
  creating: boolean;
  removeAttendee: (userType: Auth0AccountRole, id: number) => void;
  updateAttendees: (
    userType: Auth0AccountRole,
    attendees: TutorMeetingResponse[] | StudentResponse[]
  ) => void;
  currentMeetingInfo: SessionCreate;
  isAvailabilitySidebarOpen: boolean;
  setIsAvailabilitySidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attendance: StudentAttendanceResponse[];
}) {
  const localizer = momentLocalizer(moment);
  const duration = 15;
  const [selectedDate, setSelectedDate] = useState(
    new Date(availabilitySidebarDate)
  );
  const [tutorsBlock, setTutorsBlock] = useState<number[]>([]);
  const initialTutorsAvailabilities: AvailabilityMessage[][] = Array.from(
    { length: 96 },
    () => []
  );
  const [tutorsAvailabilities, setTutorsAvailabilities] = useState<
    AvailabilityMessage[][]
  >(initialTutorsAvailabilities);
  const initialAvailableTutors: number[][] = Array.from(
    { length: 96 },
    () => []
  );
  const [availableTutors, setAvailableTutors] = useState<number[][]>(
    initialAvailableTutors
  );
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [append, setAppend] = useState<ReactNode[]>([]);

  const fetchData = async () => {
    const tutor_ids = currentMeetingInfo.tutor_ids;
    const fetchedTutorsAvailabilities: AvailabilityMessage[][] =
      initialTutorsAvailabilities;
    const fetchedAvailableTutors: number[][] = initialAvailableTutors;

    for (const tutor_id of tutor_ids) {
      try {
        const tutors_availabilities =
          await AvailabilitiesService.getAvailabilitiesByTutor({
            tutorId: tutor_id,
            start: startOfDay(availabilitySidebarDate, timezone),
            until: startOfNextDay(availabilitySidebarDate, timezone),
            duration: duration,
          });

        tutors_availabilities.forEach((availability) => {
          const index = getBlockIndex(availability.start, 15);
          fetchedTutorsAvailabilities[index].push(availability);
          fetchedAvailableTutors[index].push(tutor_id);
        });
      } catch (error) {
        console.error(
          `${error}: Error fetching availability for tutor ${tutor_id}`
        );
      }
    }

    setTutorsAvailabilities(fetchedTutorsAvailabilities);
    setAvailableTutors(fetchedAvailableTutors);
  };

  useEffect(() => {
    setSelectedDate(new Date(availabilitySidebarDate));
    if (tutors.length !== 0) {
      fetchData();
    }
  }, [tutors, currentMeetingInfo, availabilitySidebarDate]);

  useEffect(() => {
    const updatedEvents: CalendarEvent[] = [];

    tutorsAvailabilities.forEach((day, index) => {
      const date = selectedDate;
      const { startTime, endTime } = getStartEndTime(date, duration, index);

      let className = "";
      if (day.length === tutors.length) {
        className = "event-green";
      } else if (day.length > 0) {
        className = "event-yellow";
      } else {
        className = "event-red";
      }
      className += " event-sheer cursor-default";

      updatedEvents.push({
        start: startTime,
        end: endTime,
        className: className,
      });
    });

    currentMeetingInfo.sessions.forEach((session) => {
      const startDate = new Date(session.start);
      const endDate = new Date(
        calculateEndTime(session.start, session.duration, timezone)
      );

      const startIndex = getBlockIndex(session.start, 15);
      const endIndex = getBlockIndex(endDate.toISOString(), 15) - 1;

      let intersection = availableTutors[startIndex];
      if (intersection !== undefined) {
        for (let i = startIndex + 1; i <= endIndex; i++) {
          const currentSet = availableTutors[i];
          intersection = intersection.filter((value) =>
            currentSet.includes(value)
          );
          if (intersection.length === 0) break;
        }

        let availability;
        if (intersection.length === 0) availability = "event-red";
        else if (intersection.length < tutors.length)
          availability = "event-yellow";
        else availability = "event-green";

        let title =
          formatTimeToHMMA(startDate) + " - " + formatTimeToHMMA(endDate);
        if (availability === "event-red") title += "\nNo tutor is available.";
        else if (availability === "event-yellow")
          title += "\nOnly " + intersection.length + " tutors are available.";
        else title += "\nEveryone is available.";

        setTutorsBlock(intersection);

        updatedEvents.push({
          title: title,
          start: startDate,
          end: endDate,
          className: availability + " cursor-pointer z-10 session-event",
          onClick: () => setTutorsBlock(intersection),
        });
      }
    });

    setCalendarEvents(updatedEvents);

    const append: ReactNode[] = [];
    tutors.forEach((tutor, index) => {
      const isAvailable = tutorsBlock.includes(tutor.id);

      if (isAvailable) {
        append.push(<GoCheckCircleFill color="var(--green)" key={index} />);
      } else {
        append.push(<GoXCircleFill color="var(--red)" key={index} />);
      }
    });
    setAppend(append);
  }, [tutorsAvailabilities, availableTutors]);

  const eventPropGetter = (event) => {
    return {
      className: event.className,
      onClick: event.onClick,
    };
  };

  return (
    <div className="bg-gray-100 rounded-lg col-span-12 md:col-span-3 m-0 availability-sidebar-wrapper">
      <div className="bg-white p-5 rounded-t-lg grid grid-cols-[1fr,auto] gap-5 items-center">
        <h1 className="font-bold text-2xl">Tutor Availability</h1>
        <Cross1Icon
          onClick={() => setIsAvailabilitySidebarOpen(false)}
          cursor="pointer"
        />
      </div>

      {tutors.length === 0 && isAvailabilitySidebarOpen ? (
        <div className="p-5">
          <div className="pb-2">
            <h2 className="font-bold mb-1">Add Tutors to See Availability</h2>
            <AttendeesList
              attendees={tutors ?? []}
              typeOfAttendees={Auth0AccountRole.ORG_TUTOR}
              editing={editing}
              creating={creating}
              event={event}
              linkPrefix={
                role === Auth0AccountRole.ORG_ADMIN
                  ? "/manage/tutors/"
                  : "/tutors/"
              }
              remove={removeAttendee}
              updateAttendees={updateAttendees}
              attendance={attendance}
            />
          </div>
        </div>
      ) : (
        <div className="overflow-y-scroll">
          <div className="flex items-center justify-center mt-3 font-bold text-lg">
            {moment(availabilitySidebarDate).format("MMM DD YYYY")}
          </div>
          <Calendar
            date={selectedDate}
            defaultDate={selectedDate}
            views={[Views.DAY]}
            defaultView={Views.DAY}
            localizer={localizer}
            formats={{
              timeGutterFormat: (date, culture) =>
                localizer.format(date, "h A", culture),
            }}
            toolbar={false}
            showMultiDayTimes={true}
            titleAccessor={"title"}
            eventPropGetter={eventPropGetter}
            events={calendarEvents}
            scrollToTime={selectedDate}
            className="pt-5"
            onNavigate
          />
        </div>
      )}

      <div className="flex flex-col xl:flex-row items-center gap-2">
        {tutors.length !== 0 && isAvailabilitySidebarOpen && (
          <div className="w-full p-5">
            <AttendeesList
              attendees={tutors ?? []}
              typeOfAttendees={Auth0AccountRole.ORG_TUTOR}
              editing={editing}
              creating={creating}
              event={event}
              linkPrefix={
                role === Auth0AccountRole.ORG_ADMIN
                  ? "/manage/tutors/"
                  : "/tutors/"
              }
              remove={removeAttendee}
              updateAttendees={updateAttendees}
              attendance={attendance}
              append={append}
            />
          </div>
        )}
      </div>
    </div>
  );
}
