/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ACHResponse } from "../models/ACHResponse";
import type { CreditCardResponse } from "../models/CreditCardResponse";
import type { InviteCreate } from "../models/InviteCreate";
import type { ParentResponse } from "../models/ParentResponse";
import type { PaymentPlanResponse } from "../models/PaymentPlanResponse";
import type { PaymentPlanUpdate } from "../models/PaymentPlanUpdate";
import type { ReInviteCreate } from "../models/ReInviteCreate";
import type { StudentCreate } from "../models/StudentCreate";
import type { StudentResponse } from "../models/StudentResponse";
import type { StudentSubjectResponse } from "../models/StudentSubjectResponse";
import type { StudentUpdate } from "../models/StudentUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class StudentsService {
  /**
   * Get Students Me
   * Get students for the currently authenticated user from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsMe(): CancelablePromise<Array<StudentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/me",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Me By Org
   * Get student for the currently authenticated user from the database for
   * a given org.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentMeByOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<StudentResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/me/by_org",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student
   * Get a student by its ID from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<StudentResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Student
   * Update a student by its ID using specified attributes
   * within StudentUpdate to update the database and return
   * the updated student.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static updateStudent({
    studentId,
    requestBody,
  }: {
    studentId: number;
    requestBody: StudentUpdate;
  }): CancelablePromise<StudentResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/students/{student_id}",
      path: {
        student_id: studentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Student
   * Delete a student. Used only by Tadpole Admins.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/students/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parents By Student
   * Get parents by student id.
   * @returns ParentResponse Successful Response
   * @throws ApiError
   */
  public static getParentsByStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<ParentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/studentsparents/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Admin
   * Get students by their organization id from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfAdmin({
    orgId,
    skip,
    limit = 100,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_admin/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Tutor
   * Get students by their organization id from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfTutor({
    orgId,
    skip,
    limit = 100,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_tutor/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Parent
   * Get students by their organization id from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfParent({
    orgId,
    skip,
    limit = 100,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_parent/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students By Organization If Student
   * Get students by their organization id from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsByOrganizationIfStudent({
    orgId,
    skip,
    limit = 100,
    phrase,
  }: {
    orgId: number;
    skip?: number;
    limit?: number;
    phrase?: string | null;
  }): CancelablePromise<Array<StudentResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/by_organization/user_is_student/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        skip: skip,
        limit: limit,
        phrase: phrase,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Cards
   * Get all cards belonging to a student.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getStudentCards({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<CreditCardResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/cards/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Ach Accounts
   * Get all banks belonging to a student.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getStudentAchAccounts({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/ach/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Student
   * Create a new student and returns the resulting student from the database.
   * @returns StudentResponse Successful Response
   * @throws ApiError
   */
  public static createStudent({
    requestBody,
  }: {
    requestBody: StudentCreate;
  }): CancelablePromise<StudentResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Invite Student To Org
   * Create a new account/student for an invited user and sends an invite email to sign up.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static inviteStudentToOrg({
    requestBody,
  }: {
    requestBody: InviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students/invite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reinvite Student
   * Reinvite a previously invited student.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static reinviteStudent({
    requestBody,
  }: {
    requestBody: ReInviteCreate;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students/invite/reinvite",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Student
   * Add a payment method onto a student.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/students/add_payment_method/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Total Revenue
   * Get the revenue generated from a student in a specific org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getStudentTotalRevenue({
    studentId,
    orgId,
    start,
    until,
  }: {
    studentId: number;
    orgId: number;
    start: string;
    until: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/student_revenue/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Total Cost
   * Get the cost to tutor a given student in a specific org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getStudentTotalCost({
    studentId,
    orgId,
    start,
    until,
  }: {
    studentId: number;
    orgId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/student_cost/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Set Custom Subject Price Rate
   * Set a custom pricing rate for a student on a given subject.
   * @returns StudentSubjectResponse Successful Response
   * @throws ApiError
   */
  public static setCustomSubjectPriceRate({
    studentId,
    orgSubjectId,
    customPriceRate,
  }: {
    studentId: any;
    orgSubjectId: number;
    customPriceRate: number;
  }): CancelablePromise<StudentSubjectResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/students/set_custom_subject_price_rate/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_subject_id: orgSubjectId,
        custom_price_rate: customPriceRate,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Payment Plan
   * Get a payment plan from the database.
   * @returns PaymentPlanResponse Successful Response
   * @throws ApiError
   */
  public static getPaymentPlan({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<PaymentPlanResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/students/{student_id}/payment_plan",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Payment Plan
   * Update a payment plan.
   * @returns PaymentPlanResponse Successful Response
   * @throws ApiError
   */
  public static updatePaymentPlan({
    studentId,
    requestBody,
  }: {
    studentId: number;
    requestBody: PaymentPlanUpdate;
  }): CancelablePromise<PaymentPlanResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/students/{student_id}/payment_plan",
      path: {
        student_id: studentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Registered Payment Method
   * This can be used to remove a registered card from a student OR an account.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static removeRegisteredPaymentMethod({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/students/payment_methods/{payment_method_id}",
      path: {
        payment_method_id: paymentMethodId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
