import { DialogContent } from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { APIResponse, PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import moment from "moment";
import { billingType } from "../../../Transactions";

import {
  ACHResponse,
  ChargesService,
  CreditCardResponse,
  PaymentMethodType,
  StudentOrgTransactionResponse,
  StudentResponse,
  StudentsService,
} from "client/openapi";
import ErrorDialog from "components/ErrorDialog";
import Cards from "../../../../../../../../components/PaymentMethods/cards";
import Banks from "../../../../../../../../components/PaymentMethods/bank";

export default function RefundModal({
  transaction,
  student,
  orgId,
}: {
  transaction: StudentOrgTransactionResponse;
  student: StudentResponse;
  orgId: number;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [error, setError] = useState<APIResponse>();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<
    CreditCardResponse | ACHResponse
  >();
  const [paymentEmail, setPaymentEmail] = useState<string>();

  type AllPaymentMethods = CreditCardResponse | ACHResponse;
  const [paymentMethodsOnFile, setPaymentMethodsOnFile] = useState<
    AllPaymentMethods[]
  >([]);

  const [
    makeSureUserDoesntSpamChargeButton,
    setMakeSureUserDoesntSpamChargeButton,
  ] = useState<boolean>(false);

  const handleRefund = () => {
    ChargesService.createRefund({
      transactionId: transaction.transaction_id?.toString() as string,
    })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });

    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const getAndSetPaymentMethodsOnFile = useCallback(async () => {
    let newMethods: AllPaymentMethods[] = [];

    try {
      const existing_cards = await StudentsService.getStudentCards({
        studentId: student.id,
      });
      if (existing_cards) {
        newMethods = [...newMethods, ...existing_cards];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    try {
      const existing_banks = await StudentsService.getStudentAchAccounts({
        studentId: student.id,
      });
      if (existing_banks) {
        newMethods = [...newMethods, ...existing_banks];
      }
    } catch (error: any) {
      setError(error);
      setStatus(PageStatus.ERROR);
      console.error("Error:", error);
    }

    setPaymentMethodsOnFile(newMethods);

    if (transaction.payment_method_type === PaymentMethodType.INVOICE) {
      setPaymentEmail(transaction.invoice_email as string);
    } else {
      setCurrentPaymentMethod(
        Array.from(newMethods).find(
          (paymentMethod) =>
            paymentMethod.payment_method_id === transaction.payment_method_id
        )
      );
    }
  }, [
    orgId,
    student.id,
    transaction.invoice_email,
    transaction.payment_method_id,
    transaction.payment_method_type,
  ]);

  useEffect(() => {
    getAndSetPaymentMethodsOnFile();
  }, [getAndSetPaymentMethodsOnFile]);

  const reset = () => {
    getAndSetPaymentMethodsOnFile();
  };

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Attempting refund. Reload the page to see your changes."
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog
        message="A Refund cannot be processed at this time"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  return (
    <DialogContent className="dialog-content max-w-[800px]" onClose={reset}>
      <div className="mt-3 font-semibold text-center header text-lg">
        You are about to refund this transaction
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">Date</p>
        <p className="header text-sm">
          {moment(transaction.timestamp).format("MM/DD/YYYY")}
        </p>
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">
          Amount
        </p>
        <p className="header text-sm">
          ${(transaction.amount / 100).toFixed(2)}
        </p>
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">
          Billing Type
        </p>
        <p className="header text-sm">
          {billingType.find((t) => t.value === transaction.billing_type)?.label}
        </p>
      </div>

      <div className="mt-4">
        <p className="underline text-gray-300 header text-lg font-bold">
          Service
        </p>
        <p className="header text-sm">{transaction.reason}</p>
      </div>

      <div className="mt-4 border border-gray-300 border-2 rounded-lg p-4">
        <p className="mb-2 text-sm font-bold">Refunding to:</p>
        <div>
          {currentPaymentMethod || paymentEmail ? (
            <div>
              {currentPaymentMethod && "brand" in currentPaymentMethod ? (
                <Cards
                  key={currentPaymentMethod.payment_method_id}
                  card={currentPaymentMethod as CreditCardResponse}
                />
              ) : currentPaymentMethod &&
                "bank_name" in currentPaymentMethod ? (
                <Banks
                  key={currentPaymentMethod.payment_method_id}
                  bank={currentPaymentMethod as ACHResponse}
                />
              ) : (
                <span className="text-sm font-bold">{paymentEmail}</span>
              )}
            </div>
          ) : (
            <div>
              <span className="font-semibold text-sm">
                Payment method cannot be found. Perhaps it was deleted?
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="w-full text-center mt-6">
        <Button
          color={
            makeSureUserDoesntSpamChargeButton || transaction.refunded
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          disabled={makeSureUserDoesntSpamChargeButton || transaction.refunded}
          size={ButtonSize.SMALL}
          onClick={() => {
            handleRefund();
            setMakeSureUserDoesntSpamChargeButton(true);
          }}
        >
          Refund
        </Button>
      </div>
    </DialogContent>
  );
}
