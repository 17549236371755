import ParentContactInfo from "./ParentContactInfo";
import {
  ParentResponse,
  StudentContactResponse,
  StudentResponse,
} from "client/openapi";
import { APIResponse, PageStatus } from "types";
import Parents from "./Parents";

export default function ParentInfoTab({
  parent_contact,
  studentContacts,
  setStudentContacts,
  setStatus,
  setError,
  student,
  orgParents,
  studentParents,
  setStudentParents,
}: {
  parent_contact: StudentContactResponse;
  studentContacts: StudentContactResponse[];
  setStudentContacts: (contacts: StudentContactResponse[]) => void;
  setStatus: (status: PageStatus) => void;
  setError: (error: APIResponse) => void;
  student: StudentResponse;
  orgParents: ParentResponse[];
  studentParents: ParentResponse[];
  setStudentParents: (students: ParentResponse[]) => void;
}) {
  return (
    <div className="grid grid-cols-1 gap-y-1 md:grid-cols-2">
      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <Parents
          student={student}
          orgParents={orgParents}
          studentParents={studentParents}
          setStudentParents={setStudentParents}
        />
      </div>
      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <ParentContactInfo
          contact={parent_contact}
          studentContacts={studentContacts}
          setStudentContacts={setStudentContacts}
          setStatus={setStatus}
          setError={setError}
        />
      </div>
    </div>
  );
}
