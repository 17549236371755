import {
  CheckCircledIcon,
  ChevronDownIcon,
  Cross2Icon,
} from "@radix-ui/react-icons";
import { clsx } from "clsx";
import ReactSelect, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
  components,
} from "react-select";

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Cross2Icon />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Cross2Icon />
    </components.MultiValueRemove>
  );
};

const Option = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row items-center gap-2">
        {props.children}
        {props.isSelected && <CheckCircledIcon className="text-purple" />}
      </div>
    </components.Option>
  );
};

const controlStyles = {
  base: "border rounded-md bg-white shadow-sm hover:cursor-pointer",
  focus: "border-indigo-300 ring ring-indigo-200 ring-opacity-50",
  nonFocus: "border-gray-300 hover:border-gray-400",
  disabled: "bg-neutral-100 cursor-not-allowed",
};
const placeholderStyles = "text-gray-400 pl-2 py-0.5";
const selectInputStyles = "px-2 py-0.5";
const valueContainerStyles = "gap-1";

const singleValueStyles = "leading-7 ml-2.5";
const multiValueStyles =
  "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6";
const multiValueRemoveStyles =
  "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md p-0.5";

const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const indicatorSeparatorStyles = "bg-gray-300";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";

const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected: "text-gray-500",
  disabled: "cursor-not-allowed",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

const Select = (props) => (
  <ReactSelect
    closeMenuOnSelect={!props.isMulti}
    hideSelectedOptions={false}
    unstyled
    styles={{
      input: (base) => ({
        ...base,
        "input:focus": {
          boxShadow: "none",
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (base) => ({
        ...base,
        whiteSpace: "normal",
        overflow: "visible",
      }),
      control: (base, state) => ({
        ...base,
        transition: "none",
        backgroundColor: state.isDisabled ? "#f0f0f0" : base.backgroundColor,
        borderColor: state.isDisabled ? "#ccc" : base.borderColor,
        color: state.isDisabled ? "#ccc" : base.color,
      }),
    }}
    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove, Option }}
    classNames={{
      control: ({ isFocused, isDisabled }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          isDisabled && controlStyles.disabled,
          controlStyles.base
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: () => valueContainerStyles,
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isDisabled, isFocused, isSelected }) =>
        clsx(
          isDisabled && optionStyles.disabled,
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
    }}
    {...props}
  />
);

export default Select;
