import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FileTextIcon,
} from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  MeetingResponse,
  MeetingsService,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import CalendarWrapper from "components/Dashboard/Calendar";
import { ErrorBlock } from "components/StatusBlock";
import moment from "moment";
import { ReactNode, useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import "./index.css";
import TutoringHours from "../TutoringHours";
import { concatenateName } from "util/concatenateName";
import { concatenateTutorSubject } from "util/concatenateSubject";
import { Dialog, DialogTrigger } from "components/Dialog";
import ExportDialog from "../ExportDialog";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

function UpcomingMeetings({
  children,
  studentFilters,
  tutorFilters,
  subjectFilters,
  addedStart,
}: {
  children?: ReactNode;
  studentFilters?: number[];
  tutorFilters?: number[];
  subjectFilters?: number[];
  addedStart?: Date;
}) {
  // set up state
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [calendarView, setCalendarView] = useState<boolean>(true);
  const [weekStart, setWeekStart] = useState<Date>(
    moment(addedStart).startOf("week").toDate()
  );
  const [meetings, setMeetings] = useState<MeetingResponse[]>([]);
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  const { account, currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  // fetch the current account's meetings
  async function fetchMeetings() {
    setStatus(PageStatus.LOADING);

    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      await MeetingsService.getMeetingsByOrg({
        orgId: currently_selected_organization as number,
        start: moment(weekStart).toISOString(),
        until: moment(weekStart)
          .add(1, mobileView && calendarView ? "day" : "week")
          .toISOString(),
        limit: 1000,
      })
        .then((allMeetings) => {
          const filteredMeetings = allMeetings.filter((meeting) => {
            return (
              (meeting.subject.id === subjectFilters?.[0] ||
                !subjectFilters?.length) &&
              meeting.tutors
                .map((tutor) => tutor.id)
                .some(
                  (tutorId) =>
                    tutorFilters?.includes(tutorId) || !tutorFilters?.length
                ) &&
              meeting.students
                .map((student) => student.id)
                .some(
                  (studentId) =>
                    studentFilters?.includes(studentId) ||
                    !studentFilters?.length
                )
            );
          });
          setStatus(PageStatus.SUCCESS);
          setMeetings(
            filteredMeetings.filter((mtg) => mtg.status !== "Cancelled")
          );
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
      await MeetingsService.getMeetingsForAllTutorsForAccount({
        start: moment(weekStart).toISOString(),
        until: moment(weekStart)
          .add(1, mobileView ? "day" : "week")
          .toISOString(),
      })
        .then((allMeetings) => {
          setStatus(PageStatus.SUCCESS);
          setMeetings(allMeetings.filter((mtg) => mtg.status !== "Cancelled"));
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (currently_selected_role === Auth0AccountRole.ME) {
      await MeetingsService.getMeetingsForAllStudentsForAccount({
        start: moment(weekStart).toISOString(),
        until: moment(weekStart)
          .add(1, mobileView ? "day" : "week")
          .toISOString(),
      })
        .then((allMeetings) => {
          setStatus(PageStatus.SUCCESS);
          setMeetings(allMeetings.filter((mtg) => mtg.status !== "Cancelled"));
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (currently_selected_role === Auth0AccountRole.PARENT) {
      await MeetingsService.getMeetingsForAllParentsForAccount({
        start: moment(weekStart).toISOString(),
        until: moment(weekStart)
          .add(1, mobileView ? "day" : "week")
          .toISOString(),
      })
        .then((allMeetings) => {
          setStatus(PageStatus.SUCCESS);
          setMeetings(allMeetings.filter((mtg) => mtg.status !== "Cancelled"));
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ error: "An unexpected error occurred" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }
  }

  useEffect(() => {
    if (account && currently_selected_role) {
      fetchMeetings();
    }
  }, [
    weekStart,
    account,
    studentFilters,
    tutorFilters,
    subjectFilters,
    currently_selected_role,
  ]);

  useEffect(() => {
    if (addedStart) {
      setWeekStart(moment(addedStart).startOf("week").toDate());
    }
  }, [addedStart]);

  let onWindowResize = () => {
    setMobileView(window.innerWidth < 1024);
  };

  useEffect(() => {
    onWindowResize();
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  return (
    <div className="py-6 px-7 rounded-xl bg-neutral-50">
      <div className="grid grid-cols-8 pb-4 border-b-2">
        <div className="col-span-8 md:col-span-5">{children}</div>
        <div className="flex flex-row items-center gap-2 col-span-8 md:col-span-3 justify-end mt-4 md:mt-0">
          {mobileView && calendarView ? (
            <span className="font-bold ml-2">
              {moment(weekStart).format("MMMM Do")}
            </span>
          ) : (
            <span className="font-bold ml-2">{`Week of ${moment(
              weekStart
            ).format("MMMM Do")}`}</span>
          )}
          <div className="flex flex-row items-center gap-2">
            <Button
              size={ButtonSize.SMALL}
              fill={ButtonFill.NO_BORDER}
              onClick={() =>
                setWeekStart(
                  moment(weekStart)
                    .subtract(1, mobileView && calendarView ? "day" : "week")
                    .toDate()
                )
              }
              extraClasses="p-0"
            >
              <ChevronLeftIcon width="20" height="20" />
            </Button>

            <Button
              size={ButtonSize.SMALL}
              fill={ButtonFill.NO_BORDER}
              onClick={() =>
                setWeekStart(
                  moment(weekStart)
                    .add(1, mobileView && calendarView ? "day" : "week")
                    .toDate()
                )
              }
              extraClasses="p-0"
            >
              <ChevronRightIcon width="20" height="20" />
            </Button>

            <Button
              size={ButtonSize.SMALL}
              fill={calendarView ? ButtonFill.HOLLOW : ButtonFill.NO_BORDER}
              color={calendarView ? ButtonColor.GREEN : ButtonColor.BLACK}
              onClick={() => {
                setCalendarView(true);
              }}
              extraClasses={`ml-4 border-2 ${
                calendarView ? "p-1" : "p-1.5"
              } !text-black`}
            >
              <CalendarIcon width="25" height="25" />
            </Button>

            <Button
              size={ButtonSize.SMALL}
              fill={!calendarView ? ButtonFill.HOLLOW : ButtonFill.NO_BORDER}
              color={!calendarView ? ButtonColor.GREEN : ButtonColor.BLACK}
              disabled={
                currently_selected_role === Auth0AccountRole.ME ||
                currently_selected_role === Auth0AccountRole.PARENT
              }
              onClick={() => {
                setCalendarView(false);
              }}
              extraClasses={`ml-2 border-2 ${
                !calendarView ? "p-1" : "p-1.5"
              } !text-black`}
            >
              <FileTextIcon width="25" height="25" />
            </Button>
          </div>
        </div>
      </div>

      {status !== PageStatus.ERROR ? (
        <>
          <div
            className={`mt-3 max-h-[75vh] overflow-y-scroll ${
              !calendarView && "border-2 border-gray-300 rounded-lg"
            }`}
          >
            {calendarView && currently_selected_role ? (
              <CalendarWrapper
                role={currently_selected_role}
                weekStart={weekStart}
                events={meetings}
                setEvents={fetchMeetings}
                days={7}
                status={status}
                mobileView={mobileView}
              />
            ) : (
              <TutoringHours
                weekStart={weekStart}
                events={meetings}
                days={7}
                status={status}
                mobileView={mobileView}
              />
            )}
          </div>
          <div className="w-full text-right mt-3">
            {currently_selected_role === Auth0AccountRole.ORG_ADMIN && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button color={ButtonColor.GREEN}>Export</Button>
                </DialogTrigger>

                <ExportDialog
                  organizationId={currently_selected_organization as number}
                />
              </Dialog>
            )}
          </div>
        </>
      ) : (
        <ErrorBlock status={status} title="Oh no!" message={error?.message} />
      )}
    </div>
  );
}

export default UpcomingMeetings;
