import "./index.css";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import moment from "moment";
import { FaClock } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa6";
import {
  MeetingResponse,
  SessionApprovalResponse,
  TutorResponse,
} from "client/openapi";

export const billingType = [
  { value: "PER_MEETING", label: "Per session charge" },
  { value: "UPFRONT", label: "Upfront charge" },
  { value: "DIRECT", label: "Direct charge" },
];

function sumNumbers(numbers) {
  let sum = 0;
  numbers.forEach((number) => {
    sum += number;
  });
  return sum;
}

function Transactions({
  transactions,
  approvals,
  tutor,
  orgId,
}: {
  transactions: MeetingResponse[];
  approvals: any;
  tutor: TutorResponse;
  orgId: number;
}) {
  // Function to retrieve a specific approval status
  const getMeetingApproval = (sessionId, meetingTime) => {
    const sessionData = approvals[sessionId];
    if (sessionData && sessionData[meetingTime] !== undefined) {
      return sessionData[meetingTime];
    } else {
      return undefined;
    }
  };

  return (
    <div className="transactions-container">
      <table className="table">
        <thead className="tableHead border-2 border-b-0 border-gray-300">
          <tr>
            <th
              style={{ width: "27.5%", paddingLeft: "20px" }}
              className="text-xs md:text-sm lg:text-base p-2"
            >
              Date
            </th>
            <th
              style={{ width: "19.0%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              {" "}
              Revenue
            </th>
            <th
              style={{ width: "21.5%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Compensation
            </th>
            <th
              style={{ width: "19.0%" }}
              className="text-xs md:text-sm lg:text-base"
            >
              Service
            </th>
            <th
              style={{ width: "14.5%" }}
              className="centeredTableData text-xs md:text-sm lg:text-base"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => {
            return (
              <tr
                key={index}
                className={`tableRow border-2 border-gray-300 ${
                  index === transactions.length - 1 && "!border-b-2 rounded-b"
                } ${index === 0 && "border-t-0"}`}
              >
                <td
                  className="text-xs md:text-sm "
                  style={{ paddingLeft: "20px" }}
                >
                  {moment(transaction.start).format("MM/DD/YYYY")} at{" "}
                  {moment(transaction.start).format("hh:mm A")}
                </td>
                <td className="text-xs md:text-sm ">
                  $
                  {parseFloat(
                    transaction.student_price_rates
                      ? (
                          ((sumNumbers(
                            Object.values(transaction.student_price_rates)
                          ) /
                            100) *
                            (transaction.duration / 60)) /
                          transaction.tutors.length
                        ).toString()
                      : "0"
                  ).toFixed(2)}
                </td>
                <td className="text-xs md:text-sm">
                  $
                  {parseFloat(
                    transaction.tutor_pay_rates
                      ? (
                          (transaction.tutor_pay_rates[tutor.id] / 100) *
                          (transaction.duration / 60)
                        ).toString()
                      : "0"
                  ).toFixed(2)}
                </td>
                <td className="text-xs md:text-sm ">
                  {transaction.name
                    ? transaction.name
                    : transaction.subject.org_subject.name}
                </td>
                <td>
                  {getMeetingApproval(
                    transaction.session_id,
                    moment.utc(transaction.start).format()
                  ) === true ? (
                    <IoCheckmarkCircle className="mx-auto green-mark" />
                  ) : getMeetingApproval(
                      transaction.session_id,
                      moment.utc(transaction.start).format()
                    ) === false ? (
                    <IoCloseCircle className="mx-auto red-mark" />
                  ) : (
                    <FaRegCircle className="mx-auto" />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Transactions;
