import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "api";
import { Profile } from "components/Profile";
import { LoadingBlock, ErrorBlock } from "components/StatusBlock";
import { PageStatus, TestScore } from "types";
import { stringifyRateRange } from "util/stringifyRateRange";
import { generateInitials } from "util/generateInitials";
import { Link } from "react-router-dom";
import {
  ApiError,
  OrganizationResponse,
  OrganizationsService,
  TutorTestScoreResponse,
  TutorTestScoresService,
} from "client/openapi";

function OrgProfile() {
  // obtain search parameters
  const params = useParams();
  const organizationId = params.id;

  const [organization, setOrganization] = useState<OrganizationResponse>();
  const [testScores, setTestScores] = useState<TutorTestScoreResponse[]>();

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const orgId = Number.parseInt(organizationId);

    OrganizationsService.getOrganization({ orgId })
      .then((org) => {
        setOrganization(org);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError("Unable to fetch organization details.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    TutorTestScoresService.getScoresByOrg({ orgId })
      .then((scores) => {
        setTestScores(scores);
        setStatus(PageStatus.SUCCESS);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError("Unable to fetch test scores.");
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }, []);

  useEffect(() => {
    if (status === PageStatus.ERROR) {
      setStatus(PageStatus.ERROR);
      setError("Unable to fetch organization details.");
    } else {
      setStatus(status);
    }
  }, [organization]);

  return (
    <div>
      <LoadingBlock status={status} />

      <ErrorBlock status={status} message={error} />

      {organization &&
        error === "This organization exists for an independent tutor." && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "4rem",
            }}
          >
            <Link
              to={`/tutors/${organization.tutors[0].id}`}
              className="button button--large button--orange"
            >
              View Tutor Profile &rarr;
            </Link>
          </div>
        )}

      {status === PageStatus.SUCCESS && organization && (
        <Profile
          pageTitle={`${organization.name}`}
          greeting={`Hi There! We're ${organization.name}.`}
          bio={organization.bio}
          imageUrl={organization.image_url || ""}
          initials={generateInitials(organization.name, 4)}
          waveType={organization.wave_type}
          subjects={organization.subjects.filter((s) => s.active)}
          rate={stringifyRateRange(organization.rate_range as [number, number])}
          testScores={testScores}
          reviews={organization.reviews}
          tutors={organization.tutors}
        />
      )}
    </div>
  );
}

export default OrgProfile;
